import React, { useRef, useState, useEffect } from "react";
import { Button, } from "reactstrap";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import happyimg from "../mysy_img/happy.png";
import unhappyimg from "../mysy_img/nothappy.png";
import middleimg from "../mysy_img/middle.png";
import parse from 'html-react-parser'
import img_met_autre from "../mysy_img/met_autre.jpg";
import img_met_digital from "../mysy_img/met_digital.jpg";
import { Rating } from 'react-simple-star-rating'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import moment from "moment";
import SendIcon from '@mui/icons-material/Send';
import axios from "axios";
import { Editor } from '@tinymce/tinymce-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import { FcInfo } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { getCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import { FcPlus } from "react-icons/fc";
import { MdDeleteForever } from "react-icons/md";

import { AddCircleOutline } from "@mui/icons-material";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Article = (props) => {
  const history = useHistory();
  const [isLoading, setLoading] = useState();

  const [rating, setRating] = useState(props.article.note * 20)
  //console.log(props.article.note * 10);
  // Catch Rating value

  function handleRating(rate) {
    alert(" rate =" + rate);
    setRating(40);
  }

  const [country_code, setcountry_code] = useState("FR");
  const [country_name, setcountry_name] = useState("France");
  const [city, setcity] = useState("Paris");
  const [postal, setpostal] = useState("75001");
  const [latitude, setlatitude] = useState("48.861000061035156");
  const [longitude, setlongitude] = useState("2.3380000591278076");
  const [IPv4, setIPv4] = useState("172.71.122.128");
  const [state, setstate] = useState("France");
  const [userIp, setuserIp] = useState("172.71.122.128");

  const [title_resized, settitle_resized] = useState("")

  const [article_img, setarticle_img] = useState("")

  const [edit_article, setedit_article] = useState("")

  const [selected_id, setselected_id] = useState(props.article._id)


  const editorRef_texte_avis = useRef(null);
  const [editorRef_texte_avis_limite, seteditorRef_texte_avis] = useState(1000);
  const [field_texte_avis, setfield_texte_avis] = useState("");
  const [field_texte_avis_initial_value, setfield_texte_avis_initial_value] = useState("");

  const [is_data_changed, setis_data_changed] = useState("");

  const [editor_tochanged, seteditor_tochanged] = useState("");

  function editor_keyup() {
    setis_data_changed("1");
  }



  const [Get_Partner_Article_Avis_Data_api, setGet_Partner_Article_Avis_Data_api] = useState();
  const [Get_Partner_Article_Avis_Data_message, setGet_Partner_Article_Avis_Data_message] = useState();
  const [Get_Partner_Article_Avis_Data_result, setGet_Partner_Article_Avis_Data_result] = useState();
  function Get_Partner_Article_Avis_Data() {

    var form = new FormData();
    form.append("_id", props.article._id);

    //console.log(" ## form = ", form);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_articles_avis_from_id/";
    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      if (String(res.data.status) === "true") {
        //console.log(" In Get_Partner_Article_Avis_Data  res.data.status = " + res.data.status);
        //console.log(" In Get_Partner_Article_Avis_Data  res.data.message r_class = " + res.data.message);
        setGet_Partner_Article_Avis_Data_api("true");
        setGet_Partner_Article_Avis_Data_result(res.data.message);

        if (res.data.message.length > 0) {
          var mylocal_document = JSON.parse(res.data.message);

          if (mylocal_document.date_publication) {
            setarticle_avis_date_publication(moment((String(mylocal_document.date_publication)).substring(0, 10)).format('YYYY-MM-DD'));

            //console.log(" ### mylocal_document.date_publication = ", mylocal_document.date_publication);
          }

          if (mylocal_document.avis) {
            setarticle_avis_avis(mylocal_document.avis);
            setfield_texte_avis_initial_value(mylocal_document.avis);
            setfield_texte_avis(mylocal_document.avis);
          }
          else {
            setarticle_avis_avis("");
            setfield_texte_avis_initial_value("");
          }


          if (mylocal_document.internal_url)
            setarticle_avis_internal_url(mylocal_document.internal_url);

          setarticle_avis_display_header_img(false);
          if (mylocal_document.display_header_img && String(mylocal_document.display_header_img) === "1") {
            setarticle_avis_display_header_img(true);
          }



          if (mylocal_document.img_path) {
            setarticle_avis_img_path(mylocal_document.img_path);
          }


        } else {
          alert(" Aucun article recuperé");
        }


      } else if (String(res.data.status) === String("Err_Connexion")) {
        alert('Erreur: ' + res.data.message);
        history.push("/Connexion");
      }

      else {
        setGet_Partner_Article_Avis_Data_api("false");
        setGet_Partner_Article_Avis_Data_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('Not good man :( Get_Partner_Article_Avis_Data = ', error);
      setGet_Partner_Article_Avis_Data_api("false");
      alert(" Impossible de recuperer les données de l'article");
      //setmyApimyApiMessage("")
    })
  }


  useEffect(() => {



    var local_title = "";
    if (props.article.title_formation && String(props.article.title_formation).length > 45) {
      local_title = String(props.article.title_formation).substring(0, 43) + " ...";

    } else {
      local_title = String(props.article.title_formation);

    }
    settitle_resized(local_title);



    var img_article = "";
    if (props.article.img_path && String(props.article.img_path).length > 10) {
      img_article = String(props.article.img_path)
      setarticle_img(img_article);

    } else {
      setarticle_img(img_met_digital);
    }


    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  }, []);


  const [Dialog_1_avis, setDialog_1_avis] = React.useState(false);
  const [Dialog_1_open, setDialog_1_open] = React.useState(false);
  function Dialog_1_handle_change_participant_session(avis) {
    setDialog_1_avis(avis);
    setDialog_1_open(true);
  }

  const Dialog_1_handleClose = () => {
    //alert(" Utiliser le bouton 'fermer' ");
    //setOpen(false);
  };

  const Dialog_1_handleClose_buton = () => {
    setDialog_1_open(false);
  };


  const [Dialog_article_avis_avis, setDialog_article_avis_avis] = React.useState(false);
  const [Dialog_article_avis_open, setDialog_article_avis_open] = React.useState(false);
  function Dialog_article_avis_handle_change_participant_session(avis) {
    setDialog_article_avis_avis(avis);
    setDialog_article_avis_open(true);
  }

  const Dialog_article_avis_handleClose = () => {

  };

  const Dialog_article_avis_handleClose_buton = () => {

    setDialog_article_avis_open(false);
    clear_article_avis_fields();

  };

  function clear_article_avis_fields() {
    setarticle_avis_title_formation("");
    setarticle_avis_email("");
    setarticle_avis_url_formation("");
    setarticle_avis_sujet("");
    setarticle_avis_avis("");
    setis_data_changed("");
  }

  const [article_avis_date_publication, setarticle_avis_date_publication] = useState(new Date().toLocaleDateString('fr-FR'));
  const [article_avis_title_formation, setarticle_avis_title_formation] = React.useState("");
  const [article_avis_email, setarticle_avis_email] = React.useState("");
  const [article_avis_url_formation, setarticle_avis_url_formation] = React.useState("");
  const [article_avis_sujet, setarticle_avis_sujet] = React.useState("");
  const [article_avis_avis, setarticle_avis_avis] = React.useState("");
  const [article_avis_internal_url, setarticle_avis_internal_url] = React.useState("");
  const [article_avis_display_header_img, setarticle_avis_display_header_img] = React.useState(false);
  const [article_avis_img_path, setarticle_avis_img_path] = React.useState("");

  function Add_Update_Article_Avis() {

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("title_formation", article_avis_title_formation);
    form.append("avis", field_texte_avis);
    form.append("qualite", "");
    form.append("note", "");

    if (!article_avis_img_path || String(article_avis_img_path).trim() === "") {
      alert(" Vous devez saisir une image à afficher avec l'article ");
      return;
    }
    form.append("img_path", article_avis_img_path);

    if (article_avis_date_publication.trim() === "") {
      alert(" Vous devez saisir la date de naissance de l'apprenant");
      return;
    }

    var formated_date_tmp = new Date(moment(article_avis_date_publication, "YYYY-MM-DD"));
    var formated_date_final = moment(formated_date_tmp).format('DD/MM/YYYY');

    form.append("date_publication", formated_date_final);


    if (article_avis_display_header_img) {
      form.append("display_header_img", "1");
    } else {
      form.append("display_header_img", "0");
    }

    form.append("user_ip", IPv4);
    form.append("user_country_code", country_code);
    form.append("user_country_name", country_name);
    form.append("user_city", city);
    form.append("user_postal", postal);
    form.append("user_latitude", latitude);
    form.append("user_longitude", longitude);
    form.append("user_state", state);


    //console.log(" ## form = ", form);
    var myurl = "";

    if (selected_id && String(selected_id).length > 3) {
      myurl = process.env.REACT_APP_API_URL + "myclass/api/update_articles_avis/";
      form.append("_id", selected_id);
    } else {
      myurl = process.env.REACT_APP_API_URL + "myclass/api/add_articles_avis/";
    }


    axios.post(myurl, form).then(res => {

      if (String(res.data.status) === String("false")) {
        alert(res.data.message);
      }

      else if (String(res.data.status) === String("true")) {
        alert(res.data.message);
        Dialog_article_avis_handleClose_buton();
        window.location.reload();

      }
      else {

      }
    }).catch((error) => {
      console.warn('contact-nous  Not good man :( mysearchtext = ');


    })
  }


  const [Delete_Article_Avis_Data_api, setDelete_Article_Avis_Data_api] = useState();
  const [Delete_Article_Avis_Data_message, setDelete_Article_Avis_Data_message] = useState();
  const [Delete_Article_Avis_Data_result, setDelete_Article_Avis_Data_result] = useState();
  function Delete_Article_Avis_Data(article_id) {

    var form = new FormData();
    //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("_id", article_id);


    var myurl = process.env.REACT_APP_API_URL + "myclass/api/delete_articles_avis_from_id/";
    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      //console.log(" In Delete_Article_Avis_Data  res.data.status = " + res.data.status);
      //console.log(" In Delete_Article_Avis_Data  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        alert(res.data.message);
        window.location.reload();
      }
      else {
        setDelete_Article_Avis_Data_api("false");
        setDelete_Article_Avis_Data_message(res.data.message);
        alert(res.data.message);

      }

    }).catch((error) => {
      setLoading(false);
      console.warn('Delete_Article_Avis_Data : Not good man :( mysearchtext = ' + error);
      setDelete_Article_Avis_Data_api("false");
      alert(" Impossible de supprimer le document");
    })
  }



  return (

    <div className="article">



      {/***   GESTION article */}
      <Dialog
        open={Dialog_article_avis_open}
        onClose={Dialog_article_avis_handleClose}
        className="displaypartnersession"
      >

        <DialogTitle>Votre article </DialogTitle>
        <DialogContent className="DialogContent_width DialogContent_width_session_800px" >


          {String(is_data_changed) === String("1") && <div className="div_row_dialog">
            <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div>
          </div>}

          <div className="div_row_dialog">
            <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
              <b> Titre </b>
            </div>
            <div className="div_row_dialog" >
              <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                <input
                  type='text'
                  name='name'
                  value={article_avis_title_formation}
                  onChange={(e) => {
                    setarticle_avis_title_formation(e.target.value);
                    setis_data_changed("1");
                  }}
                  className='disabled_style_width_100_percent'

                ></input>
              </div>
            </div>
          </div>



          <div className="div_row_dialog">
            <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', paddingTop: '15px' }}>
              <b>   Date publication </b>
            </div>


            <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
              <DatePicker
                name="date_publication"
                id="date_publication"
                selected={new Date(moment(article_avis_date_publication, "YYYY-MM-DD"))}
                onChange={(date) => {
                  setarticle_avis_date_publication(format(date, 'yyyy-MM-dd'));
                  setis_data_changed("1");
                }
                }
                showTimeSelect={false}
                dateFormat="dd/MM/yyyy"
                className="disabled_style session_caract_Dialog_DatePicker"
              />
            </div>
          </div>

          <div className="div_row_dialog">
            <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', paddingTop: '15px' }}>
              <b>   Affiche image </b>
            </div>
            <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
              <Checkbox
                name="document_join_pdf"
                 className="disabled_style session_caract_Dialog_DatePicker"
                checked={article_avis_display_header_img}
                onChange={(e) => {
                  setarticle_avis_display_header_img(e.target.checked);
                  setis_data_changed("1");
                }}

              />

            </div>
          </div>
          <div className="div_row_dialog">
            <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
              <b> Image Url </b>
            </div>
            <div className="div_row_dialog" >

            <div className="div_row_dialog" >
              <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                <input
                  type='text'
                  name='img_url'
                  id='img_url'
                  value={article_avis_img_path}
                  onChange={(e) => {
                    setarticle_avis_img_path(e.target.value);
                    setis_data_changed("1");
                  }}
                  className='disabled_style_width_100_percent'

                ></input>
              </div>
            </div>

            </div>
          </div>



          <div className="div_row_dialog">
            <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
              <b> Texte Article </b>
            </div>
            <div className="div_row_dialog" >

              <Editor
                id="detail_document_principal"
                name="detail_document_principal"
                onInit={(evt, editor) => editorRef_texte_avis.current = editor}
                // initialValue={field_texte_avis}

                onKeyUp={editor_keyup}
                value={field_texte_avis}
                onEditorChange={(newValue, editor) => {
                  setfield_texte_avis(editorRef_texte_avis.current.getContent());

                }}
                init={{
                  resize: false,
                  height: 700,
                  menubar: true,

                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace',
                    'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',

                  ],
                  toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                    'alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',

                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                }}
              />

            </div>

            {String(is_data_changed) === String("1") && <div className="div_row_dialog">
              <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div>
            </div>}

          </div>



        </DialogContent>

        <DialogActions>
          <div className="div_row">
            <div className="div_row_gauche">
              <Button onClick={Dialog_article_avis_handleClose_buton} className="bton_annule_dialog" style={{textAlign:"center"}}>Fermer &nbsp;</Button>
            </div>
            <div className="div_row_droite" style={{ "textAlign": 'center' }}>
              <Button onClick={Add_Update_Article_Avis} className="bton_enreg_dialog">Enregistrer &nbsp; </Button>

            </div>
          </div>

        </DialogActions>


      </Dialog>
      {/***  FIN GESTION article */}


      <div className="div_row">




        <div className="div_row_border" >
          <div style={{ "width": "100%", textAlign: "center" }}>

            <div className="gauche_img">
              <img src={article_img} alt={props.article.title_formation} className="img_categorie" id="projets" />
            </div>

            <div className="droite_txt">
              <div className="met_text" id="projets" onClick={(e) => {
                e.preventDefault();
                window.location.href = "/Display-Detail-Article/" + props.article.internal_url
              }} style={{
                cursor: "pointer"
              }} >   {title_resized} </div>

              <div className="met_text_detail" >


                <div className="art_criteres_value">

                  {parse(String(props.article.avis).replace(/(<([^>]+)>)/ig, ''))}

                </div>


              </div>
              <div style={{
                width: '100%', "textAlign": "right", paddingRight: "10px", float: "left", height: "2rem",
                fontStyle: "italic"
              }}>  {(String(props.article.date_publication)).substring(0, 10)}
              </div>

              <div className="grid8p" onClick={(e) => {
                e.preventDefault();
                window.location.href = "/Display-Detail-Article/" + props.article.internal_url
              }}> En savoir plus &rsaquo;
              </div>



              {props.connected_user_article_avis_droit_modif === "1" && <div style={{ "width": "100%", "float": "left" }} onClick={(e) => {
                e.preventDefault();

              }}>
                <nav style={{
                  "width": "40%", "float": "left", "textAlign": "left", "paddingLeft": "2px", "paddingRight": "2px",
                }}
                >


                  <Box sx={{ '& > :not(style)': { m: 1 }, }} onClick={(e) => {
                    setarticle_avis_title_formation(props.article.title_formation);
                    // setarticle_avis_avis(props.article.avis);
                    // setfield_texte_avis_initial_value(props.article.avis);
                    setarticle_avis_date_publication(moment((String(props.article.date_avis)).substring(0, 10)).format('YYYY-MM-DD'))

                    Get_Partner_Article_Avis_Data();
                    setedit_article("1");
                    setis_data_changed("");
                    setDialog_article_avis_open(true);

                  }}>
                    <Fab size="small" color="primary" aria-label="edit" variant="extended">
                      <EditIcon sx={{ pr: 1 }} /> Edit
                    </Fab>
                  </Box> </nav>


                <nav style={{ "width": "40%", "float": "right", "textAlign": "right", "paddingLeft": "2px", "paddingRight": "2px" }} >
                  <Popup
                    trigger={
                      <Box sx={{ '& > :not(style)': { m: 1 }, }} onClick={(e) => {


                      }}>
                        <Fab size="small" color="warning" aria-label="edit" variant="extended">
                          <MdDeleteForever sx={{ pr: 1 }} /> <font style={{ "fontsize": "9px" }}> Supr </font>
                        </Fab>
                      </Box>
                    }
                    modal
                    nested
                    position="center center"
                  >
                    {close => (
                      <div>
                        <button className="gest_close" onClick={close}>
                          &times;
                        </button>
                        <div className="gest_header"> MySy Information </div>
                        <div className="gest_content">
                          {' '}

                          En confirmant cette opération, le document sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                        </div>
                        <div className="gest_actions">
                          <div style={{ "width": "45%", "float": "left" }}>
                            <button className="gest_bton_popup" onClick={(event) => {
                              Delete_Article_Avis_Data(props.article._id);
                              close();
                            }}> Valider </button>

                          </div>
                          <div style={{ "width": "45%", "float": "right" }}>
                            <button
                              className="gest_bton_popup"
                              onClick={() => {
                                console.log('modal closed ');
                                close();
                              }}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </nav>

              </div>}


            </div>
          </div>
        </div>
      </div>



    </div>
  )
}


export default Article;