import React, { useRef, useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Articles from "../components/Article";
import { FaSearch, FaRegArrowAltCircleDown, FaRegArrowAltCircleUp } from 'react-icons/fa';
import { format } from 'date-fns'
import moment from "moment";
import Pagination from "./TestPagination";
import data from "./data/mock-data.json";
import { useHistory } from "react-router-dom";
import { getCookie, removeCookie } from 'react-use-cookie';
import { Helmet } from "react-helmet";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Button from '@mui/material/Button';
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import Header from "./Header";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useCookies } from "react-cookie";
import connect_white from "../mysy_img/connect_white_v2.png";
import connect_green from "../mysy_img/connect_green.png";
import { BsFillXCircleFill } from "react-icons/bs";
import Fotter_v3 from "./Fotter_v3";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SendIcon from '@mui/icons-material/Send';
import { Editor } from '@tinymce/tinymce-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FcInfo } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import Checkbox from '@mui/material/Checkbox';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import { FcPlus } from "react-icons/fc";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

let PageSize = 5;
const RechercheArticles_new_v2 = (props) => {
    const [userconnected, setuserconnected] = useState("");
    const [SearchText, setSearchText] = useState("");
    const [result, setResult] = useState("");
    const [myApiResponse, setmyApiResponse] = useState("false");
    const [displayAllClasses, setdisplayAllClasses] = useState(true);
    const [showelement, setshowelement] = useState("init");
    const [country_code, setcountry_code] = useState("");
    const [country_name, setcountry_name] = useState("");
    const [city, setcity] = useState("");
    const [postal, setpostal] = useState("");
    const [latitude, setlatitude] = useState("");
    const [longitude, setlongitude] = useState("");
    const [IPv4, setIPv4] = useState("");
    const [state, setstate] = useState("");
    const [userIp, setuserIp] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage2, setCurrentPage2] = useState(1);
    const [fin, setfin] = useState(0);
    const [ismobilephone, setismobilephone] = useState();
    const [TrainingDistanciel, setTrainingDistanciel] = useState();


    const stored_user = getCookie('tokenmysych');
    const history = useHistory();


    const url = process.env.REACT_APP_API_URL + "myclass/api/add_user_account/";
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm();
    const pwd = useRef({});
    pwd.current = watch("pwd", "");

    const onSubmit = async (data) => {
        const formData = new FormData();
        //await sleep(20);
        formData.append('search_text', data.name);
        formData.append('name', data.name);
        formData.append('surname', data.surname);
        formData.append('email', data.email);
        formData.append('pwd', data.pwd);
        const res = await fetch(
            url,
            {
                method: "POST",
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: formData,
            }
        );
        const results = await res.json();
        //console.log(results);
    };

    const [Trainingdistance, setTrainingdistance] = useState(0);
    const handleInput = (e) => {
        setTrainingdistance(e.target.value);
    }

    const [Trainingduration, setTrainingduration] = useState(0);
    const handleInputDuration = (e) => {
        setTrainingduration(e.target.value);
    }


    function showdisplayfilter(event) {

        if (showelement == "true") {
            setshowelement("false");
        }
        else if (showelement == "false") {
            setshowelement("true");
        }


    }

    function clearsearchtext(event) {
        setSearchText("");
        document.getElementById("SearchText").value = "";
    }


    function SearchTextHandle(event) {
        setSearchText(event.target.value);
    }



    function displayAllClassesHandle(event) {

        setdisplayAllClasses(event.target.checked);
        if (event.target.checked == true)
            document.getElementById("SearchText").disabled = true;
        else
            document.getElementById("SearchText").disabled = false;
    }


    const [get_all_articles_avis_api, setget_all_articles_avis_api] = useState();
    const [get_all_articles_avis_message, setget_all_articles_avis_message] = useState();
    const [get_all_articles_avis_result, setget_all_articles_avis_result] = useState([]);
    function get_all_articles_avis(e) {

        var token = "";
        if (typeof (stored_user) != "undefined") {
            token = stored_user
        }


        var form = new FormData();
        form.append("search_text", SearchText);
        form.append("token", token);
        // Ajout des info IP user
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);


        var myurl = ""
        /* if (document.getElementById("allelement").checked !== true &&
             document.getElementById("SearchText").disabled !== true) {
             //alert(" recherche classique laaa ");
             myurl = process.env.REACT_APP_API_URL + "myclass/api/recherche_articles_avis/";
         }
         else if (document.getElementById("allelement").checked === true &&
             document.getElementById("SearchText").disabled === true) {
             myurl = process.env.REACT_APP_API_URL + "myclass/api/get_all_articles_avis/";
         }*/
        myurl = process.env.REACT_APP_API_URL + "myclass/api/get_all_articles_avis/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) != String("false")) {
                //console.log(" In get_all_articles_avis  res.data.status = " + res.data.status);
                //console.log(" In get_all_articles_avis  res.data.message = " + res.data.message);
                setget_all_articles_avis_api(res.data.status);
                setget_all_articles_avis_result(res.data.message);
                setmyApiResponse("True");
                setResult(res.data.message)

            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("False");
                setget_all_articles_avis_api(res.data.status);
                setget_all_articles_avis_message(res.data.message);
                //setmyApimyApiMessage("")
            }


        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            setget_all_articles_avis_api("False");
            //setmyApimyApiMessage("")
        })
    }


    const [get_all_published_articles_avis_api, setget_all_published_articles_avis_api] = useState();
    const [get_all_published_articles_avis_message, setget_all_published_articles_avis_message] = useState();
    const [get_all_published_articles_avis_result, setget_all_published_articles_avis_result] = useState([]);
    function get_all_published_articles_avis(e) {

        var token = "";
        if (typeof (stored_user) != "undefined") {
            token = stored_user
        }


        var form = new FormData();
        form.append("search_text", SearchText);
        form.append("token", token);
        // Ajout des info IP user
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);


        var myurl = ""
        /* if (document.getElementById("allelement").checked !== true &&
             document.getElementById("SearchText").disabled !== true) {
             //alert(" recherche classique laaa ");
             myurl = process.env.REACT_APP_API_URL + "myclass/api/recherche_articles_avis/";
         }
         else if (document.getElementById("allelement").checked === true &&
             document.getElementById("SearchText").disabled === true) {
             myurl = process.env.REACT_APP_API_URL + "myclass/api/get_all_published_articles_avis/";
         }*/
        myurl = process.env.REACT_APP_API_URL + "myclass/api/get_all_published_articles_avis/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) != String("false")) {
                //console.log(" In get_all_published_articles_avis  res.data.status = " + res.data.status);
                //console.log(" In get_all_published_articles_avis  res.data.message = " + res.data.message);
                setget_all_published_articles_avis_api(res.data.status);
                setget_all_published_articles_avis_result(res.data.message);
                setmyApiResponse("True");


            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("False");
                setget_all_published_articles_avis_api(res.data.status);
                setget_all_published_articles_avis_message(res.data.message);
                //setmyApimyApiMessage("")
            }


        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            setget_all_published_articles_avis_api("False");
            //setmyApimyApiMessage("")
        })
    }



    const [get_all_non_published_articles_avis_api, setget_all_non_published_articles_avis_api] = useState();
    const [get_all_non_published_articles_avis_message, setget_all_non_published_articles_avis_message] = useState();
    const [get_all_non_published_articles_avis_result, setget_all_non_published_articles_avis_result] = useState([]);
    function get_all_non_published_articles_avis(e) {

        var token = "";
        if (typeof (stored_user) != "undefined") {
            token = stored_user
        }


        var form = new FormData();
        form.append("search_text", SearchText);
        form.append("token", token);
        // Ajout des info IP user
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);


        var myurl = ""
        /* if (document.getElementById("allelement").checked !== true &&
             document.getElementById("SearchText").disabled !== true) {
             //alert(" recherche classique laaa ");
             myurl = process.env.REACT_APP_API_URL + "myclass/api/recherche_articles_avis/";
         }
         else if (document.getElementById("allelement").checked === true &&
             document.getElementById("SearchText").disabled === true) {
             myurl = process.env.REACT_APP_API_URL + "myclass/api/get_all_non_published_articles_avis/";
         }*/
        myurl = process.env.REACT_APP_API_URL + "myclass/api/get_all_non_published_articles_avis/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) != String("false")) {
                //console.log(" In get_all_non_published_articles_avis  res.data.status = " + res.data.status);
                //console.log(" In get_all_non_published_articles_avis  res.data.message = " + res.data.message);
                setget_all_non_published_articles_avis_api(res.data.status);
                setget_all_non_published_articles_avis_result(res.data.message);
                setmyApiResponse("True");


            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("False");
                setget_all_non_published_articles_avis_api(res.data.status);
                setget_all_non_published_articles_avis_message(res.data.message);
                //setmyApimyApiMessage("")
            }


        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            setget_all_non_published_articles_avis_api("False");
            //setmyApimyApiMessage("")
        })
    }



    const currentTableData2 = useMemo(() => {
        if (result) {
            const firstPageIndex = (currentPage2 - 1) * PageSize;
            const lastPageIndex = firstPageIndex + PageSize;
            setfin(1);
            return result.slice(firstPageIndex, lastPageIndex);
        }
        else {
            return [];
            setfin(0);
        }
    }, [currentPage2]);


    const [PartnerMenu, setPartnerMenu] = React.useState(null);
    const [AccountMenu, setAccountMenu] = React.useState(null);

    const [someoneconnected, setsomeoneconnected] = useState("0");
    const [partnerconnected, setpartnerconnected] = useState("0");
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);

    const stored_part = getCookie('tokenmysypart');


    const [connected_user_article_avis_droit_modif, setconnected_user_article_avis_droit_modif] = useState("0");


    function whosisconnected() {
        var local_user_connect = 0;
        var local_part_connect = 0;

        if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
            setuserconnected("0");
            local_user_connect = 0;
        } else {
            setuserconnected("1");
            setsomeoneconnected("1");
            local_user_connect = 1;
            retrieve_user_acces_right();

            //alert(" user connected OK");
        }

        if (typeof (stored_part) === "undefined" || String(stored_part) === '') {
            setpartnerconnected("0");
            local_part_connect = 0;
        } else {
            setpartnerconnected("1");
            setsomeoneconnected("1");
            local_part_connect = 1;
            retrieve_user_acces_right();


            // alert(" partner connected OK");
        }
    }

    function handleAccountLogout() {

        if (String(userconnected) === String("1")) {
            user_logout();
        }
        else if (String(partnerconnected) === String("1")) {
            part_logout();
        }
    }

    function user_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_user) != "undefined") {
            setCookie("tokenmysych", "");
        }
        history.push("/recherche-formation");
    }

    function user_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (user)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => user_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    const PartnerMenu_Close = () => {
        setPartnerMenu(null);
    };

    const AccountMenu_Close = () => {
        setAccountMenu(null);
    };

    const handlePartnerMenu = (event) => {
        setPartnerMenu(event.currentTarget);
    };

    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };

    const handleClose_Account = () => {
        alert(" my account");
        setAccountMenu(null);
    };

    const handleAccountConnexion = () => {
        setAccountMenu(null);
        history.push("/Connexion")
    };

    const handleMyAccount = () => {
        setAccountMenu(null);
        history.push("/mysy-user-account")
    };

    const handleAccountCreation = () => {
        setAccountMenu(null);
        history.push("/create_account")
    };

    const handlePartnerConnexion = () => {
        setAccountMenu(null);
        history.push("/PartnerLogin")
    };

    function user_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_user) != "undefined") {
            setCookie("tokenmysych", "");
        }
        history.push("/recherche-formation");
    }

    function user_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (user)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => user_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    const handleMyPartnerAccount = () => {
        setAccountMenu(null);
        history.push("/Partner")
    };

    const [partcookie, setpartCookie, removepartCookie] = useCookies(['tokenmysypart']);
    function handleAccountConnexion_v2() {

        if (String(userconnected) === String("1")) {
            handleMyAccount();
        }
        else if (String(partnerconnected) === String("1")) {
            handleMyPartnerAccount();
        }
    }


    function part_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_part) != "undefined") {

            const stored_cookie = getCookie('tokenmysypart');
            var acces_right_token_name = stored_cookie + "_uar";

            setpartCookie("tokenmysypart", "", { path: '/' });

            setpartCookie(acces_right_token_name, "", { path: '/' });
            removeCookie(acces_right_token_name, { path: '/' });
        }
        history.push("/recherche-formation");
    }

    function part_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (pro)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => part_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }
    const [ProduitMenu, setproduitMenu] = React.useState(null);
    const [AvisMenu, setavisMenu] = React.useState(null);


    const handleProduitMenu = (event) => {
        setproduitMenu(event.currentTarget);
    };

    const handleProduitService = () => {
        setproduitMenu(null);
        history.push("/tarifs_produits_services/")
    };

    const handleQuisommesNousMenu = () => {
        setproduitMenu(null);
        history.push("/qui-sommes-nous/")
    };

    const ProduitMenu_Close = () => {
        setproduitMenu(null);
    };

    function publiecatalogue() {
        history.push("/Partner", { mysy_arg: 'partner' });
    }

    const handleFtionMenu = (event) => {
        history.push("/")
    };

    useEffect(() => {
        setdisplayAllClasses("true");
        async function chloadfunction() {
            if (document.getElementById('allelement')) {
                document.getElementById("allelement").checked = true;
            }
            // document.getElementById("SearchText").disabled = true;

        }
        chloadfunction();
        get_all_published_articles_avis();
        whosisconnected();

    }, []);



    // Cette fonction recuperer les droits d'acces stocké en cookie
    const [connected_user_access_right, setconnected_user_access_right] = React.useState([]);
    function retrieve_user_acces_right() {
        const stored_cookie = getCookie('tokenmysypart');

        if (!stored_cookie || String(stored_cookie).trim() === "") {
            history.push("/Connexion");
            return;
        }

        var acces_right_token_name = stored_cookie + "_uar";
        var acces_right_token_val = getCookie(acces_right_token_name, { path: '/' });

        var tab_json_access_right = JSON.parse(acces_right_token_val);

        setconnected_user_access_right(tab_json_access_right);


        var article_avis_droit_ecriture_retval = check_user_acces_right_with_tab("article_avis", "write", tab_json_access_right);
        if (article_avis_droit_ecriture_retval === 1) {
            setconnected_user_article_avis_droit_modif("1");
        } else {
            setconnected_user_article_avis_droit_modif("0");
        }


    }

    // check du droit d'acces de l'utilisateur
    function check_user_acces_right(local_module, local_action) {

        //console.log(" ### connected_user_access_right = ", connected_user_access_right);
        //console.log(" #### check_user_acces_right = ", local_module, " --- local_action = ", local_action);

        if (String(local_action) === "read") {
            for (let i = 0; i < connected_user_access_right.length; i++) {
                if (String(connected_user_access_right[i].module) === String(local_module)
                    && String(connected_user_access_right[i].read) === "true") {

                    return 1;
                }
            }
            return 0;
        } else if (String(local_action) === "write") {
            for (let i = 0; i < connected_user_access_right.length; i++) {
                if (String(connected_user_access_right[i].module) === String(local_module)
                    && String(connected_user_access_right[i].write) === "true") {

                    return 1;
                }
            }
            return 0;
        } else {
            return 0;
        }

    }


    // check du droit d'acces de l'utilisateur avec la table des droits
    function check_user_acces_right_with_tab(local_module, local_action, tab_acces_right) {

        //console.log(" ### connected_user_access_right = ", connected_user_access_right);
        //console.log(" #### check_user_acces_right = ", local_module, " --- local_action = ", local_action);

        if (String(local_action) === "read") {
            for (let i = 0; i < tab_acces_right.length; i++) {
                if (String(tab_acces_right[i].module) === String(local_module)
                    && String(tab_acces_right[i].read) === "true") {

                    return 1;
                }
            }
            return 0;
        } else if (String(local_action) === "write") {
            for (let i = 0; i < tab_acces_right.length; i++) {
                if (String(tab_acces_right[i].module) === String(local_module)
                    && String(tab_acces_right[i].write) === "true") {

                    return 1;
                }
            }
            return 0;
        } else {
            return 0;
        }

    }




    const [Dialog_article_avis_avis, setDialog_article_avis_avis] = React.useState(false);
    const [Dialog_article_avis_open, setDialog_article_avis_open] = React.useState(false);
    function Dialog_article_avis_handle_change_participant_session(avis) {
        setDialog_article_avis_avis(avis);
        setDialog_article_avis_open(true);
    }

    const Dialog_article_avis_handleClose = () => {

    };

    const Dialog_article_avis_handleClose_buton = () => {

        setDialog_article_avis_open(false);
        clear_article_avis_fields();

    };

    function clear_article_avis_fields() {
        setarticle_avis_title_formation("");
        setarticle_avis_email("");
        setarticle_avis_url_formation("");
        setarticle_avis_sujet("");
        setarticle_avis_avis("");
        setis_data_changed("");
    }

    const [article_avis_date_publication, setarticle_avis_date_publication] = useState(new Date().toLocaleDateString('fr-FR'));

    const [article_avis_title_formation, setarticle_avis_title_formation] = React.useState("");
    const [article_avis_email, setarticle_avis_email] = React.useState("");
    const [article_avis_url_formation, setarticle_avis_url_formation] = React.useState("");
    const [article_avis_sujet, setarticle_avis_sujet] = React.useState("");
    const [article_avis_avis, setarticle_avis_avis] = React.useState("");
    const [article_avis_internal_url, setarticle_avis_internal_url] = React.useState("");
    const [article_avis_display_header_img, setarticle_avis_display_header_img] = React.useState(false);
    const [article_avis_img_path, setarticle_avis_img_path] = React.useState("");

    function Add_Update_Article_Avis(e) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("title_formation", article_avis_title_formation);
        form.append("avis", field_texte_avis);
        form.append("qualite", " --- ");
        form.append("note", "---");

        if (!article_avis_img_path || String(article_avis_img_path).trim() === "") {
            alert(" Vous devez saisir une image à afficher avec l'article ");
            return;
        }
        form.append("img_path", article_avis_img_path);

        if (article_avis_date_publication.trim() === "") {
            alert(" Vous devez saisir la date de naissance de l'apprenant");
            return;
        }

        var formated_date_tmp = new Date(moment(article_avis_date_publication, "YYYY-MM-DD"));
        var formated_date_final = moment(formated_date_tmp).format('DD/MM/YYYY');

        form.append("date_publication", formated_date_final);


        if (article_avis_display_header_img) {
            form.append("display_header_img", "1");
        } else {
            form.append("display_header_img", "0");
        }

        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);


        //console.log(" ## form = ", form);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/add_articles_avis/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("false")) {
                alert(res.data.message);
            }

            else if (String(res.data.status) === String("true")) {
                alert(res.data.message);
                Dialog_article_avis_handleClose_buton();
                window.location.reload();

            }
            else {

            }
        }).catch((error) => {
            console.warn('contact-nous  Not good man :( mysearchtext = ');


        })


    }


 
    const editorRef_texte_avis = useRef(null);
    const [editorRef_texte_avis_limite, seteditorRef_texte_avis] = useState(1000);
    const [field_texte_avis, setfield_texte_avis] = useState("");
    const [field_texte_avis_initial_value, setfield_texte_avis_initial_value] = useState("");

    const [is_data_changed, setis_data_changed] = useState("");

    const [editor_tochanged, seteditor_tochanged] = useState("");

    function editor_keyup() {
        setis_data_changed("1");
    }


    const New_Option_Filter = [
        { "id": "published", "label": "Publié", "value": "published" },
        { "id": "notpublished", "label": "Non Publié", "value": "notpublished" },
        { "id": "all", "label": "Tout", "value": "all" },
    ]

    const [publish_status, setpublish_status] = useState("published");

    return (
        <div style={{ width: "100%", float: "left" }}>


            {/***   GESTION article */}
            <Dialog
                open={Dialog_article_avis_open}
                onClose={Dialog_article_avis_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>Votre article </DialogTitle>
                <DialogContent className="DialogContent_width DialogContent_width_session_800px" >


                    {String(is_data_changed) === String("1") && <div className="div_row_dialog">
                        <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div>
                    </div>}

                    <div className="div_row_dialog">
                        <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                            <b> Titre </b>
                        </div>
                        <div className="div_row_dialog" >
                            <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                                <input
                                    type='text'
                                    name='name'
                                    value={article_avis_title_formation}
                                    onChange={(e) => {
                                        setarticle_avis_title_formation(e.target.value);
                                        setis_data_changed("1");
                                    }}
                                    className='disabled_style_width_100_percent'

                                ></input>
                            </div>
                        </div>
                    </div>



                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', paddingTop: '15px' }}>
                            <b>   Date publication </b>
                        </div>


                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            <DatePicker
                                name="date_publication"
                                id="date_publication"
                                selected={new Date(moment(article_avis_date_publication, "YYYY-MM-DD"))}
                                onChange={(date) => {
                                    setarticle_avis_date_publication(format(date, 'yyyy-MM-dd'));
                                    setis_data_changed("1");
                                }
                                }
                                showTimeSelect={false}
                                dateFormat="dd/MM/yyyy"
                                className="disabled_style session_caract_Dialog_DatePicker"
                            />
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', paddingTop: '15px' }}>
                            <b>   Affiche image </b>
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            <Checkbox
                                name="document_join_pdf"
                                className="disabled_style session_caract_Dialog_DatePicker"
                                checked={article_avis_display_header_img}
                                onChange={(e) => {
                                    setarticle_avis_display_header_img(e.target.checked);
                                    setis_data_changed("1");
                                }}

                            />

                        </div>
                    </div>
                    <div className="div_row_dialog">
                        <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                            <b> Image Url </b>
                        </div>
                        <div className="div_row_dialog" >

                            <div className="div_row_dialog" >
                                <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                                    <input
                                        type='text'
                                        name='img_url'
                                        id='img_url'
                                        value={article_avis_img_path}
                                        onChange={(e) => {
                                            setarticle_avis_img_path(e.target.value);
                                            setis_data_changed("1");
                                        }}
                                        className='disabled_style_width_100_percent'

                                    ></input>
                                </div>
                            </div>

                        </div>
                    </div>



                    <div className="div_row_dialog">
                        <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                            <b> Texte Article </b>
                        </div>
                        <div className="div_row_dialog" >

                            <Editor
                                id="detail_document_principal"
                                name="detail_document_principal"
                                onInit={(evt, editor) => editorRef_texte_avis.current = editor}
                                // initialValue={field_texte_avis}

                                onKeyUp={editor_keyup}
                                value={field_texte_avis}
                                onEditorChange={(newValue, editor) => {
                                    setfield_texte_avis(editorRef_texte_avis.current.getContent());

                                }}
                                init={{
                                    resize: false,
                                    height: 700,
                                    menubar: true,

                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace',
                                        'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',

                                    ],
                                    toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                        'alignleft aligncenter alignright alignjustify | ' +
                                        'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',

                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                }}
                            />

                        </div>

                        {String(is_data_changed) === String("1") && <div className="div_row_dialog">
                            <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div>
                        </div>}

                    </div>



                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={Dialog_article_avis_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Add_Update_Article_Avis} className="bton_enreg_dialog">Envoyer&nbsp; <SendIcon /></Button>

                        </div>
                    </div>

                </DialogActions>


            </Dialog>
            {/***  FIN GESTION article */}


            <div className="recherchearticles_new_v2_style">
                <Helmet>


                    <title>Le blog, liste des articles</title>
                    <meta name="description" content="Le blog, liste des articles. Éditeur Français de logiciels pour organisme de formation. Avec solutions de E-Learning, gestion administrative et gestion relation client nous accompagnons les organismes de formation à réaliser l’audit QUALIOPI en toute sérénité. Nous sommes les moins cher du marché. Nous vous accompagnons automatiser vos process : inscription, évaluation, émargement (qr-code), Check-list QUALIOPI, Personnalisation et édition documents, Gestion formateurs, Catalogue formations personnalisé. Nous sommes les moins cher du marché. Nous automatisons vos process : inscription, évaluation, émargement (qr-code),  QUALIOPI, Personnalisation des documents, Gestion formateurs, Catalogue personnalisé, bilan pédagogique et financier (PBF).  Nos options comme la signature électronique sont inclus dans le tarif de base." />
                    <meta name="robots" content="index,follow" />
                    <meta name="title" content="MySy Training, contactez-nous" />
                    <link rel="canonical" href={window.location.href} />


                </Helmet>

                <Header />

                <div className="div_mobile">

                    <div className="div_row block_entete" >
                        <div className="Background-header">

                            <div className="Title">

                                <div className="besoin_aide">
                                    Restez informé. Quelques articles pour vous.
                                    <div className="detail_text_aide">
                                        &nbsp;
                                    </div>
                                </div>

                                <div className="barre_recherche">
                                    <form onSubmit={handleSubmit(onSubmit)} className="div_005">
                                        <div class="form">

                                            <i class="fa fa-search"></i>

                                            <input
                                                type="text"
                                                class="form-control form-input"
                                                placeholder=" Texte (Titre, Description)"
                                                onChange={SearchTextHandle}
                                                id={"SearchText"}
                                                name={"SearchText"}
                                                style={{ "width": "80%", float: "left" }}
                                            />
                                            <nav style={{ "width": "15%", float: "left", paddingTop: "5px" }}>
                                                {SearchText.length > 2 && <span_clear> <BsFillXCircleFill onClick={clearsearchtext} /> </span_clear>} <span class="left-pan" >
                                                    &nbsp;<FaSearch onClick={get_all_articles_avis} /></span>
                                            </nav>


                                        </div>
                                    </form>
                                </div>

                            </div>


                        </div>

                    </div>
                    <div className="block_centrer">
                        {connected_user_article_avis_droit_modif === "1" && <div className="div_row">
                            <Button onClick={(e) => {
                                var al = moment((new Date()));
                                setarticle_avis_date_publication(moment(al).format('YYYY-MM-DD'));


                                clear_article_avis_fields();
                                setis_data_changed("");
                                setDialog_article_avis_open(true);

                            }}
                                className="bton_enreg_dialog"> Ajouter 1 article &nbsp;&nbsp;
                                <FcPlus style={{ fontSize: "20px" }} />
                            </Button>
                        </div>}

                        {connected_user_article_avis_droit_modif === "1" && New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                            disablePortal
                            name="filtre1"
                            id="filtre1"
                            className="disabled_style"
                            options={New_Option_Filter}
                            defaultValue={{ id: "published", label: "Publié", value: "published" }}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setpublish_status(value.value);

                                    if (value.value === "published") {
                                        get_all_published_articles_avis();
                                    } else if (value.value === "notpublished") {
                                        get_all_non_published_articles_avis();
                                    } else if (value.value === "all") {
                                        get_all_articles_avis();
                                    }
                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}

                        <div className="liste_articles">

                            {/*result && myApiResponse === "True" && <div className="okUpdateData">
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage2}
                                totalCount={result.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage2(page)}
                            />


                        </div>*/}

                            {String(publish_status) === "all" && get_all_articles_avis_result && get_all_articles_avis_result.length > 0 && currentTableData2 && get_all_articles_avis_result.map((formation) => (
                                <div className="block_article">
                                    <Articles article={JSON.parse(formation)} key={JSON.parse(formation).title_formation}
                                        connected_user_article_avis_droit_modif={connected_user_article_avis_droit_modif}
                                        get_all_articles_avis={get_all_articles_avis}

                                    />
                                </div>

                            ))}

                            {String(publish_status) === "published" && get_all_published_articles_avis_result && get_all_published_articles_avis_result.length > 0 && get_all_published_articles_avis_result.map((formation) => (
                                <div className="block_article">
                                    <Articles article={JSON.parse(formation)} key={JSON.parse(formation).title_formation}
                                        connected_user_article_avis_droit_modif={connected_user_article_avis_droit_modif}
                                        get_all_articles_avis={get_all_articles_avis}

                                    />
                                </div>

                            ))}

                            {String(publish_status) === "notpublished" && get_all_non_published_articles_avis_result && get_all_non_published_articles_avis_result.length > 0 && get_all_non_published_articles_avis_result.map((formation) => (
                                <div className="block_article">
                                    <Articles article={JSON.parse(formation)} key={JSON.parse(formation).title_formation}
                                        connected_user_article_avis_droit_modif={connected_user_article_avis_droit_modif}
                                        get_all_articles_avis={get_all_articles_avis}

                                    />
                                </div>

                            ))}


                            {/*result && <div>
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage2}
                                totalCount={result.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage2(page)}
                            />

                        </div>
                        */}
                        </div>

                    </div>
                </div>


            </div>

            <div style={{ height: "5rem", width: "100%" }}>
                <Fotter_v3 />
            </div>

        </div>
    )
}

export default RechercheArticles_new_v2;