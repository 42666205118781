import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom'
import Header from "./Header";
import { useLocation } from "react-router-dom";
import fileDownload from 'js-file-download'
import { RichTextEditor } from '@mantine/rte';
import { Editor } from '@tinymce/tinymce-react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";

import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import Link from '@mui/material/Link';
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'

import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { v4 as uuid } from "uuid";
import { isCompositeComponent } from "react-dom/test-utils";
import moment from "moment";
import allLocales from '@fullcalendar/core/locales-all'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Module_Editique from "./Module_Editique";
import Module_Attestation_Formation from "./Module_Attestation_Formation";
import Module_Email_Management from "./Module_Email_Management";

import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';

import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { IconButton } from '@material-ui/core';

import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';


import { useDemoData } from '@mui/x-data-grid-generator';

import SignatureCanvas from 'react-signature-canvas';

import Box from '@mui/material/Box';
import {
    DataGrid, frFR, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton,
    GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExportContainer, useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
} from '@mui/x-data-grid';

//import ContextMenu from 'react-jsx-context-menu';
import { ContextMenu, ContextMenuTrigger } from "react-contextmenu";
import * as XLSX from 'xlsx';

//import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';


const useStyles = makeStyles((theme) => ({
    paper: {
        overflowY: 'unset',
    },
    customizedButton: {
        position: 'absolute',
        left: '95%',
        top: '-9%',
        backgroundColor: 'lightgray',
        color: 'gray',
    },
}));

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}


const locales = {
    'en-US': enUS,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})


const TestUrl_New = (props) => {

    // DEBUT  EXPORT EXCEL DATAGRID 

    function CustomToolbar(props) {

        return (
            <GridToolbarContainer {...props}>

                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{ tooltip: { title: 'Change density' } }}
                />
                <Box sx={{ flexGrow: 1 }} />
                <ExportButton data_colums={props.data_colums} />


            </GridToolbarContainer>
        );
    }


    const { data, loading } = useDemoData({
        dataSet: 'Commodity',
        rowLength: 4,
        maxColumns: 6,
    });

    function getExcelData(apiRef) {
        // Select rows and columns
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

        // Format the data. Here we only keep the value
        const data = filteredSortedRowIds.map((id) => {
            const row = {};
            visibleColumnsField.forEach((field) => {
                row[field] = apiRef.current.getCellParams(id, field).value;
            });
            return row;
        });

        return data;
    }


    function ExportButton(props) {
        return (
            <GridToolbarExportContainer {...props}>
                <ExportMenuItem data_colums={props.data_colums} />
            </GridToolbarExportContainer>
        );
    }

    function handleExport(apiRef, data_colums) {

        console.log(" coucouuu ### columnVisibilityModel = ", columnVisibilityModel);
        const data = getExcelData(apiRef);

        const local_config = {
            keys: data_colums.filter((mydata) => (mydata).disableExport !== true).map(function (mydata) {
                if (mydata.field)
                    return mydata.field;
                else
                    return "";
            }),


            columnNames: data_colums.filter((mydata) => (mydata).disableExport !== true).map(function (mydata) {
                if (mydata.headerName)
                    return mydata.headerName;
                else
                    return "";
            }),

            fileName: 'data_' + String(new Date().toJSON().slice(0, 10)).replaceAll("-", "_") + '.xlsx',
            sheetName: 'Export_' + String(new Date().toJSON().slice(0, 10)).replaceAll("-", "_"),
        };

        const rows = data.map((row) => {
            const mRow = {};
            for (const key of local_config.keys) {
                mRow[key] = row[key];
            }
            return mRow;
        });

        const worksheet = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.sheet_add_aoa(worksheet, [[...local_config.columnNames]], {
            origin: 'A1',
        });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, local_config.sheetName);
        XLSX.writeFile(workbook, local_config.fileName, { compression: true });
    }

    function ExportMenuItem(props) {
        const apiRef = useGridApiContext();
        const { hideMenu } = props;

        return (
            <MenuItem
                onClick={() => {
                    handleExport(apiRef, props.data_colums);
                    // Hide the export menu after the export
                    hideMenu?.();
                }}
            >
                Export Excel
            </MenuItem>
        );
    }

    // FIN EXPORT EXCEL DATAGRID 

    const { courseid, tabid } = useParams();
    const [message, setMessage] = useState(null);
    const editorRef_programme = useRef(null);
    const [editorRef_programme_limite, seteditorRef_programme] = useState(1000);
    const [field_programme, setfield_programme] = useState("");
    const [editor_tochange, seteditor_tochange] = useState("");


    const editorRef_description = useRef(null);
    const [editorRef_description_limite, seteditorRef_description_limite] = useState(5000);
    const [field_description, setfield_description] = useState("");

    function editor_keyup() {
        seteditor_tochange("1");
    }

    function DataUpdated() {

    }

    function add_text() {
        console.log(" Ajouter un texte ");
        editorRef_programme.current.insertContent("<i> OKKK </i> ");
    }

    function add_nom() {
        editorRef_programme.current.insertContent(" <i>{{nom}}</i> ");
    }

    function add_prenom() {
        editorRef_programme.current.insertContent(" <i>{{prenom}}</i> ");
    }

    function add_document() {
        var programme = editorRef_programme.current.getContent();
        console.log(" doc text = ", programme);
    }

    function delete_document() {

    }

    function add_selected_fields() {

        if (!p_collection || !p_champ_table) {
            alert(" Champ invalide ");
            return;
        }
        var local_field = "{{" + String(p_collection) + "." + String(p_champ_table) + "}}";
        editorRef_programme.current.insertContent(" <b><i>" + String(local_field) + "</i></b> ");
    }



    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_champ_table, setp_champ_table] = useState();
    const [p_collection, setp_collection] = useState();


    const [p_champ_table_bis, setp_champ_table_bis] = useState();

    const [Get_Personnalisable_Collection_and_Fields_api, setGet_Personnalisable_Collection_and_Fields_api] = useState();
    const [Get_Personnalisable_Collection_and_Fields_message, setGet_Personnalisable_Collection_and_Fields_message] = useState();
    const [Get_Personnalisable_Collection_and_Fields_result, setGet_Personnalisable_Collection_and_Fields_result] = useState();
    function Get_Personnalisable_Collection_and_Fields() {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Personnalisable_Collection_Fields/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Personnalisable_Collection_and_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Personnalisable_Collection_and_Fields  res.data.message r_class = " + res.data.message);

                setGet_Personnalisable_Collection_and_Fields_api("true");
                setGet_Personnalisable_Collection_and_Fields_result(res.data.message);

            }
            else {
                setGet_Personnalisable_Collection_and_Fields_api("false");
                setGet_Personnalisable_Collection_and_Fields_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Personnalisable_Collection_and_Fields = ', error);
            setGet_Personnalisable_Collection_and_Fields_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }

    const [Get_Personnalisable_Collections_api, setGet_Personnalisable_Collections_api] = useState();
    const [Get_Personnalisable_Collections_message, setGet_Personnalisable_Collections_message] = useState();
    const [Get_Personnalisable_Collections_result, setGet_Personnalisable_Collections_result] = useState();
    function Get_Personnalisable_Collections() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Personnalisable_Collection/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Personnalisable_Collections  res.data.status = " + res.data.status);
                //console.log(" In Get_Personnalisable_Collections  res.data.message r_class = " + res.data.message);

                setGet_Personnalisable_Collections_api("true");
                setGet_Personnalisable_Collections_result(res.data.message);

            }
            else {
                setGet_Personnalisable_Collections_api("false");
                setGet_Personnalisable_Collections_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Personnalisable_Collections = ', error);
            setGet_Personnalisable_Collections_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }

    const [mysy_events, setmysy_events] = useState();

    const [Get_List_Agenda_Events_api, setGet_List_Agenda_Events_api] = useState();
    const [Get_List_Agenda_Events_message, setGet_List_Agenda_Events_message] = useState();
    const [Get_List_Agenda_Events_result, setGet_List_Agenda_Events_result] = useState();
    function Get_List_Agenda_Events(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Agenda_Event_List/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Agenda_Events  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Agenda_Events  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Agenda_Events_api("true");
                setGet_List_Agenda_Events_result(res.data.message);

                var tab_event = []
                var filtre2 = res.data.message.map(obj => {


                    var local_val = {
                        'title': String(JSON.parse(obj).event_title),
                        'start': String(JSON.parse(obj).event_start),
                        'end': String(JSON.parse(obj).event_end),
                        'id': String(JSON.parse(obj)._id),
                    }

                    tab_event.push(local_val);



                });
                console.log(" #### tab_event =", tab_event);
                setmysy_events(tab_event);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                //history.push("/Connexion");
            }
            else {
                setGet_List_Agenda_Events_api("false");
                setGet_List_Agenda_Events_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Agenda_Events = ', error);
            setGet_List_Agenda_Events_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const [tab_label, settab_label] = useState([]);

    function Get_Local_Label() {
        var new_data2 = [];
        var node1 = {
            "_id": "@text1",
            "label": "text1_label",
        };


        var node2 = {
            "_id": "@text2",
            "label": "text2_label",
        };

        new_data2.push(node1);
        new_data2.push(node2);

        settab_label(new_data2);

    }

    useEffect(() => {
        Get_Local_Label();
        Get_List_Agenda_Events();
        Get_Personnalisable_Collections();
        Get_Personnalisable_Collection_and_Fields();
        Get_Partner_Object_Specific_Fields("ressource_humaine");

    }, [])


    const [p_document_name, setp_document_name] = useState();
    const [p_document_type, setp_document_type] = useState();
    const [p_document_ref_interne, setp_document_ref_interne] = useState();
    const [p_document_sujet, setp_document_sujet] = useState();
    const [p_document_nom, setp_document_nom] = useState();

    const [Add_one_Partner_Document_api, setAdd_one_Partner_Document_api] = useState();
    const [Add_one_Partner_Document_message, setAdd_one_Partner_Document_message] = useState();
    const [Add_one_Partner_Document_result, setAdd_one_Partner_Document_result] = useState();
    function Add_one_Partner_Document() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        if (p_document_ref_interne)
            form.append("ref_interne", p_document_ref_interne);
        else {
            alert(" Vous devez definir une reference interne");
            return;
        }


        if (p_document_type)
            form.append("type_doc", p_document_type);
        else {
            alert(" Vous devez definir un type de document");
            return;
        }


        if (p_document_sujet)
            form.append("sujet", p_document_sujet);
        else {
            alert(" Vous devez definir un sujet");
            return;
        }



        if (editorRef_programme) {
            var programme = editorRef_programme.current.getContent();
            form.append("contenu_doc", programme);
        }
        else {
            form.append("contenu_doc", "");
        }

        console.log(" Add_one_Partner_Document affectation form == ", form);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Partner_Document/";

        axios.post(myurl, form).then(res => {
            console.log(" In Add_one_Partner_Document  res.data.status = " + res.data.status);
            console.log(" In Add_one_Partner_Document  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setAdd_one_Partner_Document_api("true");
                setAdd_one_Partner_Document_result(res.data.message);

                alert(res.data.message);

            }
            else {
                setAdd_one_Partner_Document_api("false");
                setAdd_one_Partner_Document_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.log('UpdateStagiaireData : Not good man :( Add_one_Partner_Document = ' + error);
            setAdd_one_Partner_Document_api("false");
            alert(" Impossible d'ajouter le document");

        })
    }

    const myEventsList = []
    const [events, setEvents] = useState([]);
    const handleSelect = (info) => {

        const { start, end } = info;
        const eventNamePrompt = prompt("Enter, Nom evenment");
        if (eventNamePrompt) {

            setEvents([
                ...events,
                {
                    start,
                    end,
                    title: eventNamePrompt,
                    id: uuid(),
                },
            ]);
        }
    };

    const EventItem = ({ info }) => {
        const { event } = info;
        return (
            <div>
                <p>ch - {event.title}</p>
            </div>
        );
    };


    // Gestion champs specifiques 

    /*
    *** ALGORITHME
    1 - Récuperer la liste des champs specifiques de la collection concernée - Array : list_nom_champs_spec
    2 - Récupérer les caracteristiques de champs champs spécifique : "id, nom, label, type valeur, etc"
       - avec un champ 'value' à vide. Mettre dans le tableau JSON, 'spec_champs_valeur'
    
    3 - Créer une nouvelle table qui permettra de gerer les hook : const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
    4 - Afficher en html les elements 'rows_champs_specifics'
    5 - Gerer la modification avec la fonction 'change_champs_spec_handle'
    6 - On recuperer les valeur lors du click d'enregistrement.
    
    */


    const list_nom_champs_spec = []
    const [spec_champs_valeur, setspec_champs_valeur] = useState([]);

    //const spec_champs_valeur = ""

    const [Get_Partner_Object_Specific_Fields_api, setGet_Partner_Object_Specific_Fields_api] = useState();
    const [Get_Partner_Object_Specific_Fields_message, setGet_Partner_Object_Specific_Fields_message] = useState();
    const [Get_Partner_Object_Specific_Fields_result, setGet_Partner_Object_Specific_Fields_result] = useState();
    function Get_Partner_Object_Specific_Fields(local_collection_obj_metier) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("object_name", local_collection_obj_metier);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_list_specific_field_partner_by_object/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

                setGet_Partner_Object_Specific_Fields_api("true");
                setGet_Partner_Object_Specific_Fields_result(res.data.message);


                setrows_champs_specifics(res.data.message);


            }
            else {
                setGet_Partner_Object_Specific_Fields_api("false");
                setGet_Partner_Object_Specific_Fields_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
            setGet_Partner_Object_Specific_Fields_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }



    const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
    const [display_champs_specifics, setdisplay_champs_specifics] = useState("0");

    function afficher_champs_spec() {

        setdisplay_champs_specifics("1");
        //setrows_champs_specifics(spec_champs_valeur);

    }

    function findIndexByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] == value) {
                return i;
            }
        }
        return -1;
    }


    function findValueByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] == value) {

                var tmp_val = JSON.parse(data[i]);

                return tmp_val.field_value

            }
        }
        return "";
    }


    const spec_field_updated_values = []

    function change_champs_spec_handle() {

        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;


            if (document.getElementsByName(String(field_name))[0]) {

                var field_value = document.getElementsByName(String(field_name))[0].value;
                var new_val = { 'field_name': field_name, 'field_value': field_value, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);
                if (johnIndex > -1) {
                    spec_field_updated_values[johnIndex] = new_val;

                } else {
                    spec_field_updated_values.push(new_val);
                }
            }

        }

        //setrows_champs_specifics(spec_champs_valeur);

        //console.log(" OUTT spec_field_updated_values = ", spec_field_updated_values);
    }
    // -- end champs specifiques 

    const renderEventContent = (eventInfo) => {
        console.log(eventInfo);
        return (
            <>
                <b>{eventInfo.timeText}</b><br />
                <i>{eventInfo.event.title}</i>
            </>
        )
    };

    const handleEventClick = (arg) => {
        alert(arg.event.title)
        alert(arg.event.start)
        setdialogMessage(" coucou cherif");
        setOpen(true);
    }

    const [open, setOpen] = React.useState(false);

    const [dialogMessage, setdialogMessage] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const handleClose_buton = () => {
        setOpen(false);
    };

    const [contextMenu, setContextMenu] = React.useState(null);
    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleClose22 = () => {
        alert(" 22");
        setContextMenu(null);
    };

    const handleClose23 = () => {
        alert(" 23");
        setContextMenu(null);
    };
    const handleClose24 = () => {
        alert(" 24");
        setContextMenu(null);
    };
    const handleClose25 = () => {
        setContextMenu(null);
    };

    const signatureCanvasRef = useRef(null);

    // API method examples
    const checkIfEmpty = () => {
        const isEmpty = signatureCanvasRef.current.isEmpty();
        console.log('Is signature pad empty?', isEmpty);
        if (signatureCanvasRef) {
            console.log(signatureCanvasRef);
            setpad_signature_img(signatureCanvasRef.current.toDataURL())

        }
    };

    const clearCanvas = () => {
        signatureCanvasRef.current.clear();
    };

    const [pad_signature_img, setpad_signature_img] = React.useState("");


    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        setDialog_1_open(false);
    }


    const classes = useStyles();
    const [open1, setOpen1] = React.useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    function handleClick(e, data) {
        console.log(" CKILLLLL e.target.attributes.valuess.value = ", e.target.attributes.valuess.value);
        console.log(" CKILLLLL e.target.attributes.data = ", e.target.attributes.data);
    }



    function CustomToolbar_ok() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{ tooltip: { title: 'Change density' } }}
                />
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarExport
                    slotProps={{
                        tooltip: { title: 'Export data' },
                        button: { variant: 'outlined' },
                    }}

                    csvOptions={{
                        fileName: 'Export',
                        delimiter: ';',
                        utf8WithBom: true,
                    }}

                    printOptions={{ disableToolbarButton: true }}

                />
            </GridToolbarContainer>
        );
    }




    function display_data() {
        console.log(" Data = ", data);
    }
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    });

    const [visibleColumns, setVisibleColumns] = React.useState();

    
    /*const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }*/

    return (
        <div className="testurl_new">
            <Helmet>
                <title>testurl_new</title>
                <meta name="description" content="{this.metaDetails.description}" />
                <meta name="keywords" content="{this.metaDetails.keywords}" />

            </Helmet>

            <Header />

            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>Subscribe</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogMessage}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose_buton}>Cancel</Button>
                    <Button onClick={handleClose_buton}>Subscribe</Button>
                </DialogActions>
            </Dialog>


            { /********* Dialog email */}
            <Dialog
                onClose={() => null}

                //onClose={Dialog_1_handleClose}
                className="module_email_management"
                PaperProps={{
                    sx: {
                        width: "100%",
                        maxWidth: "920px!important",
                    },
                    className: classes.paper, // Apply the paper style
                    style: {
                        overflowY: 'unset',
                        position: 'absolute',
                        top: '5rem'
                    },
                }}
                open={Dialog_1_open}
                hideBackdrop={true}
                disableScrollLock
            >


                <DialogContent >
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>


                    <Module_Email_Management father_close_function={Dialog_1_handleClose} email_destinataire={"mysy1000formation+02@gmail.com,mysytraining+apprenant3@gmail.com"}
                        related_collection={"inscription"} />

                </DialogContent>



                <DialogActions>
                    <IconButton
                        autoFocus
                        onClick={Dialog_1_handleClose}
                        color="primary"
                        className={classes.customizedButton}
                    >
                        <CancelPresentationIcon />
                    </IconButton>

                </DialogActions>

            </Dialog>
            { /********* Fin Dialog email */}

            <div className="div_mobile">


                <h3> Page de test * testurl_new </h3>
                parametres courseid = {courseid}, tabid = {tabid}
                <br />

                {/*<div> <h1> test microphone </h1>

                    <div>
                        <p>Microphone: {listening ? 'on' : 'off'}</p>
                        <button onClick={SpeechRecognition.startListening}>Start</button>
                        <button onClick={SpeechRecognition.stopListening}>Stop</button>
                        <button onClick={resetTranscript}>Reset</button>
                        <p>{transcript}</p>
                    </div>

                </div>*/}

                <div>
                    <Button variant="outlined" color="primary" onClick={handleClickOpen1}>
                        Open form dialog laa
                    </Button>
                    <Dialog
                        open={open1}
                        onClose={handleClose1}
                        // PaperComponent={PaperComponent}
                        aria-labelledby="draggable-dialog-title"

                        PaperProps={{
                            className: classes.paper, // Apply the paper style
                            style: {
                                overflowY: 'unset',
                            },
                        }}

                    >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            Subscribe
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                To subscribe to this website, please enter your email address here.
                                We will send updates occasionally.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <IconButton
                                autoFocus
                                onClick={handleClose1}
                                color="primary"
                                className={classes.customizedButton}
                            >
                                <CancelPresentationIcon />
                            </IconButton>
                            <Button onClick={handleClose} color="primary">
                                Subscribe
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>


                ZZZZ
                <div>
                    {/* NOTICE: id must be unique between EVERY <ContextMenuTrigger> and <ContextMenu> pair */}
                    {/* NOTICE: inside the pair, <ContextMenuTrigger> and <ContextMenu> must have the same id */}

                    <ContextMenuTrigger id="same_unique_identifier">
                        <div className="well">Click Droite Menu </div>
                        <TextField > TTTTTTTTTTTTTTTTtt </TextField>

                    </ContextMenuTrigger>

                    <ContextMenu id="same_unique_identifier" hideOnLeave={true} style={{ backgroundColor: 'red', zIndex: "99" }}>
                        <MenuItem data={{ foo: 'bar' }} valuess="list" onClick={handleClick}>
                            ContextMenu Item 1
                        </MenuItem>
                        <MenuItem data={{ foo: 'bar' }} valuess="list02" onClick={handleClick}>
                            ContextMenu Item 2
                        </MenuItem>
                        <MenuItem divider />
                        <MenuItem data={{ foo: 'bar' }} valuess="list03" onClick={handleClick}>
                            ContextMenu Item 3
                        </MenuItem>
                    </ContextMenu>

                </div>

                {data &&
                    data.columns.map((column) => (

                        <div>
                            column.field = {column.field}<br />

                            ----

                        </div>
                    ))}

                <button onClick={display_data}> display data </button>

                <h2> manip datagrid </h2>
                <div style={{ height: 300, width: '100%' }}>
                    <DataGrid
                        {...data}
                        localeText={{
                            toolbarExportCSV: "Export CSV(;)",
                        }}
                        loading={loading}
                        components={{
                            Toolbar: CustomToolbar
                        }}
                        componentsProps={{
                            toolbar: { data_colums: data.columns }
                        }}

                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) => {
                            setColumnVisibilityModel(newModel);
                        }}

                    />
                </div>



                <h2> Module Ediditique </h2>
                <Module_Editique conntected_employee_id={"1234"} related_collection={"session_formation"} related_collection_recid={"65b378562f89e592bf0e4b78"} />

                <Button variant="contained" className="bton_enreg"
                    onClick={afficher_champs_spec}>
                    Display Spec fields
                </Button>

                <br />



                <div className="div_row">
                    liste des events =
                    {Get_List_Agenda_Events_result && <div> {Get_List_Agenda_Events_result} </div>}

                </div>

                <div className="div_row">
                    local events =
                    <button onClick={(e) => {
                        console.log("#### local events =  ", events);
                        setdialogMessage(" coucou cherif");
                        setOpen(true);
                    }}>
                        display events
                    </button>

                </div>

                <div style={{ "width": "90%", "marginLeft": "1%" }}>
                    <FullCalendar
                        locales={allLocales}
                        locale={'fr'}
                        editable
                        selectable
                        events={events}
                        //events={mysy_events}

                        select={handleSelect}
                        headerToolbar={{
                            start: "today prev next",
                            center: "title",
                            end: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        //eventContent={(info) => <EventItem info={info} />}
                        //plugins={[timeGridPlugin]}
                        plugins={[timeGridPlugin, daygridPlugin, interactionPlugin]}
                        views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                        slotMinTime="07:00"
                        slotMaxTime="20:00"
                        scrollTime='08:00'
                        displayEventEnd={true}
                        eventContent={renderEventContent}
                        eventClick={handleEventClick}


                    />;



                </div>

                <div className="div_row_gauche texte_area_filter" >
                    <TextField
                        name="document_name"
                        label="Choisir un document"
                        select
                        sx={{ m: 1, width: '100%' }}
                        value={p_document_name}
                        onChange={(e) => {
                            setp_document_name(e.target.value);
                        }}
                    >
                        <MenuItem value="convocation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Convocation &nbsp;</MenuItem>
                        <MenuItem value="document2" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Document 2  &nbsp;</MenuItem>
                        <MenuItem value="document3" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Document 3&nbsp;</MenuItem>
                        <MenuItem value="document4" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Document 4 &nbsp;</MenuItem>


                    </TextField>
                </div>

                {p_document_name && <div className="div_row_gauche texte_area_filter" >
                    <TextField
                        name="document_type"
                        label="Type de document"
                        select
                        sx={{ m: 1, width: '100%' }}
                        value={p_document_type}
                        onChange={(e) => {
                            setp_document_type(e.target.value);

                        }}
                    >
                        <MenuItem value="pdf" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Pdf &nbsp;</MenuItem>
                        <MenuItem value="sms" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >SMS  &nbsp;</MenuItem>
                        <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email&nbsp;</MenuItem>


                    </TextField>
                </div>
                }

                {p_document_name && <div className="div_row_gauche texte_area_filter" >
                    <TextField
                        name="document_ref_interne"
                        label="Code Document"
                        sx={{ m: 1, width: '100%' }}
                        value={p_document_ref_interne}
                        onChange={(e) => {
                            setp_document_ref_interne(e.target.value);

                        }}
                    />

                </div>}

                {p_document_name && <div className="div_row_gauche texte_area_filter" >
                    <TextField
                        name="document_sujet"
                        label="Sujet"
                        sx={{ m: 1, width: '100%' }}
                        value={p_document_sujet}
                        onChange={(e) => {
                            setp_document_sujet(e.target.value);

                        }}
                    />

                </div>}



                <div className="div_row">
                    <div style={{ "width": "48%", "marginLeft": "1rem", "float": "left" }}>
                        <div className="titre_training_text"> Programme (max 1000 caractères) </div>
                        <Editor
                            id="cherif"
                            onInit={(evt, editor) => editorRef_programme.current = editor}
                            initialValue={field_programme}
                            onKeyUp={editor_keyup}
                            onEditorChange={(newValue, editor) => {
                                if (String(editor_tochange) === "1") {
                                    //alert(" change");
                                    DataUpdated();
                                    seteditor_tochange("");
                                }
                            }}
                            init={{
                                resize: false,
                                height: 500,
                                menubar: true,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace',
                                    'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
                                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',

                                ],
                                toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                    'alignleft aligncenter alignright alignjustify | ' +
                                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',

                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                            }}
                        />
                        <Button className="bton_enreg" onClick={Add_one_Partner_Document} style={{ "margin": "1rem" }}
                        >Ajouter &nbsp;

                        </Button>


                        <Button className="bton_suppr" onClick={delete_document} style={{ "margin": "1rem" }}
                        > Supprimer &nbsp;

                        </Button>
                    </div>



                    <div style={{ "width": "48%", "marginLeft": "1rem", "float": "left" }}>
                        <div className="titre1"> Action </div>


                        <div className="div_row">

                            <div className="session_caract"> Choisir un objet  <br />
                                <TextField
                                    name="collection"
                                    id="collection"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_collection}
                                    onChange={(e) => {
                                        setp_collection(e.target.value);


                                    }}
                                >

                                    {Get_Personnalisable_Collections_result &&
                                        Get_Personnalisable_Collections_result.map((mycollection) => (
                                            <MenuItem key={JSON.parse(mycollection).collection_technical_name}
                                                value={JSON.parse(mycollection).collection_technical_name}
                                                style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                                {JSON.parse(mycollection).collection_fonctional_name}&nbsp;&nbsp;

                                            </MenuItem>
                                        ))}


                                </TextField>

                            </div>
                            {<div className="session_caract"> Choisir un champ  <br />
                                <TextField
                                    name="champ_table"
                                    id="champ_table"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_champ_table}
                                    onChange={(e) => {
                                        setp_champ_table(e.target.value);
                                    }}
                                >

                                    {Get_Personnalisable_Collection_and_Fields_result &&
                                        Get_Personnalisable_Collection_and_Fields_result.filter((local_mymanager) => JSON.parse(local_mymanager).collection_technical_name === p_collection).map((mycollection) => (
                                            <MenuItem key={JSON.parse(mycollection).field_technical_name}
                                                value={JSON.parse(mycollection).field_technical_name}
                                                style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                                {JSON.parse(mycollection).field_fonctional_name}&nbsp;&nbsp;

                                            </MenuItem>
                                        ))}
                                </TextField>

                            </div>}




                        </div>



                        <Button className="bton_enreg" onClick={add_selected_fields} style={{ "margin": "1rem" }}
                        >Ajouter Champ Choisi &nbsp;

                        </Button>

                    </div>
                </div>
            </div>
        </div >

    )
}


export default TestUrl_New;