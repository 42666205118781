import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove, FcInfo } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcSearch } from "react-icons/fc";
import { FcAlarmClock, FcMultipleSmartphones, FcWorkflow } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import 'moment/locale/fr';

import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle, AiOutlineConsoleSql } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RestoreFromTrashRounded } from "@mui/icons-material";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { Fab } from "@material-ui/core";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import { confirmAlert } from 'react-confirm-alert';
import ToggleSwitch from "./ToggleSwitch";
import Module_Historique_Action from "./Module_Historique_Action";
import { HiDocumentCheck } from "react-icons/hi2";
import { BsSend } from "react-icons/bs";
import Autocomplete from '@mui/material/Autocomplete';
import { FcOpenedFolder } from "react-icons/fc";
import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import Module_Editique from "./Module_Editique";
import Module_Attestation_Formation from "./Module_Attestation_Formation";

import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint, FcPlanner } from "react-icons/fc";
import { CiBoxList } from "react-icons/ci";
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import allLocales from '@fullcalendar/core/locales-all'
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { FcExport, FcBullish } from "react-icons/fc";

import { AiOutlineClear } from "react-icons/ai";
import { Parser } from "html-to-react";

const Module_IA = (props) => {

    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);

    const htmlParser = new Parser();
    const [formulaire_parent, setformulaire_parent] = useState("0");

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [isLoading, setLoading] = useState();

    useEffect(() => {
        if (props.champ_text_input) {
            const regex = /(<([^>]+)>)/gi;
            var initial_text_no_html = props.champ_text_input.replace(regex, " ");

            initial_text_no_html = initial_text_no_html.replace(/[^ -~]/g, function (chr) {
                return "&#" + chr.charCodeAt(0) + ";";
            });

            initial_text_no_html = htmlParser.parse(initial_text_no_html)

            setfield_texte_initial(initial_text_no_html);
            setformulaire_parent("1");
        } else {
            setfield_texte_initial("");
            setformulaire_parent("");
        }

    }, [])


    const editorRef_texte_initial = useRef(null);
    const [editorRef_texte_initial_limite, seteditorRef_texte_initial] = useState(1000);
    const [field_texte_initial, setfield_texte_initial] = useState("");
    const [field_texte_initial_initial_value, setfield_texte_initial_initial_value] = useState("");


    const editorRef_texte_resultat = useRef(null);
    const [editorRef_texte_resultat_limite, seteditorRef_texte_resultat] = useState(1000);
    const [field_texte_resultat, setfield_texte_resultat] = useState("");



    const [is_data_changed, setis_data_changed] = useState("");

    const [editor_tochanged, seteditor_tochanged] = useState("");

    function editor_keyup() {
        setis_data_changed("1");
    }

    const [Improve_Texte_api, setImprove_Texte_api] = useState();
    const [Improve_Texte_message, setImprove_Texte_message] = useState();
    const [Improve_Texte_result, setImprove_Texte_result] = useState();
    function Improve_Texte() {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        var initial_text_no_html = ""
        const regex = /(<([^>]+)>)/gi;
        var initial_text_no_html = field_texte_initial.replace(regex, " ");

        initial_text_no_html = initial_text_no_html.replace(/[^ -~]/g, function (chr) {
            //                    ^^^^^^ 
            // this is a regexp for "everything than printable ASCII-characters"
            // and even works in a ASCII-only charset. Identic: [^\u0020-\u007E]
            return "&#" + chr.charCodeAt(0) + ";";
        });

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("text_input", initial_text_no_html);
        form.append("action", "Improve");


        //console.log(" ### form = ", form);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/mysy_openai_improve_text/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Improve_Texte  res.data.status = " + res.data.status);
            //console.log(" In Improve_Texte  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                //alert(res.data.message);

                var mylocal_document = JSON.parse(res.data.message);
                setfield_texte_resultat(mylocal_document.reponse);
                //console.log(" ### mylocal_document.reponse = ", mylocal_document.reponse);

            }
            else {
                setImprove_Texte_api("false");
                setImprove_Texte_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Improve_Texte : Not good man :( mysearchtext = ' + error);
            setImprove_Texte_api("false");
            alert(" Impossible de lancer l'améliration du texte");
        })
    }

    const [Summurize_Texte_api, setSummurize_Texte_api] = useState();
    const [Summurize_Texte_message, setSummurize_Texte_message] = useState();
    const [Summurize_Texte_result, setSummurize_Texte_result] = useState();
    function Summurize_Texte() {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        var initial_text_no_html = ""
        const regex = /(<([^>]+)>)/gi;
        var initial_text_no_html = field_texte_initial.replace(regex, " ");

        initial_text_no_html = initial_text_no_html.replace(/[^ -~]/g, function (chr) {
            //                    ^^^^^^ 
            // this is a regexp for "everything than printable ASCII-characters"
            // and even works in a ASCII-only charset. Identic: [^\u0020-\u007E]
            return "&#" + chr.charCodeAt(0) + ";";
        });

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("text_input", initial_text_no_html);
        form.append("action", "Improve");


        //console.log(" ### form = ", form);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/mysy_openai_summarize_text/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Summurize_Texte  res.data.status = " + res.data.status);
            //console.log(" In Summurize_Texte  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                //alert(res.data.message);

                var mylocal_document = JSON.parse(res.data.message);
                setfield_texte_resultat(mylocal_document.reponse);
                //console.log(" ### mylocal_document.reponse = ", mylocal_document.reponse);

            }
            else {
                setSummurize_Texte_api("false");
                setSummurize_Texte_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Summurize_Texte : Not good man :( mysearchtext = ' + error);
            setSummurize_Texte_api("false");
            alert(" Impossible de lancer l'améliration du texte");
        })
    }

    function clean_text() {
        //setfield_texte_initial('');
        setfield_texte_resultat('');
    }

    function accept_replace() {
        if (props.champ_text_input && props.function_replace_text) {
            props.function_replace_text(field_texte_resultat);
            props.close_function();
        }
    }


    return (
        <div className="moduleia" >

            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}
            {formulaire_parent && String(formulaire_parent) === "" &&
                <nav style={{ "border": "None", "fontSize": "20px", "paddingTop": "10px", "paddingLeft": "5px" }}>
                    Module MySyIA
                </nav>}

            {formulaire_parent && String(formulaire_parent) === "" && <div className="div_row">
                L’assistance d’intelligence artificiel de <br />
                MySy Training Technology
            </div>}


            <div style={{ width: "90%", margin: "auto" }}>
                <div className="div_row vousaider" >
                    Comment puis-je vous aider ?
                </div>
                <div className="div_row">
                    <div className="div_row" style={{ "textAlign": 'left' }}>
                        <b> Votre texte  </b>
                    </div>


                    <textarea
                        minRows={2}
                        value={field_texte_initial}
                        onChange={(e) => {

                            setfield_texte_initial(e.target.value);
                        }
                        }
                        multiline

                        maxRows={10}
                        rows={5}
                        className="block_text"
                        placeholder="Saisir votre texte.."
                    />




                </div>


                <div className="div_row">
                    <ul className="ul_bouton">
                        <li> <Button className="li_button" onClick={(e) => {
                            Summurize_Texte();
                        }}> <FcExport style={{ fontSize: '15px' }} /> &nbsp; résumer un texte</Button>   </li>

                        <li> <Button className="li_button" onClick={(e) => {
                            Improve_Texte();
                        }}> <FcBullish style={{ fontSize: '15px' }} /> &nbsp; Améliorer un texte</Button>   </li>

                    </ul>
                </div>



                <div className="div_row" style={{ "textAlign": 'left' }}>
                    <b> Résultat </b>
                </div>
                <div className="div_row" >

                    {/* <Editor
                        id="detail_document_resultat"
                        name="detail_document_resultat"
                        onInit={(evt, editor) => editorRef_texte_resultat.current = editor}
                        initialValue={field_texte_resultat}


                        value={field_texte_resultat}
                        onEditorChange={(newValue, editor) => {
                            setfield_texte_resultat(editorRef_texte_resultat.current.getContent());

                        }}
                        init={{
                            resize: false,
                            height: 300,
                            menubar: true,

                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace',
                                'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
                                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',

                            ],
                            toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',

                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                        }}
                    />*/}

                    <textarea
                        minRows={2}
                        value={field_texte_resultat}

                        multiline

                        maxRows={10}
                        rows={5}
                        className="block_text_resultat"
                        placeholder="Le resultat..."
                    />


                </div>
                <div className="div_row">
                    &nbsp;
                </div>

                <div className="div_row">
                    {String(formulaire_parent) === "1" && <div style={{ width: '45%', float: 'left', textAlign: 'left' }}> <Button className="li_button"
                        onClick={(e) => {
                            accept_replace();
                        }}
                    > <FcAcceptDatabase style={{ fontSize: '15px' }} /> &nbsp; Accepter et remplacer</Button>  </div>}


                    <div style={{ width: '45%', float: 'right', marginRight: "5px", textAlign: 'right' }}> <Button className="li_button"
                        onClick={(e) => {
                            clean_text();
                        }} >
                        <AiOutlineClear style={{ fontSize: '15px' }} /> &nbsp; Effacer</Button>  </div>


                </div>

                <div className="div_row">
                    &nbsp;
                </div>



            </div>

        </div >
    );
}
export default Module_IA;