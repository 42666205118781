import Add_Articles_Avis from "../components/AddArticleAvis";
import Navigation from "../components/Navigation";

const addarticleavis = () => {


    return (
        <div>
            <Navigation />
            <div>
                <Add_Articles_Avis />
            </div>

        </div>

    )
}


export default addarticleavis;
