import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import excel_icone from "../mysy_img/excel_icone.png";

import fileDownload from 'js-file-download'
import { FcInfo } from "react-icons/fc";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { FcAcceptDatabase, } from "react-icons/fc";
import { format } from 'date-fns'
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle, AiOutlineConsoleSql } from "react-icons/ai";
import Box from '@mui/material/Box';

import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { Fab } from "@material-ui/core";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { AiTwotoneSave } from "react-icons/ai";
import { FcEditImage } from "react-icons/fc";
import ToggleSwitch from "./ToggleSwitch";

import { FcSpeaker } from "react-icons/fc";
import Autocomplete from '@mui/material/Autocomplete';
import { FcOpenedFolder } from "react-icons/fc";
import Module_Absence from "./Module_Absence";
import { RiMoreFill } from "react-icons/ri";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import 'moment/locale/fr';
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import {
    GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton,
    GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExportContainer, useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridValueGetterParams,
    GridPreferencePanelsValue,

} from '@mui/x-data-grid';
  import * as XLSX from 'xlsx';

const Apprenant = (props) => {

    const history = useHistory();
    const [submenu, setsubmenu] = useState("");



    const filters = [
        { value: 'email', label: 'Email' },
        { value: 'nom', label: 'Nom' },
        { value: 'prenom', label: 'Prénom' }
    ]

    const [selectedfilt1, setselectedfilt1] = useState("");
    const [selectedfilt2, setselectedfilt2] = useState("");
    const [selectedfilt3, setselectedfilt3] = useState("");


    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : value.slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }



    function Display_Colunm_text({ value }) {

        return (
            <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                {value}&nbsp;

            </div>
        );
    }



    const columns = [
        { field: 'id', headerName: 'id', hide: true , disableExport: true,},
        { field: '_id', headerName: '_id', hide: true , disableExport: true,},

        {
            field: 'civilite', headerName: 'Civ.', minWidth: 100, flex: 1, maxWidth: 100, hide: false, editable: false, editable: false,

            renderCell: (cellValues) => {
                return (

                    <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                        {New_civilite && New_civilite.length > 0 && cellValues.row.civilite &&
                            <nav>
                                {New_civilite.filter((data) => (data).id === String(cellValues.row.civilite))[0].label}


                            </nav>
                        }

                    </div>

                );
            },
        },


        { field: 'nom', headerName: 'Nom', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'prenom', headerName: 'Prénom', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'email', headerName: 'Email', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'telephone', headerName: 'Téléphone', hide: true, editable: false, Width: 0 },
        { field: 'date_naissance', headerName: 'date_naissance', hide: true, editable: false, Width: 0 },
        { field: 'adresse', headerName: 'adresse', hide: true, editable: false, Width: 0 },
        { field: 'code_postal', headerName: 'code_postal', hide: true, editable: false, Width: 0 },
        { field: 'ville', headerName: 'ville', hide: true, editable: false, Width: 0 },
        { field: 'pays', headerName: 'pays', hide: true, editable: false, Width: 0 },
        { field: 'employeur', headerName: 'Employeur', minWidth: 150, hide: true, editable: false },
        { field: 'client_nom', headerName: 'Client', minWidth: 150, flex: 1, hide: false, editable: false },



        {
            field: "Detail", headerName: 'Voir détail', disableExport: true,
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {

                            handleClick_edit_apprenant_From_Line(cellValues.row.id);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },

        {
            field: "delete", headerName: 'Supprimer', disableExport: true,
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'apprenant sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_Apprenant(event, cellValues);

                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        },


    ]


    const columns_apprenant_inscriptions = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'session_id', headerName: 'session_id', hide: true, Width: 1 },
        { field: 'session_code', headerName: 'Code Session', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'class_title', headerName: 'Titre Formation', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'class_internal_url', headerName: 'class_internal_url', hide: true, Width: 1 },
        {
            field: 'status', headerName: 'Statut', hide: false, flex: 1, minWidth: 50, maxWidth: 70,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Pré.";
                else if (String(params.value) === "1")
                    return "Ins";
                else if (String(params.value) === "-1")
                    return "Ann";
                else if (String(params.value) === "2")
                    return "Enc";
                else
                    return "???";
            }
        },

        { field: 'client_rattachement_id', headerName: 'client__id', hide: true, Width: 1 },
        { field: 'client_rattachement_nom', headerName: 'Client', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },

        { field: 'date_debut', headerName: 'Début', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'date_fin', headerName: 'Fin', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'presentiel', headerName: 'Présentiel', hide: false, flex: 1, minWidth: 50, maxWidth: 70 },
        { field: 'distanciel', headerName: 'Distantiel', hide: false, flex: 1, minWidth: 50, maxWidth: 70 },

        { field: 'class_recyclage_delai', headerName: 'Recycl. Delai', hide: false, flex: 1, minWidth: 100, },
        { field: 'class_recyclage_periodicite', headerName: 'Recycl. Perio', hide: false, flex: 1, minWidth: 100, },
        { field: 'nb_jour_avant_recyclage', headerName: 'Jrs avant Recycl.', hide: false, flex: 1, minWidth: 100, },


        {
            field: "Detail", headerName: 'Voir détail',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {

                            if (cellValues.row._id && String(cellValues.row._id).length > 2) {
                                var tmp = cellValues.row.session_code + "&" + cellValues.row.session_id + "&" + cellValues.row.class_internal_url + "&" + p_detail_email;

                                window.open(
                                    process.env.REACT_APP_FRONT_URL + "Partner/mes_stagiaires/" + String(cellValues.row._id) + "/" + String(tmp),
                                    '_blank'
                                );
                            }
                            //handleClick_edit_apprenant_From_Line(cellValues.row.id);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },

        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'inscription sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            //handleClick_delete_Apprenant(event, cellValues);

                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        },


    ]


    const columns_suivi_pedagogique = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'apprenant_id', headerName: 'apprenant_id', hide: true, Width: 1 },
        { field: 'class_id', headerName: 'class_id', hide: true, Width: 1 },
        { field: 'session_id', headerName: 'session_id', hide: true, Width: 1 },
        { field: 'class_eu_id', headerName: 'class_eu_id', hide: true, Width: 1 },
        { field: 'responsable_id', headerName: 'responsable_id', hide: true, Width: 1 },

        { field: 'date_debut', headerName: 'Début', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'date_fin', headerName: 'Fin', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },

        {
            field: 'responsable', headerName: 'responsable', hide: false, minWidth: 200, flex: 1,


            renderCell: (cellValues) => {
                return (
                    <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                        {New_Getall_Training_Employee_No_Filter_result && New_Getall_Training_Employee_No_Filter_result.length > 0 && <nav>
                            {New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === String(cellValues.row.responsable_id))[0].label}
                        </nav>}
                    </div>

                );
            }


        },

        { field: 'class_title', headerName: 'Titre Formation', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'session_code', headerName: 'Code Session', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'ue_code', headerName: 'UE Code', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'ue_titre', headerName: 'UE Titre', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },



        {
            field: "Detail", headerName: 'Voir détail',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {

                            //handleClick_edit_apprenant_From_Line(cellValues.row.id);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },

        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le suivi pédagogique sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_suivi_pedago(event, cellValues);

                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        },


    ]


    function handleClick_delete_suivi_pedago(event, cellValues) {

        setsuivi_pedago_gridline_id('');
        setdisplay_given_suivi_ped('');
        setgiven_suivi_ped_changed('');
        setgiven_suivi_ped_data_edit_mode('');
        setadd_one_suivi_ped("");

        var local_apprenant_id = cellValues.row._id;

        Delete_Suivi_Pedago_Data(local_apprenant_id);
    }


    const [Delete_Suivi_Pedago_Data_api, setDelete_Suivi_Pedago_Data_api] = useState();
    const [Delete_Suivi_Pedago_Data_message, setDelete_Suivi_Pedago_Data_message] = useState();
    const [Delete_Suivi_Pedago_Data_result, setDelete_Suivi_Pedago_Data_result] = useState();
    function Delete_Suivi_Pedago_Data(suivi_pedago_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", suivi_pedago_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Suivi_Pedagogique/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Suivi_Pedago_Data  res.data.status = " + res.data.status);
            //console.log(" In Delete_Suivi_Pedago_Data  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setDelete_Suivi_Pedago_Data_api("true");
                setDelete_Suivi_Pedago_Data_result(res.data.message);

                setselectionModel_suivi_pedagogique([]);

                setsuivi_pedago_gridline_id('');
                setdisplay_given_suivi_ped('');
                setgiven_suivi_ped_changed('');
                setgiven_suivi_ped_data_edit_mode('');
                setadd_one_suivi_ped("");

                Get_Appenant_List_Suivi_Pedagogique(selected_apprenant_id);

                alert(res.data.message);
            }
            else {
                setDelete_Suivi_Pedago_Data_api("false");
                setDelete_Suivi_Pedago_Data_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.log('Delete_Suivi_Pedago_Data : Not good man :( Delete_Suivi_Pedago_Data = ' + error);
            setDelete_Suivi_Pedago_Data_api("false");
            alert(" Impossible de supprimer le suivi pédagogique ");
        })
    }


    function handleClick_delete_Apprenant(event, cellValues) {

        setgridline_id('');
        setadd_One_Apprenant('');
        setapprenant_data_edit_mode('');
        setapprenant_data_changed('');

        var local_apprenant_id = cellValues.row._id;


        Delete_Apprenant_Data(local_apprenant_id);
    }


    const [p_detail_civilite, setp_detail_civilite] = useState("");
    const [p_detail_email, setp_detail_email] = useState("");
    const [p_detail_naissance, setp_detail_naissance] = useState(new Date().toLocaleDateString('fr-FR'));

    const [p_detail_nom, setp_detail_nom] = useState("");
    const [p_detail_prenom, setp_detail_prenom] = useState("");
    const [p_detail_telephone, setp_detail_telephone] = useState("");
    const [p_detail_employeur, setp_detail_employeur] = useState("");
    const [p_detail_client_rattachement_id, setp_detail_client_rattachement_id] = useState("");
    const [p_detail_adresse, setp_detail_adresse] = useState("");
    const [p_detail_code_postal, setp_detail_code_postal] = useState("");
    const [p_detail_ville, setp_detail_ville] = useState("");
    const [p_detail_pays, setp_detail_pays] = useState("");
    const [p_detail_tuteur1_nom, setp_detail_tuteur1_nom] = useState("");

    const [p_detail_tuteur1_civilite, setp_detail_tuteur1_civilite] = useState("");


    const [p_detail_tuteur1_prenom, setp_detail_tuteur1_prenom] = useState("");
    const [p_detail_tuteur1_email, setp_detail_tuteur1_email] = useState("");
    const [p_detail_tuteur1_telephone, setp_detail_tuteur1_telephone] = useState("");
    const [p_detail_tuteur1_adresse, setp_detail_tuteur1_adresse] = useState("");
    const [p_detail_tuteur1_cp, setp_detail_tuteur1_cp] = useState("");
    const [p_detail_tuteur1_ville, setp_detail_tuteur1_ville] = useState("");
    const [p_detail_tuteur1_pays, setp_detail_tuteur1_pays] = useState("");
    const [p_detail_tuteur1_include_com, setp_detail_tuteur1_include_com] = useState(false);
    const [p_detail_tuteur2_nom, setp_detail_tuteur2_nom] = useState("");

    const [p_detail_tuteur2_civilite, setp_detail_tuteur2_civilite] = useState("");

    const [p_detail_tuteur2_prenom, setp_detail_tuteur2_prenom] = useState("");
    const [p_detail_tuteur2_email, setp_detail_tuteur2_email] = useState("");
    const [p_detail_tuteur2_telephone, setp_detail_tuteur2_telephone] = useState("");
    const [p_detail_tuteur2_adresse, setp_detail_tuteur2_adresse] = useState("");
    const [p_detail_tuteur2_cp, setp_detail_tuteur2_cp] = useState("");
    const [p_detail_tuteur2_ville, setp_detail_tuteur2_ville] = useState("");
    const [p_detail_tuteur2_pays, setp_detail_tuteur2_pays] = useState("");
    const [p_detail_tuteur2_include_com, setp_detail_tuteur2_include_com] = useState(false);
    const [p_detail_date_creation, setp_detail_date_creation] = useState("");


    const [p_one_civilite, setp_one_civilite] = useState("");
    const [p_one_email, setp_one_email] = useState("");

    const [p_one_naissance, setp_one_naissance] = useState(new Date().toLocaleDateString('fr-FR'));



    const [p_one_nom, setp_one_nom] = useState("");
    const [p_one_prenom, setp_one_prenom] = useState("");
    const [p_one_telephone, setp_one_telephone] = useState("");
    const [p_one_employeur, setp_one_employeur] = useState("");
    const [p_one_client_rattachement_id, setp_one_client_rattachement_id] = useState("");
    const [p_one_adresse, setp_one_adresse] = useState("");
    const [p_one_code_postal, setp_one_code_postal] = useState("");
    const [p_one_ville, setp_one_ville] = useState("");
    const [p_one_pays, setp_one_pays] = useState("");
    const [p_one_tuteur1_nom, setp_one_tuteur1_nom] = useState("");
    const [p_one_tuteur1_prenom, setp_one_tuteur1_prenom] = useState("");
    const [p_one_tuteur1_email, setp_one_tuteur1_email] = useState("");
    const [p_one_tuteur1_telephone, setp_one_tuteur1_telephone] = useState("");
    const [p_one_tuteur1_adresse, setp_one_tuteur1_adresse] = useState("");
    const [p_one_tuteur1_cp, setp_one_tuteur1_cp] = useState("");
    const [p_one_tuteur1_ville, setp_one_tuteur1_ville] = useState("");
    const [p_one_tuteur1_pays, setp_one_tuteur1_pays] = useState("");
    const [p_one_tuteur1_include_com, setp_one_tuteur1_include_com] = useState("");
    const [p_one_tuteur2_nom, setp_one_tuteur2_nom] = useState("");
    const [p_one_tuteur2_prenom, setp_one_tuteur2_prenom] = useState("");
    const [p_one_tuteur2_email, setp_one_tuteur2_email] = useState("");
    const [p_one_tuteur2_telephone, setp_one_tuteur2_telephone] = useState("");
    const [p_one_tuteur2_adresse, setp_one_tuteur2_adresse] = useState("");
    const [p_one_tuteur2_cp, setp_one_tuteur2_cp] = useState("");
    const [p_one_tuteur2_ville, setp_one_tuteur2_ville] = useState("");
    const [p_one_tuteur2_pays, setp_one_tuteur2_pays] = useState("");
    const [p_one_tuteur2_include_com, setp_one_tuteur2_include_com] = useState("");

    const [field_detail_civilite, setfield_detail_civilite] = useState("");
    const [field_detail_email, setfield_detail_email] = useState("");
    const [field_detail_nom, setfield_detail_nom] = useState("");
    const [field_detail_prenom, setfield_detail_prenom] = useState("");
    const [field_detail_telephone, setfield_detail_telephone] = useState("");
    const [field_detail_employeur, setfield_detail_employeur] = useState("");
    const [field_detail_client_rattachement_id, setfield_detail_client_rattachement_id] = useState("");
    const [field_detail_adresse, setfield_detail_adresse] = useState("");
    const [field_detail_code_postal, setfield_detail_code_postal] = useState("");
    const [field_detail_ville, setfield_detail_ville] = useState("");
    const [field_detail_pays, setfield_detail_pays] = useState("");
    const [field_detail_tuteur1_nom, setfield_detail_tuteur1_nom] = useState("");
    const [field_detail_tuteur1_prenom, setfield_detail_tuteur1_prenom] = useState("");
    const [field_detail_tuteur1_email, setfield_detail_tuteur1_email] = useState("");
    const [field_detail_tuteur1_telephone, setfield_detail_tuteur1_telephone] = useState("");


    const [field_detail_tuteur1_adresse, setfield_detail_tuteur1_adresse] = useState("");
    const [field_detail_tuteur1_cp, setfield_detail_tuteur1_cp] = useState("");
    const [field_detail_tuteur1_ville, setfield_detail_tuteur1_ville] = useState("");
    const [field_detail_tuteur1_pays, setfield_detail_tuteur1_pays] = useState("");
    const [field_detail_tuteur1_include_com, setfield_detail_tuteur1_include_com] = useState("");


    const [field_detail_tuteur2_nom, setfield_detail_tuteur2_nom] = useState("");
    const [field_detail_tuteur2_prenom, setfield_detail_tuteur2_prenom] = useState("");
    const [field_detail_tuteur2_email, setfield_detail_tuteur2_email] = useState("");
    const [field_detail_tuteur2_telephone, setfield_detail_tuteur2_telephone] = useState("");



    const [field_detail_tuteur2_adresse, setfield_detail_tuteur2_adresse] = useState("");
    const [field_detail_tuteur2_cp, setfield_detail_tuteur2_cp] = useState("");
    const [field_detail_tuteur2_ville, setfield_detail_tuteur2_ville] = useState("");
    const [field_detail_tuteur2_pays, setfield_detail_tuteur2_pays] = useState("");
    const [field_detail_tuteur2_include_com, setfield_detail_tuteur2_include_com] = useState("");




    const [selected_apprenant_id, setselected_apprenant_id] = useState("");
    const [selected_apprenant_row_id, setselected_apprenant_row_id] = useState("");



    const [Get_Current_Apprenant_Data_api, setGet_Current_Apprenant_Data_api] = useState();
    const [Get_Current_Apprenant_Data_message, setGet_Current_Apprenant_Data_message] = useState();
    const [Get_Current_Apprenant_Data_result, setGet_Current_Apprenant_Data_result] = useState();
    function Get_Current_Apprenant_Data(local_selected_apprenant_id) {

        var my_id = local_selected_apprenant_id;
        if (!local_selected_apprenant_id)
            my_id = selected_apprenant_id

        Clear_Detail_Apprenant_Fields();

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", my_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Apprenant_Data/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_Current_Apprenant_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Current_Apprenant_Data  res.data.message r_class = " + res.data.message);
                //console.log(" In Get_Current_Apprenant_Data  res.data.message len = " + String(res.data.message).length);

                setLoading(false);
                setGet_Current_Apprenant_Data_api("true");
                if (String(res.data.message).length > 0) {
                    setGet_Current_Apprenant_Data_result(res.data.message);
                    var mylocaltraining = JSON.parse(res.data.message);

                    if (mylocaltraining.civilite) {
                        setp_detail_civilite(mylocaltraining.civilite);
                    }

                    if (mylocaltraining.email) {
                        setp_detail_email(mylocaltraining.email);
                    }

                    if (mylocaltraining.date_naissance) {
                        setp_detail_naissance(mylocaltraining.date_naissance);
                    }

                    if (mylocaltraining.nom) {
                        setp_detail_nom(mylocaltraining.nom);
                    }

                    if (mylocaltraining.prenom) {
                        setp_detail_prenom(mylocaltraining.prenom);
                    }

                    if (mylocaltraining.telephone) {
                        setp_detail_telephone(mylocaltraining.telephone);
                    }

                    if (mylocaltraining.employeur) {
                        setp_detail_employeur(mylocaltraining.employeur);
                    }

                    if (mylocaltraining.client_rattachement_id) {
                        setp_detail_client_rattachement_id(mylocaltraining.client_rattachement_id);
                    }

                    if (mylocaltraining.adresse) {
                        setp_detail_adresse(mylocaltraining.adresse);
                    }

                    if (mylocaltraining.code_postal) {
                        setp_detail_code_postal(mylocaltraining.code_postal);
                    }

                    if (mylocaltraining.ville) {
                        setp_detail_ville(mylocaltraining.ville);
                    }

                    if (mylocaltraining.pays) {
                        setp_detail_pays(mylocaltraining.pays);
                    }

                    if (mylocaltraining.tuteur1_nom) {
                        setp_detail_tuteur1_nom(mylocaltraining.tuteur1_nom);
                    }

                    if (mylocaltraining.tuteur1_civilite) {
                        setp_detail_tuteur1_civilite(mylocaltraining.tuteur1_civilite);
                    } else {
                        setp_detail_tuteur1_civilite("");
                    }


                    if (mylocaltraining.tuteur1_prenom) {
                        setp_detail_tuteur1_prenom(mylocaltraining.tuteur1_prenom);
                    }

                    if (mylocaltraining.tuteur1_email) {
                        setp_detail_tuteur1_email(mylocaltraining.tuteur1_email);
                    }

                    if (mylocaltraining.tuteur1_telephone) {
                        setp_detail_tuteur1_telephone(mylocaltraining.tuteur1_telephone);
                    }

                    if (mylocaltraining.tuteur1_adresse) {
                        setp_detail_tuteur1_adresse(mylocaltraining.tuteur1_adresse);
                    }

                    if (mylocaltraining.tuteur1_cp) {
                        setp_detail_tuteur1_cp(mylocaltraining.tuteur1_cp);
                    }

                    if (mylocaltraining.tuteur1_ville) {
                        setp_detail_tuteur1_ville(mylocaltraining.tuteur1_ville);
                    }

                    if (mylocaltraining.tuteur1_pays) {
                        setp_detail_tuteur1_pays(mylocaltraining.tuteur1_pays);
                    }

                    if (mylocaltraining.tuteur1_include_com) {
                        setp_detail_tuteur1_include_com(false);
                        if (String(mylocaltraining.tuteur2_include_com) === "1")
                            setp_detail_tuteur1_include_com(true);

                    }

                    if (mylocaltraining.tuteur2_nom) {
                        setp_detail_tuteur2_nom(mylocaltraining.tuteur2_nom);
                    }

                    if (mylocaltraining.tuteur2_civilite) {
                        setp_detail_tuteur2_civilite(mylocaltraining.tuteur2_civilite);
                    } else {
                        setp_detail_tuteur2_civilite("");
                    }

                    if (mylocaltraining.tuteur2_prenom) {
                        setp_detail_tuteur2_prenom(mylocaltraining.tuteur2_prenom);
                    }

                    if (mylocaltraining.tuteur2_email) {
                        setp_detail_tuteur2_email(mylocaltraining.tuteur2_email);
                    }

                    if (mylocaltraining.tuteur2_telephone) {
                        setp_detail_tuteur2_telephone(mylocaltraining.tuteur2_telephone);
                    }

                    if (mylocaltraining.tuteur2_adresse) {
                        setp_detail_tuteur2_adresse(mylocaltraining.tuteur2_adresse);
                    }

                    if (mylocaltraining.tuteur2_cp) {
                        setp_detail_tuteur2_cp(mylocaltraining.tuteur2_cp);
                    }

                    if (mylocaltraining.tuteur2_ville) {
                        setp_detail_tuteur2_ville(mylocaltraining.tuteur2_ville);
                    }

                    if (mylocaltraining.tuteur2_pays) {
                        setp_detail_tuteur2_pays(mylocaltraining.tuteur2_pays);
                    }

                    if (mylocaltraining.tuteur2_include_com) {
                        setp_detail_tuteur2_include_com(false);
                        if (String(mylocaltraining.tuteur2_include_com) === "1")
                            setp_detail_tuteur2_include_com(true);
                    }

                    if (mylocaltraining.date_creation) {
                        setp_detail_date_creation(mylocaltraining.date_creation);
                    }


                    /*
               * Update 22/10/2023 :
               Gestion des champs spécifiques. ils commencent tous par 'my_'
               */

                    for (let i = 0; i < rows_champs_specifics.length; i++) {

                        var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
                        var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
                        var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
                        var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;



                        if (mylocaltraining.hasOwnProperty(field_name)) {
                            var local_valeur = mylocaltraining[String(field_name)]

                            if (document.getElementById(String(field_name)))
                                document.getElementById(String(field_name)).value = local_valeur;

                            var new_val = { 'field_name': field_name, 'field_value': local_valeur, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                            var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);

                            if (johnIndex > -1) {
                                spec_field_updated_values[johnIndex] = new_val;

                            } else {
                                spec_field_updated_values.push(new_val);
                            }

                        } else {
                            if (document.getElementById(String(field_name)))
                                document.getElementById(String(field_name)).value = "";
                        }
                    }

                    setspec_field_updated_values_hooks(spec_field_updated_values);
                    /*
                                       *  end Update 22/10/2023 :
                                       */


                    Disable_Detail_Apprenant_Fields();
                    submenu_detail_apprenant();

                    if (document.getElementById('detail_apprenant')) {
                        // myRef.current.scrollIntoView({ behavior: "smooth" });
                        var divh = document.getElementById('detail_apprenant').offsetTop;
                        window.scrollTo({
                            top: divh,
                            behavior: "smooth",
                        });
                    }
                }

            } else if (String(res.data.status) === String("Err_Connexion")) {
                setLoading(false);
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setLoading(false);
                setGet_Current_Apprenant_Data_api("false");
                setGet_Current_Apprenant_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Current_Apprenant_Data = ', error);
            setGet_Current_Apprenant_Data_api("false");
            alert(" Impossible de récuperer les données de l'apprenant ");

        })
    }



    const [Record_All_PJ_api, setRecord_All_PJ_api] = useState();
    const [Record_All_PJ_result, setRecord_All_PJ_result] = useState();
    const [Record_All_PJ_message, setRecord_All_PJ_message] = useState();
    function Record_All_PJ() {

        if (!p_detail_one_file_to_download_type_name) {
            alert(" Vous devez donner un nom à la pièce jointe");
            return;
        }

        if (file_1_name && file_1_name.name) {
            console.log(" ### Traitement de  : ", file_1_name.name);

            const formData = new FormData();
            formData.append('File', file_1_name);
            //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
            const stored_cookie = getCookie('tokenmysypart');

            formData.append("token", stored_cookie);
            formData.append("file_business_object", p_detail_one_file_to_download_type_name);
            formData.append("file_name", file_1_name.name);
            formData.append("status", "0");
            formData.append("object_owner_collection", "apprenant");
            formData.append("object_owner_id", selected_apprenant_id);

            //formData.append("class_internal_url", internal_url);
            //console.log("token = " + stored_cookie);


            fetch(
                process.env.REACT_APP_API_URL + "myclass/api/Store_User_Downloaded_File/",
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {

                    //console.log(" ## result['status'] = ", result['status'])
                    if (String(result['status']) === String("true")) {
                        //console.log('Success:', result['message']);
                        setRecord_All_PJ_result(result['message']);
                        setRecord_All_PJ_api("true");

                        Get_List_Of_All_PJ(selected_apprenant_id);
                        setfile_1_name();
                        setp_detail_one_file_to_download_type_name();
                        //GetCurrentClass_trainingsession();
                        alert(" La pièce jointe " + file_1_name.name + " a été bien enregistrée")

                    }
                    else {
                        setRecord_All_PJ_message(result['message']);
                        setRecord_All_PJ_api("false");
                    }

                })
                .catch((error) => {
                    console.error(' Record_All_PJ Error:', error);
                    setRecord_All_PJ_api("false");
                });

        }

        if (file_2_name && file_2_name.name) {
            console.log(" ### Traitement de  : ", file_2_name.name);
        }

    }


    const [Delete_one_attached_document_api, setDelete_one_attached_document_api] = useState();
    const [Delete_one_attached_document_result, setDelete_one_attached_document_result] = useState();
    const [Delete_one_attached_document_message, setDelete_one_attached_document_message] = useState();
    const Delete_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');

        var formData = new FormData();
        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var file_name = event.target.id;


        formData.append("token", stored_cookie);
        formData.append("file_name", file_name);

        var url = process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/";


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setDelete_one_attached_document_result(result['message']);
                    setDelete_one_attached_document_api("true");
                    Get_List_Of_All_PJ(selected_apprenant_id);
                    alert(result['message'])

                }
                else {
                    setDelete_one_attached_document_message(result['message']);
                    setDelete_one_attached_document_api("false");
                }

            })
            .catch((error) => {
                console.error(' Record_All_PJ Error:', error);
                setDelete_one_attached_document_api("false");
                alert(" Impossible de supprimer le document")

            });


    }


    const [Get_List_Of_All_PJ_api, setGet_List_Of_All_PJ_api] = useState();
    const [Get_List_Of_All_PJ_result, setGet_List_Of_All_PJ_result] = useState();
    const [Get_List_Of_All_PJ_message, setGet_List_Of_All_PJ_message] = useState();
    function Get_List_Of_All_PJ(local_apprenand_id) {

        const formData = new FormData();

        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("object_owner_collection", "apprenant");
        formData.append("object_owner_id", local_apprenand_id);


        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);
        setLoading(true);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Get_List_object_owner_collection_Stored_Files/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Message :', result['message']);
                    setGet_List_Of_All_PJ_result(result['message']);
                    setGet_List_Of_All_PJ_api("true");

                }
                else {
                    setGet_List_Of_All_PJ_message(result['message']);
                    setGet_List_Of_All_PJ_api("false");
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error(' Get_List_Of_All_PJ Error:', error);
                setGet_List_Of_All_PJ_api("false");
            });

    }



    function Delete_file_1_name() {
        setfile_1_name();
    }

    function Delete_file_2_name() {
        setfile_2_name();
    }

    const [p_detail_one_file_to_download_type_name, setp_detail_one_file_to_download_type_name] = useState();



    const [getPartnerImages_api, setgetPartnerImages_api] = useState();
    const [getPartnerImages_message, setgetPartnerImages_message] = useState();
    const [getPartnerImages_result, setgetPartnerImages_result] = useState();
    function Get_Apprenant_Images(local_selected_apprenant_id) {

        var my_id = local_selected_apprenant_id;
        if (!local_selected_apprenant_id)
            my_id = selected_apprenant_id

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Apprenant_Recorded_Image_from_front/";

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("apprenant_id", my_id);



        axios.post(myurl, form).then(res => {

            //console.log(" Get_Apprenant_Images : In test  res.data.status = " + res.data.status);
            // console.log(" Get_Apprenant_Images: res.data.message.img = " + res.data.message);

            if (String(res.data.status) === "true") {
                setgetPartnerImages_api("true");

                if (JSON.parse(res.data.message).logo_img) {
                    var partner_logo_img = "data:image/png;base64," + JSON.parse(res.data.message).logo_img;
                    setisimgclassSelected(partner_logo_img);
                    setuserimgclassprofil(partner_logo_img);
                    setisimgclassSelected_recid(JSON.parse(res.data.message).logo_img_recid);
                }


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {
                setgetPartnerImages_api("false");
                setgetPartnerImages_message(res.data.message)

            }
        }).catch((error) => {
            console.warn('Get_Apprenant_Images ee: Not good man :(  = ', error);
            setgetPartnerImages_api("false");
            setgetPartnerImages_message(" Impossible de recuperer les images/médias de l'apprenant")
        })

    }

    function findIndexByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {

            if (data[i][key] == value) {
                return i;
            }
        }
        return -1;
    }


    function Clear_Detail_Apprenant_Fields() {

        setfield_detail_civilite('');
        setfield_detail_email('');
        setfield_detail_nom('');
        setfield_detail_prenom('');
        setfield_detail_telephone('');
        setfield_detail_employeur('');
        setfield_detail_client_rattachement_id('');
        setfield_detail_adresse('');
        setfield_detail_code_postal('');
        setfield_detail_ville('');
        setfield_detail_pays('');



        setfield_detail_tuteur1_nom('');
        setfield_detail_tuteur1_prenom('');
        setfield_detail_tuteur1_email('');
        setfield_detail_tuteur1_telephone('');
        setfield_detail_tuteur2_nom('');
        setfield_detail_tuteur2_prenom('');
        setfield_detail_tuteur2_email('');
        setfield_detail_tuteur2_telephone('');

        setp_detail_tuteur1_nom('');
        setp_detail_tuteur1_civilite("");
        setp_detail_tuteur1_prenom('');
        setp_detail_tuteur1_email('');
        setp_detail_tuteur1_telephone('');
        setp_detail_tuteur1_adresse('');
        setp_detail_tuteur1_cp('');
        setp_detail_tuteur1_ville('');
        setp_detail_tuteur1_pays('');
        setp_detail_tuteur1_include_com(false);

        setp_detail_tuteur2_nom('');
        setp_detail_tuteur2_civilite("");
        setp_detail_tuteur2_prenom('');
        setp_detail_tuteur2_email('');
        setp_detail_tuteur2_telephone('');
        setp_detail_tuteur2_adresse('');
        setp_detail_tuteur2_cp('');
        setp_detail_tuteur2_ville('');
        setp_detail_tuteur2_pays('');
        setp_detail_tuteur2_include_com(false);

        setp_detail_naissance(new Date().toLocaleDateString('fr-FR'));

        setisimgclassSelected("");
        setuserimgclassprofil("");
        setisimgclassSelected_recid("");




        if (document.getElementsByName("field_detail_civilite") && document.getElementsByName("field_detail_civilite")[0]) {
            document.getElementsByName("field_detail_civilite")[0].value = "";
        }

        if (document.getElementsByName("field_detail_naissance") && document.getElementsByName("field_detail_naissance")[0]) {
            document.getElementsByName("field_detail_naissance")[0].value = "";

        }

        if (document.getElementsByName("field_detail_email") && document.getElementsByName("field_detail_email")[0]) {
            document.getElementsByName("field_detail_email")[0].value = "";

        }

        if (document.getElementsByName("field_detail_nom") && document.getElementsByName("field_detail_nom")[0]) {
            document.getElementsByName("field_detail_nom")[0].value = "";

        }

        if (document.getElementsByName("field_detail_prenom") && document.getElementsByName("field_detail_prenom")[0]) {
            document.getElementsByName("field_detail_prenom")[0].value = "";

        }

        if (document.getElementsByName("field_detail_telephone") && document.getElementsByName("field_detail_telephone")[0]) {
            document.getElementsByName("field_detail_telephone")[0].value = "";

        }

        if (document.getElementsByName("field_detail_employeur") && document.getElementsByName("field_detail_employeur")[0]) {
            document.getElementsByName("field_detail_employeur")[0].value = "";

        }

        if (document.getElementsByName("field_detail_adresse") && document.getElementsByName("field_detail_adresse")[0]) {
            document.getElementsByName("field_detail_adresse")[0].value = "";

        }

        if (document.getElementsByName("field_detail_code_postal") && document.getElementsByName("field_detail_code_postal")[0]) {
            document.getElementsByName("field_detail_code_postal")[0].value = "";

        }

        if (document.getElementsByName("field_detail_ville") && document.getElementsByName("field_detail_ville")[0]) {
            document.getElementsByName("field_detail_ville")[0].value = "";

        }

        if (document.getElementsByName("field_detail_pays") && document.getElementsByName("field_detail_pays")[0]) {
            document.getElementsByName("field_detail_pays")[0].value = "";

        }

        if (document.getElementsByName("field_detail_tuteur1_nom") && document.getElementsByName("field_detail_tuteur1_nom")[0]) {
            document.getElementsByName("field_detail_tuteur1_nom")[0].value = "";

        }

        if (document.getElementsByName("field_detail_tuteur1_prenom") && document.getElementsByName("field_detail_tuteur1_prenom")[0]) {
            document.getElementsByName("field_detail_tuteur1_prenom")[0].value = "";
            ;
        }

        if (document.getElementsByName("field_detail_tuteur1_civilite") && document.getElementsByName("field_detail_tuteur1_civilite")[0]) {
            document.getElementsByName("field_detail_tuteur1_civilite")[0].value = "";
            ;
        }

        if (document.getElementsByName("field_detail_tuteur1_email") && document.getElementsByName("field_detail_tuteur1_email")[0]) {
            document.getElementsByName("field_detail_tuteur1_email")[0].value = "";

        }

        if (document.getElementsByName("field_detail_tuteur1_telephone") && document.getElementsByName("field_detail_tuteur1_telephone")[0]) {
            document.getElementsByName("field_detail_tuteur1_telephone")[0].value = "";

        }

        if (document.getElementsByName("field_detail_tuteur2_nom") && document.getElementsByName("field_detail_tuteur2_nom")[0]) {
            document.getElementsByName("field_detail_tuteur2_nom")[0].value = "";

        }


        if (document.getElementsByName("field_detail_tuteur2_civilite") && document.getElementsByName("field_detail_tuteur2_civilite")[0]) {
            document.getElementsByName("field_detail_tuteur2_civilite")[0].value = "";

        }



        if (document.getElementsByName("field_detail_tuteur2_prenom") && document.getElementsByName("field_detail_tuteur2_prenom")[0]) {
            document.getElementsByName("field_detail_tuteur2_prenom")[0].value = "";

        }

        if (document.getElementsByName("field_detail_tuteur2_email") && document.getElementsByName("field_detail_tuteur2_email")[0]) {
            document.getElementsByName("field_detail_tuteur2_email")[0].value = "";

        }

        if (document.getElementsByName("field_detail_tuteur2_telephone") && document.getElementsByName("field_detail_tuteur2_telephone")[0]) {
            document.getElementsByName("field_detail_tuteur2_telephone")[0].value = "";

        }



    }

    function Clear_One_Apprenant_Fields() {

        setp_one_civilite('');
        setp_one_email('');


        setp_one_naissance(new Date().toLocaleDateString('fr-FR'));

        setp_one_nom('');
        setp_one_prenom('');
        setp_one_telephone('');
        setp_one_employeur('');
        setp_one_client_rattachement_id('');
        setp_one_adresse('');
        setp_one_code_postal('');
        setp_one_ville('');
        setp_one_pays('');

        setp_one_tuteur1_nom('');
        setp_one_tuteur1_prenom('');
        setp_one_tuteur1_email('');
        setp_one_tuteur1_telephone('');
        setp_one_tuteur2_nom('');
        setp_one_tuteur2_prenom('');
        setp_one_tuteur2_email('');
        setp_one_tuteur2_telephone('');

    }



    function Disable_Detail_Apprenant_Fields() {


        if (document.getElementsByName("field_detail_civilite") && document.getElementsByName("field_detail_civilite")[0]) {
            document.getElementsByName("field_detail_civilite")[0].disabled = true;
            document.getElementsByName("field_detail_civilite")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_email") && document.getElementsByName("field_detail_email")[0]) {
            document.getElementsByName("field_detail_email")[0].disabled = true;
            document.getElementsByName("field_detail_email")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_naissance") && document.getElementsByName("field_detail_naissance")[0]) {
            document.getElementsByName("field_detail_naissance")[0].disabled = true;
            document.getElementsByName("field_detail_naissance")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_nom") && document.getElementsByName("field_detail_nom")[0]) {
            document.getElementsByName("field_detail_nom")[0].disabled = true;
            document.getElementsByName("field_detail_nom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_prenom") && document.getElementsByName("field_detail_prenom")[0]) {
            document.getElementsByName("field_detail_prenom")[0].disabled = true;
            document.getElementsByName("field_detail_prenom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_telephone") && document.getElementsByName("field_detail_telephone")[0]) {
            document.getElementsByName("field_detail_telephone")[0].disabled = true;
            document.getElementsByName("field_detail_telephone")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_employeur") && document.getElementsByName("field_detail_employeur")[0]) {
            document.getElementsByName("field_detail_employeur")[0].disabled = true;
            document.getElementsByName("field_detail_employeur")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_adresse") && document.getElementsByName("field_detail_adresse")[0]) {
            document.getElementsByName("field_detail_adresse")[0].disabled = true;
            document.getElementsByName("field_detail_adresse")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_code_postal") && document.getElementsByName("field_detail_code_postal")[0]) {
            document.getElementsByName("field_detail_code_postal")[0].disabled = true;
            document.getElementsByName("field_detail_code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_ville") && document.getElementsByName("field_detail_ville")[0]) {
            document.getElementsByName("field_detail_ville")[0].disabled = true;
            document.getElementsByName("field_detail_ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_pays") && document.getElementsByName("field_detail_pays")[0]) {
            document.getElementsByName("field_detail_pays")[0].disabled = true;
            document.getElementsByName("field_detail_pays")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("field_detail_tuteur1_nom") && document.getElementsByName("field_detail_tuteur1_nom")[0]) {
            document.getElementsByName("field_detail_tuteur1_nom")[0].disabled = true;
            document.getElementsByName("field_detail_tuteur1_nom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_tuteur1_prenom") && document.getElementsByName("field_detail_tuteur1_prenom")[0]) {
            document.getElementsByName("field_detail_tuteur1_prenom")[0].disabled = true;
            document.getElementsByName("field_detail_tuteur1_prenom")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("field_detail_tuteur1_civilite") && document.getElementsByName("field_detail_tuteur1_civilite")[0]) {
            //console.log(" iiiiiiiiiiiiiii");
            document.getElementsByName("field_detail_tuteur1_civilite")[0].disabled = true;
            document.getElementsByName("field_detail_tuteur1_civilite")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_tuteur2_civilite") && document.getElementsByName("field_detail_tuteur2_civilite")[0]) {
            document.getElementsByName("field_detail_tuteur2_civilite")[0].disabled = true;
            document.getElementsByName("field_detail_tuteur2_civilite")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("field_detail_tuteur1_email") && document.getElementsByName("field_detail_tuteur1_email")[0]) {
            document.getElementsByName("field_detail_tuteur1_email")[0].disabled = true;
            document.getElementsByName("field_detail_tuteur1_email")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_tuteur1_telephone") && document.getElementsByName("field_detail_tuteur1_telephone")[0]) {
            document.getElementsByName("field_detail_tuteur1_telephone")[0].disabled = true;
            document.getElementsByName("field_detail_tuteur1_telephone")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_tuteur2_nom") && document.getElementsByName("field_detail_tuteur2_nom")[0]) {
            document.getElementsByName("field_detail_tuteur2_nom")[0].disabled = true;
            document.getElementsByName("field_detail_tuteur2_nom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_tuteur2_prenom") && document.getElementsByName("field_detail_tuteur2_prenom")[0]) {
            document.getElementsByName("field_detail_tuteur2_prenom")[0].disabled = true;
            document.getElementsByName("field_detail_tuteur2_prenom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_tuteur2_email") && document.getElementsByName("field_detail_tuteur2_email")[0]) {
            document.getElementsByName("field_detail_tuteur2_email")[0].disabled = true;
            document.getElementsByName("field_detail_tuteur2_email")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_detail_tuteur2_telephone") && document.getElementsByName("field_detail_tuteur2_telephone")[0]) {
            document.getElementsByName("field_detail_tuteur2_telephone")[0].disabled = true;
            document.getElementsByName("field_detail_tuteur2_telephone")[0].style.backgroundColor = "#ECEFF1";
        }



    }

    function Enable_Detail_Apprenant_Fields() {

        setapprenant_data_edit_mode('1');


        if (document.getElementsByName("field_detail_civilite") && document.getElementsByName("field_detail_civilite")[0]) {
            document.getElementsByName("field_detail_civilite")[0].disabled = false;
            document.getElementsByName("field_detail_civilite")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("field_detail_email") && document.getElementsByName("field_detail_email")[0]) {
            document.getElementsByName("field_detail_email")[0].disabled = false;
            document.getElementsByName("field_detail_email")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("field_detail_naissance") && document.getElementsByName("field_detail_naissance")[0]) {
            document.getElementsByName("field_detail_naissance")[0].disabled = false;
            document.getElementsByName("field_detail_naissance")[0].style.backgroundColor = "#FFFFFF";
        }



        if (document.getElementsByName("field_detail_nom") && document.getElementsByName("field_detail_nom")[0]) {
            document.getElementsByName("field_detail_nom")[0].disabled = false;
            document.getElementsByName("field_detail_nom")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("field_detail_prenom") && document.getElementsByName("field_detail_prenom")[0]) {
            document.getElementsByName("field_detail_prenom")[0].disabled = false;
            document.getElementsByName("field_detail_prenom")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("field_detail_telephone") && document.getElementsByName("field_detail_telephone")[0]) {
            document.getElementsByName("field_detail_telephone")[0].disabled = false;
            document.getElementsByName("field_detail_telephone")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("field_detail_employeur") && document.getElementsByName("field_detail_employeur")[0]) {
            document.getElementsByName("field_detail_employeur")[0].disabled = false;
            document.getElementsByName("field_detail_employeur")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("field_detail_adresse") && document.getElementsByName("field_detail_adresse")[0]) {
            document.getElementsByName("field_detail_adresse")[0].disabled = false;
            document.getElementsByName("field_detail_adresse")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("field_detail_code_postal") && document.getElementsByName("field_detail_code_postal")[0]) {
            document.getElementsByName("field_detail_code_postal")[0].disabled = false;
            document.getElementsByName("field_detail_code_postal")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("field_detail_ville") && document.getElementsByName("field_detail_ville")[0]) {
            document.getElementsByName("field_detail_ville")[0].disabled = false;
            document.getElementsByName("field_detail_ville")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("field_detail_pays") && document.getElementsByName("field_detail_pays")[0]) {
            document.getElementsByName("field_detail_pays")[0].disabled = false;
            document.getElementsByName("field_detail_pays")[0].style.backgroundColor = "#FFFFFF";
        }


    }

    function Annule_Detail_Apprenant_Fields() {

        setdisplay_detail_apprenant('1');

        Get_Current_Apprenant_Data(selected_apprenant_id);
        Get_Apprenant_Images(selected_apprenant_id);
        Get_List_Of_All_PJ(selected_apprenant_id);

        setapprenant_data_changed("");
        setapprenant_data_edit_mode("");
        setadd_One_Apprenant('');


    }


    const [Update_Apprenant_Data_api, setUpdate_Apprenant_Data_api] = useState();
    const [Update_Apprenant_Data_message, setUpdate_Apprenant_Data_message] = useState();
    const [Update_Apprenant_Data_result, setUpdate_Apprenant_Data_result] = useState();
    function Update_Apprenant_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        if (p_detail_nom.trim() == "") {
            alert(" Vous devez saisir le nom de l'apprenant");
            return;
        }


        if (p_detail_prenom.trim() == "") {
            alert(" Vous devez saisir le prenom de l'apprenant");
            return;
        }


        if (p_detail_email.trim() == "") {
            alert(" Vous devez saisir l'email de l'apprenant");
            return;
        }


        if (p_detail_naissance.trim() == "") {
            alert(" Vous devez saisir la date de naissance de l'apprenant");
            return;
        }

        if (p_detail_civilite.trim() == "") {
            alert(" Vous devez saisir la civilité de l'apprenant");
            return;
        }


        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!p_detail_email.trim().match(validRegex)) {
            alert("L'adresse email est invalide.");
            return;
        }



        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", selected_apprenant_id);
        form.append("nom", p_detail_nom);
        form.append("email", p_detail_email);
        form.append("prenom", p_detail_prenom);
        form.append("civilite", p_detail_civilite);

        form.append("date_naissance", p_detail_naissance);

        if (p_detail_telephone)
            form.append("telephone", p_detail_telephone);
        else
            form.append("telephone", "");


        if (p_detail_employeur)
            form.append("employeur", p_detail_employeur);
        else
            form.append("employeur", "");


        if (p_detail_client_rattachement_id)
            form.append("client_rattachement_id", p_detail_client_rattachement_id);
        else
            form.append("client_rattachement_id", "");


        if (p_detail_adresse)
            form.append("adresse", p_detail_adresse);
        else
            form.append("adresse", "");

        if (p_detail_code_postal)
            form.append("code_postal", p_detail_code_postal);
        else
            form.append("code_postal", "");


        if (p_detail_ville)
            form.append("ville", p_detail_ville);
        else
            form.append("ville", "");

        if (p_detail_pays)
            form.append("pays", p_detail_pays);
        else
            form.append("pays", "");


        if (p_detail_tuteur1_nom)
            form.append("tuteur1_nom", p_detail_tuteur1_nom);
        else
            form.append("tuteur1_nom", "");

        if (p_detail_tuteur1_prenom)
            form.append("tuteur1_prenom", p_detail_tuteur1_prenom);
        else
            form.append("tuteur1_prenom", "");


        if (p_detail_tuteur1_email) {
            form.append("tuteur1_email", p_detail_tuteur1_email);
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!p_detail_tuteur1_email.trim().match(validRegex)) {
                alert("L'adresse email du tuteur 1 est invalide.");
                return;
            }
        }
        else
            form.append("tuteur1_email", "");

        if (p_detail_tuteur1_telephone)
            form.append("tuteur1_telephone", p_detail_tuteur1_telephone);
        else
            form.append("tuteur1_telephone", "");


        if (p_detail_tuteur2_nom)
            form.append("tuteur2_nom", p_detail_tuteur2_nom);
        else
            form.append("tuteur2_nom", "");

        if (p_detail_tuteur2_prenom)
            form.append("tuteur2_prenom", p_detail_tuteur2_prenom);
        else
            form.append("tuteur2_prenom", "");


        if (p_detail_tuteur2_email) {
            form.append("tuteur2_email", p_detail_tuteur2_email);
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!p_detail_tuteur2_email.trim().match(validRegex)) {
                alert("L'adresse email du tuteur 2 est invalide.");
                return;
            }
        }
        else
            form.append("tuteur2_email", "");

        if (p_detail_tuteur2_telephone)
            form.append("tuteur2_telephone", p_detail_tuteur2_telephone);
        else
            form.append("tuteur2_telephone", "");


        /*
          Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
        */
        for (let i = 0; i < spec_field_updated_values_hooks.length; i++) {
            var local_value = String(spec_field_updated_values_hooks[i].field_value);


            if (String(spec_field_updated_values_hooks[i].field_type) === "float") {

                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " doit être numérique.")
                    return;
                }

            }
            else if (String(spec_field_updated_values_hooks[i].field_type) === "string") {

            }


            if (String(spec_field_updated_values_hooks[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " est obligatoire.")
                    return;
                }

            }

            form.append(String(spec_field_updated_values_hooks[i].field_name), local_value);
        }


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Apprenant/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Update_Apprenant_Data  res.data.status = " + res.data.status);
            //console.log(" In Update_Apprenant_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setUpdate_Apprenant_Data_api("true");
                setUpdate_Apprenant_Data_result(res.data.message);

                Get_All_Apprenant();
                Show_Detailled_Apprenant_Data();

                Disable_Detail_Apprenant_Fields();

                alert(res.data.message);


                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {

                setUpdate_Apprenant_Data_api("false");
                setUpdate_Apprenant_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_Apprenant_Data = ' + error);
            setUpdate_Apprenant_Data_api("false");
            alert(" Impossible de mettre à jour l'apprenant");

        })
    }

    const spec_field_updated_values = []
    const [spec_field_updated_values_hooks, setspec_field_updated_values_hooks] = useState([]);

    const [selected_apprenant_email, setselected_apprenant_email] = useState("");


    function handleClick_edit_apprenant_From_Line(selected_row_id) {

        Clear_Detail_Apprenant_Fields();

        var line = JSON.parse(Get_All_Apprenant_result[selected_row_id]);
        setadd_One_Apprenant('');
        setdisplay_detail_apprenant('1');

        setgridline_id(selected_row_id);

        setselected_apprenant_id(line._id);
        setselected_apprenant_email(line.email);

        Get_Current_Apprenant_Data(line._id);


        Get_Apprenant_Images(line._id);
        Get_List_Of_All_PJ(line._id);

        Get_Appenant_List_Inscription(line._id);

    }


    function handleClick_edit_apprenant_From_Props(selected_id, selected_email) {

        console.log(" selected_id = ", selected_id, " -- selected_email = ", selected_email)
        Clear_Detail_Apprenant_Fields();


        setadd_One_Apprenant('');
        setdisplay_detail_apprenant('1');

        //setgridline_id(selected_row_id);

        setselected_apprenant_id(selected_id);
        setselected_apprenant_email(selected_email);


        Get_Current_Apprenant_Data(selected_id);


        Get_Apprenant_Images(selected_id);
        Get_List_Of_All_PJ(selected_id);

        Get_Appenant_List_Inscription(selected_id);

    }


    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    const [isimgclassSelected, setisimgclassSelected] = useState(false);
    const [isimgclassSelected_recid, setisimgclassSelected_recid] = useState("");
    const [isimgclassSelectedfile, setisimgclassSelectedfile] = useState(false);


    const [isimgclassdeleted, setisimgclassdeleted] = useState("");
    const [isimgclassdeleted_message, setisimgclassdeleted_message] = useState("");
    const [isimgclassSaved, setisimgclassSaved] = useState("");
    const [isimgclassSaved_message, setisimgclassSaved_message] = useState("");


    const [userimgclassprofil, setuserimgclassprofil] = useState();
    const [userimgclassprofilchanged, setuserimgclassprofilchanged] = useState("");



    const [isLoading, setLoading] = useState();
    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {

    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };



    const [Dialog_2_message, setDialog_2_message] = React.useState(false);
    const [Dialog_2_open, setDialog_2_open] = React.useState(false);
    function Dialog_2_handle_change_participant_session(message) {
        setDialog_2_message(message);
        setDialog_2_open(true);
    }

    const Dialog_2_handleClose = () => {

    };

    const Dialog_2_handleClose_buton = () => {
        setDialog_2_open(false);
    };


    const [Dialog_apprenant_message, setDialog_apprenant_message] = React.useState(false);
    const [Dialog_apprenant_open, setDialog_apprenant_open] = React.useState(false);
    function Dialog_apprenant_handle_change_participant_session(message) {
        setDialog_apprenant_message(message);
        setDialog_apprenant_open(true);
    }

    const Dialog_apprenant_handleClose = () => {

    };

    const Dialog_apprenant_handleClose_buton = () => {

        setuserimgclassprofil("");
        setselected_row_data_json_age("");
        setgridline_id('');
        setDialog_apprenant_open(false);
    };


    const [Dialog_add_inscription_message, setDialog_add_inscription_message] = React.useState(false);
    const [Dialog_add_inscription_open, setDialog_add_inscription_open] = React.useState(false);
    function Dialog_add_inscription_handle_change_participant_session(message) {
        setDialog_add_inscription_message(message);
        setDialog_add_inscription_open(true);
    }

    const Dialog_add_inscription_handleClose = () => {

    };

    const Dialog_add_inscription_handleClose_buton = () => {
        setDialog_add_inscription_open(false);
    };


    const [actionmass_ftion_val, setactionmass_ftion_val] = useState();
    const actionmass_ftion = (event) => {
        const value = event.target.value;


        if (String(value) === "supprimer" || String(value) === "inscription"
            || String(value) === "exporter") {
            setactionmass_ftion_val(value);
        }
        else {
            setactionmass_ftion_val();
        }
    }


    async function actionmass_ftion_Traitemet() {


        var liste_apprenant = GetSelectedRowsSession();

        //console.log(" liste_formation = ", liste_formation);
        //console.log(" actionmass_ftion_val = ", actionmass_ftion_val);

        if (String(actionmass_ftion_val) === String("supprimer")) {

            //console.log(" ### liste session : ", liste_session)
            Delete_LIST_Apprenant(liste_apprenant);

        }

        else if (String(actionmass_ftion_val) === String("inscription")) {
            setDialog_add_inscription_open(true);
        }

        else if (String(actionmass_ftion_val) === String("exporter")) {
            Export_apprenant(liste_apprenant);
        }

        else if (String(actionmass_ftion_val) === String("inscrire")) {
            setDialog_add_inscription_open(true);
        }


    }


    function Export_apprenant(tab_class) {
        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "export_apprenant.xlsx";

        var url = process.env.REACT_APP_API_URL + "myclass/api/Export_Apprenant_To_Excel_From_from_List_Id/" + stored_cookie + "/" + tab_class;


        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les apprenants (2) ");
                } else {
                    setSelectionModel([]);
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les apprenants ")

            });


        setLoading(false);
    }


    const [rows_champs_specifics_stagiaire, setrows_champs_specifics_stagiaire] = useState([]);

    function GetSelectedRowsSession() {
        ///alert(" les lignes slected sont :  " + selectionModel + " nb item = " + selectionModel.length);
        //const selectedRowsData = selectionModel.map((id) => rowss.find((row) => row.id === id));
        //console.log(rowss[0]);
        var tab_tmp = []
        for (var i = 0; i < selectionModel.length; i++) {
            var myid = parseInt(String(selectionModel[i]));
            var line = JSON.parse(Get_All_Apprenant_result[myid]);
            tab_tmp.push(line._id);
            //console.log(rowss[myid]['external_code']);
        }

        setselectedtraining(tab_tmp);
        return tab_tmp;

    }

    const [selectedtraining, setselectedtraining] = useState([])


    const [gridline_id, setgridline_id] = useState("");

    const [suivi_pedago_gridline_id, setsuivi_pedago_gridline_id] = useState("");

    const [Get_Partner_Object_Specific_Fields_apprenant_api, setGet_Partner_Object_Specific_Fields_apprenant_api] = useState();
    const [Get_Partner_Object_Specific_Fields_apprenant_message, setGet_Partner_Object_Specific_Fields_apprenant_message] = useState();
    const [Get_Partner_Object_Specific_Fields_apprenant_result, setGet_Partner_Object_Specific_Fields_apprenant_result] = useState();
    function Get_Partner_Object_Specific_Fields_apprenant() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("object_name", "apprenant");

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Object_Specific_Valide_Displayed_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

                setGet_Partner_Object_Specific_Fields_apprenant_api("true");
                setGet_Partner_Object_Specific_Fields_apprenant_result(res.data.message);


                setrows_champs_specifics_stagiaire(res.data.message);


            }
            else {
                setGet_Partner_Object_Specific_Fields_apprenant_api("false");
                setGet_Partner_Object_Specific_Fields_apprenant_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
            setGet_Partner_Object_Specific_Fields_apprenant_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }

    const [rows_champs_specifics, setrows_champs_specifics] = useState([]);

    const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_ismanager = JSON.parse(x).ismanager;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " " + local_prenom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "ismanager": local_ismanager
                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "prenom": "",
                    "ismanager": "",
                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Training_Employee_No_Filter_result(new_data2);

            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Training_Materiel_No_Filter_api, setGetall_Training_Materiel_No_Filter_api] = useState();
    const [Getall_Training_Materiel_No_Filter_message, setGetall_Training_Materiel_No_Filter_message] = useState();
    const [Getall_Training_Materiel_No_Filter_result, setGetall_Training_Materiel_No_Filter_result] = useState();
    function Getall_Training_Materiel_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Materielle_no_filter/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Materiel_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Materiel_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Materiel_No_Filter_api("true");
                setGetall_Training_Materiel_No_Filter_result(res.data.message);
            }
            else {
                setGetall_Training_Materiel_No_Filter_api("false");
                setGetall_Training_Materiel_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Getall_Training_Materiel_No_Filter = ', error);
            setGetall_Training_Materiel_No_Filter_api("false");
            alert(" Impossible de recuperer la liste du materiel");
            //setmyApimyApiMessage("")
        })
    }


    const [Get_All_Apprenant_api, setGet_All_Apprenant_api] = useState();
    const [Get_All_Apprenant_message, setGet_All_Apprenant_message] = useState();
    const [Get_All_Apprenant_result, setGet_All_Apprenant_result] = useState([]);
    function Get_All_Apprenant(event) {

        var form = new FormData();


        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;


        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Apprenant/";


        // Verification s'il faut faire une recherche avec une option like.
        //console.log(" p_filtre1 = ", p_filtre1, "   filtre1_value = ", filtre1_value);

        if (p_filtre1_value || p_filtre2_value) {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Apprenant_List_Partner_with_filter/";
        }


        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_All_Apprenant  res.data.status = " + res.data.status);
                //console.log(" In Get_All_Apprenant  res.data.message r_class = " + res.data.message);
                setGet_All_Apprenant_api("true");
                setGet_All_Apprenant_result(res.data.message);
            }
            else {
                setGet_All_Apprenant_api("false");
                setGet_All_Apprenant_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_All_Apprenant = ', error);
            setGet_All_Apprenant_api("false");
            alert("Impossible de récuperer la liste des apprenants");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Get_List_Partner_Clients_result, setNew_Get_List_Partner_Clients_result] = useState([]);

    const [Get_List_Partner_Clients_api, setGet_List_Partner_Clients_api] = useState();
    const [Get_List_Partner_Clients_message, setGet_List_Partner_Clients_message] = useState();
    const [Get_List_Partner_Clients_result, setGet_List_Partner_Clients_result] = useState();
    function Get_List_Partner_Clients(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_List_Partner_Client/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Partner_Clients  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Partner_Clients  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setGet_List_Partner_Clients_api("true");
                setGet_List_Partner_Clients_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_raison_sociale = JSON.parse(x).raison_sociale;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "raison_sociale": local_raison_sociale,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Partner_Clients_result(new_data2);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Partner_Clients_api("false");
                setGet_List_Partner_Clients_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_List_Partner_Clients = ', error);
            setGet_List_Partner_Clients_api("false");
            alert('Erreur: Impossible de recuperer la liste des clients');
        })
    }

    const [Get_Partner_Object_Specific_Fields_api, setGet_Partner_Object_Specific_Fields_api] = useState();
    const [Get_Partner_Object_Specific_Fields_message, setGet_Partner_Object_Specific_Fields_message] = useState();
    const [Get_Partner_Object_Specific_Fields_result, setGet_Partner_Object_Specific_Fields_result] = useState();
    function Get_Partner_Object_Specific_Fields(local_collection_obj_metier) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("object_name", local_collection_obj_metier);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Object_Specific_Valide_Displayed_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

                setGet_Partner_Object_Specific_Fields_api("true");
                setGet_Partner_Object_Specific_Fields_result(res.data.message);


                setrows_champs_specifics(res.data.message);


            }
            else {
                setGet_Partner_Object_Specific_Fields_api("false");
                setGet_Partner_Object_Specific_Fields_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
            setGet_Partner_Object_Specific_Fields_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }


    const [Get_Appenant_List_Inscription_api, setGet_Appenant_List_Inscription_api] = useState();
    const [Get_Appenant_List_Inscription_message, setGet_Appenant_List_Inscription_message] = useState();
    const [Get_Appenant_List_Inscription_result, setGet_Appenant_List_Inscription_result] = useState([]);
    function Get_Appenant_List_Inscription(local_selecte_apprenant_id) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("_id", local_selecte_apprenant_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Apprenant_List_Inscription/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_Appenant_List_Inscription  res.data.status = " + res.data.status);
            //console.log(" In Get_Appenant_List_Inscription  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_Appenant_List_Inscription_api("true");
                setGet_Appenant_List_Inscription_result(res.data.message);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_Appenant_List_Inscription_api("false");
                setGet_Appenant_List_Inscription_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Appenant_List_Inscription = ', error);
            setGet_Appenant_List_Inscription_api("false");
            alert('Erreur: Impossible de recuperer la liste des inscriptions');
        })
    }


    const [Get_Appenant_List_Suivi_Pedagogique_api, setGet_Appenant_List_Suivi_Pedagogique_api] = useState();
    const [Get_Appenant_List_Suivi_Pedagogique_message, setGet_Appenant_List_Suivi_Pedagogique_message] = useState();
    const [Get_Appenant_List_Suivi_Pedagogique_result, setGet_Appenant_List_Suivi_Pedagogique_result] = useState([]);
    function Get_Appenant_List_Suivi_Pedagogique(local_selecte_apprenant_id) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("apprenant_id", local_selecte_apprenant_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Suivi_Pedagogique_No_Filter/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_Appenant_List_Suivi_Pedagogique  res.data.status = " + res.data.status);
            //console.log(" In Get_Appenant_List_Suivi_Pedagogique  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_Appenant_List_Suivi_Pedagogique_api("true");
                setGet_Appenant_List_Suivi_Pedagogique_result(res.data.message);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_Appenant_List_Suivi_Pedagogique_api("false");
                setGet_Appenant_List_Suivi_Pedagogique_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Appenant_List_Suivi_Pedagogique = ', error);
            setGet_Appenant_List_Suivi_Pedagogique_api("false");
            alert('Erreur: Impossible de recuperer le suivi pédagogique');
        })
    }


    const [p_detail_suivi_ped_comment, setp_detail_suivi_ped_comment] = React.useState("");
    const [p_detail_suivi_ped_observation, setp_detail_suivi_ped_observation] = React.useState("");
    const [p_detail_suivi_ped_responsable_id, setp_detail_suivi_ped_responsable_id] = React.useState("");
    const [p_detail_suivi_ped_class_id, setp_detail_suivi_ped_class_id] = React.useState("");
    const [p_detail_suivi_ped_class_internal_url, setp_detail_suivi_ped_class_internal_url] = React.useState("");
    const [p_detail_suivi_ped_class_eu_id, setp_detail_suivi_ped_class_eu_id] = React.useState("");
    const [p_detail_suivi_ped_session_id, setp_detail_suivi_ped_session_id] = React.useState("");
    const [p_detail_suivi_ped_eval_date_heure_debut, setp_detail_suivi_ped_eval_date_heure_debut] = useState(format(new Date(), 'dd/MM/yyyy HH:mm'));
    const [p_detail_suivi_ped_eval_date_heure_fin, setp_detail_suivi_ped_eval_date_heure_fin] = useState(format(new Date(), 'dd/MM/yyyy HH:mm'));
    const [selected_suivi_pedagogique_id, setselected_suivi_pedagogique_id] = useState("");

    const [p_detail_suivi_ped_sujet, setp_detail_suivi_ped_sujet] = useState("");



    function Clear_Suivi_Pedago_Field() {
        setp_detail_suivi_ped_comment("");
        setp_detail_suivi_ped_observation("");
        setp_detail_suivi_ped_responsable_id("");
        setp_detail_suivi_ped_class_id("");
        setp_detail_suivi_ped_class_eu_id("");
        setp_detail_suivi_ped_session_id("");

        setp_detail_suivi_ped_eval_date_heure_debut(format(new Date(), 'dd/MM/yyyy HH:mm'));
        setp_detail_suivi_ped_eval_date_heure_fin(format(new Date(), 'dd/MM/yyyy HH:mm'));
        setselected_suivi_pedagogique_id("");

        setp_detail_suivi_ped_sujet("");

    }


    const [Add_Update_Suiv_Pedagogique_api, setAdd_Update_Suiv_Pedagogique_api] = useState();
    const [Add_Update_Suiv_Pedagogique_message, setAdd_Update_Suiv_Pedagogique_message] = useState();
    const [Add_Update_Suiv_Pedagogique_result, setAdd_Update_Suiv_Pedagogique_result] = useState();
    function Add_Update_Suiv_Pedagogique() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        form.append("apprenant_id", selected_apprenant_id);
        form.append("responsable_id", p_detail_suivi_ped_responsable_id);
        form.append("class_id", p_detail_suivi_ped_class_id);
        form.append("class_eu_id", p_detail_suivi_ped_class_eu_id);
        form.append("session_id", p_detail_suivi_ped_session_id);
        form.append("date_heure_debut", p_detail_suivi_ped_eval_date_heure_debut);
        form.append("date_heure_fin", p_detail_suivi_ped_eval_date_heure_fin);
        form.append("employee_id", "");

        var observation = "";
        if (editorRef_observation.current) {
            observation = editorRef_observation.current.getContent();
        }

        form.append("observation", observation);

        var commentaire = "";
        if (editorRef_comment.current) {
            commentaire = editorRef_comment.current.getContent();
        }
        form.append("commentaire", commentaire);
        form.append("sujet", p_detail_suivi_ped_sujet);

        var myurl = "";

        if (selected_suivi_pedagogique_id && String(selected_suivi_pedagogique_id).length > 5) {
            form.append("_id", selected_suivi_pedagogique_id);
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Suivi_Pedagogique/";
        } else {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Suivi_Pedagogique/";
        }


        //console.log(" ### form = ", form);


        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_Suiv_Pedagogique  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_Suiv_Pedagogique  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setAdd_Update_Suiv_Pedagogique_api("true");
                setAdd_Update_Suiv_Pedagogique_result(res.data.message);

                Dialog_Suivi_Pedago_handleClose_buton();



                setgiven_suivi_ped_changed("");
                setgiven_suivi_ped_data_edit_mode("");
                setadd_one_suivi_ped("");
                if (!selected_suivi_pedagogique_id || String(selected_suivi_pedagogique_id).length < 5) {
                    setsuivi_pedago_gridline_id("");
                    Clear_Suivi_Pedago_Field();
                } else {
                    Get_Geven_Suivi_Ped_Data(selected_suivi_pedagogique_id);
                    setdisplay_given_suivi_ped("1");
                    setadd_one_suivi_ped("");
                    setgiven_suivi_ped_data_edit_mode("");
                    setselected_suivi_pedagogique_id(selected_suivi_pedagogique_id);
                }


                Get_Appenant_List_Suivi_Pedagogique(selected_apprenant_id);

                alert(res.data.message);

                if (document.getElementById('myRef')) {
                    // myRef.current.scrollIntoView({ behavior: "smooth" });
                    var divh = document.getElementById('myRef').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_Update_Suiv_Pedagogique_api("false");
                setAdd_Update_Suiv_Pedagogique_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);

            console.log('UpdateStagiaireData : Not good man :( Add_Update_Suiv_Pedagogique = ' + error);
            setAdd_Update_Suiv_Pedagogique_api("false");
            alert(" Impossible d'ajouter ou  mettre à jour le suivi pédagogique");

        })
    }





    const myRef_details = useRef(null)



    const [New_Getall_TrainingSession_result, setNew_Getall_TrainingSession_result] = useState();

    const [Getall_TrainingSession_api, setGetall_TrainingSession_api] = useState();
    const [Getall_TrainingSession_message, setGetall_TrainingSession_message] = useState();
    const [Getall_TrainingSession_result, setGetall_TrainingSession_result] = useState();
    function Getall_TrainingSession(event) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAllValideSessionPartner_List/";


        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_TrainingSession  res.data.status = " + res.data.status);
                //console.log(" In Getall_TrainingSession  res.data.message r_class = " + res.data.message);
                setGetall_TrainingSession_api("true");
                setGetall_TrainingSession_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_internal_url = JSON.parse(x).class_internal_url;
                    var local_title_session = JSON.parse(x).titre;
                    var local_code_session = JSON.parse(x).code_session;
                    var local_invoiced_statut = JSON.parse(x).invoiced_statut;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code_session,
                        "class_internal_url": local_internal_url,
                        "titre": local_title_session,
                        "code_session": local_code_session,
                        "invoiced_statut": local_invoiced_statut,
                    };
                    new_data2.push(node);

                    // return {"_id": " + str(local_id) + ", "label": " + local_courrier_template_ref_interne + '" , "courrier_template_ref_interne": "' + local_courrier_template_ref_interne + '", "nom_champ_technique": "' + local_nom_champ_technique + '",  "nom_champ_fonctionel": "' + local_nom_champ_fonctionel + '","valide": "1" };

                });


                if (new_data2.length > 0)
                    setNew_Getall_TrainingSession_result(new_data2);

                //console.log(" ### new_data2 = ", new_data2)


            }
            else {
                setGetall_TrainingSession_api("false");
                setGetall_TrainingSession_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_TrainingSession = ', error);
            setGetall_TrainingSession_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const [New_Getall_Partner_Class_Reduice_Fields_result, setNew_Getall_Partner_Class_Reduice_Fields_result] = useState([]);

    const [Getall_Partner_Class_Reduice_Fields_api, setGetall_Partner_Class_Reduice_Fields_api] = useState();
    const [Getall_Partner_Class_Reduice_Fields_message, setGetall_Partner_Class_Reduice_Fields_message] = useState();
    const [Getall_Partner_Class_Reduice_Fields_result, setGetall_Partner_Class_Reduice_Fields_result] = useState();
    function Getall_Partner_Class_Reduice_Fields(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_All_Class_Few_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Class_Reduice_Fields  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Class_Reduice_Fields  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Class_Reduice_Fields_api("true");
                setGetall_Partner_Class_Reduice_Fields_result(res.data.message);


                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_external_code = JSON.parse(x).external_code;
                    var local_internal_code = JSON.parse(x).internal_code;
                    var local_internal_url = JSON.parse(x).internal_url;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_external_code,
                        "external_code": local_external_code,
                        "internal_code": local_internal_code,
                        "internal_url": local_internal_url
                    };
                    new_data2.push(node);
                });


                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "external_code": "",
                    "internal_code": "",
                    "internal_url": ""
                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Class_Reduice_Fields_result(new_data2);

            }
            else {
                setGetall_Partner_Class_Reduice_Fields_api("false");
                setGetall_Partner_Class_Reduice_Fields_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Class_Reduice_Fields = ', error);
            setGetall_Partner_Class_Reduice_Fields_api("false");
            alert(" Impossible de recuperer la liste des formations");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Partner_Session_Reduice_Fields_result, setNew_Getall_Partner_Session_Reduice_Fields_result] = useState([]);

    const [Getall_Partner_Session_Reduice_Fields_api, setGetall_Partner_Session_Reduice_Fields_api] = useState();
    const [Getall_Partner_Session_Reduice_Fields_message, setGetall_Partner_Session_Reduice_Fields_message] = useState();
    const [Getall_Partner_Session_Reduice_Fields_result, setGetall_Partner_Session_Reduice_Fields_result] = useState();
    function Getall_Partner_Session_Reduice_Fields(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Session_Ftion_Reduice_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Session_Reduice_Fields  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Session_Reduice_Fields  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Session_Reduice_Fields_api("true");
                setGetall_Partner_Session_Reduice_Fields_result(res.data.message);


                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code_session = JSON.parse(x).code_session;
                    var local_titre = JSON.parse(x).titre;
                    var local_class_internal_url = JSON.parse(x).class_internal_url;

                    var local_date_debut = JSON.parse(x).date_debut;
                    var local_date_fin = JSON.parse(x).date_fin;




                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code_session,
                        "code_session": local_code_session,
                        "titre": local_titre,
                        "class_internal_url": local_class_internal_url,
                        "date_debut": local_date_debut,
                        "date_fin": local_date_fin,
                    };
                    new_data2.push(node);
                });


                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code_session": "",
                    "titre": "",
                    "class_internal_url": "",
                    "date_debut": "",
                    "date_fin": "",
                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Session_Reduice_Fields_result(new_data2);

            }
            else {
                setGetall_Partner_Session_Reduice_Fields_api("false");
                setGetall_Partner_Session_Reduice_Fields_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Session_Reduice_Fields = ', error);
            setGetall_Partner_Session_Reduice_Fields_api("false");
            alert(" Impossible de recuperer la liste des sessions de formation (classe)");
            //setmyApimyApiMessage("")
        })
    }



    const [New_Getall_Partner_List_UE_result, setNew_Getall_Partner_List_UE_result] = useState([]);

    const [Getall_Partner_List_UE_api, setGetall_Partner_List_UE_api] = useState();
    const [Getall_Partner_List_UE_message, setGetall_Partner_List_UE_message] = useState();
    const [Getall_Partner_List_UE_result, setGetall_Partner_List_UE_result] = useState();
    function Getall_Partner_List_UE(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Unite_Enseignement_no_filter/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_List_UE  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_List_UE  res.data.message r_class = " + res.data.message);
                setGetall_Partner_List_UE_api("true");
                setGetall_Partner_List_UE_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_titre = JSON.parse(x).titre;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "titre": local_titre,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "titre": "",
                    "credit": "",
                    "pres_dist_hyp": "",
                    "is_noted": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Partner_List_UE_result(new_data2);
            }
            else {
                setGetall_Partner_List_UE_api("false");
                setGetall_Partner_List_UE_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Getall_Partner_List_UE = ', error);
            setGetall_Partner_List_UE_api("false");
            alert(" Impossible de recuperer la liste des UE de la formation");
            //setmyApimyApiMessage("")
        })
    }



    const [New_Get_List_UE_From_Class_Id_result, setNew_Get_List_UE_From_Class_Id_result] = useState([]);

    const [Get_List_UE_From_Class_Id_api, setGet_List_UE_From_Class_Id_api] = useState();
    const [Get_List_UE_From_Class_Id_message, setGet_List_UE_From_Class_Id_message] = useState();
    const [Get_List_UE_From_Class_Id_result, setGet_List_UE_From_Class_Id_result] = useState();
    function Get_List_UE_From_Class_Id(local_class_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_id", local_class_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Unite_Enseignement_Of_Given_Class/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_List_UE_From_Class_Id  res.data.status = " + res.data.status);
                //console.log(" In Get_List_UE_From_Class_Id  res.data.message r_class = " + res.data.message);
                setGet_List_UE_From_Class_Id_api("true");
                setGet_List_UE_From_Class_Id_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_titre = JSON.parse(x).titre;

                    var local_class_id = JSON.parse(x).class_id;
                    var local_internal_url = JSON.parse(x).internal_url;




                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "titre": local_titre,
                        "class_id": local_class_id,
                        "internal_url": local_internal_url,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "titre": "",
                    "class_id": "",
                    "internal_url": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Get_List_UE_From_Class_Id_result(new_data2);
            }
            else {
                setGet_List_UE_From_Class_Id_api("false");
                setGet_List_UE_From_Class_Id_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Get_List_UE_From_Class_Id = ', error);
            setGet_List_UE_From_Class_Id_api("false");
            alert(" Impossible de recuperer la liste des UE de la formation");
            //setmyApimyApiMessage("")
        })
    }



    useEffect(() => {

        Getall_Partner_Class_Reduice_Fields();
        Getall_Partner_Session_Reduice_Fields();
        Getall_Partner_List_UE();


        Get_Partner_Object_Specific_Fields_apprenant();
        Getall_Training_Employee_No_Filter();
        Getall_Training_Materiel_No_Filter();
        Get_List_Partner_Clients();

        Get_All_Apprenant();

        Getall_TrainingSession();


        if (props.object_key && props.subdata && String(props.object_key).length > 3) {
            setLoading(true);
            handleClick_edit_apprenant_From_Props(props.object_key, props.subdata);
            setLoading(false);
        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }

    }, [])



    const [Download_one_attached_document_api, setDownload_one_attached_document_api] = useState();
    const [Download_one_attached_document_result, setDownload_one_attached_document_result] = useState();
    const [Download_one_attached_document_message, setDownload_one_attached_document_message] = useState();
    const Download_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');


        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var token = stored_cookie;
        var file_name = event.target.id;


        var url = process.env.REACT_APP_API_URL + "myclass/api/Get_Stored_Downloaded_File/" + token + "/" + file_name;
        setLoading(true);

        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, nom_fiche_detaillee);
                setDownload_one_attached_document_api("true");
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setDownload_one_attached_document_api("false");

            });
    }


    const [selectionModel, setSelectionModel] = React.useState([]);
    const [selectionModel_apprenant_inscription, setselectionModel_apprenant_inscription] = React.useState([]);
    const [selectionModel_suivi_pedagogique, setselectionModel_suivi_pedagogique] = React.useState([]);

    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();

    function clean_all_filters() {

        setp_filtre1();
        setp_filtre1_value();

        setp_filtre2();
        setp_filtre2_value();

        setgridline_id('');


        setSelectionModel([]);
        setGet_All_Apprenant_result([]);

        setsubmenu("");

        Get_All_Apprenant();
    }


    const [apprenant_file_name, setapprenant_file_name] = useState();



    const [apprenants_file_change_1_api, setapprenants_file_change_1_api] = useState();
    const [apprenants_file_change_1_result, setapprenants_file_change_1_result] = useState();
    const [apprenants_file_change_1_message, setapprenants_file_change_1_message] = useState();
    const apprenants_file_change_1 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;



        var tab = file_type.split('/');
        if (tab.length != 2 || String(tab[1]).toLocaleLowerCase() !== "pdf") {
            alert("Seuls les fichier 'pdf' sont autorisés ");
            return;
        }

        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        setfile_1_name(event.target.files[0]);

        return;

    };



    const [liste_apprenants_file_change_api, setliste_apprenants_file_change_api] = useState();
    const [liste_apprenants_file_change_result, setliste_apprenants_file_change_result] = useState();
    const [liste_apprenants_file_change_message, setliste_apprenants_file_change_message] = useState();
    const liste_apprenants_file_change = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setapprenants_file_name(event.target.files[0].name);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        //console.log("token = " + stored_cookie);

        setLoading(true);



        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Add_Apprenant_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("Err_Connexion")) {
                    setLoading(false);
                    alert(result['message']);
                    history.push("/Connexion");
                }

                else if (String(result['status']) === String("true")) {
                    setLoading(false);
                    //console.log('Success:', result['message']);
                    setliste_apprenants_file_change_result(result['message']);
                    setliste_apprenants_file_change_api("true");

                    Get_All_Apprenant();
                    alert(result['message']);

                }

                else {
                    setLoading(false);
                    setliste_apprenants_file_change_message(result['message']);
                    setliste_apprenants_file_change_api("false");
                    alert(result['message']);

                }


            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setliste_apprenants_file_change_api("false");
                alert(" Impossible d'importer les apprenants ");
            });
    }

    const hiddenFileInput_apprenant = React.useRef(null);
    const [apprenants_file_name, setapprenants_file_name] = useState();

    function submenu_import_apprenant() {
        setapprenants_file_name();
        setliste_apprenants_file_change_api();
        hiddenFileInput_apprenant.current.click();
    }

    const [add_One_Apprenant, setadd_One_Apprenant] = useState("");

    const [display_detail_apprenant, setdisplay_detail_apprenant] = useState("");
    const [display_detail_inscriptions, setdisplay_detail_inscriptions] = useState("");
    const [display_detail_absence, setdisplay_detail_absence] = useState("");
    const [display_detail_historique, setdisplay_detail_historique] = useState("");
    const [display_detail_piece_joint, setdisplay_detail_piece_joint] = useState("");
    const [display_menu_suivi_pedagogique, setdisplay_menu_suivi_pedagogique] = useState("");


    async function submenu_add_one_inscription() {
        setDialog_add_inscription_open(true);
    }

    async function submenu_add_one_apprenant() {
        setsubmenu("");
        setadd_One_Apprenant("1");
        setdisplay_detail_apprenant('');
        setgridline_id('');
        setselected_apprenant_id('');
        Clear_One_Apprenant_Fields();

        //await sleep(5);


        setdisplay_detail_apprenant("");
        setdisplay_detail_inscriptions("");
        setdisplay_detail_absence("");
        setdisplay_detail_historique("");
        setdisplay_detail_piece_joint("");



        if (document.getElementById('detail_apprenant')) {
            // myRef.current.scrollIntoView({ behavior: "smooth" });
            var divh = document.getElementById('detail_apprenant').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }
    }

    function submenu_color_management(current_menu) {
        const list_sous_menu = ['detail_apprenant', 'inscriptions', 'absences', 'suivi_pedagogique', 'historique', 'piece_jointe', 'piece_jointe']

        for (let i = 0; i < list_sous_menu.length; i++) {

            var sous_menu = list_sous_menu[i];
            if (String(sous_menu) !== current_menu) {
                if (document.getElementById(sous_menu)) {
                    document.getElementById(sous_menu).style.backgroundColor = "#d8edfc";
                    document.getElementById(sous_menu).style.color = "black";
                    document.getElementById(sous_menu).style.fontWeight = "normal";
                }
            }

            if (String(sous_menu) === "editique") {
                if (document.getElementById(sous_menu)) {
                    document.getElementById(sous_menu).style.backgroundColor = "#6C3483";
                    document.getElementById(sous_menu).style.color = "#FFFFFF";
                    document.getElementById(sous_menu).style.fontWeight = "bold";
                }
            }

        }

        if (document.getElementById(current_menu)) {
            document.getElementById(current_menu).style.backgroundColor = "#104277";
            document.getElementById(current_menu).style.color = "white";
            document.getElementById(current_menu).style.fontWeight = "bold";
        }

    }

    async function submenu_detail_apprenant() {
        setsubmenu("detail_apprenant");

        //await sleep(5);

        submenu_color_management("detail_apprenant");

        setdisplay_detail_apprenant("1");
        setadd_One_Apprenant("");
        setdisplay_detail_inscriptions("");
        setdisplay_detail_absence("");
        setdisplay_detail_historique("");
        setdisplay_detail_piece_joint("");
        setdisplay_menu_suivi_pedagogique("")

    }


    async function submenu_inscriptions() {
        setsubmenu("inscriptions");

        submenu_color_management("inscriptions");

        //await sleep(5);

        setdisplay_detail_inscriptions("1");
        setadd_One_Apprenant("");
        setdisplay_detail_apprenant("");
        setdisplay_detail_absence("");
        setdisplay_detail_historique("");
        setdisplay_detail_piece_joint("");
        setdisplay_menu_suivi_pedagogique("")
    }

    async function submenu_absence() {
        setsubmenu("absences");

        submenu_color_management("absences");
        //await sleep(5);

        setdisplay_detail_absence("1");
        setadd_One_Apprenant("");
        setdisplay_detail_apprenant("");
        setdisplay_detail_inscriptions("");
        setdisplay_detail_historique("");
        setdisplay_detail_piece_joint("");
        setdisplay_menu_suivi_pedagogique("")
    }

    async function submenu_suivi_pedagogique() {
        setsubmenu("suivi_pedagogique");

        submenu_color_management("suivi_pedagogique");

        //await sleep(5);
        setdisplay_menu_suivi_pedagogique('1')
        setdisplay_detail_absence("");
        setadd_One_Apprenant("");
        setdisplay_detail_apprenant("");
        setdisplay_detail_inscriptions("");
        setdisplay_detail_historique("");
        setdisplay_detail_piece_joint("");

        Get_Appenant_List_Suivi_Pedagogique(selected_apprenant_id);
    }


    async function submenu_historique() {
        setsubmenu("historique");
        submenu_color_management("historique");
        //await sleep(5);

        setdisplay_detail_historique("1");
        setadd_One_Apprenant("");

        setdisplay_detail_apprenant("");
        setdisplay_detail_inscriptions("");
        setdisplay_detail_absence("");
        setdisplay_detail_piece_joint("");
    }

    async function submenu_piece_jointe() {
        setsubmenu("piece_jointe");

        submenu_color_management("piece_jointe");
        //await sleep(5);

        setdisplay_detail_piece_joint("1")
        setdisplay_detail_historique("");
        setadd_One_Apprenant("");

        setdisplay_detail_apprenant("");
        setdisplay_detail_inscriptions("");
        setdisplay_detail_absence("");
    }


    function Show_Detailled_Apprenant_Data() {

        Dialog_apprenant_handleClose_buton();

        setadd_One_Apprenant('');
        setapprenant_data_edit_mode('');
        setdisplay_detail_apprenant('1');

        Get_Current_Apprenant_Data(selected_apprenant_id);
        Get_Apprenant_Images(selected_apprenant_id);
        Get_List_Of_All_PJ(selected_apprenant_id);


        Get_Appenant_List_Inscription(selected_apprenant_id);

        Get_Appenant_List_Suivi_Pedagogique(selected_apprenant_id);

        setgridline_id(selected_apprenant_row_id);

        submenu_detail_apprenant();

    }



    const myRef = useRef(null)
    const myRef_detail_suivi_ped = useRef(null)

    /*
       *** ALGORITHME
       1 - Récuperer la liste des champs specifiques de la collection concernée - Array : list_nom_champs_spec
       2 - Récupérer les caracteristiques de champs champs spécifique : "id, nom, label, type valeur, etc"
          - avec un champ 'value' à vide. Mettre dans le tableau JSON, 'spec_champs_valeur'
       
       3 - Créer une nouvelle table qui permettra de gerer les hook : const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
       4 - Afficher en html les elements 'rows_champs_specifics'
       5 - Gerer la modification avec la fonction 'change_champs_spec_handle'
       6 - On recuperer les valeur lors du click d'enregistrement.
       
       */


    const list_nom_champs_spec = []
    const [spec_champs_valeur, setspec_champs_valeur] = useState([]);

    //const spec_champs_valeur = ""



    const [display_champs_specifics, setdisplay_champs_specifics] = useState("0");

    function afficher_champs_spec() {

        setdisplay_champs_specifics("1");
        //setrows_champs_specifics(spec_champs_valeur);

    }

    function findIndexByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {

            if (data[i][key] == value) {
                return i;
            }
        }
        return -1;
    }


    function findValueByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] == value) {

                var tmp_val = JSON.parse(data[i]);

                return tmp_val.field_value

            }
        }
        return "";
    }

    function change_champs_spec_handle() {

        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;



            if (document.getElementsByName(String(field_name))[0]) {

                var field_value = document.getElementsByName(String(field_name))[0].value;
                var new_val = { 'field_name': field_name, 'field_value': field_value, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);
                if (johnIndex > -1) {
                    spec_field_updated_values[johnIndex] = new_val;

                } else {
                    spec_field_updated_values.push(new_val);
                }
            }

        }

        //setrows_champs_specifics(spec_champs_valeur);

        //console.log(" OUTT spec_field_updated_values = ", spec_field_updated_values);
        setspec_field_updated_values_hooks(spec_field_updated_values)
    }



    const [file_1_name, setfile_1_name] = useState();
    const [file_2_name, setfile_2_name] = useState();

    const [Add_One_Apprenant_Data_api, setAdd_One_Apprenant_Data_api] = useState();
    const [Add_One_Apprenant_Data_message, setAdd_One_Apprenant_Data_message] = useState();
    const [Add_One_Apprenant_Data_result, setAdd_One_Apprenant_Data_result] = useState();
    function Add_One_Apprenant_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        // Controle sur les champs

        if (p_one_nom.trim() == "") {
            alert(" Vous devez saisir le nom de l'apprenant");
            return;
        }


        if (p_one_prenom.trim() == "") {
            alert(" Vous devez saisir le prenom de l'apprenant");
            return;
        }


        if (p_one_email.trim() == "") {
            alert(" Vous devez saisir l'email de l'apprenant");
            return;
        }

        if (p_one_naissance.trim() == "") {
            alert(" Vous devez saisir la date de naissance de l'apprenant");
            return;
        }

        if (p_one_civilite.trim() == "") {
            alert(" Vous devez saisir la civilité de l'apprenant");
            return;
        }


        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!p_one_email.trim().match(validRegex)) {
            alert("L'adresse email est invalide.");
            return;
        }



        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("nom", p_one_nom);
        form.append("email", p_one_email);
        form.append("prenom", p_one_prenom);
        form.append("civilite", p_one_civilite);
        form.append("date_naissance", p_one_naissance);



        if (p_one_telephone)
            form.append("telephone", p_one_telephone);
        else
            form.append("telephone", "");


        if (p_one_employeur)
            form.append("employeur", p_one_employeur);
        else
            form.append("employeur", "");


        if (p_one_client_rattachement_id)
            form.append("client_rattachement_id", p_one_client_rattachement_id);
        else
            form.append("client_rattachement_id", "");


        if (p_one_adresse)
            form.append("adresse", p_one_adresse);
        else
            form.append("adresse", "");

        if (p_one_code_postal)
            form.append("code_postal", p_one_code_postal);
        else
            form.append("code_postal", "");


        if (p_one_ville)
            form.append("ville", p_one_ville);
        else
            form.append("ville", "");

        if (p_one_pays)
            form.append("pays", p_one_pays);
        else
            form.append("pays", "");


        if (p_one_tuteur1_nom)
            form.append("tuteur1_nom", p_one_tuteur1_nom);
        else
            form.append("tuteur1_nom", "");

        if (p_one_tuteur1_prenom)
            form.append("tuteur1_prenom", p_one_tuteur1_prenom);
        else
            form.append("tuteur1_prenom", "");


        if (p_one_tuteur1_email) {
            form.append("tuteur1_email", p_one_tuteur1_email);
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!p_one_tuteur1_email.trim().match(validRegex)) {
                alert("L'adresse email du tuteur 1 est invalide.");
                return;
            }
        }
        else
            form.append("tuteur1_email", "");

        if (p_one_tuteur1_telephone)
            form.append("tuteur1_telephone", p_one_tuteur1_telephone);
        else
            form.append("tuteur1_telephone", "");


        if (p_one_tuteur2_nom)
            form.append("tuteur2_nom", p_one_tuteur2_nom);
        else
            form.append("tuteur2_nom", "");

        if (p_one_tuteur2_prenom)
            form.append("tuteur2_prenom", p_one_tuteur2_prenom);
        else
            form.append("tuteur2_prenom", "");


        if (p_one_tuteur2_email) {
            form.append("tuteur2_email", p_one_tuteur2_email);
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!p_one_tuteur2_email.trim().match(validRegex)) {
                alert("L'adresse email du tuteur 2 est invalide.");
                return;
            }
        }
        else
            form.append("tuteur2_email", "");

        if (p_one_tuteur2_telephone)
            form.append("tuteur2_telephone", p_one_tuteur2_telephone);
        else
            form.append("tuteur2_telephone", "");




        /*
       Gestion des champs spécifiques ajoutés pour l'apprenant
    */
        for (let i = 0; i < spec_field_updated_values_hooks.length; i++) {
            var local_value = String(spec_field_updated_values_hooks[i].field_value);

            if (String(spec_field_updated_values_hooks[i].field_type) === "float") {

                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " doit être numérique.")
                    return;
                }

            }
            else if (String(spec_field_updated_values_hooks[i].field_type) === "string") {

            }
            if (String(spec_field_updated_values_hooks[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " est obligatoire.")
                    return;
                }

            }

            form.append(String(spec_field_updated_values_hooks[i].field_name), local_value);
        }


        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Apprenant/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_One_Apprenant_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Apprenant_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                Clear_One_Apprenant_Fields();
                setAdd_One_Apprenant_Data_api("true");
                setAdd_One_Apprenant_Data_result(res.data.message);

                Get_All_Apprenant();

                setapprenant_data_changed("");
                setapprenant_data_edit_mode("");
                setadd_One_Apprenant("0");

                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_One_Apprenant_Data_api("false");
                setAdd_One_Apprenant_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Add_One_Apprenant_Data : Not good man :( Add_One_Apprenant_Data = ' + error);
            setAdd_One_Apprenant_Data_api("false");
            alert(" Impossible d'ajouter l'apprenant ");

        })
    }


    const [p_dialog_session_id, setp_dialog_session_id] = useState("");
    const [p_dialog_type_apprenant, setp_dialog_type_apprenant] = useState("");
    const [p_dialog_financement, setp_dialog_financement] = useState("");
    const [p_dialog_client_rattachement_id, setp_dialog_client_rattachement_id] = useState("");



    const [Add_Inscription_Apprenant_api, setAdd_Inscription_Apprenant_api] = useState();
    const [Add_Inscription_Apprenant_message, setAdd_Inscription_Apprenant_message] = useState();
    const [Add_Inscription_Apprenant_result, setAdd_Inscription_Apprenant_result] = useState();
    function Add_Inscription_Apprenant() {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", selected_apprenant_id);
        form.append("session_id", p_dialog_session_id);
        form.append("type_apprenant", p_dialog_type_apprenant);
        form.append("modefinancement", p_dialog_financement);
        form.append("client_rattachement_id", p_dialog_client_rattachement_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Apprenant_Inscrire_Session/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Inscription_Apprenant  res.data.status = " + res.data.status);
            //console.log(" In Add_Inscription_Apprenant  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {

                setAdd_Inscription_Apprenant_api("true");
                setAdd_Inscription_Apprenant_result(res.data.message);

                Dialog_add_inscription_handleClose_buton();
                Get_Appenant_List_Inscription(selected_apprenant_id);
                Get_All_Apprenant();

                setp_dialog_session_id('');
                setp_dialog_type_apprenant('');
                setp_dialog_financement('');

                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_Inscription_Apprenant_api("false");
                setAdd_Inscription_Apprenant_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Add_Inscription_Apprenant : Not good man :( Add_Inscription_Apprenant = ' + error);
            setAdd_Inscription_Apprenant_api("false");
            alert(" Impossible d'ajouter l'inscription ");

        })
    }


    const [Delete_Apprenant_Data_api, setDelete_Apprenant_Data_api] = useState();
    const [Delete_Apprenant_Data_message, setDelete_Apprenant_Data_message] = useState();
    const [Delete_Apprenant_Data_result, setDelete_Apprenant_Data_result] = useState();
    function Delete_Apprenant_Data(apprenand_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("apprenant_id", apprenand_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_Apprenant/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Apprenant_Data  res.data.status = " + res.data.status);
            //console.log(" In Delete_Apprenant_Data  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setDelete_Apprenant_Data_api("true");
                setDelete_Apprenant_Data_result(res.data.message);

                setSelectionModel([]);

                setadd_One_Apprenant('');
                setdisplay_detail_apprenant('');
                Clear_Detail_Apprenant_Fields();
                setselected_apprenant_id('');
                setgridline_id('');
                Get_All_Apprenant();
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
                alert(res.data.message);
            }
            else {
                setDelete_Apprenant_Data_api("false");
                setDelete_Apprenant_Data_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.log('Delete_Apprenant_Data : Not good man :( Delete_Apprenant_Data = ' + error);
            setDelete_Apprenant_Data_api("false");
            alert(" Impossible de supprimer l'apprenant");
        })
    }


    const [Delete_LIST_Apprenant_api, setDelete_LIST_Apprenant_api] = useState();
    const [Delete_LIST_Apprenant_message, setDelete_LIST_Apprenant_message] = useState();
    const [Delete_LIST_Apprenant_result, setDelete_LIST_Apprenant_result] = useState();
    function Delete_LIST_Apprenant(local_liste_apprenant) {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        //console.log(" #### local_liste_session = ", local_liste_session)

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("list_apprenant_id", local_liste_apprenant);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_List_Apprenant/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_LIST_Apprenant  res.data.status = " + res.data.status);
            //console.log(" In Delete_LIST_Apprenant  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setDelete_LIST_Apprenant_api("true");
                setDelete_LIST_Apprenant_result(res.data.message);

                setSelectionModel([]);

                setadd_One_Apprenant('');
                setdisplay_detail_apprenant('');
                Clear_Detail_Apprenant_Fields();
                setselected_apprenant_id('');
                setgridline_id('');
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });

                Get_All_Apprenant();

                alert(res.data.message);
            }
            else {
                setDelete_LIST_Apprenant_api("false");
                setDelete_LIST_Apprenant_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_LIST_Apprenant : Not good man :( mysearchtext = ' + error);
            setDelete_LIST_Apprenant_api("false");
            alert(" Impossible de supprimer la liste des apprenants");
        })
    }

    const [Add_Inscription_List_Apprenant_api, setAdd_Inscription_List_Apprenant_api] = useState();
    const [Add_Inscription_List_Apprenant_message, setAdd_Inscription_List_Apprenant_message] = useState();
    const [Add_Inscription_List_Apprenant_result, setAdd_Inscription_List_Apprenant_result] = useState();
    function Add_Inscription_List_Apprenant() {


        var liste_apprenant = GetSelectedRowsSession();
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("list_apprenant_id", liste_apprenant);
        form.append("session_id", p_dialog_session_id);
        form.append("type_apprenant", p_dialog_type_apprenant);
        form.append("modefinancement", p_dialog_financement);
        form.append("client_rattachement_id", p_dialog_client_rattachement_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Apprenant_Inscrire_Session_List/";

        Dialog_add_inscription_handleClose_buton();

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Inscription_List_Apprenant  res.data.status = " + res.data.status);
            //console.log(" In Add_Inscription_List_Apprenant  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {

                setAdd_Inscription_List_Apprenant_api("true");
                setAdd_Inscription_List_Apprenant_result(res.data.message);

                Dialog_add_inscription_handleClose_buton();

                Get_All_Apprenant();

                setp_dialog_session_id('');
                setp_dialog_type_apprenant('');
                setp_dialog_financement('');
                setSelectionModel([]);

                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_Inscription_List_Apprenant_api("false");
                setAdd_Inscription_List_Apprenant_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Add_Inscription_List_Apprenant : Not good man :( Add_Inscription_List_Apprenant = ' + error);
            setAdd_Inscription_List_Apprenant_api("false");
            alert(" Impossible d'inscrire les apprenant à cette session ");

        })
    }

    const myRef_head = useRef(null);
    const [apprenant_data_changed, setapprenant_data_changed] = useState('');
    const [apprenant_data_edit_mode, setapprenant_data_edit_mode] = useState('');


    function Annule_add_one_Apprenant() {
        setdisplay_detail_apprenant("");
        setadd_One_Apprenant("");
        setsubmenu("");
        setSelectionModel([]);
    }

    const imglogoclasschangeHandler = (event) => {


        let file_size = event.target.files[0].size;

        //or if you like to have name and type
        //console.log(event.target.files);

        let file_name = event.target.files[0].name;
        let file_type = event.target.files[0].type;



        //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
        if (file_size > 1000000) {
            alert("L'image ne doit pas dépasser un 1 Méga octets");
            return;
        }
        setisimgclassSelected(event.target.files[0]);
        setisimgclassSelectedfile(true);

        setuserimgclassprofil(URL.createObjectURL(event.target.files[0]));
        setuserimgclassprofilchanged("1");

    };


    const [record_apprenant_Image_api, setrecord_apprenant_Image_api] = useState();
    const [record_apprenant_Image_message, setrecord_apprenant_Image_message] = useState();
    const [record_apprenant_Image_result, setrecord_apprenant_Image_result] = useState();
    function record_apprenant_Image(l) {

        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Apprenant_Image/";

        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        formData.append("apprenant_id", selected_apprenant_id);

        formData.append('file_img', isimgclassSelected);
        formData.append('file_img_recid', isimgclassSelected_recid);

        setLoading(true);
        //console.log(" ### formData = ", formData);
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) === String("true")) {
                    setrecord_apprenant_Image_api("true");
                    setuserimgclassprofilchanged("");
                    alert(" L'image a été enregistrée ");

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                } else {
                    setrecord_apprenant_Image_api("false");
                    alert(" Erreur : " + result['message']);
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setrecord_apprenant_Image_api("false");
                alert(" Erreur : Impossible d'enregistrer l'image ");

            });


    };

    function removeRecodedLogoImage() {
        if (!isimgclassSelected_recid || String(isimgclassSelected_recid).length < 3) {
            alert(" Image incorrecte. Impossible de la supprimer ");
            return;
        }

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("image_recid", isimgclassSelected_recid);

        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Delete_Apprenant_Image/",
            {
                method: 'POST',
                body: form,
            }
        ).then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(' removeRecodedClassImage : status:', result['status']);
                // console.log('removeRecodedClassImage : Success:', result['message']);


                if (String(result['status']) === String("true")) {
                    setisimgclassSelected("");
                    setisimgclassSelected_recid("");
                    setisimgclassSelectedfile("");
                    setuserimgclassprofil("");

                    Get_Apprenant_Images(selected_apprenant_id);

                }
                else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                    return;
                }

                else {
                    alert(result['message']);
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible de supprimer l'image ");
            });

    }

    const [quel_tuteur, setquel_tuteur] = useState("1");
    const [Dialog_tuteur_1_message, setDialog_tuteur_1_message] = React.useState(false);
    const [Dialog_tuteur_1_open, setDialog_tuteur_1_open] = React.useState(false);

    function Dialog_1_handle_change_tuteur(message) {
        setDialog_tuteur_1_message(message);
        setDialog_tuteur_1_open(true);
    }

    const Dialog_tuteur_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_tuteur_1_handleClose_buton = () => {
        setDialog_tuteur_1_open(false);
    };

    const [Add_Update_Apprenant_Tuteur_api, setAdd_Update_Apprenant_Tuteur_api] = useState();
    const [Add_Update_Apprenant_Tuteur_message, setAdd_Update_Apprenant_Tuteur_message] = useState();
    const [Add_Update_Apprenant_Tuteur_result, setAdd_Update_Apprenant_Tuteur_result] = useState();
    function Add_Update_Apprenant_Tuteur() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", selected_apprenant_id);

        if (String(quel_tuteur) === "1") {
            if (p_detail_tuteur1_nom && String(p_detail_tuteur1_nom).trim() !== "")
                form.append("tuteur1_nom", p_detail_tuteur1_nom);
            else {
                alert(" Le nom du tuteur est invalide ");
                return;
            }


            if (p_detail_tuteur1_civilite)
                form.append("tuteur1_civilite", p_detail_tuteur1_civilite);
            else {
                form.append("tuteur1_civilite", "");
            }




            if (p_detail_tuteur1_prenom && String(p_detail_tuteur1_prenom).trim() !== "")
                form.append("tuteur1_prenom", p_detail_tuteur1_prenom);
            else {
                alert(" Le prénom du tuteur est invalide ");
                return;
            }

            if (p_detail_tuteur1_email && String(p_detail_tuteur1_email).trim() !== "") {
                var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (!p_detail_tuteur1_email.trim().match(validRegex)) {
                    alert("L'adresse email du tuteur est invalide.");
                    return;
                }

                form.append("tuteur1_email", p_detail_tuteur1_email.trim());
            } else {
                alert("L'adresse email du tuteur est invalide.");
                return;
            }


            if (p_detail_tuteur1_telephone)
                form.append("tuteur1_telephone", p_detail_tuteur1_telephone);
            else
                form.append("tuteur1_telephone", "");


            if (p_detail_tuteur1_adresse)
                form.append("tuteur1_adresse", p_detail_tuteur1_adresse);
            else
                form.append("tuteur1_adresse", "");


            if (p_detail_tuteur1_cp)
                form.append("tuteur1_cp", p_detail_tuteur1_cp);
            else
                form.append("tuteur1_cp", "");

            if (p_detail_tuteur1_ville)
                form.append("tuteur1_ville", p_detail_tuteur1_ville);
            else
                form.append("tuteur1_ville", "");

            if (p_detail_tuteur1_pays)
                form.append("tuteur1_pays", p_detail_tuteur1_pays);
            else
                form.append("tuteur1_pays", "");



            if (p_detail_tuteur1_include_com && String(p_detail_tuteur1_include_com) === "true")
                form.append("tuteur1_include_com", "1");
            else
                form.append("tuteur1_include_com", "0");

        }

        if (String(quel_tuteur) === "2") {
            if (p_detail_tuteur2_nom && String(p_detail_tuteur2_nom).trim() !== "")
                form.append("tuteur2_nom", p_detail_tuteur2_nom);
            else {
                alert(" Le nom du tuteur est invalide ");
                return;
            }

            if (p_detail_tuteur2_civilite)
                form.append("tuteur2_civilite", p_detail_tuteur2_civilite);
            else {
                form.append("tuteur2_civilite", "");
            }



            if (p_detail_tuteur2_prenom && String(p_detail_tuteur2_prenom).trim() !== "")
                form.append("tuteur2_prenom", p_detail_tuteur2_prenom);
            else {
                alert(" Le prénom du tuteur est invalide ");
                return;
            }

            if (p_detail_tuteur2_email && String(p_detail_tuteur2_email).trim() !== "") {
                var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (!p_detail_tuteur2_email.trim().match(validRegex)) {
                    alert("L'adresse email du tuteur est invalide.");
                    return;
                }
                form.append("tuteur2_email", p_detail_tuteur2_email.trim());
            } else {
                alert("L'adresse email du tuteur est invalide.");
                return;
            }



            if (p_detail_tuteur2_telephone)
                form.append("tuteur2_telephone", p_detail_tuteur2_telephone);
            else
                form.append("tuteur2_telephone", "");


            if (p_detail_tuteur2_adresse)
                form.append("tuteur2_adresse", p_detail_tuteur2_adresse);
            else
                form.append("tuteur2_adresse", "");


            if (p_detail_tuteur2_cp)
                form.append("tuteur2_cp", p_detail_tuteur2_cp);
            else
                form.append("tuteur2_cp", "");

            if (p_detail_tuteur2_ville)
                form.append("tuteur2_ville", p_detail_tuteur2_ville);
            else
                form.append("tuteur2_ville", "");

            if (p_detail_tuteur2_pays)
                form.append("tuteur2_pays", p_detail_tuteur2_pays);
            else
                form.append("tuteur2_pays", "");



            if (p_detail_tuteur2_include_com && String(p_detail_tuteur2_include_com) === "true")
                form.append("tuteur2_include_com", "1");
            else
                form.append("tuteur2_include_com", "0");

        }

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Apprenant_Tuteurs/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
            //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_Update_Apprenant_Tuteur_api("true");
                setAdd_Update_Apprenant_Tuteur_result(res.data.message);
                Dialog_tuteur_1_handleClose_buton();

                alert(res.data.message);

            }
            else {
                setAdd_Update_Apprenant_Tuteur_api("false");
                setAdd_Update_Apprenant_Tuteur_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( mysearchtext = ' + error);
            setAdd_Update_Apprenant_Tuteur_api("false");
            alert(" Impossible de mettre à jour le tuteur");

        })
    }


    const New_Type_apprenant = [
        { "id": "0", "label": "Autre" },
        { "id": "1", "label": "Salariés" },
        { "id": "2", "label": "Apprentis" },
        { "id": "3", "label": "Particuliers" },
        { "id": "4", "label": "Sans Emploi" }
    ]

    const New_civilite = [
        { "id": "mme", "label": "Mme", "value": "mme" },
        { "id": "m", "label": "M", "value": "m" },
        { "id": "neutre", "label": "Neutre", "value": "neutre" },
        { "id": "", "label": "", "value": "" },
    ]


    const New_Financement = [
        {
            value: 'cpf',
            label: 'CPF',
        },
        {
            value: 'perso',
            label: 'Fonds Propres',
        },
        {
            value: 'entreprise',
            label: 'Votre entreprise',
        },

        {
            value: 'mixte_cpf_perso',
            label: 'CPF & Fonds Propres',
        },
        {
            value: 'modefinancement',
            label: 'modefinancement',
        },
        {
            value: 'autre',
            label: 'Autre',
        },

    ];


    const [New_Get_List_Apprenant_Modele_Courrier_result, setNew_Get_List_Apprenant_Modele_Courrier_result] = useState([]);

    const [Get_List_Apprenant_Modele_Courrier_api, setGet_List_Apprenant_Modele_Courrier_api] = useState();
    const [Get_List_Apprenant_Modele_Courrier_message, setGet_List_Apprenant_Modele_Courrier_message] = useState();
    const [Get_List_Apprenant_Modele_Courrier_result, setGet_List_Apprenant_Modele_Courrier_result] = useState();
    function Get_List_Apprenant_Modele_Courrier(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        //form.append("inscription_id", selected_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Fiche_Apprenant/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_List_Apprenant_Modele_Courrier  res.data.status = " + res.data.status);
                //console.log(" In Get_List_Apprenant_Modele_Courrier  res.data.message r_class = " + res.data.message);
                setGet_List_Apprenant_Modele_Courrier_api("true");
                setGet_List_Apprenant_Modele_Courrier_result(res.data.message);

                var new_data2 = [];
                var node = {
                    "_id": "default_pdf",
                    "id": "default_pdf",
                    "label": "PDF par default",
                    "ref_interne": "",
                    "nom": "",
                    "sujet": "",
                    "type_doc": "",
                    "cible": "",
                    "joint_pdf": "",

                };
                new_data2.push(node);

                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_ref_interne = JSON.parse(x).ref_interne;
                    var local_nom = JSON.parse(x).nom;
                    var local_sujet = JSON.parse(x).sujet;
                    var local_type_doc = JSON.parse(x).type_doc;
                    var local_cible = JSON.parse(x).cible;
                    var local_joint_pdf = JSON.parse(x).joint_pdf;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "ref_interne": local_ref_interne,
                        "nom": local_nom,
                        "sujet": local_sujet,
                        "type_doc": local_type_doc,
                        "cible": local_cible,
                        "joint_pdf": local_joint_pdf,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Apprenant_Modele_Courrier_result(new_data2);

            }
            else {
                setGet_List_Apprenant_Modele_Courrier_api("false");
                setGet_List_Apprenant_Modele_Courrier_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Apprenant_Modele_Courrier = ', error);
            setGet_List_Apprenant_Modele_Courrier_api("false");
            alert("Impossible de récupérer les modèles de convention");
            //setmyApimyApiMessage("")
        })
    }



    const [Download_Apprenant_Fiche_PDF_api, setDownload_Apprenant_Fiche_PDF_api] = useState();
    const [Download_Apprenant_Fiche_PDF_result, setDownload_Apprenant_Fiche_PDF_result] = useState();
    const [Download_Apprenant_Fiche_PDF_message, setDownload_Apprenant_Fiche_PDF_message] = useState();

    const Download_Apprenant_Fiche_PDF = (event) => {
        const stored_cookie = getCookie('tokenmysypart');


        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var token = stored_cookie;


        var url = process.env.REACT_APP_API_URL + "myclass/api/Print_Apprenant_PDF/" + token + "/" + selected_apprenant_id + "/" + fiche_apprenant_id;

        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, nom_fiche_detaillee);
                setDownload_Apprenant_Fiche_PDF_api("true");
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setDownload_Apprenant_Fiche_PDF_api("false");

            });
    }


    const [selected_row_data_json_age, setselected_row_data_json_age] = useState("");
    const [fiche_apprenant_id, setfiche_apprenant_id] = React.useState("");

    const [Dialog_fiche_apprenant_message, setDialog_fiche_apprenant_message] = React.useState(false);
    const [Dialog_fiche_apprenant_open, setDialog_fiche_apprenant_open] = React.useState(false);

    function Dialog_fiche_apprenant_handle_change(message) {
        setDialog_fiche_apprenant_message(message);
        setDialog_fiche_apprenant_open(true);
    }


    const Dialog_fiche_apprenant_handleClose_buton = () => {
        setDialog_fiche_apprenant_open(false);
    };

    const Dialog_fiche_apprenant_handleClose = () => {
        setDialog_fiche_apprenant_open(false);
    };


    const New_Option = [

        { "id": "0", "label": "Dupliquer", "value": "dupliquer" },
        { "id": "1", "label": "Exporter", "value": "exporter" },
        { "id": "3", "label": "(R)Inscrire", "value": "inscrire" },
        { "id": "2", "label": "Supprimer", "value": "supprimer" },
    ]





    const [Dialog_Suivi_Pedago_message, setDialog_Suivi_Pedago_message] = React.useState(false);
    const [Dialog_Suivi_Pedago_open, setDialog_Suivi_Pedago_open] = React.useState(false);
    function Dialog_Suivi_Pedago_handle_change_participant_session(message) {
        setDialog_Suivi_Pedago_message(message);
        setDialog_Suivi_Pedago_open(true);
    }

    const Dialog_Suivi_Pedago_handleClose = () => {

    };

    const Dialog_Suivi_Pedago_handleClose_buton = () => {
        setDialog_Suivi_Pedago_open(false);
    };


    function add_suivi_ped_funct() {
        Clear_Suivi_Pedago_Field();

        setdisplay_given_suivi_ped("");
        setgiven_suivi_ped_changed("");
        setadd_one_suivi_ped("1");

        if (document.getElementById('myRef_detail_suivi_ped')) {
            // myRef.current.scrollIntoView({ behavior: "smooth" });
            var divh = document.getElementById('myRef_detail_suivi_ped').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }

    function Annule_Add_One_Suiv_Pedagogique() {
        Clear_Suivi_Pedago_Field();
        setdisplay_given_suivi_ped("");
        setgiven_suivi_ped_changed("");
        setadd_one_suivi_ped("");
    }



    const [Get_Geven_Suivi_Ped_Data_api, setGet_Geven_Suivi_Ped_Data_api] = useState();
    const [Get_Geven_Suivi_Ped_Data_message, setGet_Geven_Suivi_Ped_Data_message] = useState();
    const [Get_Geven_Suivi_Ped_Data_result, setGet_Geven_Suivi_Ped_Data_result] = useState();
    function Get_Geven_Suivi_Ped_Data(local_selected_suivi_pedagogique_id) {

        var my_id = local_selected_suivi_pedagogique_id;

        Clear_Suivi_Pedago_Field();

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", my_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Suivi_Pedagogique/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_Geven_Suivi_Ped_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Geven_Suivi_Ped_Data  res.data.message r_class = " + res.data.message);
                //console.log(" In Get_Geven_Suivi_Ped_Data  res.data.message len = " + String(res.data.message).length);

                setLoading(false);
                setGet_Geven_Suivi_Ped_Data_api("true");
                if (String(res.data.message).length > 0) {
                    setGet_Geven_Suivi_Ped_Data_result(res.data.message);

                    var mylocaltraining = JSON.parse(res.data.message);


                    if (mylocaltraining.responsable_id) {
                        setp_detail_suivi_ped_responsable_id(mylocaltraining.responsable_id);
                    } else {
                        setp_detail_suivi_ped_responsable_id("");
                    }

                    if (mylocaltraining.class_id) {
                        setp_detail_suivi_ped_class_id(mylocaltraining.class_id);
                    } else {
                        setp_detail_suivi_ped_class_id("");
                    }


                    if (mylocaltraining.class_eu_id) {
                        setp_detail_suivi_ped_class_eu_id(mylocaltraining.class_eu_id);
                    } else {
                        setp_detail_suivi_ped_class_eu_id("");
                    }

                    if (mylocaltraining.session_id) {
                        setp_detail_suivi_ped_session_id(mylocaltraining.session_id);
                    } else {
                        setp_detail_suivi_ped_session_id("");
                    }


                    if (mylocaltraining.date_heure_debut) {
                        setp_detail_suivi_ped_eval_date_heure_debut(mylocaltraining.date_heure_debut);
                    } else {
                        setp_detail_suivi_ped_eval_date_heure_debut("");
                    }

                    if (mylocaltraining.date_heure_fin) {
                        setp_detail_suivi_ped_eval_date_heure_fin(mylocaltraining.date_heure_fin);
                    } else {
                        setp_detail_suivi_ped_eval_date_heure_fin("");
                    }

                    if (mylocaltraining.observation) {
                        setfield_contenu_observation(mylocaltraining.observation);
                    } else {
                        setfield_contenu_observation("");
                    }


                    if (mylocaltraining.commentaire) {
                        setfield_contenu_comment(mylocaltraining.commentaire);
                    } else {
                        setfield_contenu_comment("");
                    }



                    if (mylocaltraining.class_internal_url) {
                        setp_detail_suivi_ped_class_internal_url(mylocaltraining.class_internal_url);
                    } else {
                        setp_detail_suivi_ped_class_internal_url("");
                    }

                    if (mylocaltraining.sujet) {
                        setp_detail_suivi_ped_sujet(mylocaltraining.sujet);
                    } else {
                        setp_detail_suivi_ped_sujet("");
                    }


                    if (document.getElementById('myRef_detail_suivi_ped')) {
                        // myRef.current.scrollIntoView({ behavior: "smooth" });
                        var divh = document.getElementById('myRef_detail_suivi_ped').offsetTop;
                        window.scrollTo({
                            top: divh,
                            behavior: "smooth",
                        });
                    }

                    Disable_Suivi_Pedago_Fields();

                }

            } else if (String(res.data.status) === String("Err_Connexion")) {
                setLoading(false);
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setLoading(false);
                setGet_Geven_Suivi_Ped_Data_api("false");
                setGet_Geven_Suivi_Ped_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Geven_Suivi_Ped_Data = ', error);
            setGet_Geven_Suivi_Ped_Data_api("false");
            alert(" Impossible de récuperer les données de l'apprenant ");

        })
    }


    const [display_given_suivi_ped, setdisplay_given_suivi_ped] = useState("");
    const [given_suivi_ped_changed, setgiven_suivi_ped_changed] = useState("");
    const [given_suivi_ped_data_edit_mode, setgiven_suivi_ped_data_edit_mode] = useState("");
    const [add_one_suivi_ped, setadd_one_suivi_ped] = useState("");


    function Disable_Suivi_Pedago_Fields() {
        setgiven_suivi_ped_data_edit_mode("");
        const list_fields = ['suivi_pedago_detail_ftion', 'suivi_pedago_detail_classe', 'suivi_pedago_detail_ue',
            'suivi_pedago_detail_debut', 'suivi_pedago_detail_fin', 'suivi_pedago_responsable',
            'suivi_pedago_observation', 'suivi_pedago_comment', 'suivi_pedago_detail_sujet']

        for (let i = 0; i < list_fields.length; i++) {
            if (document.getElementsByName(String(list_fields[i])) && document.getElementsByName(String(list_fields[i]))[0]) {
                document.getElementsByName(String(list_fields[i]))[0].disabled = true;
                document.getElementsByName(String(list_fields[i]))[0].style.backgroundColor = "#ECEFF1";

            }
        }

    }

    function Enable_Suivi_Pedago_Fields() {
        setgiven_suivi_ped_data_edit_mode("1");


        const list_fields = ['suivi_pedago_detail_ftion', 'suivi_pedago_detail_classe', 'suivi_pedago_detail_ue',
            'suivi_pedago_detail_debut', 'suivi_pedago_detail_fin', 'suivi_pedago_responsable',
            'suivi_pedago_observation', 'suivi_pedago_comment', 'suivi_pedago_detail_sujet']

        for (let i = 0; i < list_fields.length; i++) {
            if (document.getElementsByName(String(list_fields[i])) && document.getElementsByName(String(list_fields[i]))[0]) {
                document.getElementsByName(String(list_fields[i]))[0].disabled = false;
                document.getElementsByName(String(list_fields[i]))[0].style.backgroundColor = "#FFFFFF";

            }
        }
    }



    const [field_contenu_observation, setfield_contenu_observation] = useState("");
    const editorRef_observation = useRef(null);
    const [editorRef_observation_limite, seteditorRef_observation_limite] = useState(800);

    function field_contenu_observation_keyup() {
        setgiven_suivi_ped_changed("1");
    }

    const [field_contenu_comment, setfield_contenu_comment] = useState("");
    const editorRef_comment = useRef(null);
    const [editorRef_comment_limite, seteditorRef_comment_limite] = useState(800);

    function field_contenu_comment_keyup() {
        setgiven_suivi_ped_changed("1");
    }

    function cancel_edit_suivi_ped() {
        setgiven_suivi_ped_data_edit_mode("");
        setgiven_suivi_ped_changed("");
        Get_Geven_Suivi_Ped_Data(selected_suivi_pedagogique_id);

    }

    
// DEBUT  EXPORT EXCEL DATAGRID 

function CustomToolbar(props) {


    return (
        <GridToolbarContainer {...props}>

            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector
                slotProps={{ tooltip: { title: 'Change density' } }}
            />
            <Box sx={{ flexGrow: 1 }} />
            <ExportButton data_colums={props.data_colums} />


        </GridToolbarContainer>
    );
}

function getExcelData(apiRef) {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

    // Format the data. Here we only keep the value
    const data = filteredSortedRowIds.map((id) => {
        const row = {};
        visibleColumnsField.forEach((field) => {
            row[field] = apiRef.current.getCellParams(id, field).value;
        });
        return row;
    });

    return data;
}


function ExportButton(props) {
    return (
        <GridToolbarExportContainer {...props}>
            <ExportMenuItem data_colums={props.data_colums} />
        </GridToolbarExportContainer>
    );
}

function handleExport(apiRef, data_colums) {
    const data = getExcelData(apiRef);
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
   

    const local_config = {
        keys: visibleColumnsField,

        columnNames: data_colums.filter((mydata) => (mydata).disableExport !== true).map(function (mydata) {
            if (mydata.headerName && visibleColumnsField.includes(mydata.field)) {
                if (mydata.headerName) {
                    return mydata.headerName;
                }
            }

        }),

        fileName: 'data_' + String(new Date().toJSON().slice(0, 10)).replaceAll("-", "_") + '.xlsx',
        sheetName: 'Export_' + String(new Date().toJSON().slice(0, 10)).replaceAll("-", "_"),
    };

    local_config.columnNames = local_config.columnNames.filter(function( element ) {
        return element !== undefined;
     });


    const rows = data.map((row) => {
        const mRow = {};
        for (const key of local_config.keys) {
            mRow[key] = row[key];
        }
        return mRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(rows);
    XLSX.utils.sheet_add_aoa(worksheet, [[...local_config.columnNames]], {
        origin: 'A1',
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, local_config.sheetName);
    XLSX.writeFile(workbook, local_config.fileName, { compression: true });
}

function ExportMenuItem(props) {
    const apiRef = useGridApiContext();
    const { hideMenu } = props;

    return (
        <MenuItem
            onClick={() => {
                handleExport(apiRef, props.data_colums);
                // Hide the export menu after the export
                hideMenu?.();
            }}
        >
            Export Excel
        </MenuItem>
    );
}

// FIN EXPORT EXCEL DATAGRID 

    return (
        <div className="apprenant">

            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            {/***** Dialog Suivi Pedagogique */}
            <Dialog
                open={Dialog_Suivi_Pedago_open}
                onClose={Dialog_Suivi_Pedago_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_Suivi_Pedago_message}
                    </DialogContentText>

                    <div className="session_caract"> <b> Civilité </b><br />
                        <Autocomplete
                            disablePortal
                            name="event_dialog_civilite"
                            id="event_dialog_civilite"
                            value={New_civilite.filter((data) => (data).value === String(p_detail_tuteur1_civilite))[0].label}

                            fullWidth

                            options={New_civilite}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_detail_tuteur1_civilite(value.value);
                                } else {
                                    setp_detail_tuteur1_civilite("");
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />
                        <div className="session_caract_Dialog" > <b> Nom </b>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_title"
                                id="event_dialog_title"
                                fullWidth
                                value={p_detail_tuteur1_nom}
                                onChange={(e) => {
                                    setp_detail_tuteur1_nom(e.target.value);
                                }
                                }

                            />

                        </div>


                    </div>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_Suivi_Pedago_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>

            {/***** FIN Dialog Suivi Pedagogique */}


            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>


            {/****** Dialog synthèse apprenant */}
            <Dialog
                open={Dialog_apprenant_open}
                onClose={Dialog_apprenant_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>Apprenant</DialogTitle>
                <DialogContent className="DialogContent_width">
                    <div>

                        <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >
                            <div className="div_row_gauche_image" style={{ "float": "right" }} >
                                <img class="img_class_logo_mini" src={userimgclassprofil} /><br />

                            </div>

                        </div>


                    </div>
                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Civilité
                        </div>
                        {Get_All_Apprenant_result &&
                            selected_apprenant_id &&
                            String(selected_apprenant_id).length > 0 &&
                            Get_All_Apprenant_result[selected_apprenant_row_id] &&
                            <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {New_civilite && New_civilite.length > 0 && selected_apprenant_row_id &&
                                    <nav>
                                        {New_civilite.filter((data) => (data).id === String(JSON.parse(Get_All_Apprenant_result[selected_apprenant_row_id]).civilite))[0].label}
                                    </nav>
                                }
                            </div>}



                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Nom & Prénom
                        </div>
                        {Get_All_Apprenant_result && selected_apprenant_id &&
                            String(selected_apprenant_id).length > 0 &&
                            Get_All_Apprenant_result[selected_apprenant_row_id] &&
                            <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                                {JSON.parse(Get_All_Apprenant_result[selected_apprenant_row_id]).nom.toUpperCase()}   {JSON.parse(Get_All_Apprenant_result[selected_apprenant_row_id]).prenom.charAt(0).toUpperCase() + JSON.parse(Get_All_Apprenant_result[selected_apprenant_row_id]).prenom.slice(1)}


                            </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            E-mail
                        </div>
                        {Get_All_Apprenant_result && selected_apprenant_id &&
                            String(selected_apprenant_id).length > 0 &&
                            Get_All_Apprenant_result[selected_apprenant_row_id] && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {JSON.parse(Get_All_Apprenant_result[selected_apprenant_row_id]).email}
                            </div>}
                    </div>


                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Téléphone
                        </div>
                        {Get_All_Apprenant_result && selected_apprenant_id &&
                            String(selected_apprenant_id).length > 0 &&
                            Get_All_Apprenant_result[selected_apprenant_row_id] && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {JSON.parse(Get_All_Apprenant_result[selected_apprenant_row_id]).telephone}
                            </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Age
                        </div>
                        {Get_All_Apprenant_result && selected_apprenant_id &&
                            String(selected_apprenant_id).length > 0 &&
                            Get_All_Apprenant_result[selected_apprenant_row_id] &&
                            <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {selected_row_data_json_age} ans - {JSON.parse(Get_All_Apprenant_result[selected_apprenant_row_id]).date_naissance}
                            </div>}

                    </div>


                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Adresse postale
                        </div>
                        {Get_All_Apprenant_result && selected_apprenant_id &&
                            String(selected_apprenant_id).length > 0 &&
                            Get_All_Apprenant_result[selected_apprenant_row_id] &&
                            <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {JSON.parse(Get_All_Apprenant_result[selected_apprenant_row_id]).adresse} <br />
                                {JSON.parse(Get_All_Apprenant_result[selected_apprenant_row_id]).code_postal} {JSON.parse(Get_All_Apprenant_result[selected_apprenant_row_id]).ville} <br />
                                {JSON.parse(Get_All_Apprenant_result[selected_apprenant_row_id]).pays.toUpperCase()}
                            </div>}
                    </div>

                    <div className="div_row" > &nbsp;

                    </div>
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={Show_Detailled_Apprenant_Data} className="bton_enreg_dialog">Voir détail</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_apprenant_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>
            {/****** FIN Dialog synthèse apprenant */}

            <Dialog
                open={Dialog_tuteur_1_open}
                onClose={Dialog_tuteur_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle>Tuteur  {quel_tuteur}</DialogTitle>
                {String(quel_tuteur) === "1" && <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_tuteur_1_message}
                    </DialogContentText>

                    <div className="session_caract"> <b> Civilité </b><br />
                        <Autocomplete
                            disablePortal
                            name="event_dialog_civilite"
                            id="event_dialog_civilite"
                            value={New_civilite.filter((data) => (data).value === String(p_detail_tuteur1_civilite))[0].label}

                            fullWidth

                            options={New_civilite}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_detail_tuteur1_civilite(value.value);
                                } else {
                                    setp_detail_tuteur1_civilite("");
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />


                    </div>


                    <div className="session_caract_Dialog" > <b> Nom </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_nom}
                            onChange={(e) => {
                                setp_detail_tuteur1_nom(e.target.value);
                            }
                            }

                        />

                    </div>

                    <div className="session_caract_Dialog" > <b> Prénom </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_prenom}
                            onChange={(e) => {
                                setp_detail_tuteur1_prenom(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" ><b> Email </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_email}
                            onChange={(e) => {
                                setp_detail_tuteur1_email(e.target.value);
                            }}
                        />
                    </div>


                    <div className="session_caract_Dialog" > Téléphone
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_telephone}
                            onChange={(e) => {
                                setp_detail_tuteur1_telephone(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Adresse
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_adresse}
                            onChange={(e) => {
                                setp_detail_tuteur1_adresse(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Code postal
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_cp}
                            onChange={(e) => {
                                setp_detail_tuteur1_cp(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Ville
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_ville}
                            onChange={(e) => {
                                setp_detail_tuteur1_ville(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Pays
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_pays}
                            onChange={(e) => {
                                setp_detail_tuteur1_pays(e.target.value);
                            }}
                        />
                    </div>

                    {/* <div className="session_caract_Dialog" > Inclure Communication
                        <Switch checked={p_detail_tuteur1_include_com} onChange={(e) => {
                            setp_detail_tuteur1_include_com(e.target.checked)
                        }} />
                    </div>*/}

                    <Tooltip className="tooltip_css" id="tooltip_com1" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="tooltip_com1" data-tooltip-html="Si oui, ce tuteur recevra tous les emails échangés">
                        <div className="session_caract" style={{ "float": "right", "fontSize": "12px", 'fontWeight': 'bolder', "width": "100%" }}>

                            <nav onChange={(e) => {


                                //console.log("is_contact_include_com == ", is_contact_include_com);
                                if (p_detail_tuteur1_include_com === true)
                                    setp_detail_tuteur1_include_com(false)
                                else {
                                    // Pour activier la fontion de "include com", le contact foit avoir une adresse email
                                    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                                    if (!p_detail_tuteur1_email.trim().match(validRegex)) {
                                        alert("L'adresse email est invalide. Pour activer cette fontion, vous devez saisir une adresse email valide");
                                        return;
                                    }

                                    setp_detail_tuteur1_include_com(true);

                                }

                            }}
                                id="toggleSwitchNav" name="toggleSwitchNav">
                                <ToggleSwitch
                                    label="Inclure / Exclure de la communication ? " id="toggleSwitch" name="toggleSwitch" checked={p_detail_tuteur1_include_com} style={{ height: "2rem" }} />
                            </nav>

                        </div>
                    </a>

                </DialogContent>}

                {String(quel_tuteur) === "2" && <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_tuteur_1_message}
                    </DialogContentText>

                    <div className="session_caract"> <b> Civilité </b><br />
                        <Autocomplete
                            disablePortal
                            name="event_dialog_civilite"
                            id="event_dialog_civilite"
                            value={New_civilite.filter((data) => (data).value === String(p_detail_tuteur2_civilite))[0].label}

                            fullWidth

                            options={New_civilite}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_detail_tuteur2_civilite(value.value);
                                } else {
                                    setp_detail_tuteur2_civilite("");
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />


                    </div>

                    <div className="session_caract_Dialog" > <b> Nom </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_nom}
                            onChange={(e) => {
                                setp_detail_tuteur2_nom(e.target.value);
                            }
                            }

                        />

                    </div>

                    <div className="session_caract_Dialog" > <b> Prénom </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_prenom}
                            onChange={(e) => {
                                setp_detail_tuteur2_prenom(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > <b> Email </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_email}
                            onChange={(e) => {
                                setp_detail_tuteur2_email(e.target.value);
                            }}
                        />
                    </div>


                    <div className="session_caract_Dialog" > Téléphone
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_telephone}
                            onChange={(e) => {
                                setp_detail_tuteur2_telephone(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Adresse
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_adresse}
                            onChange={(e) => {
                                setp_detail_tuteur2_adresse(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Code postal
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_cp}
                            onChange={(e) => {
                                setp_detail_tuteur2_cp(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Ville
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_ville}
                            onChange={(e) => {
                                setp_detail_tuteur2_ville(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Pays
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_pays}
                            onChange={(e) => {
                                setp_detail_tuteur2_pays(e.target.value);
                            }}
                        />
                    </div>

                    {/*<div className="session_caract_Dialog" > Inclure Communication
                        <Switch checked={p_detail_tuteur2_include_com} onChange={(e) => {
                            setp_detail_tuteur2_include_com(e.target.checked)
                        }} />
                    </div>*/}

                    <Tooltip className="tooltip_css" id="tooltip_com" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="tooltip_com" data-tooltip-html="Si oui, ce tuteur recevra tous les emails échangés">
                        <div className="session_caract" style={{ "float": "right", "fontSize": "12px", 'fontWeight': 'bolder', "width": "100%" }}>

                            <nav onChange={(e) => {


                                //console.log("is_contact_include_com == ", is_contact_include_com);
                                if (p_detail_tuteur2_include_com === true)
                                    setp_detail_tuteur2_include_com(false)
                                else {
                                    // Pour activier la fontion de "include com", le contact foit avoir une adresse email
                                    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                                    if (!p_detail_tuteur2_email.trim().match(validRegex)) {
                                        alert("L'adresse email est invalide. Pour activer cette fontion, vous devez saisir une adresse email valide");
                                        return;
                                    }

                                    setp_detail_tuteur2_include_com(true);

                                }

                            }}
                                id="toggleSwitchNav" name="toggleSwitchNav">
                                <ToggleSwitch
                                    label="Inclure / Exclure de la communication ? " id="toggleSwitch" name="toggleSwitch" checked={p_detail_tuteur2_include_com} style={{ height: "2rem" }} />
                            </nav>

                        </div>
                    </a>


                </DialogContent>}


                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            <Button onClick={Add_Update_Apprenant_Tuteur} className="bton_enreg_dialog">Mettre à jour</Button>

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_tuteur_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>

            {/*** Dialog pour reinscrire un apprenant à une session */}
            <Dialog
                open={Dialog_add_inscription_open}
                onClose={Dialog_add_inscription_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle> Inscrire </DialogTitle>
                <DialogContent className="DialogContent_width">

                    {New_Getall_TrainingSession_result && <div className="session_caract_Dialog"> <b> Session</b>

                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="session_caract_Dialog"
                            fullWidth
                            options={New_Getall_TrainingSession_result.filter((data) => (data).invoiced_statut !== "2")}
                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setp_dialog_session_id(value._id);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />
                    </div>}

                    <div className="session_caract_Dialog" > <b> Type apprenant </b>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="session_caract_Dialog"
                            fullWidth
                            options={New_Type_apprenant}
                            onChange={(event, value) => {
                                if (value && value.id) {
                                    setp_dialog_type_apprenant(value.id);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > <b> Mode Financement </b>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="session_caract_Dialog"
                            fullWidth
                            options={New_Financement}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_dialog_financement(value.value);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Client
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="session_caract_Dialog"
                            fullWidth
                            options={New_Get_List_Partner_Clients_result}
                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setp_dialog_client_rattachement_id(value._id);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>




                    <div className="div_row" > &nbsp;

                    </div>
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            {selectionModel.length <= 1 && <Button onClick={Add_Inscription_Apprenant} className="bton_enreg_dialog"> Inscrire</Button>}

                            {selectionModel.length > 1 && <Button onClick={Add_Inscription_List_Apprenant} className="bton_enreg_dialog"> Inscrire en masse</Button>}
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_add_inscription_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>


            {/*** Fin  Dialog pour reinscrire un apprenant à une session */}

            <Dialog
                open={Dialog_fiche_apprenant_open}
                onClose={Dialog_fiche_apprenant_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "20rem" }}>




                    <div className="session_caract_Dialog" > Choisir un modèle de fiche
                        <Autocomplete
                            disablePortal
                            name="event_dialog_version_convention"
                            id="event_dialog_version_convention"
                            fullWidth
                            //className="disabled_style enable_style"
                            options={New_Get_List_Apprenant_Modele_Courrier_result}

                            onChange={(event, value) => {
                                if (value && value._id) {

                                    if (String(value._id) === "default_pdf") {
                                        setfiche_apprenant_id("default_pdf");
                                    } else {
                                        setfiche_apprenant_id(value._id);
                                    }
                                }

                            }}


                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>

                </DialogContent>


                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            <Button className="bton_enreg_dialog" onClick={(e) => {
                                Download_Apprenant_Fiche_PDF();
                                Dialog_fiche_apprenant_handleClose_buton();
                            }}
                            >Télécharger </Button>
                        </div>



                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_fiche_apprenant_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>


            <h3> Vos apprenants </h3>
            <div>
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >
                        <TextField
                            name="filtre1"
                            label="Choisir un champ"
                            select
                            sx={{ m: 1, width: '100%' }}
                            value={p_filtre1}
                            onChange={(e) => {
                                setp_filtre1(e.target.value)
                            }}
                        >

                            <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Email &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom &nbsp;</MenuItem>
                            <MenuItem value="prenom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Prénom &nbsp;</MenuItem>

                        </TextField>
                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1();
                                setp_filtre1_value();
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            <TextField
                                name="filtre2"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}
                                options={filters}
                                value={p_filtre2}
                                onChange={(e) => {
                                    setp_filtre2(e.target.value)
                                }}
                            >

                                <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Email &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom &nbsp;</MenuItem>
                                <MenuItem value="prenom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Prénom &nbsp;</MenuItem>

                            </TextField>
                        </div>
                        <div className="div_row_droite texte_area_filter" >
                            {String(p_filtre2).length > 3 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>


                        {String(p_filtre2).length > 3 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2();
                                        setp_filtre2_value();
                                    }}
                                />
                            </a>
                        </div>}
                    </div>
                }


                {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Get_All_Apprenant}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>}

            </div>
            <br />
            <div style={{ "textAlign": "left", "float": "left", "width": "100%", "paddingRight": "10px" }}
                id="participant_menu_tab">

                <div className="session_data" ref={myRef_head} id="myRef_head">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',
                                    '& .cell--presentiel': {
                                        backgroundColor: '#a2cf6e',
                                        color: '#1a3e72',
                                        fontWeight: 'bold',
                                    },
                                    '& .cell--distantiel': {
                                        backgroundColor: '#ffac33',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },

                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },


                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },
                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection

                                    components={{
                                        Toolbar: CustomToolbar
                                    }}
                                    componentsProps={{ toolbar: { data_colums: columns } }}

                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);

                                        if (newSelectionModel.length > 1) {
                                            setadd_One_Apprenant('');
                                            setdisplay_detail_apprenant('');
                                            Clear_Detail_Apprenant_Fields();
                                            setselected_apprenant_id('');
                                            setgridline_id('');
                                        }
                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={Get_All_Apprenant_result.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            civilite: JSON.parse(item).civilite,
                                            nom: JSON.parse(item).nom,
                                            prenom: JSON.parse(item).prenom,
                                            email: JSON.parse(item).email,
                                            telephone: JSON.parse(item).telephone,
                                            date_naissance: JSON.parse(item).date_naissance,
                                            adresse: JSON.parse(item).adresse,
                                            civilcode_postalite: JSON.parse(item).code_postal,
                                            ville: JSON.parse(item).ville,
                                            pays: JSON.parse(item).pays,
                                            employeur: JSON.parse(item).employeur,
                                            client_nom: JSON.parse(item).client_rattachement_nom,

                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {
                                        setuserimgclassprofil("");
                                        setselected_row_data_json_age("");
                                        setgridline_id(newSelectionModel.row.id);
                                        setselected_apprenant_id(newSelectionModel.row._id);
                                        setselected_apprenant_row_id(newSelectionModel.row.id);
                                        setselected_apprenant_email(newSelectionModel.row.email);



                                        Get_Apprenant_Images(newSelectionModel.row._id);

                                        var local_date_naissance = newSelectionModel.row.date_naissance;

                                        if (local_date_naissance) {
                                            var date_eval = new Date(moment(local_date_naissance, "DD/MM/YYYY"));
                                            var diff = Date.now() - date_eval.getTime();
                                            var age = new Date(diff);
                                            var reel_age = Math.abs(age.getUTCFullYear() - 1970);
                                            setselected_row_data_json_age(reel_age)

                                        }

                                        Clear_Suivi_Pedago_Field();
                                        setdisplay_given_suivi_ped("");
                                        setsuivi_pedago_gridline_id('');
                                        setgiven_suivi_ped_changed("");
                                        setgiven_suivi_ped_data_edit_mode("");

                                        setDialog_apprenant_message(String(JSON.parse(Get_All_Apprenant_result[newSelectionModel.row.id]).title));
                                        setDialog_apprenant_open(true);

                                    }}



                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                  /*  components={{
                                        Toolbar: GridToolbar,
                                    }}*/
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {
                                        //field === 'distantiel'
                                        if (params.field === 'distantiel' && String(params.value) === "1") {
                                            return 'cell--distantiel';
                                        }
                                        if (params.field === "presentiel" && String(params.value) == "1") {
                                            return 'cell--presentiel';
                                        }


                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {

                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                    }}


                                />
                            </Box>
                            <br />

                        </div>

                        <div className="div_row">

                            {selectionModel && selectionModel.length >= 1 && <div className="block_en_mass">
                                <nav >Traitement en masse </nav>
                                &nbsp;
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    className="block_en_mass_select"
                                    fullWidth
                                    options={New_Option}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            if (String(value.value) === "supprimer"
                                                || String(value.value) === "dupliquer" || String(value.value) === "exporter"
                                                || String(value.value) === "inscrire") {
                                                setactionmass_ftion_val(value.value);
                                            }
                                            else {
                                                setactionmass_ftion_val();
                                            }

                                        }

                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />



                                {actionmass_ftion_val && <nav className='block_en_mass_bton_action'>
                                    <Popup
                                        trigger={<Button className="bton_enreg" style={{ "width": "90%" }}>

                                            <FcAcceptDatabase /> Traiter

                                        </Button>}
                                        modal
                                        nested
                                        position="center center"

                                    >

                                        {close => (
                                            <div>
                                                <button className="gest_close" onClick={close}>
                                                    &times;
                                                </button>
                                                <div className="gest_header"> MySy Information - {actionmass_ftion_val} </div>
                                                <div className="gest_content">
                                                    {' '}
                                                    {String(actionmass_ftion_val) === "supprimer" &&
                                                        <font color="red"> Confirmer la suppression de {selectionModel.length} apprenants .
                                                        </font>}

                                                    {String(actionmass_ftion_val) === "inscription" &&
                                                        <font> Confirmer l'inscription de {selectionModel.length} apprenants à la session que vous aller choisir à présent.
                                                        </font>}

                                                    {String(actionmass_ftion_val) === "exporter" &&
                                                        <font> Confirmer l'export excel de {selectionModel.length} apprenant(s).
                                                        </font>}

                                                    {String(actionmass_ftion_val) === "inscrire" &&
                                                        <font> Confirmer l'inscription en masse de {selectionModel.length} apprenant(s).
                                                        </font>}
                                                </div>
                                                <div className="gest_actions">
                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                        <button className="gest_bton_popup" onClick={(event) => {
                                                            actionmass_ftion_Traitemet();
                                                            close();
                                                        }}> Valider </button>

                                                    </div>
                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                        <button
                                                            className="gest_bton_popup"
                                                            onClick={() => {
                                                                //console.log('modal closed ');
                                                                close();
                                                            }}
                                                        >
                                                            Annuler
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                                </nav>
                                }

                            </div>}
                        </div>

                    </div>

                </div>
            </div>

            <div className="div_row" style={{ "border": "None" }}>

                <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>

                    <Button variant="outlined" onClick={submenu_import_apprenant} className="detail_class_submenu bton_import_excel"
                        id='menu_import_participant' name='menu_import_participant'>Importer des apprenants excel &nbsp;
                        <img src={excel_icone} alt="ajout csv" className="icon_excel" />
                    </Button>


                    <Button variant="outlined" onClick={submenu_add_one_apprenant}
                        className="detail_class_submenu bton_add_session"
                        id='menu_import_participant' name='menu_import_participant'>Ajouter 1 apprenant &nbsp;
                        <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                    </Button>

                    <br />
                    {apprenant_file_name && <nav><i>{apprenant_file_name}</i></nav>}
                    {String(liste_apprenants_file_change_api) === String("true") && <nav className="okUpdateData"> Les apprenants ont été correctement importés</nav>}
                    {String(liste_apprenants_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_apprenants_file_change_message} </nav>}

                    <input type="file"
                        accept=".csv"
                        ref={hiddenFileInput_apprenant}
                        style={{ display: 'none' }}
                        name="liste_apprenants_file"
                        onChange={liste_apprenants_file_change}
                    />

                    <a href='/sample/template_import_apprenant.csv' download>Télécharger un fichier modèle</a>
                </div>
            </div>

            <div className="div_row" ref={myRef_details} id="myRef_details"> &nbsp;</div>



            {((selected_apprenant_id && String(selected_apprenant_id).length > 2) || (String(add_One_Apprenant) === "1")) && <div className="div_row">
                <Button variant="outlined" ref={myRef} onClick={submenu_detail_apprenant} className="detail_class_submenu" id='detail_apprenant' name='detail_apprenant'>Détail Apprenant</Button>
                <Button variant="outlined" onClick={submenu_inscriptions} className="detail_class_submenu" id='inscriptions' name='inscriptions'>Inscriptions </Button>
                <Button variant="outlined" onClick={submenu_piece_jointe} className="detail_class_submenu" id='piece_jointe' name='piece_jointe'>Pièces Jointes </Button>

                <Button variant="outlined" onClick={submenu_absence} className="detail_class_submenu" id='absences' name='absences'>Absences </Button>
                <Button variant="outlined" onClick={submenu_suivi_pedagogique} className="detail_class_submenu" id='suivi_pedagogique' name='suivi_pedagogique'>Suivi Pédago. </Button>
                {/*<Button variant="outlined" onClick={submenu_historique} className="detail_class_submenu" id='historique' name='historique'>Historique </Button>*/}

            </div>}

            {String(add_One_Apprenant) === "1" && <div className="div_row session_data" style={{ "border": "None", "backgroundColor": "#F0F0F0" }}>

                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}>Ajout d'un nouvel apprenant </nav>

                <div className="session_caract"> <b> Civilité </b> <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="one_nom_part"
                        id="one_nom_part"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        select
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_one_civilite}
                        onChange={(e) => {
                            setp_one_civilite(e.target.value);
                        }
                        }

                    >
                        <MenuItem value="m" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>M. &nbsp;&nbsp;</MenuItem>
                        <MenuItem value="mme" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Mme. &nbsp;</MenuItem>
                        <MenuItem value="neutre" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Neutre &nbsp;</MenuItem>

                    </TextField>
                </div>


                <div className="session_caract"> <b> Nom </b> <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="one_nom_part"
                        id="one_nom_part"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_one_nom}
                        onChange={(e) => {
                            setp_one_nom(e.target.value);
                        }
                        }

                    />
                </div>


                <div className="session_caract"> <b> Prénom </b><br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="one_prenom_part"
                        id="one_prenom_part"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_one_prenom}
                        onChange={(e) => {
                            setp_one_prenom(e.target.value);
                        }
                        }
                    />
                </div>

                <div className="session_caract"> <b> Email </b><br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="one_email_part"
                        id="one_email_part"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_one_email}
                        onChange={(e) => {
                            setp_one_email(e.target.value);
                        }
                        }
                    />
                </div>

                <div className="session_caract"> <b> Date Naissance </b><br />
                    <DatePicker
                        name="one_naissance"
                        id="one_naissance"
                        selected={new Date(moment(p_one_naissance, "DD/MM/YYYY"))}
                        onChange={(date) => {
                            setp_one_naissance(format(date, 'd/MM/yyyy'));

                        }
                        }
                        showTimeSelect={false}
                        dateFormat="dd/MM/yyyy"
                        className="disabled_style enable_style"
                        locale='fr-FR'

                    />
                </div>

                <div className="session_caract"> Téléphone <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="one_phone_part"
                        id="one_phone_part"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_one_telephone}
                        onChange={(e) => {
                            setp_one_telephone(e.target.value);
                        }
                        }
                    />
                </div>


                <div className="session_caract"> Adresse <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="one_adresse_part"
                        id="one_adresse_part"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_one_adresse}
                        onChange={(e) => {
                            setp_one_adresse(e.target.value);
                        }
                        }
                    />
                </div>


                <div className="session_caract"> Code postal <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="one_code_postal_part"
                        id="one_code_postal_part"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_one_code_postal}
                        onChange={(e) => {
                            setp_one_code_postal(e.target.value);
                        }
                        }
                    />
                </div>


                <div className="session_caract"> Ville <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="one_ville_part"
                        id="one_ville_part"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_one_ville}
                        onChange={(e) => {
                            setp_one_ville(e.target.value);
                        }
                        }
                    />
                </div>

                <div className="session_caract"> Pays <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="one_pays_part"
                        id="one_pays_part"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_one_pays}
                        onChange={(e) => {
                            setp_one_pays(e.target.value);
                        }
                        }
                    />
                </div>



                {/* -- début   champs specifiques **/}

                <div className="div_row">
                    <hr />
                </div>
                <div className="div_row" style={{ "padding": "5px" }}> Vos champs spécifiques<br />

                    {rows_champs_specifics &&
                        rows_champs_specifics.map((champ_spec) => (

                            <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />
                                <br />
                                {String(JSON.parse(champ_spec).field_type) === "float" && <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name={JSON.parse(champ_spec).field_name}
                                    id={JSON.parse(champ_spec).field_name}
                                    type="number"
                                    inputProps={{ min: "1", max: "999999", step: "1" }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}


                                    className="disabled_style"
                                    onChange={(e) => {
                                        change_champs_spec_handle(e.target.value);

                                    }}
                                //onChange={change_champs_spec_handle}


                                />}

                                {String(JSON.parse(champ_spec).field_type) === "string" && <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name={JSON.parse(champ_spec).field_name}
                                    id={JSON.parse(champ_spec).field_name}

                                    InputLabelProps={{
                                        shrink: true,
                                    }}


                                    className="disabled_style"
                                    onChange={(e) => {
                                        change_champs_spec_handle(e.target.value);

                                    }}
                                //onChange={change_champs_spec_handle}


                                />}

                            </div>
                        ))}

                    <br />

                </div>

                <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                </div>
                {/* -- end  champs specifiques **/}


                <br />
                <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    {<div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                        <Button variant="contained" className="bton_enreg" onClick={Add_One_Apprenant_Data}>Enregistrer
                        </Button>

                    </div>}

                    {<div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                        <Button variant="contained" className="bton_annule" onClick={Annule_add_one_Apprenant}>Annuler
                        </Button>
                    </div>}
                </div>

                <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                </div>


            </div>}


            {String(display_detail_apprenant) === "1" && String(add_One_Apprenant) !== "1" && selected_apprenant_id && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>
                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Détail Apprenant </nav>
                <div className="div_row" style={{ "border": "None" }}>
                    <div className="div_row_gauche">

                        <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >
                            <div className="div_row_gauche_image">
                                <img class="img_class_logo" src={userimgclassprofil} /><br />

                            </div>
                            <div className="div_row_droite_image">
                                <input type="file" id="files_img_class" accept="image/*" onChange={imglogoclasschangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} /><br />
                                {<nav>

                                    <label for="files_img_class" className="bton_image_class">Charger une photo</label><br />

                                    {String(userimgclassprofilchanged) === "1" &&
                                        <nav className="bton_image_class" onClick={record_apprenant_Image}>Enregistrer la photo<br />  </nav>
                                    }

                                    {String(isimgclassSelected_recid) && String(isimgclassSelected_recid).length > 3 &&
                                        <nav className="bton_supprime_image_class" onClick={removeRecodedLogoImage}>Supprimer la photo<br />  </nav>
                                    }
                                    {String(userimgclassprofilchanged) === "1" &&
                                        <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer la photo
                                        </div>}


                                </nav>}

                                {/*isimgclassSelected && <Button variant="outlined" onClick={sendClassImage} className="bton_image_class">Enregistrer image</Button>*/}
                                {isimgclassdeleted && String(isimgclassdeleted) === "1" && <nav className="koUpdateData"> {isimgclassdeleted_message}</nav>}
                                {isimgclassdeleted && String(isimgclassdeleted) === "0" && <nav className="okUpdateData"> {isimgclassdeleted_message}</nav>}
                                {isimgclassSelected && String(isimgclassSaved) === "0" && <nav className="koUpdateData"> {isimgclassSaved_message}</nav>}
                            </div>

                        </div>
                        <div className="div_row_bis tips_img_class">
                            <li>
                                L'image doit etre carrée.
                            </li>
                            <li>
                                Les formats autorisés sont : <i>['jpg', 'jpeg', 'png', 'jpe', 'webp']</i>
                            </li>
                            <li>
                                Les dimensions recommandées: 128pixels X 128pixels --   144pixels X 144pixels --  168pixels X 168pixels<br />
                                Les dimensions maximales sont de 256pixels X 256pixels
                            </li>
                            <li>
                                La taille de l'image ne doit pas dépasser 1 mega octet
                            </li>
                        </div>

                    </div>

                    {/* <div className="div_row_droite">
                        <div style={{ "marginLeft": "1rem" }}>

                            <label className="bton_image_class" onClick={(e) => {

                                Get_List_Apprenant_Modele_Courrier();
                                setDialog_fiche_apprenant_open(true);
                            }} >Imprimer une fiche</label><br />
                        </div>

                    </div>*/}

                </div>



                {String(apprenant_data_edit_mode) !== "1" && <div className="session_caract"> <b> Civilité </b> <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="field_detail_civilite"
                        id="field_detail_civilite"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={New_civilite.filter((data) => (data).id === String(p_detail_civilite))[0].label}


                    />

                </div>}

                {String(apprenant_data_edit_mode) === "1" && <div className="session_caract"> <b> Civilité </b> <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="field_detail_civilite"
                        id="field_detail_civilite"
                        select
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_detail_civilite}
                        onChange={(e) => {
                            setp_detail_civilite(e.target.value);
                            setapprenant_data_changed("1");
                        }
                        }

                    >
                        <MenuItem value="m" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>M. &nbsp;&nbsp;</MenuItem>
                        <MenuItem value="mme" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Mme. &nbsp;</MenuItem>
                        <MenuItem value="neutre" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Neutre &nbsp;</MenuItem>

                    </TextField>
                </div>}

                <div className="session_caract"> <b> Nom </b> <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="field_detail_nom"
                        id="field_detail_nom"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_detail_nom}
                        onChange={(e) => {
                            setp_detail_nom(e.target.value);
                            setapprenant_data_changed("1");
                        }
                        }

                    />
                </div>


                <div className="session_caract"> <b> Prénom </b><br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="field_detail_prenom"
                        id="field_detail_prenom"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_detail_prenom}
                        onChange={(e) => {
                            setp_detail_prenom(e.target.value);
                            setapprenant_data_changed("1");
                        }
                        }
                    />
                </div>

                <div className="session_caract"> <b> Email </b><br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="field_detail_email"
                        id="field_detail_email"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_detail_email}
                        onChange={(e) => {
                            setp_detail_email(e.target.value);
                            setapprenant_data_changed("1");
                        }
                        }
                    />
                </div>
 
                <div className="session_caract"> <b> Date Naissance </b><br />
                    <DatePicker
                        name="field_detail_naissance"
                        id="field_detail_naissance"
                        selected={new Date(moment(p_detail_naissance, "DD/MM/YYYY"))}
                        onChange={(date) => {
                            setp_detail_naissance(format(date, 'd/MM/yyyy'));
                            setapprenant_data_changed("1");
                        }
                        }
                        showTimeSelect={false}
                        dateFormat="dd/MM/yyyy"
                        className="disabled_style enable_style"
                        locale='fr-FR'

                    />

                </div>


                <div className="session_caract"> <b> Téléphone </b> <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="field_detail_telephone"
                        id="field_detail_telephone"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_detail_telephone}
                        onChange={(e) => {
                            setp_detail_telephone(e.target.value);
                            setapprenant_data_changed("1");
                        }
                        }
                    />
                </div>


                <div className="session_caract"> Adresse <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="field_detail_adresse"
                        id="field_detail_adresse"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_detail_adresse}
                        onChange={(e) => {
                            setp_detail_adresse(e.target.value);
                            setapprenant_data_changed("1");
                        }
                        }
                    />
                </div>


                <div className="session_caract"> Code postal <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="field_detail_code_postal"
                        id="field_detail_code_postal"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_detail_code_postal}
                        onChange={(e) => {
                            setp_detail_code_postal(e.target.value);
                            setapprenant_data_changed("1");
                        }
                        }
                    />
                </div>


                <div className="session_caract"> Ville <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="field_detail_ville"
                        id="field_detail_ville"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_detail_ville}
                        onChange={(e) => {
                            setp_detail_ville(e.target.value);
                            setapprenant_data_changed("1");
                        }
                        }
                    />
                </div>

                <div className="session_caract"> Pays <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="field_detail_pays"
                        id="field_detail_pays"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_detail_pays}
                        onChange={(e) => {
                            setp_detail_pays(e.target.value);
                            setapprenant_data_changed("1");
                        }
                        }
                    />
                </div>

                <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                </div>

                {String(apprenant_data_changed) === "1" && String(apprenant_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                    /!\ Pensez à enregistrer les modifications
                </div>}
                <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    {String(apprenant_data_changed) === "1" && String(apprenant_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                        <Button variant="contained" className="bton_enreg" onClick={Update_Apprenant_Data}>Enregistrer les modifications
                        </Button>

                    </div>}

                    {String(apprenant_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                        <Button variant="contained" className="bton_annule" onClick={Annule_Detail_Apprenant_Fields}>Annuler
                        </Button>
                    </div>}
                </div>


                {String(apprenant_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                    <Button variant="contained" className="bton_edit" onClick={Enable_Detail_Apprenant_Fields}>Editer
                    </Button>

                </div>}
                <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                </div>


                <div>
                    <div className="div_row" style={{ "border": "None" }}>
                        <hr className="hr_break" />
                    </div>

                    <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                        Tuteurs

                        <div style={{ "width": '100%', "float": 'left' }}>
                            <div style={{ "width": '50%', "float": 'left', "borderRight": "thick double #32a1ce" }}>
                                <div className="tuteur_field" style={{ "textAlign": "center", "width": "100%", "float": "left" }}>
                                    <nav style={{ "textAlign": "center", "width": "80%", "float": "left", "fontSize": "large" }}>
                                        Tuteur 1
                                    </nav>
                                    {p_detail_tuteur1_include_com === true && <nav style={{ "textAlign": "center", "width": "15%", "float": "right", "fontSize": "large" }}>
                                        <Tooltip className="tooltip_css" id="tooltip_speacker" />
                                        <a data-tooltip-id="tooltip_speacker" data-tooltip-html="Contact utilisé dans les échanges de mails">
                                            <FcSpeaker style={{ "border": "1px solid", "cursor": "pointer" }} /><FcInfo />
                                        </a>
                                    </nav>}

                                </div>

                                <div className="tuteur_field"> <b> Civilité </b><br />

                                    {p_detail_tuteur1_civilite && <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="field_detail_tuteur1_civilite"
                                        id="field_detail_tuteur1_civilite"
                                        className="disabled_style mysy_gris"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}

                                        value={String(p_detail_tuteur1_civilite)[0].toUpperCase() + String(p_detail_tuteur1_civilite).slice(1)}

                                    />}

                                    {!p_detail_tuteur1_civilite && <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="field_detail_tuteur1_civilite"
                                        id="field_detail_tuteur1_civilite"
                                        className="disabled_style mysy_gris"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}

                                        value=""
                                    />}



                                </div>

                                <div className="tuteur_field">  <b>Nom </b> <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="field_detail_tuteur1_nom"
                                        id="field_detail_tuteur1_nom"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"

                                        value={p_detail_tuteur1_nom}
                                        onChange={(e) => {
                                            setp_detail_tuteur1_nom(e.target.value);

                                            setapprenant_data_changed("1");
                                        }}
                                    />


                                </div>

                                <div className="tuteur_field" > <b> Prénom </b> <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="field_detail_tuteur1_prenom"
                                        id="field_detail_tuteur1_prenom"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_tuteur1_prenom}
                                        onChange={(e) => {
                                            setp_detail_tuteur1_prenom(e.target.value);
                                            setapprenant_data_changed("1");
                                        }}
                                    />


                                </div>

                                <div className="tuteur_field"> <b> Email </b> <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="field_detail_tuteur1_email"
                                        id="field_detail_tuteur1_email"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_tuteur1_email}
                                        onChange={(e) => {
                                            setp_detail_tuteur1_email(e.target.value);
                                            setapprenant_data_changed("1");
                                        }}
                                    />


                                </div>

                                <div className="tuteur_field"> Téléphone <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="field_detail_tuteur1_telephone"
                                        id="field_detail_tuteur1_telephone"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_tuteur1_telephone}
                                        onChange={(e) => {
                                            setp_detail_tuteur1_telephone(e.target.value);
                                            setapprenant_data_changed("1");
                                        }}
                                    />


                                </div>
                                <div className="tuteur_field" style={{ "cursor": "pointer" }} onClick={(e) => {
                                    setquel_tuteur("1");
                                    setDialog_tuteur_1_open(true);
                                }}> Voir plus  <RiMoreFill /> <FcEditImage style={{ "fontSize": "large" }} />

                                </div>



                            </div>
                            <div style={{ "width": '50%', "float": 'left', }}>

                                <div className="tuteur_field" style={{ "textAlign": "center", "width": "100%", "float": "left" }}>
                                    <nav style={{ "textAlign": "center", "width": "80%", "float": "left", "fontSize": "large" }}>
                                        Tuteur 2
                                    </nav>
                                    {p_detail_tuteur2_include_com === true && <nav style={{ "textAlign": "center", "width": "15%", "float": "right", "fontSize": "large" }}>
                                        <Tooltip className="tooltip_css" id="tooltip_speacker2" />
                                        <a data-tooltip-id="tooltip_speacker2" data-tooltip-html="Contact utilisé dans les échanges de mails">
                                            <FcSpeaker style={{ "border": "1px solid", "cursor": "pointer" }} /><FcInfo />
                                        </a>
                                    </nav>}

                                </div>

                                <div className="tuteur_field"> <b> Civilité </b><br />


                                    {p_detail_tuteur2_civilite && <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="field_detail_tuteur2_civilite"
                                        id="field_detail_tuteur2_civilite"
                                        className="disabled_style mysy_gris"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}

                                        value={String(p_detail_tuteur2_civilite)[0].toUpperCase() + String(p_detail_tuteur2_civilite).slice(1)}

                                    />}

                                    {!p_detail_tuteur2_civilite && <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="field_detail_tuteur2_civilite"
                                        id="field_detail_tuteur2_civilite"
                                        className="disabled_style mysy_gris"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}

                                        value=""
                                    />}


                                </div>

                                <div className="tuteur_field">  <b> Nom </b> <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="field_detail_tuteur2_nom"
                                        id="field_detail_tuteur2_nom"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"

                                        value={p_detail_tuteur2_nom}
                                        onChange={(e) => {
                                            setp_detail_tuteur2_nom(e.target.value);
                                            setapprenant_data_changed("1");
                                        }}
                                    />


                                </div>

                                <div className="tuteur_field">  <b>Prénom </b> <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="field_detail_tuteur2_prenom"
                                        id="field_detail_tuteur2_prenom"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_tuteur2_prenom}
                                        onChange={(e) => {
                                            setp_detail_tuteur2_prenom(e.target.value);
                                            setapprenant_data_changed("1");
                                        }}
                                    />


                                </div>

                                <div className="tuteur_field"> <b> Email </b> <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="field_detail_tuteur2_email"
                                        id="field_detail_tuteur2_email"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_tuteur2_email}
                                        onChange={(e) => {
                                            setp_detail_tuteur2_email(e.target.value);
                                            setapprenant_data_changed("1");
                                        }}
                                    />


                                </div>

                                <div className="tuteur_field"> Téléphone  <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="field_detail_tuteur2_telephone"
                                        id="field_detail_tuteur2_telephone"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_tuteur2_telephone}
                                        onChange={(e) => {
                                            setp_detail_tuteur2_telephone(e.target.value);
                                            setapprenant_data_changed("1");
                                        }}
                                    />


                                </div>

                                <div className="tuteur_field" style={{ "cursor": "pointer" }} onClick={(e) => {
                                    setquel_tuteur("2");
                                    setDialog_tuteur_1_open(true);
                                }}> Voir plus  <RiMoreFill /> <FcEditImage style={{ "fontSize": "large" }} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                </div>

                {String(apprenant_data_changed) === "1" && String(apprenant_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                    /!\ Pensez à enregistrer les modifications
                </div>}
                <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    {String(apprenant_data_changed) === "1" && String(apprenant_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                        <Button variant="contained" className="bton_enreg" onClick={Update_Apprenant_Data}>Enregistrer les modifications
                        </Button>

                    </div>}

                    {String(apprenant_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                        <Button variant="contained" className="bton_annule" onClick={Annule_Detail_Apprenant_Fields}>Annuler
                        </Button>
                    </div>}
                </div>


                {String(apprenant_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                    <Button variant="contained" className="bton_edit" onClick={Enable_Detail_Apprenant_Fields}>Editer
                    </Button>

                </div>}
                <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                </div>


            </div>}

            {String(display_detail_inscriptions) === "1" && String(add_One_Apprenant) !== "1" && selected_apprenant_id && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>
                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Détail des inscriptions </nav>

                <div className="session_data" ref={myRef_head} id="myRef_head">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',
                                    '& .cell--presentiel': {
                                        backgroundColor: '#a2cf6e',
                                        color: '#1a3e72',
                                        fontWeight: 'bold',
                                    },
                                    '& .cell--distantiel': {
                                        backgroundColor: '#ffac33',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },




                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection

                                    onSelectionModelChange={(newSelectionModel) => {
                                        setselectionModel_apprenant_inscription(newSelectionModel);

                                    }}
                                    selectionModel={selectionModel_apprenant_inscription}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={Get_Appenant_List_Inscription_result.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            session_id: JSON.parse(item).session_id,
                                            session_code: JSON.parse(item).code_session,
                                            class_title: JSON.parse(item).class_title,
                                            status: JSON.parse(item).status,
                                            date_debut: JSON.parse(item).date_debut,
                                            date_fin: JSON.parse(item).date_fin,
                                            presentiel: JSON.parse(item).distantiel,
                                            distanciel: JSON.parse(item).presentiel,
                                            class_internal_url: JSON.parse(item).class_internal_url,
                                            client_rattachement_nom: JSON.parse(item).client_rattachement_nom,
                                            client_rattachement_id: JSON.parse(item).client_rattachement_id,

                                            class_recyclage_delai: JSON.parse(item).class_recyclage_delai,
                                            class_recyclage_periodicite: JSON.parse(item).class_recyclage_periodicite,
                                            nb_jour_avant_recyclage: JSON.parse(item).nb_jour_avant_recyclage,

                                        }
                                    ))}

                                    columns={columns_apprenant_inscriptions}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {
                                        /* setgridline_id(newSelectionModel.row.id);
                                         setselected_apprenant_id(newSelectionModel.row._id);
                                         setselected_apprenant_row_id(newSelectionModel.row.id);
                                         setselected_apprenant_email(newSelectionModel.row.email);
 
 
                                         setDialog_apprenant_message(String(JSON.parse(Get_All_Apprenant_result[newSelectionModel.row.id]).title));
                                         setDialog_apprenant_open(true);*/

                                    }}



                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {
                                        //field === 'distantiel'
                                        if (params.field === 'distantiel' && String(params.value) === "1") {
                                            return 'cell--distantiel';
                                        }
                                        if (params.field === "presentiel" && String(params.value) == "1") {
                                            return 'cell--presentiel';
                                        }

                                    }}

                                    getRowClassName={(params) => {
                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }
                                    }}

                                    getEstimatedRowHeight={() => 200}
                                    getRowHeight={() => "auto"}
                                    sx={{
                                        "& .MuiDataGrid-cellContent": {
                                            minHeight: 50,

                                        }
                                    }}
                                />
                            </Box>
                            <br />

                        </div>

                        {/*<div className="div_row">

                            {selectionModel && selectionModel.length >= 1 && <div className="block_en_mass">
                                <nav >Traitement en masse </nav>
                                &nbsp;

                                <TextField

                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="block_en_mass_select"

                                    value={actionmass_ftion_val}
                                    onChange={actionmass_ftion}
                                >
                                    <MenuItem value="n/a" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Action &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="supprimer" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Supprimer &nbsp;</MenuItem>
                                    <MenuItem value="dupliquer" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Dupliquer &nbsp;</MenuItem>
                                </TextField>


                                {actionmass_ftion_val && <nav className='block_en_mass_bton_action'>
                                    <Popup
                                        trigger={<Button className="bton_enreg" style={{ "width": "90%" }}>

                                            <FcAcceptDatabase /> Traiter

                                        </Button>}
                                        modal
                                        nested
                                        position="center center"

                                    >

                                        {close => (
                                            <div>
                                                <button className="gest_close" onClick={close}>
                                                    &times;
                                                </button>
                                                <div className="gest_header"> MySy Information </div>
                                                <div className="gest_content">
                                                    {' '}

                                                    <font color="red">  Confirmer l'action <b> {actionmass_ftion_val} </b> en masse pour {selectionModel.length} lignes.
                                                    </font>
                                                </div>
                                                <div className="gest_actions">
                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                        <button className="gest_bton_popup" onClick={(event) => {
                                                            actionmass_ftion_Traitemet();
                                                            close();
                                                        }}> Valider </button>

                                                    </div>
                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                        <button
                                                            className="gest_bton_popup"
                                                            onClick={() => {
                                                                //console.log('modal closed ');
                                                                close();
                                                            }}
                                                        >
                                                            Annuler
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                                </nav>
                                }

                            </div>}
                        </div>*/}

                    </div>

                    <div className="div_row" style={{ "border": "None" }}>

                        <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>



                            <Button variant="outlined" onClick={submenu_add_one_inscription}
                                className="detail_class_submenu bton_add_session"
                                id='menu_import_participant' name='menu_import_participant'>Ajouter 1 inscription &nbsp;
                                <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                            </Button>

                            <br />



                        </div>
                    </div>

                </div>

            </div>}

            {String(display_detail_absence) === "1" && String(add_One_Apprenant) !== "1" && selected_apprenant_id && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>
                <Module_Absence attendee_email={selected_apprenant_email}
                    inscription_id={selected_apprenant_id}
                    related_collection={"apprenant"}
                    read_access={props.read_access}
                    write_access={props.write_access} />
            </div>}

            {String(display_detail_historique) === "1" && String(add_One_Apprenant) !== "1" && selected_apprenant_id && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>
                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Historique </nav>

            </div>}

            {String(display_detail_piece_joint) === "1" && String(add_One_Apprenant) !== "1" && selected_apprenant_id && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>

                <div className="div_row" >
                    <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}> Pièces Jointes </nav>
                    <div className="div_row" style={{ "padding": "5px" }}>
                        <div style={{ "fontSize": "12px" }}>
                            <label htmlFor="upload-photo">
                                <input
                                    style={{ display: "none" }}
                                    id="upload-photo"
                                    name="upload-photo"
                                    type="file"
                                    accept=".pdf"
                                    onChange={apprenants_file_change_1}
                                />

                                <Fab
                                    color="secondary"
                                    size="small"
                                    component="span"
                                    aria-label="add"
                                    variant="extended"
                                >
                                    <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                </Fab>

                            </label>
                        </div>
                        {file_1_name && file_1_name.name &&
                            <nav style={{ "fontSize": "12px" }}>
                                <div className="session_caract">Nom du fichier <br />
                                    <TextField
                                        name="file_to_download_type_name"
                                        id="file_to_download_type_name"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_detail_one_file_to_download_type_name}
                                        onChange={(e) => {
                                            setp_detail_one_file_to_download_type_name(e.target.value);

                                        }}
                                    />

                                </div>
                                <br />
                                <br />
                                Fichier : {file_1_name.name} <br /> <font style={{ "cursor": "pointer" }}
                                    onClick={Delete_file_1_name}>  Supprimer <IoIosRemoveCircleOutline />  </font>
                            </nav>}

                        {Record_All_PJ_api && String(Record_All_PJ_api) === "true" && <div className="okUpdateData"> Pièce jointe enregistrée </div>}

                        {Record_All_PJ_api && String(Record_All_PJ_api) === "false" && <div className="koUpdateData"> {Record_All_PJ_message} </div>}


                    </div>



                    {file_1_name && file_1_name.name && <div className="div_row">

                        <div className="div_row_gauche">
                            <Button variant="contained" onClick={Record_All_PJ}
                                startIcon={<AiTwotoneSave />} className="bton_enreg">ENREGISTRER LA PIECE</Button>
                        </div>


                    </div>}

                    {Get_List_Of_All_PJ_result && <div className="div_row" style={{ "padding": "5px" }}>
                        <div className="div_row">
                            Liste des pièces jointes <br />
                            <div className="div_row">
                                {Get_List_Of_All_PJ_result && Get_List_Of_All_PJ_result.map((val) => (
                                    <div className="div_row_list_pj"  >
                                        <nav style={{ "color": "green", "cursor": "pointer" }} onClick={Download_one_attached_document} name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}> Télécharger &nbsp;<i> {(JSON.parse(val).file_business_object)}</i> </nav> <br />
                                        <Popup
                                            trigger={
                                                <nav style={{ "color": "red", "cursor": "pointer" }}  > Supprimer</nav>


                                            }
                                            modal
                                            nested
                                            position="center center"
                                        >
                                            {close => (
                                                <div>
                                                    <button className="gest_close" onClick={close}>
                                                        &times;
                                                    </button>
                                                    <div className="gest_header"> MySy Information </div>
                                                    <div className="gest_content">
                                                        {' '}

                                                        En confirmant cette opération, la pièce jointe sera <i><font color="red"> définitivement supprimée</font></i>. <br />


                                                    </div>
                                                    <div className="gest_actions">
                                                        <div style={{ "width": "45%", "float": "left" }}>
                                                            <button className="gest_bton_popup" onClick={(event) => {

                                                                Delete_one_attached_document(event);
                                                                close();
                                                            }}
                                                                name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}
                                                            > Valider </button>

                                                        </div>
                                                        <div style={{ "width": "45%", "float": "right" }}>
                                                            <button
                                                                className="gest_bton_popup"
                                                                onClick={() => {
                                                                    //console.log('modal closed ');
                                                                    close();
                                                                }}
                                                            >
                                                                Annuler
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>}
                </div>


            </div>}


            {String(display_menu_suivi_pedagogique) === "1" && String(add_One_Apprenant) !== "1" && selected_apprenant_id && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>


                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}>  Suivi pédagoqigue</nav>

                <div className="session_data" ref={myRef_head} id="myRef_head">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',



                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection

                                    onSelectionModelChange={(newSelectionModel) => {
                                        setselectionModel_suivi_pedagogique(newSelectionModel);

                                    }}
                                    selectionModel={selectionModel_suivi_pedagogique}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={Get_Appenant_List_Suivi_Pedagogique_result.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            apprenant_id: JSON.parse(item).apprenant_id,
                                            class_id: JSON.parse(item).class_id,
                                            session_id: JSON.parse(item).session_id,
                                            class_eu_id: JSON.parse(item).class_eu_id,
                                            responsable_id: JSON.parse(item).responsable_id,
                                            date_debut: JSON.parse(item).date_heure_debut,
                                            date_fin: JSON.parse(item).date_heure_fin,
                                            class_title: JSON.parse(item).class_title,
                                            session_code: JSON.parse(item).session_code,
                                            ue_code: JSON.parse(item).ue_code,
                                            ue_titre: JSON.parse(item).ue_titre,

                                        }
                                    ))}

                                    columns={columns_suivi_pedagogique}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {

                                        setsuivi_pedago_gridline_id(newSelectionModel.row.id);
                                        Get_Geven_Suivi_Ped_Data(newSelectionModel.row._id);
                                        setdisplay_given_suivi_ped("1");
                                        setadd_one_suivi_ped("");
                                        setgiven_suivi_ped_data_edit_mode("");
                                        setselected_suivi_pedagogique_id(newSelectionModel.row._id);

                                        //console.log(" setselected_suivi_pedagogique_id = ", newSelectionModel.row._id);
                                    }}



                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {
                                        //field === 'distantiel'
                                        if (params.field === 'distantiel' && String(params.value) === "1") {
                                            return 'cell--distantiel';
                                        }
                                        if (params.field === "presentiel" && String(params.value) == "1") {
                                            return 'cell--presentiel';
                                        }

                                    }}

                                    getRowClassName={(params) => {
                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(suivi_pedago_gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }
                                    }}

                                    getEstimatedRowHeight={() => 200}
                                    getRowHeight={() => "auto"}
                                    sx={{
                                        "& .MuiDataGrid-cellContent": {
                                            minHeight: 50,

                                        }
                                    }}
                                />
                            </Box>
                            <br />

                        </div>

                        <div className="div_row">


                        </div>

                    </div>

                    <div className="div_row" style={{ "border": "None" }}>

                        <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>



                            <Button variant="outlined" onClick={add_suivi_ped_funct}
                                className="detail_class_submenu bton_add_session"
                                id='menu_import_participant' name='menu_import_participant'>Ajout 1 Suivi &nbsp;
                                <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                            </Button>

                            <br />



                        </div>
                    </div>

                </div>

                <div className="div_row" ref={myRef_detail_suivi_ped} id="myRef_detail_suivi_ped">



                    {String(add_one_suivi_ped) === "1" && String(display_given_suivi_ped) !== "1" && <div>
                        <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Nouveau suivi pédagogique</nav>


                        <div className="session_caract"> <b>Sujet  </b> <br />
                            <TextField
                                name="suivi_pedago_detail_sujet"
                                id="suivi_pedago_detail_sujet"
                                text
                                InputLabelProps={{
                                    shrink: true,
                                }}

                                disabled={false}
                                className="disabled_style"
                                value={p_detail_suivi_ped_sujet}
                                onChange={(e) => {
                                    setp_detail_suivi_ped_sujet(e.target.value);

                                }
                                }

                            />

                        </div>

                        <div className="session_caract"> <b>Formation  </b> <br />
                            {New_Getall_Partner_Class_Reduice_Fields_result && New_Getall_Partner_Class_Reduice_Fields_result.length > 0 &&

                                <Autocomplete
                                    disablePortal
                                    name="detail_ftion"
                                    id="detail_ftion"
                                    className="disabled_style"
                                    //className="disabled_style enable_style"
                                    options={New_Getall_Partner_Class_Reduice_Fields_result}
                                    value={New_Getall_Partner_Class_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_suivi_ped_class_id))[0].label}
                                    //value={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_class_id))[0].label}
                                    onChange={(event, value) => {
                                        if (value && value._id) {
                                            Get_List_UE_From_Class_Id(value._id);
                                            setp_detail_suivi_ped_class_id(value._id);
                                            setp_detail_suivi_ped_class_internal_url(value.internal_url);

                                        } else {
                                            Get_List_UE_From_Class_Id("");
                                            setp_detail_suivi_ped_class_id("");
                                            setp_detail_suivi_ped_class_internal_url("");

                                        }

                                        // Quand on modifie la formation, la session et l'UE sont reinitialitées
                                        setp_detail_suivi_ped_session_id("");
                                        setp_detail_suivi_ped_class_eu_id("");
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />

                            }

                        </div>


                        <div className="session_caract"> <b> Classe  </b> <br />
                            {New_Getall_Partner_Session_Reduice_Fields_result && New_Getall_Partner_Session_Reduice_Fields_result.length > 0 &&

                                <Autocomplete
                                    disablePortal
                                    name="detail_classe"
                                    id="detail_classe"
                                    className="disabled_style"
                                    //className="disabled_style enable_style"
                                    options={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data).class_internal_url === String(p_detail_suivi_ped_class_internal_url))}
                                    value={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_suivi_ped_session_id))[0].label}
                                    onChange={(event, value) => {
                                        if (value && value._id) {
                                            setp_detail_suivi_ped_session_id(value._id);

                                        } else {
                                            setp_detail_suivi_ped_session_id("");

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />

                            }

                        </div>

                        <div className="session_caract"> <b>Unite D'enseignement  </b> <br />
                            {New_Get_List_UE_From_Class_Id_result &&

                                <Autocomplete
                                    disablePortal
                                    name="detail_ue"
                                    id="detail_ue"
                                    className="disabled_style"
                                    //className="disabled_style enable_style"
                                    options={New_Get_List_UE_From_Class_Id_result.filter((data) => (data).class_id === String(p_detail_suivi_ped_class_id))}
                                    value={New_Getall_Partner_List_UE_result.filter((data) => (data)._id === String(p_detail_suivi_ped_class_eu_id))[0].label}

                                    // value={New_Get_List_UE_From_Class_Id_result.filter((data) => (data)._id === String(p_detail_class_eu_id))[0].label}

                                    onChange={(event, value) => {
                                        if (value && value._id) {

                                            console.log("### value =  ", value);
                                            setp_detail_suivi_ped_class_eu_id(value._id);

                                        } else {
                                            setp_detail_suivi_ped_class_eu_id("");

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />

                            }

                        </div>

                        <div className="session_caract" > <b> Début </b><br />
                            <DatePicker
                                name="detail_debut"
                                id="detail_debut"
                                selected={new Date(moment(p_detail_suivi_ped_eval_date_heure_debut, "DD/MM/YYYY hh:mm"))}
                                onChange={(date) => {
                                    setp_detail_suivi_ped_eval_date_heure_debut(format(date, 'dd/MM/yyyy HH:mm'));

                                }
                                }


                                className="disabled_style enable_style"
                                locale='fr-FR'
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="dd/MM/yyyy HH:mm"
                                is24Hour

                            />

                        </div>



                        <div className="session_caract"> <b>Fin </b> <br />
                            <DatePicker
                                name="detail_fin"
                                id="detail_fin"
                                selected={new Date(moment(p_detail_suivi_ped_eval_date_heure_fin, "DD/MM/YYYY hh:mm"))}
                                onChange={(date) => {

                                    setp_detail_suivi_ped_eval_date_heure_fin(format(date, 'dd/MM/yyyy HH:mm'));

                                }
                                }


                                className="disabled_style enable_style"
                                locale='fr-FR'
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="dd/MM/yyyy HH:mm"
                                is24Hour

                            />

                        </div>

                        <div className="session_caract"> Responsable  <br />
                            {New_Getall_Training_Employee_No_Filter_result && New_Getall_Training_Employee_No_Filter_result.length > 0 &&

                                <Autocomplete
                                    disablePortal
                                    name="detail_ue"
                                    id="detail_ue"
                                    className="disabled_style"
                                    //className="disabled_style enable_style"
                                    options={New_Getall_Training_Employee_No_Filter_result}
                                    value={New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === String(p_detail_suivi_ped_responsable_id))[0].label}

                                    onChange={(event, value) => {
                                        if (value && value._id) {
                                            setp_detail_suivi_ped_responsable_id(value._id);

                                        } else {
                                            setp_detail_suivi_ped_responsable_id("");

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />

                            }

                        </div>

                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>

                        <div className="div_row" style={{ "border": "None" }}>

                            <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                    <Button variant="contained" className="bton_enreg" onClick={Add_Update_Suiv_Pedagogique}>Enregistrer
                                    </Button>

                                </div>

                                <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                    <Button variant="contained" className="bton_annule" onClick={Annule_Add_One_Suiv_Pedagogique}>Annuler
                                    </Button>
                                </div>
                            </div>



                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                        </div>

                    </div>}

                    {String(add_one_suivi_ped) !== "1" && String(display_given_suivi_ped) === "1" && <div>
                        <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Détail suivi pédagogique</nav>

                        {String(given_suivi_ped_data_edit_mode) !== "1" && <div className="div_row" style={{ "border": "None" }}>


                            <div className="session_caract"> <b>Sujet  </b> <br />
                                <TextField
                                    name="suivi_pedago_detail_sujet"
                                    id="suivi_pedago_detail_sujet"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_suivi_ped_sujet}

                                />

                            </div>

                            <div className="session_caract"> <b>Formation  </b> <br />
                                {New_Getall_Partner_Class_Reduice_Fields_result && New_Getall_Partner_Class_Reduice_Fields_result.length > 0 && <TextField
                                    name="suivi_pedago_detail_ftion"
                                    id="suivi_pedago_detail_ftion"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={New_Getall_Partner_Class_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_suivi_ped_class_id))[0].label}


                                />}

                            </div>


                            <div className="session_caract"> <b> Classe  </b> <br />
                                {New_Getall_Partner_Session_Reduice_Fields_result && New_Getall_Partner_Session_Reduice_Fields_result.length > 0 && <TextField
                                    name="suivi_pedago_detail_classe"
                                    id="suivi_pedago_detail_classe"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_suivi_ped_session_id))[0].label}


                                />}

                            </div>

                            <div className="session_caract"> <b>Unite D'enseignement  </b> <br />
                                {New_Getall_Partner_List_UE_result && New_Getall_Partner_List_UE_result.length > 0 && <TextField
                                    name="suivi_pedago_detail_ue"
                                    id="suivi_pedago_detail_ue"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={New_Getall_Partner_List_UE_result.filter((data) => (data)._id === String(p_detail_suivi_ped_class_eu_id))[0].label}

                                />}




                            </div>

                            <div className="session_caract" > <b> Début </b><br />
                                <TextField
                                    name="suivi_pedago_detail_debut"
                                    id="suivi_pedago_detail_debut"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_suivi_ped_eval_date_heure_debut}
                                />


                            </div>



                            <div className="session_caract"> <b>Fin </b> <br />
                                <TextField
                                    name="suivi_pedago_detail_fin"
                                    id="suivi_pedago_detail_fin"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_suivi_ped_eval_date_heure_fin}
                                />


                            </div>

                            <div className="session_caract"> Responsable  <br />
                                {New_Getall_Training_Employee_No_Filter_result && New_Getall_Training_Employee_No_Filter_result.length > 0 && <TextField
                                    name="suivi_pedago_responsable"
                                    id="suivi_pedago_responsable"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === String(p_detail_suivi_ped_responsable_id))[0].label}

                                />}


                            </div>

                            <div className="div_row" >
                                Observation <br />
                                <div style={{ marginLeft: '1%', 'marginRight': '1%' }}>

                                    <Editor
                                        onInit={(evt, editor) => editorRef_observation.current = editor}
                                        initialValue={field_contenu_observation}
                                        onKeyUp={field_contenu_observation_keyup}
                                        //disabled={false}

                                        init={{
                                            resize: false,
                                            height: 300,
                                            menubar: false,
                                            editable_root: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],

                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="div_row" >
                                Commentaire <br />
                                <div style={{ marginLeft: '1%', 'marginRight': '1%' }}>

                                    <Editor
                                        onInit={(evt, editor) => editorRef_comment.current = editor}
                                        initialValue={field_contenu_comment}
                                        onKeyUp={field_contenu_comment_keyup}
                                        //disabled={false}

                                        init={{
                                            resize: false,
                                            height: 300,
                                            menubar: false,
                                            editable_root: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],

                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                        }}
                                    />
                                </div>
                            </div>





                        </div>
                        }


                        {String(given_suivi_ped_data_edit_mode) === "1" && <div>


                            <div className="session_caract"> <b>Sujet  </b> <br />
                                <TextField
                                    name="suivi_pedago_detail_sujet"
                                    id="suivi_pedago_detail_sujet"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_suivi_ped_sujet}
                                    onChange={(e) => {
                                        setp_detail_suivi_ped_sujet(e.target.value);
                                        setgiven_suivi_ped_changed("1");
                                    }
                                    }

                                />

                            </div>

                            <div className="session_caract"> <b>Formation  </b> <br />
                                {New_Getall_Partner_Class_Reduice_Fields_result && New_Getall_Partner_Class_Reduice_Fields_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="suivi_pedago_detail_ftion"
                                        id="suivi_pedago_detail_ftion"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Partner_Class_Reduice_Fields_result}
                                        value={New_Getall_Partner_Class_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_suivi_ped_class_id))[0].label}
                                        //value={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_class_id))[0].label}
                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                Get_List_UE_From_Class_Id(value._id);
                                                setp_detail_suivi_ped_class_id(value._id);
                                                setp_detail_suivi_ped_class_internal_url(value.internal_url);

                                            } else {
                                                Get_List_UE_From_Class_Id("");
                                                setp_detail_suivi_ped_class_id("");
                                                setp_detail_suivi_ped_class_internal_url("");

                                            }
                                            setgiven_suivi_ped_changed("1");

                                            // Quand on modifie la formation, la session et l'UE sont reinitialitées
                                            setp_detail_suivi_ped_session_id("");
                                            setp_detail_suivi_ped_class_eu_id("");
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>


                            <div className="session_caract"> <b> Classe  </b> <br />
                                {New_Getall_Partner_Session_Reduice_Fields_result && New_Getall_Partner_Session_Reduice_Fields_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="suivi_pedago_detail_classe"
                                        id="suivi_pedago_detail_classe"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data).class_internal_url === String(p_detail_suivi_ped_class_internal_url))}
                                        value={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_suivi_ped_session_id))[0].label}
                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_suivi_ped_session_id(value._id);

                                            } else {
                                                setp_detail_suivi_ped_session_id("");

                                            }
                                            setgiven_suivi_ped_changed("1");
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>

                            <div className="session_caract"> <b>Unite D'enseignement  </b> <br />
                                {New_Get_List_UE_From_Class_Id_result &&

                                    <Autocomplete
                                        disablePortal
                                        name="suivi_pedago_detail_ue"
                                        id="suivi_pedago_detail_ue"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Get_List_UE_From_Class_Id_result.filter((data) => (data).class_id === String(p_detail_suivi_ped_class_id))}
                                        value={New_Getall_Partner_List_UE_result.filter((data) => (data)._id === String(p_detail_suivi_ped_class_eu_id))[0].label}

                                        // value={New_Get_List_UE_From_Class_Id_result.filter((data) => (data)._id === String(p_detail_class_eu_id))[0].label}

                                        onChange={(event, value) => {
                                            if (value && value._id) {

                                                //console.log("### value =  ", value);
                                                setp_detail_suivi_ped_class_eu_id(value._id);

                                            } else {
                                                setp_detail_suivi_ped_class_eu_id("");

                                            }
                                            setgiven_suivi_ped_changed("1");
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>

                            <div className="session_caract" > <b> Début </b><br />
                                <DatePicker
                                    name="suivi_pedago_detail_debut"
                                    id="suivi_pedago_detail_debut"
                                    selected={new Date(moment(p_detail_suivi_ped_eval_date_heure_debut, "DD/MM/YYYY hh:mm"))}
                                    onChange={(date) => {
                                        setp_detail_suivi_ped_eval_date_heure_debut(format(date, 'dd/MM/yyyy HH:mm'));
                                        setgiven_suivi_ped_changed("1");
                                    }
                                    }


                                    className="disabled_style enable_style"
                                    locale='fr-FR'
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    is24Hour

                                />

                            </div>


                            <div className="session_caract"> <b>Fin </b> <br />
                                <DatePicker
                                    name="suivi_pedago_detail_fin"
                                    id="suivi_pedago_detail_fin"
                                    selected={new Date(moment(p_detail_suivi_ped_eval_date_heure_fin, "DD/MM/YYYY hh:mm"))}
                                    onChange={(date) => {

                                        setp_detail_suivi_ped_eval_date_heure_fin(format(date, 'dd/MM/yyyy HH:mm'));
                                        setgiven_suivi_ped_changed("1");
                                    }
                                    }


                                    className="disabled_style enable_style"
                                    locale='fr-FR'
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    is24Hour

                                />

                            </div>

                            <div className="session_caract"> Responsable  <br />
                                {New_Getall_Training_Employee_No_Filter_result && New_Getall_Training_Employee_No_Filter_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="suivi_pedago_responsable"
                                        id="suivi_pedago_responsable"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Training_Employee_No_Filter_result}
                                        value={New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === String(p_detail_suivi_ped_responsable_id))[0].label}

                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_suivi_ped_responsable_id(value._id);

                                            } else {
                                                setp_detail_suivi_ped_responsable_id("");

                                            }
                                            setgiven_suivi_ped_changed("1");
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>


                            <div className="div_row" >
                                Observation <br />
                                <div style={{ marginLeft: '1%', 'marginRight': '1%' }}>

                                    <Editor
                                        onInit={(evt, editor) => editorRef_observation.current = editor}
                                        initialValue={field_contenu_observation}
                                        onKeyUp={field_contenu_observation_keyup}
                                        //disabled={false}

                                        init={{
                                            resize: false,
                                            height: 300,
                                            menubar: false,
                                            editable_root: true,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],

                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="div_row" >
                                Commentaire <br />
                                <div style={{ marginLeft: '1%', 'marginRight': '1%' }}>

                                    <Editor
                                        onInit={(evt, editor) => editorRef_comment.current = editor}
                                        initialValue={field_contenu_comment}
                                        onKeyUp={field_contenu_comment_keyup}
                                        //disabled={false}

                                        init={{
                                            resize: false,
                                            height: 300,
                                            menubar: false,
                                            editable_root: true,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],

                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                        }}
                                    />
                                </div>
                            </div>



                        </div>
                        }


                        <div className="div_row" style={{ "border": "None" }}>
                            {String(given_suivi_ped_changed) === "1" && String(given_suivi_ped_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                /!\ Pensez à enregistrer les modifications
                            </div>}


                            <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                {String(given_suivi_ped_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                    <Button variant="contained" className="bton_enreg" onClick={Add_Update_Suiv_Pedagogique}>Enregistrer les modifications
                                    </Button>

                                </div>}

                                {String(given_suivi_ped_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                    <Button variant="contained" className="bton_annule" onClick={cancel_edit_suivi_ped}>Annuler
                                    </Button>
                                </div>}
                            </div>


                            {String(given_suivi_ped_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                <Button variant="contained" className="bton_edit" onClick={Enable_Suivi_Pedago_Fields}>Editer
                                </Button>


                            </div>}
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                        </div>



                    </div>}





                </div>

            </div>}

        </div >
    );
}

export default Apprenant;