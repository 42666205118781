import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import { useParams } from 'react-router-dom'
import { Button, } from "reactstrap";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import ReactStars from "react-rating-stars-component";
import { confirmAlert } from 'react-confirm-alert';
//mport slideimg4 from "../mysy_img/mysy_img.png";
import 'react-slideshow-image/dist/styles.css'
import slideimg1 from "../mysy_img/Image_article2.jpg";
//import slideimg4 from "../mysy_img/Image_article1.png";
import Articles from "../components/Article";
import { Fade } from 'react-slideshow-image';
import { CommentSection } from 'react-comments-section'
import DiscussionBoard from 'react-discussion-board'
import 'react-discussion-board/dist/index.css'
import ArticleComment from "./ArticleComment"
import { useForm } from 'react-hook-form';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import parse from 'html-react-parser'
import { RichTextEditor } from '@mantine/rte';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import Fotter_v3 from "./Fotter_v3";
import Header from "./Header";
import ListeArticle from "./ListeArticles";
import moment from "moment";
import slidearticle1 from "../mysy_img/Image_article2.jpg";
import slidearticle2 from "../mysy_img/Image_article1.png";
import img_met_digital from "../mysy_img/met_digital.jpg";
import connect_white from "../mysy_img/connect_white_v2.png";
import connect_green from "../mysy_img/connect_green.png";
import { useHistory } from "react-router-dom";
import { getCookie, removeCookie } from 'react-use-cookie';

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
 
import { useLocation } from "react-router-dom";


const DisplayDetailArticle_new_v2 = (props) => {
  const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);
  const { articleId } = useParams();
  const [result, setResult] = useState("");
  const [commentresult, setcommentresult] = useState();
  const [myApicommentResponse, setmyApicommentResponse] = useState();

  const [myApiResponse, setmyApiResponse] = useState("false");
  const [DetailArticleAvis, setDetailArticleAvis] = useState([]);
  const [closepopup, setclosepopup] = useState(0);
  const [country_code, setcountry_code] = useState("");
  const [country_name, setcountry_name] = useState("");
  const [city, setcity] = useState("");
  const [postal, setpostal] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [IPv4, setIPv4] = useState("");
  const [state, setstate] = useState("");
  const [userIp, setuserIp] = useState("");
  const [action, setaction] = useState("");
  const [userconnected, setuserconnected] = useState("");
  const [mynote, setmynote] = useState(0);
  const stored_user = cookie.tokenmysych;
  const [qualityrating, setqualityrating] = useState("");
  const [avisrating, setavisrating] = useState("");
  const [noterating, setnoterating] = useState("");
  const [makecomment, setmakecomment] = useState("1");

  const random1 = Math.floor(Math.random() * 10);
  const random2 = Math.floor(Math.random() * 10);
  const [editorState, setEditorState] = useState(null);

  const [windowWidth, setwindowWidth] = useState(null);
  const [windowheight, setwindowheight] = useState(null);

  const [text_value, settext_value] = useState("");

  const [display_header_img, setdisplay_header_img] = useState("");

  const history = useHistory();


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();


  const onSubmit = async (data) => {
    const { name, email, subject, message } = data;

    var total = "??";
    if (document.getElementsByName("sommerandom")[0].value) {
      total = document.getElementsByName("sommerandom")[0].value;
    }


    var calcaul_total = parseInt(random1) + parseInt(random2);


    //alert(" le total saisi = " + total + "  la val cacule est " + calcaul_total);

    if (parseInt(total) != parseInt(calcaul_total)) {
      alert(" Le total dans la zone 'je ne suis pas un robot' est incohérent. Recommencez svp :) !!!");
      return;
    }

    confirmPostCommment();

  };


  function confirmPostCommment() {
    confirmAlert({
      title: 'Confirmer le commentaire?',
      message: '',
      buttons: [
        {
          label: 'Oui',
          onClick: () => SendMessage()
        },
        {
          label: 'Non',
          onClick: () => { return }

        }
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      keyCodeForClose: [8, 32],
      willUnmount: () => { },
      afterClose: () => { },
      onClickOutside: () => { },
      onKeypress: () => { },
      onKeypressEscape: () => { },
    });
  }


  function clearComment(e) {
    ////console.log("contacter " + sender_mail + " - " + sender_tel + " - ")
    document.getElementsByName("message")[0].value = "";
    document.getElementsByName("name")[0].value = "";
    document.getElementsByName("email")[0].value = "";
  }

  function SendMessage(e) {

    var sender_mail = "";
    var sender_tel = "";
    var token = "";



    ////console.log("contacter " + sender_mail + " - " + sender_tel + " - ")
    var mymessage = document.getElementsByName("message")[0].value;
    var mysender_name = document.getElementsByName("name")[0].value;
    var mysender_email = document.getElementsByName("email")[0].value;


    //alert("contacter "+mysender_email+" - "+mymessage+" - url = "
    //+process.env.REACT_APP_API_URL+" - user_ip ="+IPv4+" - "+latitude);

    var re = /\S+@\S+\.\S+/;
    if (re.test(mysender_email) == false) {
      alert("l'email est incorrecte");
      return;
    }




    var myurl = process.env.REACT_APP_API_URL + "myclass/api/add_user_message/";
    var form = new FormData();
    form.append("type", "comment_article");
    form.append("recever_mail", "contact@mysy-training.com");
    form.append("recever_tel", "+33769203945");
    form.append("sender_mail", mysender_email);
    form.append("sender_tel", sender_tel);
    form.append("object", "");
    form.append("message", mymessage);
    form.append("sender_name", mysender_name);
    form.append("token", "");
    form.append("user_ip", IPv4);
    form.append("user_country_code", country_code);
    form.append("user_country_name", country_name);
    form.append("user_city", city);
    form.append("user_postal", postal);
    form.append("user_latitude", latitude);
    form.append("user_longitude", longitude);
    form.append("user_state", state);
    form.append("article_avis_url", articleId);


    axios.post(myurl, form).then(res => {

      if (String(res.data.status) === String("false")) {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        setmyApiResponse("false");
      }

      else if (String(res.data.status) === String("true")) {
        //console.log(" In test  res.data.status (true) = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        document.getElementsByName("message")[0].value = "";
        document.getElementsByName("name")[0].value = "";
        document.getElementsByName("email")[0].value = "";
        setmakecomment("");
      }
      else {
        //console.log(" contact-nous  statut = " + res.data.status);
        //console.log(" contact-nous   res.data.message = " + res.data.message);
        setmyApiResponse("false");
      }
    }).catch((error) => {
      console.warn('contact-nous  Not good man :( mysearchtext = ');
      setmyApiResponse("false");

    })


  }



  const avisratingChanged = (newRating) => {
    setavisrating(String(newRating));
    var humeur = ""
    if (newRating < 2) {
      humeur = ":("
    } else if (newRating < 4) {
      humeur = ":)"
    } else if (newRating >= 4) {
      humeur = ":) :)"
    }
    var text = "Une note de " + String(newRating) + ", merci. Dites nous en plus " + humeur + " ?"

    document.getElementsByName("avis")[0].value = text;
  };

  const noteratingChanged = (newRating) => {
    var text = String(newRating)
    document.getElementsByName("note")[0].value = text;
    setnoterating(String(newRating));
  };


  const [pagecanoniktitle, setpagecanoniktitle] = useState("");
  const [pagecanonikurl, setpagecanonikurl] = useState(window.location.host);
  const [article_img, setarticle_img] = useState("")


  useEffect(() => {
   
    setpagecanonikurl(window.location.host);

    async function fetchData() {
      const result = await axios('https://geolocation-db.com/json/',);
      setuserIp(result.data.IPv4);
      setcountry_code(result.data.country_code);
      setcountry_name(result.data.country_name);
      setcity(result.data.city);
      setpostal(result.data.postal);
      setlatitude(result.data.latitude);
      setlongitude(result.data.longitude);
      setIPv4(result.data.IPv4);
      setstate(result.data.state);
      setuserIp(result.data.IPv4);

    }
    fetchData();
    Display();
    //  Display_article_comment();
    Get_3_Articles();

   


    //alert("stored_user =  "+stored_user);
    if (typeof (articleId) === "undefined") {
      setaction("creation");
    } else {
      setaction("affichage");
      desablefield();
    }

    if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
      setuserconnected("0");
    } else {
      setuserconnected("1");
    }
    setwindowWidth(window.innerWidth);
    setwindowheight(window.innerHeight);


    //console.log(" articleId = " + articleId);
  }, [makecomment]);


  function Display(e) {


    var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_articles_avis/";
    var form = new FormData();
    form.append("internal_url", articleId);
    form.append("token", "");
    // Ajout des info IP user
    form.append("user_ip", IPv4);
    form.append("user_country_code", country_code);
    form.append("user_country_name", country_name);
    form.append("user_city", city);
    form.append("user_postal", postal);
    form.append("user_latitude", latitude);
    form.append("user_longitude", longitude);
    form.append("user_state", state);


    //alert("myurl = "+myurl);

    axios.post(myurl, form).then(res => {
      // console.log(" In test  res.data.status = " + res.data.status);
      //console.log(" In test  res.data.message = " + res.data.message);
      if (String(res.data.status) === "true") {
        setmyApiResponse("True");

        setResult(res.data.message);
        setDetailArticleAvis(JSON.parse(res.data.message));

        var my_avis = JSON.parse(res.data.message)

        if (my_avis && my_avis.display_header_img && String(my_avis.display_header_img) === "0") {
          setdisplay_header_img("0")
        }

        if (my_avis && my_avis.title_formation) {
          setpagecanoniktitle(my_avis.title_formation)
        }

        if (my_avis && my_avis.internal_url) {
          setpagecanonikurl(my_avis.internal_url);

          console.log(process.env.REACT_APP_BASE_URL+"/"+my_avis.internal_url);
        }


        if (my_avis && my_avis.img_path && String(my_avis.img_path).length > 10) {
          setarticle_img(my_avis.img_path);
        } else {
          setarticle_img(img_met_digital);
        }

        //  console.log(" ### my_avis.display_header_img = ", my_avis.display_header_img);
      }
      else {
        //console.log(" In Erreur   res.data.status = " + res.data.status);
        //console.log(" In Erreur  res.data.message = " + res.data.message);
        setmyApiResponse("False");
      }
    }).catch((error) => {
      console.warn('Not good man :( mysearchtext = ');
      setmyApiResponse("False");
    })
  }

  const [Get_3_Articles_api, setGet_3_Articles_api] = useState();
  const [Get_3_Articles_message, setGet_3_Articles_message] = useState();
  const [Get_3_Articles_result, setGet_3_Articles_result] = useState([]);
  function Get_3_Articles(e) {
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_last_3_articles/";
    var form = new FormData();
    form.append("internal_url", articleId);
    form.append("token", "");
    // Ajout des info IP user
    form.append("user_ip", IPv4);
    form.append("user_country_code", country_code);
    form.append("user_country_name", country_name);
    form.append("user_city", city);
    form.append("user_postal", postal);
    form.append("user_latitude", latitude);
    form.append("user_longitude", longitude);
    form.append("user_state", state);


    // console.log("#### form = ", form);

    axios.post(myurl, form).then(res => {
      //console.log(" In Get_3_Articles  res.data.status = " + res.data.status);
      //console.log(" In Get_3_Articles  res.data.message = " + res.data.message);

      if (String(res.data.status) === "true") {

        setGet_3_Articles_result(res.data.message);

      }
      else {
        //console.log(" In Erreur   res.data.status = " + res.data.status);
        //console.log(" In Erreur  res.data.message = " + res.data.message);
        setGet_3_Articles_result([]);
        setGet_3_Articles_api("False");

      }
    }).catch((error) => {
      console.warn('Not good man :( mysearchtext = ');
      setGet_3_Articles_api("False");
    })
  }



  function Display_article_comment(e) {
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_user_message/";
    var form = new FormData();
    form.append("article_avis_url", articleId);
    form.append("type", "comment_article");


    //alert("myurl = "+myurl);

    axios.post(myurl, form).then(res => {
      if (res.data.status != "False") {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        if (res.data.message.length > 0) {
          setmyApicommentResponse("True");
        }
        else {
          setmyApicommentResponse("False");
        }
        setcommentresult(res.data.message);

      }
      else {
        //console.log(" In Erreur   res.data.status = " + res.data.status);
        //console.log(" In Erreur  res.data.message = " + res.data.message);
        setmyApicommentResponse("False");
      }
    }).catch((error) => {
      console.warn('Not good man :( mysearchtext = ');
      setmyApicommentResponse("False");
    })
  }
 


  function RecordArticle(e) {

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/add_articles_avis/";
    var form = new FormData();
    var title = document.getElementsByName("mytitle")[0].value;
    var url = document.getElementsByName("url")[0].value;
    //var quality = document.getElementsByName("quality")[0].value;
    var avis = text_value;
    var note = document.getElementsByName("note")[0].value;
    var postedby = ""
    var token = ""

    if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
      postedby = document.getElementsByName("postedby")[0].value;
    } else {
      token = stored_user;
    }

    if (postedby.length > 0) {
      var re = /\S+@\S+\.\S+/;
      if (re.test(postedby) == false) {
        alert("le champs 'Posté par' n'est pas une adresse email valide");
        return;
      }

    }

    form.append("token", token);
    form.append("title_formation", title);
    form.append("qualite", "none");
    form.append("avis", avis);
    form.append("note", note);
    form.append("postedby", postedby);
    form.append("qualityrating", qualityrating);
    form.append("avisrating", avisrating);


    form.append("url_formation", url);
    form.append("user_ip", IPv4);
    form.append("user_country_code", country_code);
    form.append("user_country_name", country_name);
    form.append("user_city", city);
    form.append("user_postal", postal);
    form.append("user_latitude", latitude);
    form.append("user_longitude", longitude);
    form.append("user_state", state);

    axios.post(myurl, form).then(res => {
      setResult(String(res.data.status));

      if (String(res.data.status) == String("true")) {
        //console.log(" contact-nous  statut = " + res.data.status);
        //console.log(" contact-nous   res.data.message = " + res.data.message);
        setmyApiResponse(String(res.data.message))
        clearForm();
      }
      else {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        setmyApiResponse(String(res.data.message))

      }

    }).catch((error) => {
      console.warn('contact-nous  Not good man :( mysearchtext = ');

    })
  }

  function clearForm(e) {

    document.getElementsByName("mytitle")[0].value = "";
    document.getElementsByName("url")[0].value = "";
    //document.getElementsByName("quality")[0].value = "";
    settext_value("");
    document.getElementsByName("note")[0].value = "";
    document.getElementsByName("postedby")[0].value = "";
  }


  function desablefield(e) {


    if (document.getElementsByName("mytitle")[0]) {
      document.getElementsByName("mytitle")[0].disabled = true;
      document.getElementsByName("mytitle")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("url")[0]) {
      document.getElementsByName("url")[0].disabled = true;
      document.getElementsByName("url")[0].style.backgroundColor = "#ECEFF1";
    }


    if (document.getElementsByName("avis")[0]) {
      document.getElementsByName("avis")[0].disabled = true;
      document.getElementsByName("avis")[0].style.backgroundColor = "#ECEFF1";
    }


    if (document.getElementsByName("note")[0]) {
      document.getElementsByName("note")[0].disabled = true;
      document.getElementsByName("note")[0].style.backgroundColor = "#ECEFF1";
    }

  }


  function makecommentary(e) {
    setmakecomment("1");
    alert(" commentaire ");
  }

  function CheckNote(e) {
    const val = e.target.value;

    if (isNaN(val)) {
      //alert(val + " is not a number <br/> mynote = "+mynote);
      document.getElementById("note").value = mynote;
    } else {
      if (val > 5) {
        alert(" Valeur Maxi doit etre 5");
        document.getElementById("note").value = mynote;
      } else {
        setmynote(val);
      }
    }

  }

  function confirmpostarticle(e) {



    var mytitle = document.getElementsByName("mytitle")[0].value;
    var url = document.getElementsByName("url")[0].value;
    //var quality = document.getElementsByName("quality")[0].value;
    var avis = text_value
    var note = document.getElementsByName("note")[0].value;
    var postedby = ""
    var token = ""

    //alert("title = "+mytitle+"  url = "+url+"  document.getElementsByName(title)[0].value; ="+document.getElementsByName('title')[0].value);

    if (mytitle.length < 5) {
      alert("le champs 'Titre' n'est pas correcte");
      return;
    }

    if (url.length < 5) {
      alert("le champs 'lien de la formation' n'est pas correcte");
      return;
    }

    if (avis.length <= 0) {
      alert("le champs 'avis' n'est pas correcte");
      return;
    }

    if (note.length <= 0) {
      alert("le champs 'note' n'est pas correcte");
      return;
    }

    if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
      postedby = document.getElementsByName("postedby")[0].value;

      if (postedby.length <= 0) {
        alert("le champs 'Posté par' n'est pas correcte");
        return;
      }


      if (postedby.length > 0) {
        var re = /\S+@\S+\.\S+/;
        if (re.test(postedby) === false) {
          alert("le champs 'Posté par' n'est pas une adresse email valide");
          return;
        }
      }
    }


    confirmAlert({
      title: 'Confirmer la note',
      message: " ",
      buttons: [
        {
          label: 'Oui',
          onClick: () => { RecordArticle() }
        },
        {
          label: 'Non',
          onClick: () => { return }

        },

      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      keyCodeForClose: [8, 32],
      willUnmount: () => { },
      afterClose: () => { },
      onClickOutside: () => { },
      onKeypress: () => { },
      onKeypressEscape: () => { },
    });

  }

  const fadeImages = [
    {
      url: slideimg1,
    },

    {
      url: slideimg1,
    },
  ];

  const data = [
    {
      userId: '02b',
      comId: '017',
      fullName: 'Lily',
      userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
      text: 'I think you have a point🤔',
      avatarUrl: 'https://ui-avatars.com/api/name=Lily&background=random',
      replies: []
    }
  ]

  const allPosts = [
    {
      profileImage:
        'https://www.rd.com/wp-content/uploads/2017/09/01-shutterstock_476340928-Irina-Bg.jpg',
      name: 'Jane Doe',
      content: '<p>Hello everyone!</p><p>How are you all doing?</p><p>-Jane</>',
      date: new Date('01 Jan 2020 01:12:00 GMT')
    },
    {
      profileImage:
        'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      name: 'John Doe',
      content:
        '<p>Raising say express had chiefly detract demands she. Quiet led own cause three him. Front no party young abode state up. Saved he do fruit woody of to. Met defective are allowance two perceived listening consulted contained. It chicken oh colonel pressed excited suppose to shortly. He improve started no we manners however effects. Prospect humoured mistress to by proposal marianne attended. Simplicity the far admiration preference everything. Up help home head spot an he room in Barton waited twenty always repair in within we do. An delighted offending curiosity my is dashwoods at. Boy prosperous increasing surrounded companions her nor advantages sufficient put. John on time down give meet help as of. Him waiting and correct believe now cottage she another. Vexed six shy yet along learn maids her tiled. Through studied shyness evening bed him winding present. Become excuse hardly on my thirty it wanted. </p>',
      date: new Date('01 Jan 2020 09:12:00 GMT')
    }
  ]

  const [posts, setPosts] = useState(allPosts)

  const submitPost = (text) => {
    const curDate = new Date()

    setPosts([
      ...posts,
      {
        profileImage:
          'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        name: 'Jane Doe',
        content: text,
        date: curDate
      }
    ])
  }

  const [PartnerMenu, setPartnerMenu] = React.useState(null);
  const [AccountMenu, setAccountMenu] = React.useState(null);

  const [someoneconnected, setsomeoneconnected] = useState("0");
  const [partnerconnected, setpartnerconnected] = useState("0");

  const stored_part = getCookie('tokenmysypart');

  function whosisconnected() {
    var local_user_connect = 0;
    var local_part_connect = 0;

    if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
      setuserconnected("0");
      local_user_connect = 0;
    } else {
      setuserconnected("1");
      setsomeoneconnected("1");
      local_user_connect = 1;
      //alert(" user connected OK");
    }

    if (typeof (stored_part) === "undefined" || String(stored_part) === '') {
      setpartnerconnected("0");
      local_part_connect = 0;
    } else {
      setpartnerconnected("1");
      setsomeoneconnected("1");
      local_part_connect = 1;
      // alert(" partner connected OK");
    }
  }

  function handleAccountLogout() {

    if (String(userconnected) === String("1")) {
      user_logout();
    }
    else if (String(partnerconnected) === String("1")) {
      part_logout();
    }
  }

  function user_logout_confirmation() {
    /* si c'est une connexion partner qui est active */
    if (typeof (stored_user) != "undefined") {
      setCookie("tokenmysych", "");
    }
    history.push("/recherche-formation");
  }

  function user_logout() {
    confirmAlert({
      title: '',
      message: 'Confirmez la deconnexion (user)',
      buttons: [
        {
          label: 'Oui',
          onClick: () => user_logout_confirmation()
        },
        {
          label: 'Non',
          onClick: () => { return }
        }
      ]
    });
  }


  const PartnerMenu_Close = () => {
    setPartnerMenu(null);
  };

  const AccountMenu_Close = () => {
    setAccountMenu(null);
  };

  const handlePartnerMenu = (event) => {
    setPartnerMenu(event.currentTarget);
  };

  const handleAccountMenu = (event) => {
    setAccountMenu(event.currentTarget);
  };

  const handleClose_Account = () => {
    alert(" my account");
    setAccountMenu(null);
  };

  const handleAccountConnexion = () => {
    setAccountMenu(null);
    history.push("/Connexion")
  };

  const handleMyAccount = () => {
    setAccountMenu(null);
    history.push("/mysy-user-account")
  };

  const handleAccountCreation = () => {
    setAccountMenu(null);
    history.push("/create_account")
  };

  const handlePartnerConnexion = () => {
    setAccountMenu(null);
    history.push("/PartnerLogin")
  };

  function user_logout_confirmation() {
    /* si c'est une connexion partner qui est active */
    if (typeof (stored_user) != "undefined") {
      setCookie("tokenmysych", "");
    }
    history.push("/recherche-formation");
  }

  function user_logout() {
    confirmAlert({
      title: '',
      message: 'Confirmez la deconnexion (user)',
      buttons: [
        {
          label: 'Oui',
          onClick: () => user_logout_confirmation()
        },
        {
          label: 'Non',
          onClick: () => { return }
        }
      ]
    });
  }


  const handleMyPartnerAccount = () => {
    setAccountMenu(null);
    history.push("/Partner")
  };

  const [partcookie, setpartCookie, removepartCookie] = useCookies(['tokenmysypart']);
  function handleAccountConnexion_v2() {

    if (String(userconnected) === String("1")) {
      handleMyAccount();
    }
    else if (String(partnerconnected) === String("1")) {
      handleMyPartnerAccount();
    }
  }


  function part_logout_confirmation() {
    /* si c'est une connexion partner qui est active */
    if (typeof (stored_part) != "undefined") {

      const stored_cookie = getCookie('tokenmysypart');
      var acces_right_token_name = stored_cookie + "_uar";

      setpartCookie("tokenmysypart", "", { path: '/' });

      setpartCookie(acces_right_token_name, "", { path: '/' });
      removeCookie(acces_right_token_name, { path: '/' });
    }
    history.push("/recherche-formation");
  }

  function part_logout() {
    confirmAlert({
      title: '',
      message: 'Confirmez la deconnexion (pro)',
      buttons: [
        {
          label: 'Oui',
          onClick: () => part_logout_confirmation()
        },
        {
          label: 'Non',
          onClick: () => { return }
        }
      ]
    });
  }
  const [ProduitMenu, setproduitMenu] = React.useState(null);
  const [AvisMenu, setavisMenu] = React.useState(null);


  const handleProduitMenu = (event) => {
    setproduitMenu(event.currentTarget);
  };

  const handleProduitService = () => {
    setproduitMenu(null);
    history.push("/tarifs_produits_services/")
  };

  const handleQuisommesNousMenu = () => {
    setproduitMenu(null);
    history.push("/qui-sommes-nous/")
  };

  const ProduitMenu_Close = () => {
    setproduitMenu(null);
  };

  function publiecatalogue() {
    history.push("/Partner", { mysy_arg: 'partner' });
  }

  const handleFtionMenu = (event) => {
    history.push("/")
  };


  return (
    <div style={{ width: "100%", float: "left" }}>
      <div className="DisplayDetailArticle">
      
        
          <Helmet>
            <title>{`${pagecanoniktitle}`}</title>
            <meta name="description" content={`${pagecanoniktitle}`} />
            <meta name="robots" content="index,follow" />
            <meta name="title" content={`${pagecanoniktitle}`+". Nous sommes les moins cher du marché. Nous automatisons vos process : inscription, évaluation, émargement (qr-code),  QUALIOPI, Personnalisation des documents, Gestion formateurs, Catalogue personnalisé, bilan pédagogique et financier (PBF).  Nos options comme la signature électronique sont inclus dans le tarif de base."} />
            <link rel="canonical" href={window.location.href} />


          </Helmet>

       
        <Header />

        <div className="div_mobile">

          <div className="div_row style_perso" >
            <div className="Background-header">

              <div className="Title">

                {DetailArticleAvis && DetailArticleAvis.title_formation &&
                  <div className="besoin_aide">
                    <h1 className="title_h1">  {DetailArticleAvis.title_formation} </h1>
                    {DetailArticleAvis && DetailArticleAvis.date_avis && <div className="detail_text_aide">
                      {(String(DetailArticleAvis.date_avis)).substring(0, 16)}
                    </div>}
                  </div>}

              </div>


            </div>



          </div>
          <div style={{ width: '100%' }}>
            <div className="block_centrer">

  
              <div className="aricles_a_la_une">

                {DetailArticleAvis && DetailArticleAvis.avis && <div className="desc_article">
                  {
                    <div>

                      {/*** important :  si DetailArticleAvis.avis === "0", alors on 
                     * n'affiche pas l'image d'entete.
                     * Cela permet de gerer les articles sans image
                     * 
                     */}

                      {String(display_header_img) !== "0" && <div style={{ "width": "100%", "height": "15rem", textAlign: "center" }}>
                        <img src={article_img} alt={pagecanoniktitle} className="img_photo" id="projets" />
                      </div>}

                      <div className="avis">


                        {<div style={{ "marginTop": "1rem" }}>

                          {parse(DetailArticleAvis.avis)}


                        </div>}
                      </div>

                    </div>
                  }
                </div>}


              </div>

            </div>
          </div>


          <div className="block_centrer">
            <div className="retour_list_articles" onClick={(e) => {

              e.preventDefault();
              window.location.href = "/Recherche-Article-formation"
            }}>
              Retour vers la liste des articles
            </div>
          </div>

          <div style={{ width: '100%' }}>

            <div className="liste_articles">
              <div className="titre_possible_interet" > Cela pourrait aussi vous intéresser</div>
              {Get_3_Articles_result && Get_3_Articles_result.map((formation) => (
                <div className="block_article" >
                  <Articles article={JSON.parse(formation)} key={JSON.parse(formation).title_formation} />
                </div>

              ))}



            </div>
          </div>

        </div>

        <div className="div_row" style={{ "border": "None" }}>
          &nbsp;
        </div>

      </div>
      <div style={{ height: "5rem", width: "100%" }}>
        <Fotter_v3 />
      </div>

    </div>

  )
}

export default DisplayDetailArticle_new_v2;