import React, { useRef, useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import Navigation from "../components/Navigation";
import { Helmet } from "react-helmet";
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import 'react-toastify/dist/ReactToastify.min.css';
import axios from "axios";
import { Button, } from "reactstrap";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import ousommesnousimg from "../mysy_img/mysy_training_technology_ici.png"
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import Header from "./Header";
import SendIcon from '@mui/icons-material/Send';
import { getCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";

const AddArticleAvis = () => {

    const [country_code, setcountry_code] = useState("");
    const [country_name, setcountry_name] = useState("");
    const [city, setcity] = useState("");
    const [postal, setpostal] = useState("");
    const [latitude, setlatitude] = useState("");
    const [longitude, setlongitude] = useState("");
    const [IPv4, setIPv4] = useState("");
    const [state, setstate] = useState("");
    const [userIp, setuserIp] = useState("");
    const [mynote, setmynote] = useState(0);
    const [usermessage, setusermessage] = useState("");
    const [object, setobject] = useState("");
    const [result, setResult] = useState("");
    const [myApiResponse, setmyApiResponse] = useState("");



    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const onSubmit = async (data) => {
        const { name, email, subject, message } = data;

        SendMail();
    };

    function SendMail(e) {

        var sender_mail = "";
        var sender_tel = "";
        var token = "";


        ////console.log("contacter " + sender_mail + " - " + sender_tel + " - ")

        var mysubject = document.getElementsByName("subject")[0].value;
        var mymessage = document.getElementsByName("message")[0].value;
        var mysender_name = document.getElementsByName("name")[0].value;
        var mysender_email = document.getElementsByName("email")[0].value;


        //alert("contacter "+mysender_email+" - "+mymessage+" - url = "
        //+process.env.REACT_APP_API_URL+" - user_ip ="+IPv4+" - "+latitude);

        var re = /\S+@\S+\.\S+/;
        if (re.test(mysender_email) == false) {
            alert("l'email est incorrecte");
            return;
        }


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/add_user_message/";
        var form = new FormData();
        form.append("type", "email");
        form.append("recever_mail", "contact@mysy-training.com");
        form.append("recever_tel", "+33769203945");
        form.append("sender_mail", mysender_email);
        form.append("sender_tel", sender_tel);
        form.append("object", mysubject);
        form.append("message", mymessage);
        form.append("sender_name", mysender_name);
        form.append("token", "");
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("false")) {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("false");
            }

            else if (String(res.data.status) === String("true")) {
                //console.log(" In test  res.data.status (true) = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                document.getElementsByName("subject")[0].value = "";
                document.getElementsByName("message")[0].value = "";
                document.getElementsByName("name")[0].value = "";
                document.getElementsByName("email")[0].value = "";
                setmyApiResponse("true");
            }
            else {
                //console.log(" contact-nous  statut = " + res.data.status);
                //console.log(" contact-nous   res.data.message = " + res.data.message);
                setmyApiResponse("false");
            }
        }).catch((error) => {
            console.warn('contact-nous  Not good man :( mysearchtext = ');
            setmyApiResponse("false");

        })


    }

    // recuperation et gestion  du token utilisateur 
    const history = useHistory();
    const stored_part = getCookie('tokenmysypart');
    if (!stored_part || stored_part.length <= 0) {
        history.push("/PartnerLogin");
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [])


    return (
        <div className='addarticleavis'>
            <Helmet>
                <title>MySy Training, ajout article</title>
                <meta name="description" content="Éditeur Français de logiciels pour organisme de formation. Avec solutions de E-Learning, gestion administrative et gestion relation client nous accompagnons les organismes de formation à réaliser l’audit QUALIOPI en toute sérénité. Nous sommes les moins cher du marché. Nous vous accompagnons automatiser vos process : inscription, évaluation, émargement (qr-code), Check-list QUALIOPI, Personnalisation et édition documents, Gestion formateurs, Catalogue formations personnalisé. Nous sommes les moins cher du marché. Nous automatisons vos process : inscription, évaluation, émargement (qr-code),  QUALIOPI, Personnalisation des documents, Gestion formateurs, Catalogue personnalisé, bilan pédagogique et financier (PBF).  Nos options comme la signature électronique sont inclus dans le tarif de base." />
                <meta name="robots" content="index,follow" />
                <meta name="title" content="MySy Training, ajout article" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <Header />

            <h1 style={{ fontSize: '0px' }}> MySy Training, ajout article</h1>
            <div className="div_mobile">
                <div> &nbsp;<br /></div>
                <div className="titre"> Contactez-Nous </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <div className='contactForm'>
                                <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                                    {/* Row 1 of form */}
                                    <div className='row formRow'>
                                        <div className='col-6'>
                                            <input
                                                type='text'
                                                name='name'
                                                {...register('name', {
                                                    required: { value: true, message: 'votre nom/prenom' },
                                                    maxLength: {
                                                        value: 30,
                                                        message: 'svp pas plus de 30 caractères'
                                                    }
                                                })}
                                                className='form-control formInput'
                                                placeholder='Nom'
                                            ></input>
                                            {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type='email'
                                                name='email'
                                                id='email'
                                                {...register('email', {
                                                    required: true,
                                                    pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                                })}
                                                className='form-control formInput'
                                                placeholder='Adresse email'
                                            ></input>
                                            {errors.email && (
                                                <span className='errorMessage'>Adresse email invalide</span>
                                            )}
                                        </div>
                                    </div>
                                    {/* Row 2 of form */}
                                    <div className='row formRow'>
                                        <div className='col'>
                                            <input
                                                type='text'
                                                name='subject'
                                                {...register('subject', {
                                                    required: { value: true, message: 'Saisir un sujet' },
                                                    maxLength: {
                                                        value: 75,
                                                        message: 'maximim  75 charactères'
                                                    }
                                                })}
                                                className='form-control formInput'
                                                placeholder='Sujet'
                                            ></input>
                                            {errors.subject && (
                                                <span className='errorMessage'>{errors.subject.message}</span>
                                            )}
                                        </div>
                                    </div>
                                    {/* Row 3 of form */}
                                    <div className='row formRow'>
                                        <div className='col'>
                                            <textarea
                                                rows={3}
                                                name='message'
                                                {...register('message', {
                                                    required: true
                                                })}
                                                className='form-control formInput'
                                                placeholder='Message'
                                            ></textarea>
                                            {errors.message && <span className='errorMessage'>Saisir un message</span>}
                                        </div>
                                    </div>
                                    <br />

                                    <div style={{ "textAlign": "left" }}>

                                        <label for="newsletter">En cochant vous acceptez que l'équipe MySy vous recontacte par email &nbsp;</label>
                                        <input type={"checkbox"} checked
                                            id="newsletter"
                                            name="newsletter" />
                                    </div>
                                    <br />
                                    <Button variant="contained" color="success" type='submit'
                                        className="div_row22 btn_login button"
                                    >Envoyez le message &nbsp; &nbsp;  {<SendIcon />}</Button>



                                </form>
                                {String(myApiResponse) === String("true") && <div className="okUpdateData">
                                    Votre message a bien été envoyé. Merci.
                                </div>}

                                {String(myApiResponse) === String("false") && <div className="koUpdateData">
                                    Impossible d'envoyer votre message. Merci de ressayer dans un instant.
                                </div>}
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="div_row" >
                        <div className="nom_prenom">Où nous trouver ? </div>

                        <div className="div_carte">
                            <a target="_blank"
                                href="https://www.google.com/maps/place/MySy+Training+Technology/@48.8761591,2.3260624,15z/data=!4m5!3m4!1s0x0:0x8239ba23406c9699!8m2!3d48.8761591!4d2.3260624">
                                <img src={ousommesnousimg} alt="logo MySy Technology ou sommes nous" style={{ 'width': "100%", 'height': '50%' }} />
                            </a>


                        </div>

                        <div className="div_adresse" >
                            <br />
                            <div>MySy Training Technology<br />
                            </div>
                            <div style={{ fontSize: "small" }}>

                                Adresse : 1 Cr du Havre, 75008 Paris <br />
                                Adresse email :
                                <a href="mailto: contact@mysy-training.com?subject=Mail from mysy website" target="_blank"> contact@mysy-training.com</a> <br />

                                Téléphone fixe : +331 77 00 38 57<br />
                                Téléphone : +337 69 20 39 45<br />

                            </div>
                            <br />
                            <div> Sur nos reseaux sociaux

                                <div style={{ 'float': "center", "textAlign": "center" }}>
                                    <div className="media_sociaux">
                                        <FacebookShareButton
                                            url={"https://www.facebook.com/MySy-Training-114572794561371/"}
                                        >
                                            <SocialIcon network="facebook" className="SocialIcon" />
                                        </FacebookShareButton>
                                        &nbsp;

                                        <TwitterShareButton
                                            url={"https://twitter.com/mysy_training"}
                                            hashtag={"#MySyTraining"}
                                        >
                                            <SocialIcon network="twitter" className="SocialIcon" />
                                        </TwitterShareButton>

                                        &nbsp;
                                        <LinkedinShareButton
                                            url={"https://www.linkedin.com/c"}
                                            summary={"ee"}
                                            source={"https://www.linkedin.com/in/mysy-training-96b53a237/"}

                                        >
                                            <SocialIcon network="linkedin" className="SocialIcon" />
                                        </LinkedinShareButton>


                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>
                </div>


                <div className="pieddepage">
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default AddArticleAvis;