import React, { useState, useEffect } from "react";
import Navigation from "../components/Navigation";
import bannerimg2 from "./../mysy_img/MYSY-LOGO-BLUE.png";
import { Helmet } from "react-helmet";
import Footer from "./../components/Fotter";
import Header from "./../components/Header";
import Fotter_v3 from "./../components/Fotter_v3";


const NotFound = () => {

  const [tempo, settempo] = useState(".");
  setInterval(check, 2000);


  window.setTimeout(function () {
    window.location.href = process.env.REACT_APP_BASE_URL
  }, 9000);


  function check() {
    var val = tempo + " .";
    //alert(" val = "+val);
    settempo(val);

  }
  return (
 
    <div style={{ width: "100%", float: "left" }}>

      <div className="notfound">
        <div>
          <Navigation />
        </div>
        <Helmet>
          <title>MySy Training, Page inconnue</title>
          <meta name="description" content="Nous sommes une base de données uniquement dediée aux formations et tutoriels" />
          <meta name="robots" content="noindex, nofollow" />
          <meta name="title" content="Page inconnue" />
          <link rel="canonical" href={window.location.href} />

        </Helmet>
        <Header />


        <div className="div_mobile">

          <div className="div_row block_entete" >

            <div className="Background-header">
              <div>
                <h1 className="text_welcome"> MySy Training Technology</h1>
              </div>

            </div>


          </div>

          <div className="block_centrer">
            <div className="display_screen_600_901">



              <div className="nom_prenom"> Page inconnue</div>
              <div className="nom_prenom">  Vous allez être redirigé vers le moteur de recherche dans quelques secondes. {tempo}</div>

              <div className="nom_prenom"> <a href={process.env.REACT_APP_BASE_URL}> Retour vers le moteur de recherche </a> </div>
            </div>
          </div>
        </div>

      </div>

      <div style={{ height: "5rem", width: "100%" }}>
        <Fotter_v3 />
      </div>

    </div>
  )
}

export default NotFound;