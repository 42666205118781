import React, { useRef, useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import axios from "axios";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import { Helmet } from "react-helmet";
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';
import slideimg1 from "../mysy_img/education.jpg";
import slideimg4 from "../mysy_img/mysy_img.png";
import Footer from "./Fotter";
import Header from "./Header";
import bannerimg from "../mysy_img/MYSY_banner_compte.png";
import img_met_program from "../mysy_img/met_programmation.jpg";
import img_met_hotel from "../mysy_img/met_hotel.jpg";
import img_met_graphisme from "../mysy_img/metier_graphisme.jpg";

import img_met_autre from "../mysy_img/met_autre.jpg";
import img_met_digital from "../mysy_img/met_digital.jpg";
import img_met_management from "../mysy_img/met_management.jpg";
import img_met_projet from "../mysy_img/met_projet.jpg";

import img_met_market from "../mysy_img/met_market.jpg";
import img_met_autre2 from "../mysy_img/met_autre2.jpg";
import img_met_dev_perso from "../mysy_img/metier_dev_perso.jpg";
import img_met_rh from "../mysy_img/met_rh.jpg";

import img_met_bureautic from "../mysy_img/met_burautic.jpg";
import img_met_vente from "../mysy_img/met_vente.jpg";
import img_met_sport from "../mysy_img/met_sport.png";
import img_met_sante_nat from "../mysy_img/met_sante_nat.jpeg";
/*
/!\ important: les images du slides doivent avoir une dimension de : 250 X 450
*/
import { Button, } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import { confirmAlert } from 'react-confirm-alert';
//import { Rating } from 'react-simple-star-rating';
import Rating from '@mui/material/Rating';
import parse from 'html-react-parser';
import Inscription from "./Inscription_Information";
import SendIcon from '@mui/icons-material/Send';
import mysy_logo2 from "../mysy_img2/Logo_MySy.png"
import { FaSearch, FaRegArrowAltCircleDown, FaRegArrowAltCircleUp } from 'react-icons/fa';
import { areOptionsEqual } from "@mui/base";
import { style } from "@mui/system";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import DescriptionIcon from '@mui/icons-material/Description';

const DisplayDetailClass_new_v3 = (props) => {
  const { classId, action } = useParams();
  const [result, setResult] = useState("");
  const [myApiResponse, setmyApiResponse] = useState();
  const [DetailTraining, setDetailTraining] = useState([]);
  const [closepopup, setclosepopup] = useState(0);
  const [country_code, setcountry_code] = useState("");
  const [country_name, setcountry_name] = useState("");
  const [city, setcity] = useState("");
  const [postal, setpostal] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [IPv4, setIPv4] = useState("");
  const [state, setstate] = useState("");
  const [userIp, setuserIp] = useState("");
  const [mynote, setmynote] = useState(0);
  const [userconnected, setuserconnected] = useState("0");
  const [someoneconnected, setsomeoneconnected] = useState("0");
  const [partnerconnected, setpartnerconnected] = useState("0");
  const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);
  const [cookie_part, setCookie_part, removeCookie_part] = useCookies(['tokenmysypart']);

  const [inscritpion, setinscritpion] = useState("");
  const [ratingvalue, setratingvalue] = useState(0);

  const [text_size, settext_size] = useState("0");


  // Gestion des Cookies
  const stored_partner = cookie_part.tokenmysypart;
  const stored_user = cookie.tokenmysych;


  const [childdata, setchilddata] = useState();


  // Cette fonction verifier si le token de l'evaluation est valide
  //si $action != 'information' et 'inscription', alors la valeur est forcement celle d'un token.
  const [tokenEval_api, settokenEval_api] = useState();
  const [tokenEval_message, settokenEval_message] = useState();
  const [tokenEval_result, settokenEval_result] = useState();
  function CheckEvaluationToken() {

    var form = new FormData();
    //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

    form.append("eval_token", action);
    form.append("class_internal_url", classId);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/MySyckeckEvaluationToken/";

    axios.post(myurl, form).then(res => {
      //console.log(" In CheckEvaluationToken  res.data.status = " + res.data.status);
      //console.log(" In CheckEvaluationToken  res.data.message r_class = " + res.data.message);


      if (String(res.data.status) !== "false") {
        settokenEval_api("true");
        settokenEval_result(res.data.message);
      }
      else {
        //console.log(" In tokenEval_trainingsession  res.data.status = " + res.data.status);
        settokenEval_api("false");
        settokenEval_message(res.data.message);
      }

    }).catch((error) => {
      console.warn('Not good man :( CheckEvaluationToken = ', error);
      settokenEval_api("false");
      //setmyApimyApiMessage("")
    })
  }


  useEffect(() => {

    async function funclocaltoken() {


      if (action && String(action).toLocaleLowerCase() !== "inscription" &&
        String(action).toLocaleLowerCase() !== "information") {

        CheckEvaluationToken();

      }
    }


    var local_user_connect = 0;
    var local_part_connect = 0;

    if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
      setuserconnected("0");
      local_user_connect = 0;
    } else {
      setuserconnected("1");
      local_user_connect = 1;
    }

    if (typeof (stored_partner) === "undefined" || String(stored_partner) === '') {
      setpartnerconnected("0");
      local_part_connect = 0;
    } else {
      setpartnerconnected("1");
      local_part_connect = 1;
    }

    async function fetchData() {
      const result = await axios('https://geolocation-db.com/json/',);
      setuserIp(result.data.IPv4);
      setcountry_code(result.data.country_code);
      setcountry_name(result.data.country_name);
      setcity(result.data.city);
      setpostal(result.data.postal);
      setlatitude(result.data.latitude);
      setlongitude(result.data.longitude);
      setIPv4(result.data.IPv4);
      setstate(result.data.state);
      setuserIp(result.data.IPv4);
      submenu_datelieu();
    }
    fetchData();


    GetCurrentClass_trainingsession();
    Display();
    GetAssociatedTraning();


    funclocaltoken();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });


  }, []);


  function contactUs(e) {

    var sender_mail = "";
    var sender_tel = "";
    var token = "";
    if (String(userconnected) === "0") {
      sender_mail = document.getElementsByName("usermail")[0].value;
      sender_tel = document.getElementsByName("usernumtel")[0].value;
      if (sender_mail.length == 0 && sender_tel.length == 0) {
        alert(" Les information fournies sont incorrectes ");
        return;
      }
    } else {
      token = String(stored_user)

    }


    var useravis = document.getElementsByName("useravis")[0].value;
    var usersupqual = document.getElementsByName("userqualsup")[0].value;
    var usernote = document.getElementsByName("usernote")[0].value;

    if (useravis.length < 20 || usersupqual.length < 20 || usernote.length < 0) {
      alert("Merci de saisir le nombre minimum de caractère requis pour les champs ");
      return;
    }


    var re = /\S+@\S+\.\S+/;
    if (re.test(sender_mail) == false) {
      alert("l'email est incorrecte");
      return;
    }


    ////console.log("contacter " + sender_mail + " - " + sender_tel + " - ")


    //alert("contacter "+sender_mail+" - "+sender_tel+" - "+usermessage+" - url = "
    //+process.env.REACT_APP_API_URL+" - user_ip ="+IPv4+" - "+latitude);
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/add_user_message/";
    var form = new FormData();
    form.append("type", "email");
    form.append("recever_mail", "");
    form.append("recever_tel", "");
    form.append("sender_mail", sender_mail);
    form.append("sender_tel", sender_tel);
    form.append("object", DetailTraining["external_code"]);
    form.append("token", "");
    form.append("user_ip", IPv4);
    form.append("user_country_code", country_code);
    form.append("user_country_name", country_name);
    form.append("user_city", city);
    form.append("user_postal", postal);
    form.append("user_latitude", latitude);
    form.append("user_longitude", longitude);
    form.append("user_state", state);

    axios.post(myurl, form).then(res => {
      if (res.data.status != "False") {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        setclosepopup(1);
        document.getElementsByName("usermail")[0].value = "";
        document.getElementsByName("usernumtel")[0].value = "";
        document.getElementsByName("usercomment")[0].value = "";
      }
      else {
        //console.log(" contact-nous  statut = " + res.data.status);
        //console.log(" contact-nous   res.data.message = " + res.data.message);
      }
    }).catch((error) => {
      console.warn('contact-nous  Not good man :( mysearchtext = ');

    })


  }


  /* Cette variable accompagne la gestion des images par defaut.
  idée : si la valeur de "DetailTraining.metier" != "management, digital, office, rh, vente, dev_perso"
  alors l'utilisateur à créer une formation avec un metier qui n'est pas geré, donc on met une image par defaut
  */
  const [ismetiermanaged, setismetiermanaged] = useState();
  const liste_metier = ["management", "digital", "office", "rh", "vente", "dev_perso"];


  const [nb_pave_a_afficher, setnb_pave_a_afficher] = useState();

  const [isdatadock, setisdatadock] = useState();
  const [isqualiopi, setisqualiopi] = useState();
  const [iscertitrace, setiscertitrace] = useState();
  const [isbureaucertitrace, setisbureaucertitrace] = useState();
  const [iscertifvoltaire, setiscertifvoltaire] = useState();
  const [partnair_name, setpartnair_name] = useState();
  const [website_partenaire, setwebsite_partenaire] = useState();

  const [class_sales_price, setclass_sales_price] = useState("0");

  const [myclassimage, setmyclassimage] = useState();
  const [isclassimage, setisclassimage] = useState("False");
  const [userimgclassprofil, setuserimgclassprofil] = useState("");

  const [class_img_stored_in_bdd, setclass_img_stored_in_bdd] = useState("");

  function getClassImage_no_token(class_id, class_external_code) {

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecodedClassImage_no_token/";

    var form = new FormData();

    form.append("type", "partner");
    form.append("related_collection", "myclass");
    form.append("related_collection_recid", class_id);
    form.append("class_external_code", class_external_code);


    axios.post(myurl, form).then(res => {

      if (res.data.status != "False") {

        //console.log(" getClassImage : In test  res.data.status = " + res.data.status);
        console.log(" getClassImage: res.data.message.img = " + res.data.message);

        if (res.data.message.img.length > 0) {
          setisclassimage("True");
        }
        else {
          setisclassimage("False");
        }
        setisclassimage(res.data.message.img);
        var ch_img = "data:image/png;base64," + res.data.message.img;
        setclass_img_stored_in_bdd(ch_img);
      }
      else {
        //console.log(" In Erreur   res.data.status = " + res.data.status);
        //console.log(" In Erreur  res.data.message = " + res.data.message);
        setisclassimage("False");
        setuserimgclassprofil();
        setclass_img_stored_in_bdd();
      }
    }).catch((error) => {

      console.warn('getClassImage ee: Not good man :( getClassImage = ');
      setisclassimage("False");
      setuserimgclassprofil("");
      setclass_img_stored_in_bdd("");
    })

  }



  function Display(e) {
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_class/";
    var form = new FormData();
    form.append("internal_url", classId);

    var my_local_user_connect;
    var my_local_part_connect;
    if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
      my_local_user_connect = 0;
    } else {
      my_local_user_connect = 1;
    }

    if (typeof (stored_partner) === "undefined" || String(stored_partner) === '') {
      my_local_part_connect = 0;
    } else {
      my_local_part_connect = 1;
    }



    if (String(my_local_user_connect) === "1") {
      form.append("token", stored_user);
      form.append("connection_type", "user");
    }
    else if (String(my_local_part_connect) === "1") {
      form.append("token", stored_partner);
      form.append("connection_type", "partner");
    }
    else {
      form.append("token", "");
      form.append("connection_type", "");
    }

    // Ajout des info IP user
    form.append("user_ip", IPv4);
    form.append("user_country_code", country_code);
    form.append("user_country_name", country_name);
    form.append("user_city", city);
    form.append("user_postal", postal);
    form.append("user_latitude", latitude);
    form.append("user_longitude", longitude);
    form.append("user_state", state);

    var cpt_pave = 0;
    //alert("myurl = "+myurl);

    axios.post(myurl, form).then(res => {
      // console.log(" In Display  res.data.status = " + res.data.status);
      //  console.log(" In Display  res.data.message = " + res.data.message);
      if (String(res.data.status) === String("true")) {

        if (res.data.message.length > 0) {
          setmyApiResponse("True");

          if (String(JSON.parse(res.data.message).coeur) !== String("1") &&
            String(JSON.parse(res.data.message).freeacces) !== String("1")) {

            //alert(" ce n'est pas un coeur = " + JSON.parse(res.data.message).coeur)
            // Verification si on est en  e connecté ou non.
            var local_user_connect = 0;
            var local_part_connect = 0;

            if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
              setuserconnected("0");
              local_user_connect = 0;
            } else {
              setuserconnected("1");
              local_user_connect = 1;
            }

            if (typeof (stored_partner) === "undefined" || String(stored_partner) === '') {
              setpartnerconnected("0");
              local_part_connect = 0;
            } else {
              setpartnerconnected("1");
              local_part_connect = 1;
            }

            if (local_user_connect === 0 && local_part_connect === 0) {
              confirmAlert({
                title: 'Connexion ?',
                message: 'Connectez-vous pour acceder&nbsp; au&nbsp;detail de cette formation',
                buttons: [
                  {
                    label: 'Vous avez un compte',
                    onClick: () => { history.push("/mysy-training-login/training/" + classId) }
                  },
                  {
                    label: "Vous n'avez pas de compte",
                    onClick: () => { history.push("/create_account") }
                  },

                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
                keyCodeForClose: [8, 32],
                willUnmount: () => { },
                afterClose: () => { },
                onClickOutside: () => { },
                onKeypress: () => { },
                onKeypressEscape: () => { },
              });


            }
          } else {
            //alert(" c'est pas un coeur = " + JSON.parse(res.data.message).coeur)
          }
        }
        else {
          setmyApiResponse("False");
        }
        //console.log(" In displaydetailclass   res.data.status = " + res.data.status);
        //console.log(" In displaydetailclass  res.data.message = " + res.data.message);
        //console.log(" In displaydetailclass taille  res.data.message = " + res.data.message.length);
        setResult(res.data.message);
        setDetailTraining(JSON.parse(res.data.message));


        getClassImage_no_token(JSON.parse(res.data.message)._id, JSON.parse(res.data.message).external_code);


        if (JSON.parse(res.data.message).note) {
          setratingvalue(JSON.parse(res.data.message).note);
        }

        if (JSON.parse(res.data.message).isdatadock) {
          setisdatadock(JSON.parse(res.data.message).isdatadock);
        }

        if (JSON.parse(res.data.message).business_prices && JSON.parse(res.data.message).business_prices.length == 1) {
          setclass_sales_price(JSON.parse(res.data.message).business_prices[0].discounted_price);

        } else {
          setclass_sales_price(JSON.parse(res.data.message).price);
          setclass_sales_price(JSON.parse(res.data.message).price);
        }


        if (JSON.parse(res.data.message).isqualiopi) {
          setisqualiopi(JSON.parse(res.data.message).isqualiopi);
        }


        if (JSON.parse(res.data.message).iscertitrace) {
          setiscertitrace(JSON.parse(res.data.message).iscertitrace);
        }

        if (JSON.parse(res.data.message).isbureaucertitrace) {
          setisbureaucertitrace(JSON.parse(res.data.message).isbureaucertitrace);
        }

        if (JSON.parse(res.data.message).iscertifvoltaire) {
          setiscertifvoltaire(JSON.parse(res.data.message).iscertifvoltaire);
        }

        if (JSON.parse(res.data.message).nom_partenaire) {
          setpartnair_name(JSON.parse(res.data.message).nom_partenaire);
        }

        if (JSON.parse(res.data.message).nb_pave_a_afficher) {
          setnb_pave_a_afficher(JSON.parse(res.data.message).nb_pave_a_afficher);
          //console.log(" ### NB setnb_pave_a_afficher = ", JSON.parse(res.data.message).nb_pave_a_afficher);
        }


        if (JSON.parse(res.data.message).website_partenaire) {
          setwebsite_partenaire(JSON.parse(res.data.message).website_partenaire);
        }

        // Verifier si le metier est geré
        if (JSON.parse(res.data.message).metier) {
          if (!liste_metier.includes(JSON.parse(res.data.message).metier)) {
            setismetiermanaged("0"); // j'ai une valeur dans metier, mais qui n'est pas geré
          }
        }
        else {
          setismetiermanaged("0");
        }

        if (JSON.parse(res.data.message).img_url && String(JSON.parse(res.data.message).img_url).length > 0) {
          setismetiermanaged("1");
        }

        if (JSON.parse(res.data.message).text_size) {
          settext_size(JSON.parse(res.data.message).text_size);

          //"block_crossell_meme_orga"
          var local_tmp;
          var val;
          var footer_top;
          var main_h;
          var main_h_px;


          //console.log("nb_partner_certificat =  ", JSON.parse(res.data.message).nb_partner_certificat);


          if (window.screen.width < 601) {

            var decalage_pr_certificat = parseInt(String(JSON.parse(res.data.message).nb_partner_certificat)) * 150;
            //console.log("decalage_pr_certificat =  ", decalage_pr_certificat);

            var local_val = parseInt(String(JSON.parse(res.data.message).nb_pave_a_afficher));

            //console.log("### local_val = ", local_val)
            if (local_val >= 5) {
              //console.log(" ON PASSE&nbsp; au&nbsp;MAX")
              var local_tmp_val = 2300 + decalage_pr_certificat;
              var local_main_h_px = 4000 + decalage_pr_certificat;
              //console.log("### local_tmp_val = ", local_tmp_val, "  local_main_h_px = ",local_main_h_px);
              local_tmp = local_tmp_val + "px";
              main_h_px = local_main_h_px + "px";

              if (String(action).toLowerCase() === "information" || String(action).toLowerCase() === "inscription") {

                var local_tmp_val = 2800 + decalage_pr_certificat;
                var local_main_h_px = 4500 + decalage_pr_certificat;
                local_tmp = local_tmp_val + "px";
                main_h_px = local_main_h_px + "px";
              }

            } else {
              //console.log(" ON PASSE&nbsp; au&nbsp;MIN");
              var local_tmp_val = 2100 + decalage_pr_certificat;
              var local_main_h_px = 4800 + decalage_pr_certificat;

              local_tmp = local_tmp_val + "px";
              main_h_px = local_main_h_px + "px";

              if (String(action).toLowerCase() === "information" || String(action).toLowerCase() === "inscription") {
                var local_tmp_val = 2600 + decalage_pr_certificat;
                var local_main_h_px = 5300 + decalage_pr_certificat;
                local_tmp = local_tmp_val + "px";
                main_h_px = local_main_h_px + "px";
              }
            }
            // document.getElementById('mob_block_cross_sell_cat').style.top = local_tmp;
            //document.getElementById('mobile').style.height = main_h_px;

          }

          if (window.screen.width >= 601 && window.screen.width <= 991) {

            var local_val = parseInt(String(JSON.parse(res.data.message).nb_pave_a_afficher));
            if (local_val >= 5) {
              //console.log(" ON PASSE&nbsp; au&nbsp;MAX")
              local_tmp = "2000px";
              main_h_px = "2700px";

              if (String(action).toLowerCase() === "information" || String(action).toLowerCase() === "inscription") {
                local_tmp = "2500px";
                main_h_px = "3200px";
              }

            } else {
              console.log(" ON PASSE&nbsp; au&nbsp;MIN");
              local_tmp = "1700px";
              main_h_px = "2500px";

              if (String(action).toLowerCase() === "information" || String(action).toLowerCase() === "inscription") {
                local_tmp = "2200px";
                main_h_px = "3000px";
              }

            }
            document.getElementById('block_crossell_meme_orga').style.top = local_tmp;
            document.getElementById('desktop').style.height = main_h_px;


          }

          if (window.screen.width >= 992 && window.screen.width <= 1199) {

            var local_val = parseInt(String(JSON.parse(res.data.message).nb_pave_a_afficher));
            if (local_val >= 5) {
              console.log(" ON PASSE&nbsp; au&nbsp;MAX")
              local_tmp = "2000px";
              main_h_px = "2800px";

              if (String(action).toLowerCase() === "information" || String(action).toLowerCase() === "inscription") {
                local_tmp = "2500px";
                main_h_px = "3200px";
              }

            } else {
              console.log(" ON PASSE&nbsp; au&nbsp;MIN");
              local_tmp = "1700px";
              main_h_px = "2500px";

              if (String(action).toLowerCase() === "information" || String(action).toLowerCase() === "inscription") {
                local_tmp = "2200px";
                main_h_px = "3000px";
              }

            }
            document.getElementById('block_crossell_meme_orga').style.top = local_tmp;
            document.getElementById('desktop').style.height = main_h_px;
          }

          if (window.screen.width >= 1200) {
            var local_val = parseInt(String(JSON.parse(res.data.message).nb_pave_a_afficher));
            if (local_val >= 5) {
              console.log(" ON PASSE&nbsp; au&nbsp;MAX")
              local_tmp = "2000px";
              main_h_px = "2800px";

              if (String(action).toLowerCase() === "information" || String(action).toLowerCase() === "inscription") {
                local_tmp = "2500px";
                main_h_px = "3200px";
              }

            } else {
              //console.log(" ON PASSE&nbsp; au&nbsp;MIN");
              local_tmp = "1700px";
              main_h_px = "2500px";

              if (String(action).toLowerCase() === "information" || String(action).toLowerCase() === "inscription") {
                local_tmp = "2300px";
                main_h_px = "3000px";
              }
            }

            document.getElementById('block_crossell_meme_orga').style.top = local_tmp;
            document.getElementById('desktop').style.height = main_h_px;
          }


        }
      }
      else {
        //console.log(" In Erreur   res.data.status = " + res.data.status);
        //console.log(" In Erreur  res.data.message = " + res.data.message);
        setmyApiResponse("false");
        history.push("/formation-inconnue/");

      }


    }).catch((error) => {
      console.warn('Not good man :( Display = ', error);
      //console.log(error);
      setmyApiResponse("false");

    })


  }

  const [associatedtraining_api, setassociatedtraining_api] = useState("");
  const [associatedtraining_message, setassociatedtraining_message] = useState("");
  function GetAssociatedTraning(e) {
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_associated_class_of_partnair/";
    var form = new FormData();
    form.append("internal_url", classId);
    // Ajout des info IP user
    form.append("user_ip", IPv4);
    form.append("user_country_code", country_code);
    form.append("user_country_name", country_name);
    form.append("user_city", city);
    form.append("user_postal", postal);
    form.append("user_latitude", latitude);
    form.append("user_longitude", longitude);
    form.append("user_state", state);


    //alert("myurl = "+myurl);

    axios.post(myurl, form).then(res => {
      if (String(res.data.status) !== String("false")) {
        //console.log(" In GetAssociatedTraning  res.data.status = " + res.data.status);
        //console.log(" In GetAssociatedTraning  res.data.message = " + res.data.message);
        setassociatedtraining_api("True");
        setassociatedtraining_message(res.data.message);

      }
      else {
        //console.log(" In Erreur   res.data.status = " + res.data.status);
        //console.log(" In Erreur  res.data.message = " + res.data.message);
        setassociatedtraining_api("False");
      }


    }).catch((error) => {
      console.warn('Not good man :( GetAssociatedTraning = ');
      setassociatedtraining_api("False");

    })


  }

  const history = useHistory();

  const fadeImages = [
    {
      url: slideimg1,
    },

    {
      url: slideimg4,
    },
  ];


  function CheckAvis(e) {

    const val = document.getElementById("useravis").value
    //alert(" evalu " + val);
    if (val.length < 20) {
      alert(" Le champ 'Avis' doit etre plus renseigné");
    }
    //alert(val);
  }

  function CheckNote(e) {
    const val = e.target.value;

    if (isNaN(val)) {
      //alert(val + " is not a number <br/> mynote = "+mynote);
      document.getElementById("usernote").value = mynote;
    } else {
      if (val > 5) {
        alert(" Valeur Maxi doit etre 5");
        document.getElementById("usernote").value = mynote;
      } else {
        setmynote(val);
      }
    }

  }

  const [submenu, setsubmenu] = useState("datelieu");

  function submenu_info() {
    setsubmenu("formateur");
    //if (document.getElementsByName("menu_formateur")[0])
    // document.getElementsByName('menu_formateur')[0].focus();

    if (document.getElementsByName("menu_datelieu")[0])
      document.getElementsByName('menu_datelieu')[0].blur();

    if (document.getElementsByName("submenu_divers")[0])
      document.getElementsByName('submenu_divers')[0].blur();
  }

  function submenu_datelieu() {
    setsubmenu("datelieu");
    //if (document.getElementsByName("menu_datelieu")[0])
    // document.getElementsByName('menu_datelieu')[0].focus();

    if (document.getElementsByName("menu_formateur")[0])
      document.getElementsByName('menu_formateur')[0].blur();

    if (document.getElementsByName("submenu_divers")[0])
      document.getElementsByName('submenu_divers')[0].blur();
  }


  function submenu_divers() {
    setsubmenu("divers");
    //if (document.getElementsByName("submenu_divers")[0])
    //document.getElementsByName('submenu_divers')[0].focus();

    if (document.getElementsByName("menu_datelieu")[0])
      document.getElementsByName('menu_datelieu')[0].blur();

    if (document.getElementsByName("submenu_divers")[0])
      document.getElementsByName('submenu_divers')[0].blur();
  }


  function Inscription_bton(event) {
    if (DetailTraining["internal_url"] && String(DetailTraining["class_inscription_url"]).length > 10) {
      window.open(
        String(DetailTraining["class_inscription_url"]),
        '_blank'
      );

    } else {
      setinscritpion("1");
    }


    if (document.getElementById('myRef_informe')) {
      var divh = document.getElementById('myRef_informe').offsetTop;
      window.scrollTo({
        top: divh,
        behavior: "smooth",
      });
    }


  }

  function Contact_bton(event) {
    setinscritpion("2");


    if (document.getElementById('myRef_informe')) {
      var divh = document.getElementById('myRef_informe').offsetTop;
      window.scrollTo({
        top: divh,
        behavior: "smooth",
      });
    }


  }


  const [GetCurrentClass_api, setGetCurrentClass_api] = useState();
  const [GetCurrentClass_message, setGetCurrentClass_message] = useState();
  const [GetCurrentClass_result, setGetCurrentClass_result] = useState();
  function GetCurrentClass_trainingsession(event) {

    var form = new FormData();

    form.append("class_internal_url", classId);

    form.append("token", stored_partner);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetActiveSessionFormation_List/";

    axios.post(myurl, form).then(res => {
      //console.log(" In GetCurrentClass_trainingsession  res.data.status = " + res.data.status);
      // console.log(" In GetCurrentClass_trainingsession  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        //console.log(" In GetCurrentClass_trainingsession  res.data.status = " + res.data.status);
        //console.log(" In GetCurrentClass_trainingsession  res.data.message r_class = " + res.data.message);
        setGetCurrentClass_api("true");
        setGetCurrentClass_result(res.data.message);

      }
      else {
        setGetCurrentClass_api("false");
        setGetCurrentClass_message(res.data.message);
      }

    }).catch((error) => {
      console.warn('Not good man :( GetCurrentClass_trainingsession = ', error);
      setGetCurrentClass_api("false");
      //setmyApimyApiMessage("")
    })
  }


  const handleAccountCreation = () => {
    history.push("/create_account")
  };

  const handleAccountConnexion = () => {
    history.push("/Connexion")
  };

  function handleAccountLogout() {

    if (String(userconnected) === String("1")) {
      user_logout();
    }
    else if (String(partnerconnected) === String("1")) {
      part_logout();
    }
  }


  function part_logout_confirmation() {
    /* si c'est une connexion partner qui est active */
    if (typeof (stored_part) != "undefined") {
      setCookie_part("tokenmysypart", "", { path: '/' });
    }
    history.push("/recherche-formation");
  }

  function part_logout() {
    confirmAlert({
      title: '',
      message: 'Confirmez la deconnexion (pro)',
      buttons: [
        {
          label: 'Oui',
          onClick: () => part_logout_confirmation()
        },
        {
          label: 'Non',
          onClick: () => { return }
        }
      ]
    });
  }


  function user_logout_confirmation() {
    /* si c'est une connexion partner qui est active */
    if (typeof (stored_user) != "undefined") {
      setCookie("tokenmysych", "");
    }
    history.push("/recherche-formation");
  }

  function user_logout() {
    confirmAlert({
      title: '',
      message: 'Confirmez la deconnexion (user)',
      buttons: [
        {
          label: 'Oui',
          onClick: () => user_logout_confirmation()
        },
        {
          label: 'Non',
          onClick: () => { return }
        }
      ]
    });
  }


  function Close_info_form() {
    setinscritpion();
    document.getElementById('mob_block_cross_sell_cat').style.display = "flex";
    if (window.screen.width < 601) {
      var meme_orga_top = document.getElementById('mob_block_cross_sell_cat').style.top;
      var displaydetailclass_new_v2_hei = document.getElementById('mobile').style.height;
      var sticky_div_hilight_top = document.getElementById('sticky_div_hilight_pave').style.top;

      var new_meme_orga_top = parseInt(meme_orga_top) - 900;
      var new_displaydetailclass_new_v2_hei = parseInt(displaydetailclass_new_v2_hei) - 900;
      var new_sticky_div_hilight_top = parseInt(sticky_div_hilight_top) - 900;


      document.getElementById('mob_block_cross_sell_cat').style.top = new_meme_orga_top + "px";
      document.getElementById('mobile').style.height = new_displaydetailclass_new_v2_hei + "px";
      document.getElementById('sticky_div_hilight_pave').style.top = new_sticky_div_hilight_top + "px";


    }


    else if (window.screen.width >= 601 && window.screen.width <= 991) {
      var meme_orga_top = document.getElementById('block_crossell_meme_orga').style.top;
      var displaydetailclass_new_v2_hei = document.getElementById('desktop').style.height;
      var new_meme_orga_top = parseInt(meme_orga_top) - 900;
      var new_displaydetailclass_new_v2_hei = parseInt(displaydetailclass_new_v2_hei) - 900;

      document.getElementById('block_crossell_meme_orga').style.top = new_meme_orga_top + "px";
      document.getElementById('desktop').style.height = new_displaydetailclass_new_v2_hei + "px";

    }
    else if (window.screen.width >= 992 && window.screen.width <= 1199) {
      var meme_orga_top = document.getElementById('block_crossell_meme_orga').style.top;
      var displaydetailclass_new_v2_hei = document.getElementById('desktop').style.height;
      var new_meme_orga_top = parseInt(meme_orga_top) - 500;
      var new_displaydetailclass_new_v2_hei = parseInt(displaydetailclass_new_v2_hei) - 500;

      document.getElementById('block_crossell_meme_orga').style.top = new_meme_orga_top + "px";
      document.getElementById('desktop').style.height = new_displaydetailclass_new_v2_hei + "px";
    }
    else if (window.screen.width >= 1200) {
      var meme_orga_top = document.getElementById('block_crossell_meme_orga').style.top;
      var displaydetailclass_new_v2_hei = document.getElementById('desktop').style.height;
      var new_meme_orga_top = parseInt(meme_orga_top) - 500;
      var new_displaydetailclass_new_v2_hei = parseInt(displaydetailclass_new_v2_hei) - 500;

      document.getElementById('block_crossell_meme_orga').style.top = new_meme_orga_top + "px";
      document.getElementById('desktop').style.height = new_displaydetailclass_new_v2_hei + "px";


    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  }

  function Close_inscrip_form() {
    setinscritpion();
    document.getElementById('mob_block_cross_sell_cat').style.display = "flex";

    if (window.screen.width < 601) {
      var meme_orga_top = document.getElementById('mob_block_cross_sell_cat').style.top;
      var displaydetailclass_new_v2_hei = document.getElementById('mobile').style.height;
      var sticky_div_hilight_top = document.getElementById('sticky_div_hilight_pave').style.top;

      var new_meme_orga_top = parseInt(meme_orga_top) - 900;
      var new_displaydetailclass_new_v2_hei = parseInt(displaydetailclass_new_v2_hei) - 900;
      var new_sticky_div_hilight_top = parseInt(sticky_div_hilight_top) - 900;

      document.getElementById('mob_block_cross_sell_cat').style.top = new_meme_orga_top + "px";
      document.getElementById('mobile').style.height = new_displaydetailclass_new_v2_hei + "px";
      document.getElementById('sticky_div_hilight_pave').style.top = new_sticky_div_hilight_top + "px";
    }
    else if (window.screen.width >= 601 && window.screen.width <= 991) {
      var meme_orga_top = document.getElementById('block_crossell_meme_orga').style.top;
      var displaydetailclass_new_v2_hei = document.getElementById('desktop').style.height;
      var new_meme_orga_top = parseInt(meme_orga_top) - 900;
      var new_displaydetailclass_new_v2_hei = parseInt(displaydetailclass_new_v2_hei) - 900;

      document.getElementById('block_crossell_meme_orga').style.top = new_meme_orga_top + "px";
      document.getElementById('desktop').style.height = new_displaydetailclass_new_v2_hei + "px";

    }
    else if (window.screen.width >= 992 && window.screen.width <= 1199) {
      var meme_orga_top = document.getElementById('block_crossell_meme_orga').style.top;
      var displaydetailclass_new_v2_hei = document.getElementById('desktop').style.height;
      var new_meme_orga_top = parseInt(meme_orga_top) - 500;
      var new_displaydetailclass_new_v2_hei = parseInt(displaydetailclass_new_v2_hei) - 500;

      document.getElementById('block_crossell_meme_orga').style.top = new_meme_orga_top + "px";
      document.getElementById('desktop').style.height = new_displaydetailclass_new_v2_hei + "px";
    }
    else if (window.screen.width >= 1200) {
      var meme_orga_top = document.getElementById('block_crossell_meme_orga').style.top;
      var displaydetailclass_new_v2_hei = document.getElementById('desktop').style.height;
      var new_meme_orga_top = parseInt(meme_orga_top) - 500;
      var new_displaydetailclass_new_v2_hei = parseInt(displaydetailclass_new_v2_hei) - 500;

      document.getElementById('block_crossell_meme_orga').style.top = new_meme_orga_top + "px";
      document.getElementById('desktop').style.height = new_displaydetailclass_new_v2_hei + "px";


    }


    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  }


  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const myRef_informe = useRef(null)

  const [Dialog_contact_message, setDialog_contact_message] = React.useState(false);
  const [Dialog_contact_open, setDialog_contact_open] = React.useState(false);
  function Dialog_contact_handle_change_participant_session(message) {
    setDialog_contact_message(message);
    setDialog_contact_open(true);
  }

  const Dialog_contact_handleClose = () => {

  };

  const Dialog_contact_handleClose_buton = () => {

    setDialog_contact_open(false);
    clear_contact_message_fields();

  };

  const [contact_message_nom_prenom, setcontact_message_nom_prenom] = React.useState("");
  const [contact_message_email, setcontact_message_email] = React.useState("");
  const [contact_message_phone, setcontact_message_phone] = React.useState("");
  const [contact_message_sujet, setcontact_message_sujet] = React.useState("");
  const [contact_message_message, setcontact_message_message] = React.useState("");

  function clear_contact_message_fields() {
    setcontact_message_nom_prenom("");
    setcontact_message_email("");
    setcontact_message_phone("");
    setcontact_message_sujet("");
    setcontact_message_sujet("");
    setcontact_message_message("");
  }

  function Send_Contact_Message(e) {

    var sender_mail = "";
    var sender_tel = "";
    var token = "";

    ////console.log("contacter " + sender_mail + " - " + sender_tel + " - ")

    var mysubject = contact_message_sujet;
    var mymessage = contact_message_message;
    var mysender_name = contact_message_nom_prenom;
    var mysender_email = contact_message_email;


    //alert("contacter "+mysender_email+" - "+mymessage+" - url = "
    //+process.env.REACT_APP_API_URL+" - user_ip ="+IPv4+" - "+latitude);

    var re = /\S+@\S+\.\S+/;
    if (re.test(mysender_email) == false) {
      alert("l'email est incorrecte");
      return;
    }

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/add_user_message/";
    var form = new FormData();
    form.append("type", "email");
    form.append("recever_mail", "contact@mysy-training.com");
    form.append("recever_tel", "+33769203945");
    form.append("sender_mail", mysender_email);
    form.append("sender_tel", sender_tel);
    form.append("object", mysubject);
    form.append("message", mymessage);
    form.append("sender_name", mysender_name);
    form.append("token", "");
    form.append("user_ip", IPv4);
    form.append("user_country_code", country_code);
    form.append("user_country_name", country_name);
    form.append("user_city", city);
    form.append("user_postal", postal);
    form.append("user_latitude", latitude);
    form.append("user_longitude", longitude);
    form.append("user_state", state);


    // console.log("## form = ", form);



    axios.post(myurl, form).then(res => {

      if (String(res.data.status) === String("false")) {

        alert(res.data.message);
      }

      else if (String(res.data.status) === String("true")) {
        Dialog_contact_handleClose_buton();
        alert(res.data.message);

      }
      else {

      }
    }).catch((error) => {
      console.warn('contact-nous  Not good man :( mysearchtext = ');

    })
  }




  /// ----
  return (

    <div className="displaydetailclass_new_v3">
      <Helmet>
        <meta name="description" content={`${DetailTraining["title"]}` + " -  Éditeur Français de logiciels pour organisme de formation. Avec solutions de E-Learning, gestion administrative et gestion relation client nous accompagnons les organismes de formation à réaliser l’audit QUALIOPI en toute sérénité. Nous sommes les moins cher du marché. Nous vous accompagnons automatiser vos process : inscription, évaluation, émargement (qr-code), Check-list QUALIOPI, Personnalisation et édition documents, Gestion formateurs, Catalogue formations personnalisé"} />
        <meta name="robots" content="index,follow" />
        <meta name="title" content={DetailTraining["title"]} />
        <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}` + "/" + `${DetailTraining["internal_url"]}`} />
        <title> {`${DetailTraining["title"]}`} </title>
      </Helmet>

      {/***   GESTION Contact */}
      <Dialog
        open={Dialog_contact_open}
        onClose={Dialog_contact_handleClose}
        className="displaypartnersession"
      >

        <DialogTitle>Contactez Nous</DialogTitle>
        <DialogContent className="DialogContent_width">


          <div className="div_row_dialog">
            <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
              Nom & Prénom
            </div>
            <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

              <input
                type='text'
                name='name'
                value={contact_message_nom_prenom}
                onChange={(e) => {
                  setcontact_message_nom_prenom(e.target.value);
                }}
                className='form-control formInput'
                placeholder='Nom'
              ></input>
            </div>
          </div>

          <div className="div_row_dialog">
            <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
              E-mail
            </div>
            <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
              <input
                type='email'
                name='email'
                id='email'
                value={contact_message_email}
                onChange={(e) => {
                  setcontact_message_email(e.target.value);
                }}
                className='form-control formInput'
                placeholder='Adresse email'
              ></input>
            </div>
          </div>


          <div className="div_row_dialog">
            <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
              Téléphone
            </div>
            <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
              <input
                type='text'
                name='phone'
                id='phone'
                value={contact_message_phone}
                onChange={(e) => {
                  setcontact_message_phone(e.target.value);
                }}
                className='form-control formInput'
                placeholder='Numéro Tél'
              ></input>
            </div>
          </div>

          <div className="div_row_dialog">
            <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
              Sujet
            </div>
            <div className="div_row_dialog" >
              <input
                type='text'
                name='subject'
                value={contact_message_sujet}
                onChange={(e) => {
                  setcontact_message_sujet(e.target.value);
                }}
                className='form-control formInput'
                placeholder='Sujet'
              ></input>
            </div>
          </div>

          <div className="div_row_dialog">
            <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
              Votre message
            </div>
            <div className="div_row_dialog" >
              <textarea
                rows={3}
                name='message'
                value={contact_message_message}
                onChange={(e) => {
                  setcontact_message_message(e.target.value);
                }}
                className='form-control formInput'
                placeholder='Message'
              ></textarea>
            </div>
          </div>

          <div className="div_row_dialog" >

            <div>MySy Training Technology<br />
            </div>
            <div style={{ fontSize: "small" }}>

              Adresse : 1 Cr du Havre, 75008 Paris <br />
              Adresse email :
              <a href="mailto: contact@mysy-training.com?subject=Mail from mysy website" target="_blank"> contact@mysy-training.com</a> <br />

              Téléphone fixe : +331 77 00 38 57<br />
            </div>

          </div>


        </DialogContent>

        <DialogActions>
          <div className="div_row">
            <div className="div_row_gauche">
              <Button onClick={Dialog_contact_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
            </div>
            <div className="div_row_droite" style={{ "textAlign": 'center' }}>
              <Button onClick={Send_Contact_Message} className="bton_enreg_dialog">Envoyer&nbsp; <SendIcon /></Button>

            </div>
          </div>

        </DialogActions>


      </Dialog>
      {/***  FIN GESTION Contact */}

      <div className="mobile">
        <div style={{ width: '100%', float: 'left', }}>

          <div style={{ width: '100%', float: 'left', background: "white" }}>
            <Header />
          </div>


          <div style={{ width: '100%', float: 'left', minHeight: '95vh', background: "rgb(247, 247, 247)", position: "relative", top: "5rem" }}>

            {String(ismetiermanaged) !== "0" && String(ismetiermanaged) !== "1" && <div className="mob_img">

              {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("it") &&
                <img src={img_met_program} alt="informatique" className="mob_training_img" />}


              {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("graphisme") &&
                <img src={img_met_graphisme} alt="graphisme" className="mob_training_img" />}

              {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("management") &&
                <img src={img_met_management} alt="management" className="mob_training_img" />}

              {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("digital") &&
                <img src={img_met_digital} alt="digital" className="mob_training_img" />}

              {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("office") &&
                <img src={img_met_bureautic} alt="office" className="mob_training_img" />}

              {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("rh") &&
                <img src={img_met_rh} alt="ressources humaines" className="mob_training_img" />}

              {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("vente") &&
                <img src={img_met_vente} alt="vente" className="mob_training_img" />}

              {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("dev_perso") &&
                <img src={img_met_dev_perso} alt="developpement personnel" className="mob_training_img" />}



            </div>}

            {String(ismetiermanaged) === "0" && <div className="mob_img">
              <img src={img_met_autre} alt="autre" className="mob_training_img" />

            </div>}

            {String(ismetiermanaged) === "1" && <div className="mob_img">
              <img src={DetailTraining.img_url} alt="autre" className="mob_training_img" />
            </div>}

            <div className="mob_block_content">
              <div className="mob_block_content_rating">
                <Rating name="half-rating-reade"

                  precision={0.5}
                  value={ratingvalue}
                  readOnly={true}
                />
              </div>
              <div className="mob_block_content_title">
                {DetailTraining["title"]}
              </div>
              <div className="mob_tag_cpf">

                <div className="mob_tag_cpf_txt">
                  {DetailTraining["cpf"] && String(DetailTraining["cpf"]) === "1" &&
                    "Éligible au CPF"}

                  {!DetailTraining["cpf"] || String(DetailTraining["cpf"]) !== "1" &&
                    "NON Éligible au CPF"}
                </div>

              </div>
            </div>

            {String(DetailTraining["price"]) && String(DetailTraining["price"]) === String("0") &&
              <div className="mob_price">Gratuit
              </div>
            }

            {String(DetailTraining["price"]) && String(DetailTraining["price"]) !== String("0") && String(DetailTraining["price"]) === String("0") && (!DetailTraining.business_prices || Object.keys(DetailTraining.business_prices).length <= 0)
              && DetailTraining["price"] &&
              <div className="mob_price">
                {String(DetailTraining["price"])} € HT
              </div>}

            {String(DetailTraining["price"]) && String(DetailTraining["price"]) !== String("0") && DetailTraining.business_prices && Object.keys(DetailTraining.business_prices).length > 0
              && DetailTraining["price"] && <div className="mob_price">
                <nav className="discounted_price">{String(DetailTraining.business_prices[0].discounted_price)} € HT</nav>

                <nav className="training_price_barre">   {String(DetailTraining["price"])} € HT   </nav>
                <nav className="discount_block">  (- {String(DetailTraining.business_prices[0].discount)} % ) </nav>
              </div>}

            {String(action).toLowerCase() !== "information" && String(action).toLowerCase() !== "inscription" &&
              <div className="mob_cats">

                <div className="mob_cats_bton_inscr" onClick={Inscription_bton}>

                  <div className="mob_cats_bton_inscr_txt">
                    M'inscrire
                  </div>

                </div>

                <div className="mob_cats_bton_info" onClick={Contact_bton}>

                  <div className="mob_cats_bton_info_txt">
                    M'informer sur la formation

                  </div>


                </div>

              </div>}

            <hr style={{ "width": "100%", border: "3px solid grey" }} />

            <div className="mob_caracteristic">

              <div className="mob_caracteristic_title">
                Caractéristiques
              </div>

              <div className="mob_caracteristic_list">

                <ul style={{ "width": "80%" }}>
                  <li>
                    <div className="mob_caracteristic_list_title_desc">

                      <div className="mob_caracteristic_list_title_desc_title">

                        <nav style={{ textAlign: 'left', float: 'left', width: '95%' }}>  A Distance </nav>    <nav style={{ textAlign: 'left', float: 'right', width: '5%' }}> : </nav>

                      </div>

                      <div className="mob_caracteristic_list_title_desc_desc">
                        {DetailTraining.presentiel && DetailTraining.presentiel.distantiel === "1" && " Oui "}
                        {DetailTraining.presentiel && DetailTraining.presentiel.distantiel === "0" && " Non "}
                      </div>


                    </div>

                  </li>

                  <li>
                    <div className="mob_caracteristic_list_title_desc">

                      <div className="mob_caracteristic_list_title_desc_title">

                        <nav style={{ textAlign: 'left', float: 'left', width: '95%' }}> Présentiel </nav>    <nav style={{ textAlign: 'left', float: 'right', width: '5%' }}> : </nav>
                      </div>

                      <div className="mob_caracteristic_list_title_desc_desc">
                        {DetailTraining.presentiel && DetailTraining.presentiel.presentiel === "1" && " Oui "}
                        {DetailTraining.presentiel && DetailTraining.presentiel.presentiel === "0" && " Non "}
                      </div>


                    </div>

                  </li>

                  <li>

                    <div className="mob_caracteristic_list_title_desc">

                      <div className="mob_caracteristic_list_title_desc_title">
                        <nav style={{ textAlign: 'left', float: 'left', width: '95%' }}> Durée </nav>    <nav style={{ textAlign: 'left', float: 'right', width: '5%' }}> : </nav>
                      </div>

                      {String(DetailTraining["duration_unit"]) !== "user_rythme" &&
                        <div className="mob_caracteristic_list_title_desc_desc">
                          {DetailTraining["duration"]} {DetailTraining["duration_unit"]}(s)
                        </div>
                      }

                      {String(DetailTraining["duration_unit"]) === "user_rythme" &&
                        <div className="mob_caracteristic_list_title_desc_desc">
                          A votre rythme
                        </div>
                      }

                    </div>

                  </li>

                  <li>

                    <div className="mob_caracteristic_list_title_desc">

                      <div className="mob_caracteristic_list_title_desc_title">

                        <nav style={{ textAlign: 'left', float: 'left', width: '95%' }}> Certification </nav>    <nav style={{ textAlign: 'left', float: 'right', width: '5%' }}> : </nav>

                      </div>

                      <div className="mob_caracteristic_list_title_desc_desc">
                        {DetailTraining["certif"] && String(DetailTraining["certif"]) !== String("1") && " Non "
                        }
                        {DetailTraining["certif"] && String(DetailTraining["certif"]) === String("1") && " Oui "
                        }
                      </div>


                    </div>

                  </li>

                  <li>

                    <div className="mob_caracteristic_list_title_desc">

                      <div className="mob_caracteristic_list_title_desc_title">

                        <nav style={{ textAlign: 'left', float: 'left', width: '95%' }}> Support </nav>    <nav style={{ textAlign: 'left', float: 'right', width: '5%' }}> : </nav>

                      </div>

                      <div className="mob_caracteristic_list_title_desc_desc">
                        {DetailTraining["support"] && <nav>  {String(DetailTraining["support"]).charAt(0).toUpperCase() + String(DetailTraining["support"]).slice(1)}  </nav>}
                        {!DetailTraining["support"] && "Non précisé"}

                      </div>


                    </div>

                  </li>



                </ul>
              </div>





            </div>

            <hr style={{ "width": "100%", border: "3px solid grey" }} />

            <div className="mob_block_social_icon">

              <div className="mob_block_social_icon_RS">
                <SocialIcon network="facebook" className="mob_social_icone_SocialIcon" />
              </div>

              <div className="mob_block_social_icon_RS">
                <SocialIcon network="linkedin" className="mob_social_icone_SocialIcon" />
              </div>

              <div className="mob_block_social_icon_RS">
                <SocialIcon network="twitter" className="mob_social_icone_SocialIcon" />

              </div>

            </div>

            <div className="mob_block_organisme_ftion" id="mob_block_organisme_ftion">

              <div className="mob_block_organisme_ftion_orga">
                Organisme de formation
              </div>

              <div className="mob_block_organisme_ftion_link">
                {partnair_name && <nav
                  style={{ "cursor": "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      website_partenaire,
                      '_blank'
                    );

                  }}
                >  {partnair_name} </nav>}
              </div>


            </div>

            <hr style={{ "width": "100%", border: "3px solid grey" }} id="myRef_informe" ref={myRef_informe} />

            <div className="mob_info_page" id="mob_info_page">

              <div className="mob_info_page_title_desc"  >

                {String(inscritpion) === "2" && <div>

                  <Inscription internal_url={DetailTraining["internal_url"]} action="information"
                    Close_info_form={Close_info_form} trainig_price={class_sales_price} />

                </div>
                }
                {String(inscritpion) === "1" && <div className="description detail_element">
                  <Inscription internal_url={DetailTraining["internal_url"]} action="inscription"
                    Close_info_form={Close_inscrip_form} trainig_price={class_sales_price} />

                </div>

                }


                <div className="mob_info_page_title_desc_title">
                  Description
                </div>


                <div className="mob_info_page_title_desc_desc description_block">
                  {parse(String(DetailTraining["description"]))}
                </div>
              </div>


              {GetCurrentClass_result && GetCurrentClass_result.length > 0 &&
                <div className="mob_info_page_title_desc">

                  <div className="mob_info_page_title_desc_title">
                    Les sessions
                  </div>

                  <div className="mob_info_page_title_desc_desc session_block">
                    <ul style={{ "width": "100%" }}>


                      {GetCurrentClass_result && GetCurrentClass_result.slice(0, 5).map((formation) => (
                        <li style={{ "height": "2.8rem" }}>
                          {(JSON.parse(formation).session_ondemande) && (JSON.parse(formation).session_ondemande) === "1" && <nav> Sessions de formation à la demande</nav>}

                          {(!(JSON.parse(formation).session_ondemande) || (JSON.parse(formation).session_ondemande) === "0") && <nav>
                            {(JSON.parse(formation).distantiel) === "1" && (JSON.parse(formation).presentiel) === "1" &&
                              (JSON.parse(formation).date_debut).substring(0, 10) !== (JSON.parse(formation).date_fin).substring(0, 10) &&
                              <nav>
                                {(JSON.parse(formation).date_debut).substring(0, 10)} au
                                {(JSON.parse(formation).date_fin).substring(0, 10)}  <b> A Distance</b>  et <b>En Présentiel </b>- {(JSON.parse(formation).adresse)} &nbsp;{(JSON.parse(formation).code_postal)}
                              </nav>
                            }

                            {(JSON.parse(formation).distantiel) === "1" && (JSON.parse(formation).presentiel) === "1" &&
                              (JSON.parse(formation).date_debut).substring(0, 10) === (JSON.parse(formation).date_fin).substring(0, 10) &&
                              <nav>
                                Le &nbsp;
                                {(JSON.parse(formation).date_fin).substring(0, 10)}  <b> A Distance</b>  et <b>En Présentiel </b>- {(JSON.parse(formation).adresse)} &nbsp;{(JSON.parse(formation).code_postal)}
                              </nav>
                            }



                            {(JSON.parse(formation).distantiel) === "1" && ((JSON.parse(formation).presentiel) === "0" || !(JSON.parse(formation).presentiel)) &&
                              (JSON.parse(formation).date_debut).substring(0, 10) !== (JSON.parse(formation).date_fin).substring(0, 10) &&
                              <nav>
                                {(JSON.parse(formation).date_debut).substring(0, 10)} au
                                {(JSON.parse(formation).date_fin).substring(0, 10)}  <b>A Distance </b> </nav>
                            }

                            {(JSON.parse(formation).distantiel) === "1" && ((JSON.parse(formation).presentiel) === "0" || !(JSON.parse(formation).presentiel)) &&
                              (JSON.parse(formation).date_debut).substring(0, 10) === (JSON.parse(formation).date_fin).substring(0, 10) &&
                              <nav>
                                Le &nbsp;
                                {(JSON.parse(formation).date_fin).substring(0, 10)}  <b>A Distance </b> </nav>
                            }


                            {(JSON.parse(formation).presentiel) === "1" && ((JSON.parse(formation).distantiel) === "0" || !(JSON.parse(formation).distantiel)) &&
                              (JSON.parse(formation).date_debut).substring(0, 10) !== (JSON.parse(formation).date_fin).substring(0, 10) &&
                              <nav>
                                {(JSON.parse(formation).date_debut).substring(0, 10)}&nbsp; au&nbsp;{(JSON.parse(formation).date_fin).substring(0, 10)} <b>En Présentiel </b> -
                                {(JSON.parse(formation).adresse)} &nbsp;{(JSON.parse(formation).code_postal)}
                              </nav>}

                            {(JSON.parse(formation).presentiel) === "1" && ((JSON.parse(formation).distantiel) === "0" || !(JSON.parse(formation).distantiel)) &&
                              (JSON.parse(formation).date_debut).substring(0, 10) === (JSON.parse(formation).date_fin).substring(0, 10) &&
                              <nav>
                                Le  &nbsp; {(JSON.parse(formation).date_fin).substring(0, 10)} <b>En Présentiel </b> -
                                {(JSON.parse(formation).adresse)} &nbsp;{(JSON.parse(formation).code_postal)}
                              </nav>}

                          </nav>}
                        </li>

                      ))}

                    </ul>
                  </div>


                </div>}

              <div className="mob_info_page_title_desc">

                <div className="mob_info_page_title_desc_title">
                  Objectif
                </div>

                {DetailTraining["objectif"] && <div className="mob_info_page_title_desc_desc objectif_block">
                  {parse(String(DetailTraining["objectif"]))}
                </div>
                }


              </div>

              <div className="mob_info_page_title_desc_long_txt">

                <div className="mob_info_page_title_desc_title">
                  Programme
                </div>

                {DetailTraining["programme"] && <div className="mob_info_page_title_desc_desc programme_block">
                  {parse(String(DetailTraining["programme"]))}sticky_div_hilight
                </div>
                }


              </div>

              <br />
              {DetailTraining["tab_ue_detail_data"] && DetailTraining["tab_ue_detail_data"].length > 0 && < div className="mob_info_page_title_desc_long_txt" >
                <div className="mob_info_page_title_desc_title">
                  Modules
                </div>


                {DetailTraining["tab_ue_detail_data"] && <div style={{ width: '100%' }}>

                  <div style={{ width: '100%' }}>
                    {DetailTraining["tab_ue_detail_data"] && DetailTraining["tab_ue_detail_data"].map((val) => (
                      <div style={{ width: '100%' }}>

                        <Accordion expanded={expanded === val._id} onChange={handleChange(val._id)}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={val._id}
                            id={val._id}
                          >

                            <Typography sx={{ color: 'text.secondary' }}>
                              {val.titre}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <div className="mob_info_page_title_desc_desc programme_block">   {parse(String(val.description).replace(/(<([^>]+)>)/ig, ''))} </div>

                            </Typography>
                          </AccordionDetails>
                        </Accordion>


                      </div>
                    ))}
                  </div>

                </div>}

              </div>}

              <br />



              {DetailTraining["pourqui"] && <div className="mob_info_page_title_desc_long_txt">

                <div className="mob_info_page_title_desc_title">
                  Pour Qui ?
                </div>

                {DetailTraining["pourqui"] && <div className="mob_info_page_title_desc_desc pourqui_block">
                  {parse(String(DetailTraining["pourqui"]))}
                </div>
                }


              </div>}

            </div>



          </div>


          <div style={{ width: '100%', float: 'left', minHeight: '10rem', background: 'None', zIndex: '99', position: "relative", backgroundColor: 'white' }}>
            <br />

            <div className="mob_block_cross_sell_cat" id="mob_block_cross_sell_cat_new">

              <div className="mob_block_sous_cross_sell" id="mob_block_sous_cross_sell">

                {associatedtraining_message && associatedtraining_message.length > 0 &&
                  <div className="mob_block_sous_cross_sell_title">
                    Cela pourrait aussi vous intéresser
                  </div>}

                <div className="mob_cross_sell_list_card">

                  {associatedtraining_message && String(associatedtraining_api) === "True" &&
                    associatedtraining_message.slice(0, 3).map((formation) => (

                      <div className="mob_cross_sell_list_card_card">

                        <div className="mob_cross_sell_card_img">

                          {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("it") &&
                            <img src={img_met_program} alt="informatique" className="mob_training_img_crossel" />}

                          {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("graphisme") &&
                            <img src={img_met_graphisme} alt="graphisme" className="mob_training_img_crossel" />}

                          {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("management") &&
                            <img src={img_met_management} alt="management" className="mob_training_img_crossel" />}

                          {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("digital") &&
                            <img src={img_met_digital} alt="digital" className="mob_training_img_crossel" />}

                          {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("office") &&
                            <img src={img_met_bureautic} alt="office" className="mob_training_img_crossel" />}

                          {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("rh") &&
                            <img src={img_met_rh} alt="ressources humaines" className="mob_training_img_crossel" />}

                          {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("vente") &&
                            <img src={img_met_vente} alt="vente" className="mob_training_img_crossel" />}

                          {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("sport") &&
                            <img src={img_met_sport} alt="sport" className="mob_training_img_crossel" />}

                          {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("sante_nat") &&
                            <img src={img_met_sante_nat} alt="sante naturelle" className="mob_training_img_crossel" />}


                          {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("dev_perso") &&
                            <img src={img_met_dev_perso} alt="developpement personnel" className="mob_training_img_crossel" />}

                          {!JSON.parse(formation).metier && <img src={img_met_autre} alt="autre personnel" className="mob_training_img_crossel" />}

                        </div>

                        <div className="mob_cross_sell_card_tag_cpf">
                          <div className="mob_cross_sell_card_tag_cpf_txt">
                            {JSON.parse(formation).cpf && (String(JSON.parse(formation).cpf)) === "1" &&
                              "Éligible au CPF"}

                            {!JSON.parse(formation).cpf || String(JSON.parse(formation).cpf) !== "1" &&
                              "NON Éligible au CPF"}

                          </div>

                        </div>


                        <div className="mob_cross_sell_card_content">

                          <div className="mob_cross_sell_card_content_rating">
                            {JSON.parse(formation).note && String(JSON.parse(formation).note).length === 1 &&
                              parseInt(String(JSON.parse(formation).note)) <= 5 &&
                              <Rating name="half-rating-reade"

                                precision={0.5}
                                readOnly={true}
                                value={(String(JSON.parse(formation).note))}
                              />}
                          </div>

                          <div className="mob_cross_sell_card_content_title">
                            {parse(String(JSON.parse(formation).title))}
                          </div>

                          {JSON.parse(formation).price && <div className="mob_cross_sell_card_content_price">
                            {(String(JSON.parse(formation).price))} € HT

                          </div>}


                        </div>

                        <div className="mob_cross_sell_card_content_cat">

                          <div className="mob_cross_sell_card_content_cat_bton_detail" onClick={(e) => {
                            e.preventDefault();
                            //test(props.formation.external_code);
                            window.open(
                              "/Display-Detail-formation/" + JSON.parse(formation).internal_url,
                              '_self'
                            );


                          }}>
                            <div className="mob_cross_sell_card_content_cat_bton_detail_txt">
                              Voir les détails
                            </div>

                          </div>


                        </div>

                      </div>
                    ))}

                </div>

              </div>


            </div>

          </div>

          <div style={{ width: '100%', float: 'left', minHeight: '10rem', background: 'None', zIndex: '99', position: "relative", backgroundColor: 'white' }}>
            <div className="footer" id="footer">
              <Footer />
            </div>
          </div>


        </div>
      </div>


      <div className="desktop">
        <div style={{ width: '100%', float: 'left', }}>
          <div className="Background_Header">
            <Header />
          </div>

          <div style={{ width: '100%', float: 'left', background: "rgb(247, 247, 247)" }}>

            <div style={{ width: '100%', }}>
              <div style={{ background: '#0A043C', width: '100%', color: '#FFF', height: '200px', }}>
                <div className="block_sous_header_rate_title">

                  <div className="rating">
                    {ratingvalue && String(ratingvalue).length === 1 &&
                      parseInt(String(ratingvalue)) <= 5 &&
                      <Rating name="half-rating-reade"
                        readOnly={true}
                        precision={0.5}
                        value={ratingvalue}
                      />}
                  </div>

                  <div className="sous_header_title">
                    {DetailTraining["title"]}
                  </div>

                  <div className="block_sous_header_tag">

                    <div className="block_sous_header_tag_cpf">
                      {DetailTraining["cpf"] && String(DetailTraining["cpf"]) === "1" &&
                        "Éligible au CPF"}

                      {!DetailTraining["cpf"] || String(DetailTraining["cpf"]) !== "1" &&
                        "NON Éligible au CPF"}
                    </div>

                  </div>

                </div>



              </div>
            </div>

          </div>




          <div style={{ width: '100%', float: 'left', minHeight: '95vh', background: "rgb(247, 247, 247)" }}>

            <div style={{ width: '70%', float: 'left', "paddingLeft": "2rem", paddingRight: '2rem' }} >

              {String(inscritpion) === "1" && <div className="description detail_element">
                <Inscription internal_url={DetailTraining["internal_url"]} action="inscription"
                  Close_info_form={Close_info_form} trainig_price={class_sales_price} />
              </div>

              }

              {String(inscritpion) === "2" && <div className="description detail_element">
                <Inscription internal_url={DetailTraining["internal_url"]} action="information"
                  Close_info_form={Close_inscrip_form} trainig_price={class_sales_price} />

              </div>

              }

              {(String(action).toLocaleLowerCase() === "inscription" || String(action).toLocaleLowerCase() === "information")
                && String(action).toLocaleLowerCase() !== "evaluation"
                && <div className="description detail_element" name='section_info'>

                  <Inscription internal_url={classId} action={action} trainig_price={class_sales_price} />
                </div>
              }

              {String(action).toLocaleLowerCase() !== "inscription" && String(action).toLocaleLowerCase() !== "information" && String(tokenEval_api) === "true"
                && <div className="description detail_element" name='section_info'>


                  <Inscription internal_url={classId} action={"evaluation"} eval_token={action} />
                </div>
              }



              <br />
              <div className="block_info_page_pave_title_desc">
                <div className="block_info_page_pave_title_desc_title">  Description

                </div>
                {DetailTraining["description"] && <div className="block_info_page_pave_title_desc description_block">
                  {parse(String(DetailTraining["description"]))}

                </div>
                }
              </div>
              <br />

              {GetCurrentClass_result && GetCurrentClass_result.length > 0 &&
                <div className="block_info_page_pave_title_desc">
                  <div className="block_info_page_pave_title_desc_title">
                    Les sessions


                  </div>
                  <div className="block_info_page_pave_title_desc_desc session_block">
                    <ul style={{ "width": "100%" }}>

                      {GetCurrentClass_result && GetCurrentClass_result.slice(0, 5).map((formation) => (


                        <li style={{ "height": "2.8rem" }}>

                          {(JSON.parse(formation).session_ondemande) && (JSON.parse(formation).session_ondemande) === "1" && <nav> Sessions de formation à la demande</nav>}

                          {(!(JSON.parse(formation).session_ondemande) || (JSON.parse(formation).session_ondemande) === "0") && <nav>
                            {(JSON.parse(formation).distantiel) === "1" && (JSON.parse(formation).presentiel) === "1" &&
                              (JSON.parse(formation).date_debut).substring(0, 10) !== (JSON.parse(formation).date_fin).substring(0, 10) &&
                              <nav>
                                {(JSON.parse(formation).date_debut).substring(0, 10)}&nbsp; au&nbsp;
                                {(JSON.parse(formation).date_fin).substring(0, 10)}  <b> A Distance</b>  et <b>En Présentiel </b>- {(JSON.parse(formation).adresse)} &nbsp;{(JSON.parse(formation).code_postal)}
                              </nav>
                            }

                            {(JSON.parse(formation).distantiel) === "1" && (JSON.parse(formation).presentiel) === "1" &&
                              (JSON.parse(formation).date_debut).substring(0, 10) === (JSON.parse(formation).date_fin).substring(0, 10) &&
                              <nav>
                                Le &nbsp;
                                {(JSON.parse(formation).date_fin).substring(0, 10)}  <b> A Distance</b>  et <b>En Présentiel </b>- {(JSON.parse(formation).adresse)} &nbsp;{(JSON.parse(formation).code_postal)}
                              </nav>
                            }



                            {(JSON.parse(formation).distantiel) === "1" && ((JSON.parse(formation).presentiel) === "0" || !(JSON.parse(formation).presentiel)) &&
                              (JSON.parse(formation).date_debut).substring(0, 10) !== (JSON.parse(formation).date_fin).substring(0, 10) &&
                              <nav>
                                {(JSON.parse(formation).date_debut).substring(0, 10)}&nbsp; au&nbsp;
                                {(JSON.parse(formation).date_fin).substring(0, 10)}  <b>A Distance </b> </nav>
                            }

                            {(JSON.parse(formation).distantiel) === "1" && ((JSON.parse(formation).presentiel) === "0" || !(JSON.parse(formation).presentiel)) &&
                              (JSON.parse(formation).date_debut).substring(0, 10) === (JSON.parse(formation).date_fin).substring(0, 10) &&
                              <nav>
                                Le &nbsp;
                                {(JSON.parse(formation).date_fin).substring(0, 10)}  <b>A Distance </b> </nav>
                            }


                            {(JSON.parse(formation).presentiel) === "1" && ((JSON.parse(formation).distantiel) === "0" || !(JSON.parse(formation).distantiel)) &&
                              (JSON.parse(formation).date_debut).substring(0, 10) !== (JSON.parse(formation).date_fin).substring(0, 10) &&
                              <nav>
                                {(JSON.parse(formation).date_debut).substring(0, 10)}&nbsp; au&nbsp;{(JSON.parse(formation).date_fin).substring(0, 10)} <b>En Présentiel </b> -
                                {(JSON.parse(formation).adresse)} &nbsp;{(JSON.parse(formation).code_postal)}
                              </nav>}

                            {(JSON.parse(formation).presentiel) === "1" && ((JSON.parse(formation).distantiel) === "0" || !(JSON.parse(formation).distantiel)) &&
                              (JSON.parse(formation).date_debut).substring(0, 10) === (JSON.parse(formation).date_fin).substring(0, 10) &&
                              <nav>
                                Le  &nbsp;  {(JSON.parse(formation).date_fin).substring(0, 10)} <b>En Présentiel </b> -
                                {(JSON.parse(formation).adresse)} &nbsp;{(JSON.parse(formation).code_postal)}
                              </nav>}

                          </nav>}
                        </li>

                      ))}

                    </ul>
                  </div>

                </div>
              }

              <br />
              <div className="block_info_page_pave_title_desc">
                <div className="block_info_page_pave_title_desc_title">
                  Objectif
                </div>
                {DetailTraining["objectif"] && <div className="block_info_page_pave_title_desc_desc objectif_block">
                  {parse(String(DetailTraining["objectif"]))}

                </div>
                }

              </div>


              <br />


              <div className="block_info_page_pave_title_desc_long_text" >
                <div className="block_info_page_pave_title_desc_title">
                  Programme
                </div>
                {DetailTraining["programme"] && <div className="block_info_page_pave_title_desc_desc programme_block">
                  {parse(String(DetailTraining["programme"]))}

                </div>
                }
              </div>


              <br />
              {DetailTraining["tab_ue_detail_data"] && DetailTraining["tab_ue_detail_data"].length > 0 && < div className="block_info_page_pave_title_desc_long_text" >
                <div className="block_info_page_pave_title_desc_title">
                  Modules
                </div>


                {DetailTraining["tab_ue_detail_data"] && <div style={{ width: '100%' }}>

                  <div style={{ width: '100%' }}>
                    {DetailTraining["tab_ue_detail_data"] && DetailTraining["tab_ue_detail_data"].map((val) => (
                      <div style={{ width: '100%' }}>

                        <Accordion expanded={expanded === val._id} onChange={handleChange(val._id)}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={val._id}
                            id={val._id}
                          >

                            <Typography sx={{ color: 'text.secondary' }}>
                              {val.titre}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <div className="block_info_page_pave_title_desc_desc programme_block">   {parse(String(val.description).replace(/(<([^>]+)>)/ig, ''))} </div>

                            </Typography>
                          </AccordionDetails>
                        </Accordion>


                      </div>
                    ))}
                  </div>

                </div>}

              </div>}

              <br />

              {DetailTraining["pourqui"] && String(DetailTraining["pourqui"]).length > 2 && <div className="block_info_page_pave_title_desc_long_text">
                <div className="block_info_page_pave_title_desc_title ">
                  Pour Qui ?
                </div>
                {DetailTraining["pourqui"] && <div className="block_info_page_pave_title_desc_desc pourqui_block">
                  {parse(String(DetailTraining["pourqui"]))}


                </div>
                }
              </div>}
              <br />

              {DetailTraining["plus_produit"] && String(DetailTraining["plus_produit"]).length > 2 && <div className="block_info_page_pave_title_desc_long_text">
                <div className="block_info_page_pave_title_desc_title ">
                  Points forts
                </div>
                {DetailTraining["plus_produit"] && <div className="block_info_page_pave_title_desc_desc objectif_block">
                  {parse(String(DetailTraining["plus_produit"]))}


                </div>

                }
              </div>}




              <br />

            </div>

            {/****** Stick droire */}
            <div style={{ width: '29%', float: 'left', "marginRight": "5px" }}>


              <div className="block_stick_v2_092024">


                {class_img_stored_in_bdd && String(class_img_stored_in_bdd).length > 50 && <div className="block_sticky_info_img">

                  <img src={class_img_stored_in_bdd} className="training_img" />
                </div>
                }

                {(!class_img_stored_in_bdd || String(class_img_stored_in_bdd).length < 50) && String(ismetiermanaged) !== "0" && String(ismetiermanaged) !== "1" && <div className="block_sticky_info_img">

                  {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                    DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("it") &&
                    <img src={img_met_program} alt="informatique" className="training_img" />}


                  {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                    DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("graphisme") &&
                    <img src={img_met_graphisme} alt="graphisme" className="training_img" />}

                  {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                    DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("management") &&
                    <img src={img_met_management} alt="management" className="training_img" />}

                  {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                    DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("digital") &&
                    <img src={img_met_digital} alt="digital" className="training_img" />}

                  {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                    DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("office") &&
                    <img src={img_met_bureautic} alt="office" className="training_img" />}

                  {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                    DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("rh") &&
                    <img src={img_met_rh} alt="ressources humaines" className="training_img" />}

                  {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                    DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("vente") &&
                    <img src={img_met_vente} alt="vente" className="training_img" />}

                  {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                    DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("sport") &&
                    <img src={img_met_sport} alt="sport" className="training_img" />}

                  {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                    DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("sante_nat") &&
                    <img src={img_met_sante_nat} alt="sante naturelle" className="training_img" />}

                  {(!DetailTraining.img_url || String(DetailTraining.img_url).length <= 0) &&
                    DetailTraining.metier && String(DetailTraining.metier).toLocaleLowerCase() === String("dev_perso") &&
                    <img src={img_met_dev_perso} alt="developpement personnel" className="training_img" />}


                </div>}

                {(!class_img_stored_in_bdd || String(class_img_stored_in_bdd).length < 50) && String(ismetiermanaged) === "0" && <div className="block_sticky_info_img">
                  <img src={img_met_autre} alt="autre" className="training_img" />

                </div>}

                {(!class_img_stored_in_bdd || String(class_img_stored_in_bdd).length < 50) && String(ismetiermanaged) === "1" && <div className="">
                  <img src={DetailTraining.img_url} alt="autre" className="" style={{ "maxWidth": "100%" }} />

                </div>}




                {String(DetailTraining["price"]) && String(DetailTraining["price"]) === String("0") &&
                  <div className="block_sticky_info_price">Gratuit
                  </div>
                }

                {String(DetailTraining["price"]) && String(DetailTraining["price"]) !== String("0") && (!DetailTraining.business_prices || Object.keys(DetailTraining.business_prices).length <= 0)
                  && DetailTraining["price"] && <div className="block_sticky_info_price">  {String(DetailTraining["price"])} € HT  </div>}

                {String(DetailTraining["price"]) && String(DetailTraining["price"]) !== String("0") && DetailTraining.business_prices && Object.keys(DetailTraining.business_prices).length > 0
                  && DetailTraining["price"] && <div className="block_sticky_info_price">
                    <nav className="discounted_price">{String(DetailTraining.business_prices[0].discounted_price)}  € HT</nav>

                    <nav className="training_price_barre">   {String(DetailTraining["price"])} € HT   </nav>
                    <nav className="discount_block">  (- {String(DetailTraining.business_prices[0].discount)} % ) </nav>
                  </div>}



                {String(action).toLowerCase() !== "information" && String(action).toLowerCase() !== "inscription" && <div className="sticky_cats">

                  <div className="sticky_cats_buton_inscrire" onClick={Inscription_bton}>

                    <div className="sticky_cats_buton_inscrire_box" > </div>
                    <div className="sticky_cats_buton_inscrire_txt" >M’inscrire </div>
                  </div>

                  <div className="sticky_cats_buton_info" onClick={Contact_bton}>
                    <div className="sticky_cats_buton_info_box"> </div>
                    <div className="sticky_cats_buton_info_txt" > M’informer sur la formation</div>
                  </div>

                </div>}



                <div className="block_sticky_info_caracteristique">

                  <div className="block_sticky_info_caracteristique_title">  Caractéristiques : </div>


                  <div className="block_sticky_info_caracteristique_list">
                    <ul style={{ "width": "100%" }}>

                      <li>
                        <div className="block_sticky_info_caracteristique_list_title_desc">

                          <div className="block_sticky_info_caracteristique_list_title_desc_title">

                            <nav style={{ textAlign: 'left', float: 'left', width: '95%' }}> Durée </nav>    <nav style={{ textAlign: 'left', float: 'right', width: '5%' }}> : </nav>

                          </div>
                          {String(DetailTraining["duration_unit"]) !== "user_rythme" &&
                            <div className="block_sticky_info_caracteristique_list_title_desc_desc">
                              {DetailTraining["duration"]} &nbsp;{DetailTraining["duration_unit"]}(s)

                            </div>
                          }

                          {String(DetailTraining["duration_unit"]) === "user_rythme" &&
                            <div className="block_sticky_info_caracteristique_list_title_desc_desc">
                              A votre rythme

                            </div>
                          }

                        </div>
                      </li>

                      <li>
                        <div className="block_sticky_info_caracteristique_list_title_desc">

                          <div className="block_sticky_info_caracteristique_list_title_desc_title">

                            <nav style={{ textAlign: 'left', float: 'left', width: '95%' }}> Certification </nav>    <nav style={{ textAlign: 'left', float: 'right', width: '5%' }}> : </nav>

                          </div>

                          <div className="block_sticky_info_caracteristique_list_title_desc_desc">
                            {DetailTraining["certif"] && String(DetailTraining["certif"]) !== String("1") && <div>

                              Non  &nbsp; </div>
                            }
                            {DetailTraining["certif"] && String(DetailTraining["certif"]) === String("1") && <div>

                              Oui  &nbsp; </div>
                            }

                          </div>


                        </div>
                      </li>

                      <li>
                        <div className="block_sticky_info_caracteristique_list_title_desc">

                          <div className="block_sticky_info_caracteristique_list_title_desc_title">

                            <nav style={{ textAlign: 'left', float: 'left', width: '95%' }}> Distanciel </nav>    <nav style={{ textAlign: 'left', float: 'right', width: '5%' }}> : </nav>

                          </div>

                          <div className="block_sticky_info_caracteristique_list_title_desc_desc">
                            {DetailTraining.presentiel && DetailTraining.presentiel.distantiel === "1" && <div> Oui </div>}
                            {DetailTraining.presentiel && DetailTraining.presentiel.distantiel !== "1" && <div> Non </div>}

                          </div>


                        </div>
                      </li>

                      <li>
                        <div className="block_sticky_info_caracteristique_list_title_desc">

                          <div className="block_sticky_info_caracteristique_list_title_desc_title">

                            <nav style={{ textAlign: 'left', float: 'left', width: '95%' }}> Presentiel </nav>    <nav style={{ textAlign: 'left', float: 'right', width: '5%' }}> : </nav>
                          </div>

                          <div className="block_sticky_info_caracteristique_list_title_desc_desc">
                            {DetailTraining.presentiel && DetailTraining.presentiel.presentiel === "1" && <div> Oui </div>}
                            {DetailTraining.presentiel && DetailTraining.presentiel.presentiel !== "1" && <div> Non </div>}

                          </div>


                        </div>
                      </li>

                      <li>
                        <div className="block_sticky_info_caracteristique_list_title_desc">

                          <div className="block_sticky_info_caracteristique_list_title_desc_title">
                            <nav style={{ textAlign: 'left', float: 'left', width: '95%' }}> Support </nav>    <nav style={{ textAlign: 'left', float: 'right', width: '5%' }}> : </nav>
                          </div>

                          <div className="block_sticky_info_caracteristique_list_title_desc_desc">
                            {DetailTraining["support"] && <div>

                              {String(DetailTraining["support"]).charAt(0).toUpperCase() + String(DetailTraining["support"]).slice(1)}   &nbsp; </div>
                            }
                            {!DetailTraining["support"] && <div>

                              Non précisé  &nbsp; </div>
                            }

                          </div>


                        </div>
                      </li>


                    </ul>

                  </div>


                </div>
                <hr style={{ "width": "100%", border: "3px solid grey" }} />


                <div className="sticky_div_hilight">

                  {String(isqualiopi) === "1" && <div className="sticky_img_hilight1">
                    <img src="https://img.mysy-training.com/certificat_img/IMG_Qualiopi.jpg" alt="Qualiopi" className="img_hili" />
                  </div>}

                  {String(isdatadock) === "1" && <div className="sticky_img_hilight1">
                    <img src="https://img.mysy-training.com/certificat_img/IMG_Datadock.jpg" alt="DataDock" className="img_hili" />

                  </div>}

                  {String(iscertitrace) === "1" && <div className="sticky_img_hilight1">
                    <img src="https://img.mysy-training.com/certificat_img/IMG_Certitrace.jpg" alt="Certitrace" className="img_hili" />

                  </div>}

                  {String(isbureaucertitrace) === "1" && <div className="sticky_img_hilight1">
                    <img src="https://img.mysy-training.com/certificat_img/IMG_Bureau_Certitrace.jpg" alt="Bureau Certitrace" className="img_hili" />

                  </div>}

                  {String(iscertifvoltaire) === "1" && <div className="sticky_img_hilight1">
                    <img src="https://img.mysy-training.com/certificat_img/IMG_CertifVoltaire.gif" alt="Certitrace" className="img_hili" />

                  </div>}


                </div>

                <hr style={{ "width": "100%", border: "3px solid grey" }} />

                <div className="sticky_social_icone">


                  <div className="sticky_social_icone_facebook">
                    <SocialIcon network="facebook" className="sticky_social_icone_SocialIcon" />
                  </div>

                  <div className="sticky_social_icone_link">
                    <SocialIcon network="linkedin" className="sticky_social_icone_SocialIcon" />
                  </div>

                  <div className="sticky_social_icone_twitter">
                    <SocialIcon network="twitter" className="sticky_social_icone_SocialIcon" />

                  </div>
                </div>

                <div className="sticky_organisme_title_desc">

                  <div className="sticky_organisme_title_desc_title">
                    Organisme de formation
                  </div>

                  <div className="sticky_organisme_title_desc_desc">

                    {partnair_name && <nav
                      style={{ "cursor": "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          website_partenaire,
                          '_blank'
                        );

                      }}
                    >  {partnair_name} </nav>}
                  </div>

                </div>


              </div>


            </div>

          </div>


          <div style={{ width: '100%', float: 'left', minHeight: '10rem', background: 'None', zIndex: '99', position: "relative", backgroundColor: 'white' }}>
            <br />
            {associatedtraining_message && String(associatedtraining_api) === "True" && associatedtraining_message.length > 0 &&
              <div style={{
                height: '40px', fontWeight: '700', textAlign: "center",
                fontFamily: 'Roboto, Verdana, Georgia, Arial, Times New Roman',
                fontSize: "24px", color: '#3E6EE3', lineHeight: '29px'
              }}> Cela pourrait aussi vous intéresser</div>}

            <div className="sous_crossell_list_card">
              {associatedtraining_message && String(associatedtraining_api) === "True" &&
                associatedtraining_message.slice(0, 3).map((formation) => (
                  <div className="sous_crossell_list_card_card">

                    <div className="sous_crossell_list_card_card_img">

                      {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("it") &&
                        <img src={img_met_program} alt="informatique" className="training_img_crossel" />}

                      {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("graphisme") &&
                        <img src={img_met_graphisme} alt="graphisme" className="training_img_crossel" />}

                      {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("management") &&
                        <img src={img_met_management} alt="management" className="training_img_crossel" />}

                      {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("digital") &&
                        <img src={img_met_digital} alt="digital" className="training_img_crossel" />}

                      {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("office") &&
                        <img src={img_met_bureautic} alt="office" className="training_img_crossel" />}

                      {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("rh") &&
                        <img src={img_met_rh} alt="ressources humaines" className="training_img_crossel" />}

                      {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("vente") &&
                        <img src={img_met_vente} alt="vente" className="training_img_crossel" />}

                      {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("sport") &&
                        <img src={img_met_sport} alt="sport" className="training_img_crossel" />}

                      {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("sante_nat") &&
                        <img src={img_met_sante_nat} alt="sante naturelle" className="training_img_crossel" />}


                      {JSON.parse(formation).metier && String(JSON.parse(formation).metier).toLocaleLowerCase() === String("dev_perso") &&
                        <img src={img_met_dev_perso} alt="developpement personnel" className="training_img_crossel" />}

                      {!JSON.parse(formation).metier && <img src={img_met_autre} alt="autre personnel" className="training_img_crossel" />}


                    </div>

                    <div className="sous_crossell_list_card_card_tag_cpf">
                      <div className="sous_crossell_list_card_card_tag_cpf_txt">
                        {JSON.parse(formation).cpf && (String(JSON.parse(formation).cpf)) === "1" &&
                          "Éligible au CPF"}

                        {!JSON.parse(formation).cpf || String(JSON.parse(formation).cpf) !== "1" &&
                          "NON Éligible au CPF"}


                      </div>
                    </div>

                    <div className="sous_crossell_list_card_card_content">

                      <div className="sous_crossell_list_card_card_content_rating">

                        {JSON.parse(formation).note && String(JSON.parse(formation).note).length === 1 &&
                          parseInt(String(JSON.parse(formation).note)) <= 5 &&

                          <Rating name="half-rating-reade"
                            readOnly={true}
                            precision={0.5}
                            value={(String(JSON.parse(formation).note))}
                          />}
                      </div>

                      <div className="sous_crossell_list_card_card_content_title">  {parse(String(JSON.parse(formation).title)).substring(0, 50)}
                      </div>

                      {JSON.parse(formation).price && <div className="sous_crossell_list_card_card_content_price">
                        {(String(JSON.parse(formation).price))} € HT
                      </div>}




                      <div className="sous_crossell_list_card_card_CAT">

                        <div className="sous_crossell_list_card_card_CAT_bton" onClick={(e) => {
                          e.preventDefault();
                          //test(props.formation.external_code);
                          window.open(
                            "/Display-Detail-formation/" + JSON.parse(formation).internal_url,
                            '_self'
                          );


                        }}>
                          <div className="sous_crossell_list_card_card_CAT_bton_txt">
                            Voir les détails
                          </div>

                        </div>


                      </div>
                    </div>


                  </div>
                ))}



            </div>


          </div>


          <div style={{ width: '100%', float: 'left', minHeight: '10rem', background: 'None', zIndex: '99', position: "relative", backgroundColor: 'white' }}>
            <div className="footer" id="footer">
              <Footer />
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}


export default DisplayDetailClass_new_v3;