import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import { useParams } from 'react-router-dom'
import { Button, } from "reactstrap";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import ReactStars from "react-rating-stars-component";
import { confirmAlert } from 'react-confirm-alert';
//mport slideimg4 from "../mysy_img/mysy_img.png";
import 'react-slideshow-image/dist/styles.css'
import slideimg1 from "../mysy_img/Image_article2.jpg";
//import slideimg4 from "../mysy_img/Image_article1.png";

import { Fade } from 'react-slideshow-image';
import { CommentSection } from 'react-comments-section'
import DiscussionBoard from 'react-discussion-board'
import 'react-discussion-board/dist/index.css'
import ArticleComment from "./ArticleComment"
import { useForm } from 'react-hook-form';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import parse from 'html-react-parser'
import { RichTextEditor } from '@mantine/rte';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import Header from "./Header";
import ListeArticle from "./ListeArticles";

import slidearticle1 from "../mysy_img/Image_article2.jpg";
import slidearticle2 from "../mysy_img/Image_article1.png";

 
const DisplayDetailArticle = (props) => {
  const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);
  const { articleId } = useParams();
  const [result, setResult] = useState("");
  const [commentresult, setcommentresult] = useState();
  const [myApicommentResponse, setmyApicommentResponse] = useState();

  const [myApiResponse, setmyApiResponse] = useState("false");
  const [DetailArticleAvis, setDetailArticleAvis] = useState([]);
  const [closepopup, setclosepopup] = useState(0);
  const [country_code, setcountry_code] = useState("");
  const [country_name, setcountry_name] = useState("");
  const [city, setcity] = useState("");
  const [postal, setpostal] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [IPv4, setIPv4] = useState("");
  const [state, setstate] = useState("");
  const [userIp, setuserIp] = useState("");
  const [action, setaction] = useState("");
  const [userconnected, setuserconnected] = useState("");
  const [mynote, setmynote] = useState(0);
  const stored_user = cookie.tokenmysych;
  const [qualityrating, setqualityrating] = useState("");
  const [avisrating, setavisrating] = useState("");
  const [noterating, setnoterating] = useState("");
  const [makecomment, setmakecomment] = useState("1");

  const random1 = Math.floor(Math.random() * 10);
  const random2 = Math.floor(Math.random() * 10);
  const [editorState, setEditorState] = useState(null);

  const [windowWidth, setwindowWidth] = useState(null);
  const [windowheight , setwindowheight] = useState(null);

  const [text_value, settext_value] = useState("");


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();


  const onSubmit = async (data) => {
    const { name, email, subject, message } = data;

    var total = "??";
    if (document.getElementsByName("sommerandom")[0].value) {
      total = document.getElementsByName("sommerandom")[0].value;
    }


    var calcaul_total = parseInt(random1) + parseInt(random2);


    //alert(" le total saisi = " + total + "  la val cacule est " + calcaul_total);

    if (parseInt(total) != parseInt(calcaul_total)) {
      alert(" Le total dans la zone 'je ne suis pas un robot' est incohérent. Recommencez svp :) !!!");
      return;
    }

    confirmPostCommment();

  };


  function confirmPostCommment() {
    confirmAlert({
      title: 'Confirmer le commentaire?',
      message: '',
      buttons: [
        {
          label: 'Oui',
          onClick: () => SendMessage()
        },
        {
          label: 'Non',
          onClick: () => { return }

        }
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      keyCodeForClose: [8, 32],
      willUnmount: () => { },
      afterClose: () => { },
      onClickOutside: () => { },
      onKeypress: () => { },
      onKeypressEscape: () => { },
    });
  }


  function clearComment(e) {
    ////console.log("contacter " + sender_mail + " - " + sender_tel + " - ")
    document.getElementsByName("message")[0].value = "";
    document.getElementsByName("name")[0].value = "";
    document.getElementsByName("email")[0].value = "";
  }

  function SendMessage(e) {

    var sender_mail = "";
    var sender_tel = "";
    var token = "";



    ////console.log("contacter " + sender_mail + " - " + sender_tel + " - ")
    var mymessage = document.getElementsByName("message")[0].value;
    var mysender_name = document.getElementsByName("name")[0].value;
    var mysender_email = document.getElementsByName("email")[0].value;


    //alert("contacter "+mysender_email+" - "+mymessage+" - url = "
    //+process.env.REACT_APP_API_URL+" - user_ip ="+IPv4+" - "+latitude);

    var re = /\S+@\S+\.\S+/;
    if (re.test(mysender_email) == false) {
      alert("l'email est incorrecte");
      return;
    }




    var myurl = process.env.REACT_APP_API_URL + "myclass/api/add_user_message/";
    var form = new FormData();
    form.append("type", "comment_article");
    form.append("recever_mail", "contact@mysy-training.com");
    form.append("recever_tel", "+33769203945");
    form.append("sender_mail", mysender_email);
    form.append("sender_tel", sender_tel);
    form.append("object", "");
    form.append("message", mymessage);
    form.append("sender_name", mysender_name);
    form.append("token", "");
    form.append("user_ip", IPv4);
    form.append("user_country_code", country_code);
    form.append("user_country_name", country_name);
    form.append("user_city", city);
    form.append("user_postal", postal);
    form.append("user_latitude", latitude);
    form.append("user_longitude", longitude);
    form.append("user_state", state);
    form.append("article_avis_url", articleId);


    axios.post(myurl, form).then(res => {

      if (String(res.data.status) === String("false")) {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        setmyApiResponse("false");
      }

      else if (String(res.data.status) === String("true")) {
        //console.log(" In test  res.data.status (true) = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        document.getElementsByName("message")[0].value = "";
        document.getElementsByName("name")[0].value = "";
        document.getElementsByName("email")[0].value = "";
        setmakecomment("");
      }
      else {
        //console.log(" contact-nous  statut = " + res.data.status);
        //console.log(" contact-nous   res.data.message = " + res.data.message);
        setmyApiResponse("false");
      }
    }).catch((error) => {
      console.warn('contact-nous  Not good man :( mysearchtext = ');
      setmyApiResponse("false");

    })


  }



  const avisratingChanged = (newRating) => {
    setavisrating(String(newRating));
    var humeur = ""
    if (newRating < 2) {
      humeur = ":("
    } else if (newRating < 4) {
      humeur = ":)"
    } else if (newRating >= 4) {
      humeur = ":) :)"
    }
    var text = "Une note de " + String(newRating) + ", merci. Dites nous en plus " + humeur + " ?"

    document.getElementsByName("avis")[0].value = text;
  };

  const noteratingChanged = (newRating) => {
    var text = String(newRating)
    document.getElementsByName("note")[0].value = text;
    setnoterating(String(newRating));
  };

  useEffect(() => {
    async function fetchData() {
      const result = await axios('https://geolocation-db.com/json/',);
      setuserIp(result.data.IPv4);
      setcountry_code(result.data.country_code);
      setcountry_name(result.data.country_name);
      setcity(result.data.city);
      setpostal(result.data.postal);
      setlatitude(result.data.latitude);
      setlongitude(result.data.longitude);
      setIPv4(result.data.IPv4);
      setstate(result.data.state);
      setuserIp(result.data.IPv4);

    }
    fetchData();
    Display();
    Display_article_comment();


    //alert("stored_user =  "+stored_user);
    if (typeof (articleId) === "undefined") {
      setaction("creation");
    } else {
      setaction("affichage");
      desablefield();
    }

    if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
      setuserconnected("0");
    } else {
      setuserconnected("1");
    }
    setwindowWidth(window.innerWidth);
    setwindowheight(window.innerHeight);
  

    //console.log(" articleId = " + articleId);
  }, [makecomment]);


  function Display(e) {
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_articles_avis/";
    var form = new FormData();
    form.append("internal_url", articleId);
    form.append("token", "");
    // Ajout des info IP user
    form.append("user_ip", IPv4);
    form.append("user_country_code", country_code);
    form.append("user_country_name", country_name);
    form.append("user_city", city);
    form.append("user_postal", postal);
    form.append("user_latitude", latitude);
    form.append("user_longitude", longitude);
    form.append("user_state", state);


    //alert("myurl = "+myurl);

    axios.post(myurl, form).then(res => {
      if (res.data.status != "False") {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        if (res.data.message.length > 0) {
          setmyApiResponse("True");
        }
        else {
          setmyApiResponse("False");
        }
        setResult(res.data.message);
        setDetailArticleAvis(JSON.parse(res.data.message));
      }
      else {
        //console.log(" In Erreur   res.data.status = " + res.data.status);
        //console.log(" In Erreur  res.data.message = " + res.data.message);
        setmyApiResponse("False");
      }
    }).catch((error) => {
      console.warn('Not good man :( mysearchtext = ');
      setmyApiResponse("False");
    })
  }


  function Display_article_comment(e) {
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_user_message/";
    var form = new FormData();
    form.append("article_avis_url", articleId);
    form.append("type", "comment_article");


    //alert("myurl = "+myurl);

    axios.post(myurl, form).then(res => {
      if (res.data.status != "False") {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        if (res.data.message.length > 0) {
          setmyApicommentResponse("True");
        }
        else {
          setmyApicommentResponse("False");
        }
        setcommentresult(res.data.message);

      }
      else {
        //console.log(" In Erreur   res.data.status = " + res.data.status);
        //console.log(" In Erreur  res.data.message = " + res.data.message);
        setmyApicommentResponse("False");
      }
    }).catch((error) => {
      console.warn('Not good man :( mysearchtext = ');
      setmyApicommentResponse("False");
    })
  }

 

  function RecordArticle(e) {

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/add_articles_avis/";
    var form = new FormData();
    var title = document.getElementsByName("mytitle")[0].value;
    var url = document.getElementsByName("url")[0].value;
    //var quality = document.getElementsByName("quality")[0].value;
    var avis = text_value;
    var note = document.getElementsByName("note")[0].value;
    var postedby = ""
    var token = ""

    if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
      postedby = document.getElementsByName("postedby")[0].value;
    } else {
      token = stored_user;
    }

    if (postedby.length > 0) {
      var re = /\S+@\S+\.\S+/;
      if (re.test(postedby) == false) {
        alert("le champs 'Posté par' n'est pas une adresse email valide");
        return;
      }

    }

    form.append("token", token);
    form.append("title_formation", title);
    form.append("qualite", "none");
    form.append("avis", avis);
    form.append("note", note);
    form.append("postedby", postedby);
    form.append("qualityrating", qualityrating);
    form.append("avisrating", avisrating);


    form.append("url_formation", url);
    form.append("user_ip", IPv4);
    form.append("user_country_code", country_code);
    form.append("user_country_name", country_name);
    form.append("user_city", city);
    form.append("user_postal", postal);
    form.append("user_latitude", latitude);
    form.append("user_longitude", longitude);
    form.append("user_state", state);

    axios.post(myurl, form).then(res => {
      setResult(String(res.data.status));

      if (String(res.data.status) == String("true")) {
        //console.log(" contact-nous  statut = " + res.data.status);
        //console.log(" contact-nous   res.data.message = " + res.data.message);
        setmyApiResponse(String(res.data.message))
        clearForm();
      }
      else {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        setmyApiResponse(String(res.data.message))

      }

    }).catch((error) => {
      console.warn('contact-nous  Not good man :( mysearchtext = ');

    })
  }

  function clearForm(e) {

    document.getElementsByName("mytitle")[0].value = "";
    document.getElementsByName("url")[0].value = "";
    //document.getElementsByName("quality")[0].value = "";
    settext_value("");
    document.getElementsByName("note")[0].value = "";
    document.getElementsByName("postedby")[0].value = "";
  }


  function desablefield(e) {


    if (document.getElementsByName("mytitle")[0]) {
      document.getElementsByName("mytitle")[0].disabled = true;
      document.getElementsByName("mytitle")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("url")[0]) {
      document.getElementsByName("url")[0].disabled = true;
      document.getElementsByName("url")[0].style.backgroundColor = "#ECEFF1";
    }


    if (document.getElementsByName("avis")[0]) {
      document.getElementsByName("avis")[0].disabled = true;
      document.getElementsByName("avis")[0].style.backgroundColor = "#ECEFF1";
    }


    if (document.getElementsByName("note")[0]) {
      document.getElementsByName("note")[0].disabled = true;
      document.getElementsByName("note")[0].style.backgroundColor = "#ECEFF1";
    }

  }


  function makecommentary(e) {
    setmakecomment("1");
    alert(" commentaire ");
  }

  function CheckNote(e) {
    const val = e.target.value;

    if (isNaN(val)) {
      //alert(val + " is not a number <br/> mynote = "+mynote);
      document.getElementById("note").value = mynote;
    } else {
      if (val > 5) {
        alert(" Valeur Maxi doit etre 5");
        document.getElementById("note").value = mynote;
      } else {
        setmynote(val);
      }
    }

  }

  function confirmpostarticle(e) {



    var mytitle = document.getElementsByName("mytitle")[0].value;
    var url = document.getElementsByName("url")[0].value;
    //var quality = document.getElementsByName("quality")[0].value;
    var avis = text_value
    var note = document.getElementsByName("note")[0].value;
    var postedby = ""
    var token = ""

    //alert("title = "+mytitle+"  url = "+url+"  document.getElementsByName(title)[0].value; ="+document.getElementsByName('title')[0].value);

    if (mytitle.length < 5) {
      alert("le champs 'Titre' n'est pas correcte");
      return;
    }

    if (url.length < 5) {
      alert("le champs 'lien de la formation' n'est pas correcte");
      return;
    }

    if (avis.length <= 0) {
      alert("le champs 'avis' n'est pas correcte");
      return;
    }

    if (note.length <= 0) {
      alert("le champs 'note' n'est pas correcte");
      return;
    }

    if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
      postedby = document.getElementsByName("postedby")[0].value;

      if (postedby.length <= 0) {
        alert("le champs 'Posté par' n'est pas correcte");
        return;
      }


      if (postedby.length > 0) {
        var re = /\S+@\S+\.\S+/;
        if (re.test(postedby) === false) {
          alert("le champs 'Posté par' n'est pas une adresse email valide");
          return;
        }
      }
    }


    confirmAlert({
      title: 'Confirmer la note',
      message: " ",
      buttons: [
        {
          label: 'Oui',
          onClick: () => { RecordArticle() }
        },
        {
          label: 'Non',
          onClick: () => { return }

        },

      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      keyCodeForClose: [8, 32],
      willUnmount: () => { },
      afterClose: () => { },
      onClickOutside: () => { },
      onKeypress: () => { },
      onKeypressEscape: () => { },
    });

  }

  const fadeImages = [
    {
      url: slideimg1,
    },

    {
      url: slideimg1,
    },
  ];

  const data = [
    {
      userId: '02b',
      comId: '017',
      fullName: 'Lily',
      userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
      text: 'I think you have a point🤔',
      avatarUrl: 'https://ui-avatars.com/api/name=Lily&background=random',
      replies: []
    }
  ]

  const allPosts = [
    {
      profileImage:
        'https://www.rd.com/wp-content/uploads/2017/09/01-shutterstock_476340928-Irina-Bg.jpg',
      name: 'Jane Doe',
      content: '<p>Hello everyone!</p><p>How are you all doing?</p><p>-Jane</>',
      date: new Date('01 Jan 2020 01:12:00 GMT')
    },
    {
      profileImage:
        'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      name: 'John Doe',
      content:
        '<p>Raising say express had chiefly detract demands she. Quiet led own cause three him. Front no party young abode state up. Saved he do fruit woody of to. Met defective are allowance two perceived listening consulted contained. It chicken oh colonel pressed excited suppose to shortly. He improve started no we manners however effects. Prospect humoured mistress to by proposal marianne attended. Simplicity the far admiration preference everything. Up help home head spot an he room in Barton waited twenty always repair in within we do. An delighted offending curiosity my is dashwoods at. Boy prosperous increasing surrounded companions her nor advantages sufficient put. John on time down give meet help as of. Him waiting and correct believe now cottage she another. Vexed six shy yet along learn maids her tiled. Through studied shyness evening bed him winding present. Become excuse hardly on my thirty it wanted. </p>',
      date: new Date('01 Jan 2020 09:12:00 GMT')
    }
  ]

  const [posts, setPosts] = useState(allPosts)

  const submitPost = (text) => {
    const curDate = new Date()

    setPosts([
      ...posts,
      {
        profileImage:
          'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        name: 'Jane Doe',
        content: text,
        date: curDate
      }
    ])
  }


  return (
    <div className="DisplayDetailArticle">
      <Helmet>
        <meta name="description" content={DetailArticleAvis.avis} />
        <meta name="robots" content="index,follow" />
        <meta name="title" content={DetailArticleAvis.title_formation} />
        <link rel="canonical" href={window.location.href} />
        <title> {`${DetailArticleAvis.title_formation}`} </title>
      </Helmet>

      <Header />

      <h1 className="h1_transparent">{`${DetailArticleAvis.title_formation}`}</h1>
      <div className="div_mobile">
        <div>
          <div className="div_left">
            <div className="pub">
              <div className="slide-container">
                <Fade>
                  {fadeImages.map((fadeImage, index) => (
                    <div className="each-fade" key={index}>
                      <div className="image-container">
                        <img src={fadeImage.url} className="slide_img" />
                      </div>
                      <h2>{fadeImage.caption}</h2>
                    </div>
                  ))}
                </Fade>
              </div>
            </div>
          </div>
          <div className="div_right">
            {String(action) !== String("affichage") && <div className="detail_container">

              <div style={{ "width": "100%" }}>
                <div className="detail_row">
                  Titre (*)
                </div>
                <textarea id="mytitle" name="mytitle"
                  rows="1" cols="33" value={DetailArticleAvis.title_formation} />
              </div>

              <div style={{ "width": "100%" }}>
                <div className="detail_row">
                  <a href={DetailArticleAvis.url_formation} target="_blank"> Lien vers la formation (url) (*)  </a>
                </div>
                <textarea id="url" name="url"

                  rows="1" cols="33" value={DetailArticleAvis.url_formation} />
              </div>

              <div style={{ "width": "100%" }}>
                <div style={{ "textAlign": "left", "color": "rgb(10, 42, 77)", "fontSize": "0.9rem" }}>
                  Votre avis (*)
                </div>
                <RichTextEditor style={{ "width": "100%", }}
                  value={text_value} onChange={settext_value} maxLength={100} readonly={true}
                  controls={[
                    ['bold', 'italic', 'underline', 'link'],
                    ['unorderedList', 'h1', 'h2', 'h3'],
                    ['sup', 'sub'],
                    ['alignLeft', 'alignCenter', 'alignRight'],
                  ]}
                  placeholder="Donner votre avis..."
                  readOnly={false}
                />


              </div>

              <div className="detail_row" style={{ width: "40%", float: "left" }} >
                Note (5 max) :
              </div>
              {String(action) !== "affichage" && <div style={{ width: "60%", float: "right", display: "flex", justifyContent: "flex-end" }}>
                <ReactStars
                  count={5}
                  onChange={noteratingChanged}
                  size={24}
                  isHalf={true}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor="#ffd700"


                />
              </div>}
              <input disabled
                id="note"
                name="note"
                value={DetailArticleAvis.note}
                onChange={CheckNote}
              />

              {String(action) !== String("creation") &&
                <div className="detail_row">
                  Posté par : {DetailArticleAvis.postedby}, le  {(String(DetailArticleAvis.date_avis)).substring(0, 16)}
                </div>}

              {String(action) === String("creation") && String(userconnected) !== String("1") && <div>
                <div className="detail_row">
                  Posté par (email)
                </div>
                <input type="text" id="postedby" placeholder="adresse@mail.com"
                  name="postedby"
                />

              </div>}



              {String(action) === String("creation") && <div style={{ 'float': "right" }}>
                <Button variant="contained" title='play' color="success" onClick={confirmpostarticle} style={{ 'borderRadius': "10rem", background: "red", width: "10rem", marginTop: "1rem", }}>Valider !
                </Button>
              </div>}

              {String(action) !== String("creation") && <div style={{ 'float': "right", "marginTop": "1rem" }}>
                <div className="media_sociaux">
                  <FacebookShareButton
                    url={"https://www.mysy-training.com/Display-Detail-Article/" + DetailArticleAvis.internal_url}

                    quote={DetailArticleAvis.title_formation + " -- " + DetailArticleAvis.avis}
                    hashtag={"#MySyTraining"}
                  >
                    <SocialIcon network="facebook" className="SocialIcon" />
                  </FacebookShareButton>
                  &nbsp;

                  <TwitterShareButton
                    url={"https://www.mysy-training.com/Display-Detail-Article/" + DetailArticleAvis.internal_url}
                    hashtag={"#MySyTraining"}
                  >
                    <SocialIcon network="twitter" className="SocialIcon" />
                  </TwitterShareButton>

                  &nbsp;
                  <LinkedinShareButton
                    url={"https://www.mysy-training.com/Display-Detail-Article/" + DetailArticleAvis.internal_url}
                    title={" Un avis sur  : " + DetailArticleAvis.title_formation}
                    summary={DetailArticleAvis.title_formation + " -- " + DetailArticleAvis.avis}
                    source={"https://www.mysy-training.com/Display-Detail-Article/" + DetailArticleAvis.internal_url}

                  >
                    <SocialIcon network="linkedin" className="SocialIcon" />
                  </LinkedinShareButton>


                </div>

              </div>}

              {String(result) === String("true") && <div className="okUpdateData">
                L'articles a bien été posté.
                Il sera validé sous peu.

              </div>}


              {String(result) === String("false") && <div className="koUpdateData">
                Impossible de valider de poster l'article <br />
                {myApiResponse}
              </div>}


            </div>}

            {String(action) === String("affichage") && DetailArticleAvis.type && String(DetailArticleAvis.type) === "blog" &&
            <div className="detail_container_affichage_blog">

              <div className="titre_article">

                {DetailArticleAvis.title_formation}
                <div className="titre_date_publication">
                  Publié le  {(String(DetailArticleAvis.date_avis)).substring(0, 16)}
                </div>
              </div>

              <div className="avis">

                {windowWidth < 601 && DetailArticleAvis.type && String(DetailArticleAvis.type) === "blog" &&
                  DetailArticleAvis.avis_mobile && <div style={{ "marginTop": "1rem" }}>
                   
                    {parse(DetailArticleAvis.avis_mobile)}

                  </div>}

                  {windowWidth >= 601 && DetailArticleAvis.type && String(DetailArticleAvis.type) === "blog" &&
                  DetailArticleAvis.avis && <div style={{ "marginTop": "1rem" }}>
                   
                    {parse(DetailArticleAvis.avis)}

                  </div>}


               
              </div>
             
             
              <div className="tous_nos_article">
                <div style={{ "textAlign": "left", "width": "45%", float: "left" }}>
                  <a href="/Recherche-Article-formation" target=""> {"<<"} Liste des articles </a>
                </div>

                <div style={{ "textAlign": "right", "width": "45%", float: "right" }}>
                  <a href="/recherche-formation" target="">Liste des formations {">>"} </a>
                </div>


              </div>



              {String(action) === String("creation") && <div style={{ 'float': "right" }}>
                <Button variant="contained" title='play' color="success" onClick={confirmpostarticle} style={{ 'borderRadius': "10rem", background: "red", width: "10rem", marginTop: "1rem", }}>Valider !
                </Button>
              </div>}


              {String(result) === String("true") && <div className="okUpdateData">
                L'articles a bien été posté.
                Il sera validé sous peu.

              </div>}


              {String(result) === String("false") && <div className="koUpdateData">
                Impossible de valider de poster l'article <br />
                {myApiResponse}
              </div>}
            </div>
            }

            {String(action) === String("affichage") && (!DetailArticleAvis.type || String(DetailArticleAvis.type) !== "blog") && 
            <div className="detail_container_affichage">

              <div className="titre_article">

                {DetailArticleAvis.title_formation}
                <div className="titre_date_publication">
                  Publié le  {(String(DetailArticleAvis.date_avis)).substring(0, 16)}
                </div>
              </div>

              <div className="avis">

               
                {(!DetailArticleAvis.type || String(DetailArticleAvis.type) !== "blog") && DetailArticleAvis.avis && <div style={{ "marginTop": "1rem" }}>
                  <b>Avis 1 :</b><br />
                  {parse(DetailArticleAvis.avis)}


                </div>}
              </div>
              <div>
                <b>Note  :</b> {DetailArticleAvis.note} /5
              </div>
              <br />
              <div ><b>Y acceder : </b> &nbsp;
                <a href={DetailArticleAvis.url_formation} target="_blank">   Lien (url)  </a>
              </div>
              <br />

              <div className="tous_nos_article">
                <div style={{ "textAlign": "left", "width": "45%", float: "left" }}>
                  <a href="/Recherche-Article-formation" target=""> {"<<"} Liste des articles </a>
                </div>

                <div style={{ "textAlign": "right", "width": "45%", float: "right" }}>
                  <a href="/recherche-formation" target="">Liste des formations {">>"} </a>
                </div>


              </div>



              {String(action) === String("creation") && <div style={{ 'float': "right" }}>
                <Button variant="contained" title='play' color="success" onClick={confirmpostarticle} style={{ 'borderRadius': "10rem", background: "red", width: "10rem", marginTop: "1rem", }}>Valider !
                </Button>
              </div>}


              {String(result) === String("true") && <div className="okUpdateData">
                L'articles a bien été posté.
                Il sera validé sous peu.

              </div>}


              {String(result) === String("false") && <div className="koUpdateData">
                Impossible de valider de poster l'article <br />
                {myApiResponse}
              </div>}
            </div>
            }

            {String(action) !== String("creation") && <div style={{ 'float': "right", "marginTop": "0rem" }}>
              <div className="media_sociaux">
                <FacebookShareButton
                  url={"https://www.mysy-training.com/Display-Detail-Article/" + DetailArticleAvis.internal_url}

                  quote={DetailArticleAvis.title_formation + " -- " + DetailArticleAvis.avis}
                  hashtag={"#MySyTraining"}
                >
                  <SocialIcon network="facebook" className="SocialIcon" />
                </FacebookShareButton>
                &nbsp;

                <TwitterShareButton
                  url={"https://www.mysy-training.com/Display-Detail-Article/" + DetailArticleAvis.internal_url}
                  hashtag={"#MySyTraining"}
                >
                  <SocialIcon network="twitter" className="SocialIcon" />
                </TwitterShareButton>

                &nbsp;
                <LinkedinShareButton
                  url={"https://www.mysy-training.com/Display-Detail-Article/" + DetailArticleAvis.internal_url}
                  title={" Un avis sur  : " + DetailArticleAvis.title_formation}
                  summary={DetailArticleAvis.title_formation + " -- " + DetailArticleAvis.avis}
                  source={"https://www.mysy-training.com/Display-Detail-Article/" + DetailArticleAvis.internal_url}

                >
                  <SocialIcon network="linkedin" className="SocialIcon" />
                </LinkedinShareButton>


              </div>

            </div>}

          </div>


        </div>

        {String(action) === String("affichage") && String(makecomment) !== String("1")
          && <Button variant="contained" color="success" className="btonPosterComment"

            onClick={(e) => {
              if (String(makecomment) === String("1")) {
                setmakecomment("0");

              } else if (String(makecomment) !== String("1")) {
                setmakecomment("1");
              }
            }}
          >Poster un commentaire </Button>
        }

        {String(action) === String("affichage") && String(makecomment) === String("1")
          && <Button variant="contained" color="success"
            className="btonPosterComment"
            onClick={(e) => {
              if (String(makecomment) === String("1")) {
                setmakecomment("0");

              } else if (String(makecomment) !== String("1")) {
                setmakecomment("1");
              }
            }}
          >Fermer le commentaire </Button>
        }


        {String(makecomment) === String("1") && <div className="commentarticleavis">
          <div className='row'>
            <div className='col-12 text-center'>
              <div className='contactForm'>
                <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                  {/* Row 1 of form */}
                  <div className='row formRow'>
                    <div className='col-6'>
                      <input
                        type='text'
                        name='name'
                        {...register('name', {
                          required: { value: true, message: 'votre nom/prenom' },
                          maxLength: {
                            value: 30,
                            message: 'svp pas plus de 30 caractères'
                          }
                        })}
                        className='form-control formInput inputcommentarticle'
                        placeholder='Nom'
                      ></input>
                      {errors.name && <span className='koUpdateData'>{errors.name.message}</span>}
                    </div>
                    <div className='col-6'>
                      <input
                        type='email'
                        name='email'
                        id='email'
                        {...register('email', {
                          required: true,
                          pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                        })}
                        className='form-control formInput inputcommentarticle_d'
                        placeholder='Adresse email'
                      ></input>
                      {errors.email && (
                        <span className='koUpdateData'>Adresse email invalide</span>
                      )}
                    </div>
                  </div>



                  {/* Row 3 of form */}
                  <div className='row formRow'>
                    <div className='col'>
                      <textarea
                        rows={3}
                        name='message'
                        {...register('message', {
                          required: true
                        })}
                        className='form-control formInput inputcommentarticlemessage'
                        placeholder='Message'
                      ></textarea>
                      {errors.message && <span className='koUpdateData'>Saisir un message</span>}
                    </div>
                  </div>
                  <br />
                  <div className='row formRow' >
                    <div className='col-6'>
                      &nbsp;
                    </div>

                    <div className='col-6'> {random1} + {random2} =
                      <input
                        type='text'
                        name='sommerandom'
                        id='sommerandom'
                        {...register('sommerandom', {
                          required: { value: true, message: 'sommes de valeurs' },
                          maxLength: {
                            value: 30,
                          }
                        })}
                        className='form-control formInput inputcommentarticle_d'
                        placeholder='Le total est ?'
                      ></input>
                      {errors.sommerandom && (
                        <span className='koUpdateData'>la sommes est incorrect</span>
                      )}
                    </div>
                  </div>
                  <br />

                  <Button variant="contained" color="success" type='submit'
                    className="div_row2 btn_login button"
                    style={{
                      'borderRadius': "5rem", "fontSize": "0.6rem", "color": "black",
                      "width": "6rem", background: "#619E31"
                    }}

                  >Envoyer</Button> &nbsp;&nbsp;&nbsp;


                  <Button variant="contained" color="warning" onClick={clearComment}
                    className="div_row2 btn_login button"
                    style={{
                      'borderRadius': "10rem", "fontSize": "0.6rem", "color": "black",
                      "width": "5rem", background: "#EAECEE", "float": "right"
                    }}
                  >Effacer </Button>


                </form>
                {String(myApiResponse) === String("true") && <div className="okUpdateData">
                  Votre message bien été envoyez. Merci.
                </div>}

                {String(myApiResponse) === String("false") && <div className="koUpdateData">
                  Impossible d'envoyer votre message. Merci de ressayer dans un instant.
                </div>}
              </div>
            </div>
          </div>

        </div>}

        <br />&nbsp;
        {String(myApicommentResponse) === String("True") && commentresult &&
          commentresult.map((articelcomment2) => (

            <div>

              {<ArticleComment articelcomment={JSON.parse(articelcomment2)}
                key={JSON.parse(articelcomment2).tmp_key} edit="1" />}
            </div>))}



        <ListeArticle />
        <div className="pieddepage">
          <Footer />
        </div>

      </div>



    </div>
  )
}

export default DisplayDetailArticle;
