import React, { useRef, useState, useEffect } from "react";
//import { useLocation } from 'react-router-dom';
import Navigation from "./Navigation";
import { Helmet } from "react-helmet";

import ousommesnousimg from "../mysy_img/mysy_training_technology_ici.png"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import Header from "./Header";
import Button from '@mui/material/Button';
import img_1_old from "../mysy_img/1.jpg";
import gestion_admin_img from "../mysy_img/gestion_admin_img.png";
import e_learning_lms from "../mysy_img/e_learning_lms.png";



import img_2 from "../mysy_img/lms.png";
import img_3_old from "../mysy_img/3.jpg";
import mysy_crm from "../mysy_img/mysy_crm.png";
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player/lazy'
import tick_circle_ok from "../mysy_img/tick_circle_ok.png";

import img_catalog from "../mysy_img/catalogue.png";
import img_inscription from "../mysy_img/inscription.png";
import img_emargement from "../mysy_img/emargement.png";
import img_checklistqualiopi from "../mysy_img/checklistqualiopi.png";

import img_avisparticipants from "../mysy_img/avisparticipants.png";
import img_evaluations from "../mysy_img/evaluations.png";
import img_stockdocuments from "../mysy_img/stockdocuments.png";

import img_personnalisationdoc from "../mysy_img/personnalisationdoc.png";
import img_planifsession from "../mysy_img/planifsession.png";

import img_materiel from "../mysy_img/materiel.png";
import img_enseignants from "../mysy_img/enseignants.png";
import img_tableaudebord from "../mysy_img/tableaudebord.png";



import img_devis from "../mysy_img/devis.png";
import img_client from "../mysy_img/client.png";
import img_collaborateur from "../mysy_img/collaborateur.png";
import img_paiement from "../mysy_img/paiement.png";
import img_opportunite from "../mysy_img/opportunite.png";
import img_facture from "../mysy_img/facture.png";

import img_partagefichier from "../mysy_img/partagefichier.png";
import img_classevirtuelle from "../mysy_img/classevirtuelle.png";
import img_suivitempsapprenant from "../mysy_img/suivitempsapprenant.png";
import img_echangeprofapprenant from "../mysy_img/echangeprofapprenant.png";
import img_exportscrom from "../mysy_img/exportscrom.png";
import img_intranetapprenant from "../mysy_img/intranetapprenant.png";
import img_courstdtpexamen from "../mysy_img/courstdtpexamen.png";

import pack_independant from "../mysy_img/pack_independant.png";
import pack_standart from "../mysy_img/pack_standart.png";
import pack_gold from "../mysy_img/pack_gold.png";

import img_wiki from "../mysy_img/wiki.png";
import img_serviceshebergement from "../mysy_img/serviceshebergement.png";
import img_sociallearning from "../mysy_img/sociallearning.png";

const Fonctionnalites_V1 = () => {

    const ButtonMailto = ({ mailto, label }) => {
        return (
            <Link
                to='#'
                onClick={(e) => {
                    window.location.href = mailto;
                    e.preventDefault();
                }}
            >
                <nav className="contactez_nous_new">  {label} </nav>
            </Link>
        );
    };

    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
        <GoogleMap

            defaultZoom={18}
            defaultCenter={{ lat: 48.866669, lng: 2.33333 }}
        >
            {props.isMarkerShown && <Marker position={{ lat: 48.866669, lng: 2.33333 }} />}
        </GoogleMap>
    ))

    function sendEmail() {
        window.location.assign("mailto:contact@mysy-training.com");

    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });



    }, []);

    return (
        <div className="fonctionnalite_v1">
            <Helmet>
                <title>Logiciel de gestion pour Organisme de formation : Gestion Administrative, CRM, LMS, Marketplace, découvrez les Fonctionnalités et Modules du logiciel de gestion des organismes de formation</title>
                <meta name="description" content="Avec notre logiciel Tout-en-Un, Nous automatisons les étapes Qualiopi (Inscription, Documents personnalisés, Les émargements sécurisés, Les évaluations, etc). Votre Le bilan pédagogique et financier est généré automatiquement." />
                <meta name="robots" content="index,follow" />
                <meta name="title" content="Gestion Administrative, CRM, LMS, Marketplace, découvrez les Fonctionnalités et Modules du logiciel de gestion des organismes de formation" />
                <link rel="canonical" href={window.location.href} />

            </Helmet>
            <Header />
            <h1 style={{ fontSize: '0.1px', opacity: '0', height: '0.1px' }}> Fonctionnalites du logiciel de gestion des organismes de formation </h1>

            <div className="div_mobile_v3" >


                <div className="Background-header">

                </div>
                <div className="div_mobile_v3_debut">

                    <div className="Title">
                        <div className="Title_txt1">
                            A propos
                        </div>
                        <div className="Title_txt2">
                            Fonctionnalités & Modules
                        </div>

                    </div>


                    <div className="parent">
                        <div className="div1">
                            <div className="text_activite_head">
                                Gestion Administrative
                            </div>
                            <br />
                            <div className="image_activite_head">
                                <img src={gestion_admin_img} alt="MySy Training Gestion administrative" className="image_illustration" />
                            </div>

                            <button className="bton_en_savoir_plus_header" onClick={(e) => {
                                if (document.getElementById('gestion_administrative')) {
                                    var divh = document.getElementById('gestion_administrative').offsetTop;

                                    window.scrollTo({
                                        top: divh,
                                        behavior: "smooth",
                                    });
                                }

                            }}
                            > En savoir plus</button>
                        </div>

                        <div className="div2">
                            <div className="text_activite_head" id="lms">
                                E-Learning (LMS)
                            </div>
                            <div className="image_activite_head">
                                <img src={e_learning_lms} alt="MySy Training E-Learning LMS" className="image_illustration" />
                            </div>
                            <br />

                            <button className="bton_en_savoir_plus_header" onClick={(e) => {
                                if (document.getElementById('gestion_elearning')) {
                                    var divh = document.getElementById('gestion_elearning').offsetTop;

                                    window.scrollTo({
                                        top: divh,
                                        behavior: "smooth",
                                    });
                                }

                            }}> En savoir plus</button>
                        </div>

                        <div className="div3">

                            <div className="text_activite_head">
                                Relation Client (CRM)
                            </div>
                            <br />
                            <div className="image_activite_head">
                                <img src={mysy_crm} alt="MySy Training gestion relation client CRM " className="image_illustration" />
                            </div>

                            <button className="bton_en_savoir_plus_header" onClick={(e) => {
                                if (document.getElementById('gestion_crm')) {
                                    var divh = document.getElementById('gestion_crm').offsetTop;

                                    window.scrollTo({
                                        top: divh,
                                        behavior: "smooth",
                                    });
                                }

                            }}> En savoir plus</button>
                        </div>

                    </div>



                    <div className="fonctionalite_header">
                        Détail des modules applicatifs
                    </div>


                    <div id="gestion_administrative"> &nbsp; </div>
                    <div className="content_gestion_admin">

                        <div className="content_gestion_admin_img">
                            <div className="image_activite_detail">
                                <img src={gestion_admin_img} alt="MySy Training Gestion administrative" className="image_illustration" />
                            </div>
                        </div>

                        <div className="content_gestion_admin_txt" >
                            <div>
                                <div className="detail_content_text_head_1">
                                    GESTION
                                </div>

                                <div className="detail_content_text_head_2" >
                                    Gestion Administrative
                                </div>

                                <div className="detail_content_text_head_3">
                                    Permet de gérer le processus d’inscription des apprenants, les émargements à distance ou en présentiel et les évaluations. Cette solution permet également de gérer les enseignants, le matériel pédagogique, le planning de formation, les agendas, etc.
                                </div>

                                <div className="detail_content_text_head_3">
                                    Elle vous permet également de produire en un clic votre Bilan Pédagogique et Financier (BPF) Un intranet intégré permet de publier et de partager les formations sur internet.   </div>
                            </div>

                        </div>
                    </div>

                    {/*** detail gestion admin */}
                    <div className="div_row" style={{ border: "none" }} >
                        <div className="div_block_fonction">

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Catalogue formations personnalisé
                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_catalog} alt="Catalogue formations personnalisé" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Catalogue formations <br />
                                    Nom domaine personnalisé <br />
                                    Modèle siteweb personnalisé
                                </div>

                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Gestion des inscriptions

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_inscription} alt=" Gestion des inscriptions, Préinscription, Annulation avec motif" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Préinscription <br />
                                    Annulation avec motif <br />
                                    Import / export (excel et API)

                                </div>



                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Gestion des Émargements

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_emargement} alt=" Gestion des Émargements,  Par QR code, A distance par email, Signature main levée" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Par QR code<br />
                                    A distance par email <br />
                                    Signature main levée<br />
                                    En présentiel    <br />
                                </div>

                            </div>



                        </div>


                        {/*** Ligne 2 */}
                        <div className="div_block_fonction">

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Check-list QUALIOPI
                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_checklistqualiopi} alt="Check-list QUALIOPI" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Traitement automatisé <br />
                                    Affichage du reste à faire <br />
                                    Affichage ce qui a été fait
                                </div>
                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Gestion avis participants

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_avisparticipants} alt="Gestion avis participants" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Avis sur la formation <br />
                                    Avis sur le formateur  <br />
                                    Génération automatique des statistiques de satisfaction
                                </div>

                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Gestion Evaluation

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_evaluations} alt="Gestion Evaluation" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Questionnaire Positionnement <br />
                                    Evaluation à chaud <br />
                                    Evaluation à froid <br />
                                    Evaluation formateur
                                </div>

                            </div>



                        </div>

                        {/*** Ligne 3 */}
                        <div className="div_block_fonction">

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Stockage des documents
                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_stockdocuments} alt="Stockage des documents" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Documents client (factures, devis, etc) <br />
                                    Documents apprenant (convention, convocation, etc) <br />
                                    Documents formateur (diplomes, certificats, etc)
                                </div>
                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Personnalisation et édition documents

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_personnalisationdoc} alt="Personnalisation et édition automatique des documents " className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Personnalisation de tous les documents <br />
                                    Utilisation de tous les champs en base  <br />
                                    Gestion des versions et restoration
                                </div>

                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Gestion des Sessions de formation

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_planifsession} alt="Gestion des Sessions de formation" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Session inter / intra <br />
                                    Personnalisation des étapes <br />
                                    Affectation devis / ressources (matérielles et humaines)


                                </div>

                            </div>



                        </div>

                        {/*** Ligne 4 */}
                        <div className="div_block_fonction">

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Gestion des ressources
                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_materiel} alt="Gestion des ressources" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Salle de classe <br />
                                    Formateur & personnel administratif <br />
                                    Matériel pédagogique
                                </div>
                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Gestion formateurs

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_enseignants} alt="Gestion formateurs" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Contrat formateurs (internes/vacataires) <br />
                                    Edition et stockage des contrats<br />
                                    Compétence, Accès au système

                                </div>

                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Tableau de bord & BPF

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_tableaudebord} alt="Tableau de bord & BPF" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Suivi d’activité<br />
                                    BPF<br />
                                    &nbsp;


                                </div>

                            </div>



                        </div>

                    </div>

                    <div className="div_row" style={{ border: "none" }} >
                        <hr className="hr_break" />
                    </div>

                    <div id="gestion_crm"> &nbsp; </div>
                    <div className="content_gestion_admin">

                        <div className="content_gestion_admin_img">
                            <div className="image_activite_detail">
                                <img src={mysy_crm} alt="MySy Training gestion relation client CRM " className="image_illustration" />
                            </div>
                        </div>

                        <div className="content_gestion_admin_txt" >
                            <div>
                                <div className="detail_content_text_head_1">
                                    RELATION CLIENT (CRM)
                                </div>

                                <div className="detail_content_text_head_2">
                                    Gestion Relation Client
                                </div>

                                <div className="detail_content_text_head_3">
                                    Permet de gérer vos clients, les activités en cours, à venir, en retards. Ce module permet d’éditer, d’envoyer les devis, de les convertir en commande et de les facturer.    </div>

                                <div className="detail_content_text_head_3">
                                    Un tableau de bord vous permet de suivre vos revenus en cours, ceux à venir et de prendre les bonnes décisions de gestion
                                </div>
                            </div>

                        </div>
                    </div>
                    {/*** detail crm */}
                    <div className="div_row" style={{ border: "none" }} >
                        <div className="div_block_fonction">

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Signature électronique
                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_catalog} alt="Signature électronique" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Un module intégrée <br />
                                    Permet de signer vos documents <br />

                                </div>

                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Gestion des collaborateurs

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_collaborateur} alt=" Gestion des collaborateurs" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Affectation tâches <br />
                                    Suivi avancement<br />
                                    Notification par email

                                </div>



                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Les Devis

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_devis} alt=" Les Devis " className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Envoi par email <br />
                                    Validation automatique client
                                    <br />
                                    Relance automatique

                                </div>

                            </div>

                        </div>

                        {/** ligne 2 */}
                        <div className="div_block_fonction">

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Gestion Clients, Financeurs
                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_client} alt="Gestion Clients, Financeurs" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Contacts <br />
                                    Stockage documents  <br />
                                    Import / Export Excel

                                </div>

                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Paiements

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_paiement} alt=" Gestion des Paiements" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Multi paiement <br />
                                    Import / Export Excel  <br />
                                    Suivi du reste à payer


                                </div>



                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Facturation et Avoir

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_facture} alt=" Facturation et Avoir " className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Envoi par email au client <br />
                                    Fractionnement des factures <br />
                                    Transformation en avoir et Annotation
                                </div>

                            </div>

                        </div>

                        {/** ligne 3 */}
                        <div className="div_block_fonction">

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    opportunités d’affaire
                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_opportunite} alt="opportunités d’affaire" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Personnalisation des étapes <br />
                                    Notification équipe <br />
                                    Conversion en devis


                                </div>

                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Tableau de bord

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_tableaudebord} alt="Tableau de bord" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    Suivi clients <br />
                                    Suivi facturation <br />
                                    Factures prévisionnelles


                                </div>



                            </div>



                        </div>



                    </div>

                    <div id="gestion_elearning"> &nbsp; </div>
                    <div className="content_gestion_elearning" >

                        <div className="content_gestion_elearning_img">
                            <div className="image_activite_detail">
                                <img src={e_learning_lms} alt="MySy Training E-Learning LMS" style={{ "width": "60%" }} className="image_illustration" />
                            </div>
                        </div>

                        <div className="content_gestion_elearning_txt" >
                            <div>
                                <div className="detail_content_text_head_1">
                                    E-LEARNING
                                </div>

                                <div className="detail_content_text_head_2" >
                                    E-Learning (LMS)
                                </div>

                                <div className="detail_content_text_head_3_gauche">
                                    Permet d’avoir votre espace de E-learning, de publier les formations en ligne. A l’aide de son login et mot de passe, chaque apprenant peut suivre la formation en ligne, interagir avec l’enseignant (email, réseaux social).    </div>

                                <div className="detail_content_text_head_3_gauche">
                                    L’enseignant peut élaborer des travaux dirigés, suivre le temps de réponses des apprenants, corriger les TD.Un tableau de bord permet de suivre de manière détaillée chaque apprenant.  </div>
                            </div>

                        </div>
                    </div>

                    {/*** detail lms - e-learning */}
                    <div className="div_row" style={{ border: "none" }} >
                        <div className="div_block_fonction">

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Classe virtuelle
                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_classevirtuelle} alt="Classe virtuelle" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    &nbsp;

                                </div>

                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Cours – TD – TP - Examen

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_courstdtpexamen} alt=" Cours – TD – TP - Examen" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    &nbsp;

                                </div>



                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Intranet Apprenant & Enseignant
                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_intranetapprenant} alt="Intranet Apprenant & Enseignant" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    &nbsp;

                                </div>

                            </div>

                        </div>

                        {/** ligne 2 */}
                        <div className="div_block_fonction">

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Suivi temps de connexion apprenants
                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_suivitempsapprenant} alt="Suivi temps de connexion apprenants" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    &nbsp;

                                </div>

                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Echange Enseignant / Apprenant

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_echangeprofapprenant} alt="Echange Enseignant / Apprenant " className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    &nbsp;

                                </div>


                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Export / Import format SCORM

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_exportscrom} alt=" Export / Import format SCORM " className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    &nbsp;

                                </div>

                            </div>

                        </div>

                        {/** ligne 3 */}
                        <div className="div_block_fonction">

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Partage de fichiers
                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_partagefichier} alt=" Partage de fichiers" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    &nbsp;

                                </div>

                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Tableau de bord / Statistiques

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_tableaudebord} alt="Tableau de bord / Statistiques " className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    &nbsp;

                                </div>

                            </div>

                            <div className="div_block_fonction_detail">
                                <div className="div_block_fonction_detail_titre">
                                    Social Learning

                                </div>

                                <div className="div_block_fonction_detail_img">
                                    <img src={img_sociallearning} alt="Social Learning" className="div_block_fonction_detail_img_inside" />
                                </div>

                                <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                    &nbsp;

                                </div>

                            </div>





                        </div>

                    </div>


                </div>


                {/*
                <div className="fonctionalite_header">
                    Pour en bénéficier ?
                </div>
                <div className="parent">

                    <div className="div1">
                        <div className="div_block_fonction_detail_titre">
                            Pack Indépendant
                            <hr className="hr_break" />
                        </div>

                        <div className="div_block_fonction_detail_img">
                            <img src={pack_independant} alt="pack indépendant : Destiné aux organismes de formation qui démarrent et qui moins de 25 sessions de formation par an. Nous automatisons Qualiopi. L’ensemble de nos options : signature électronique, Gestion avis participants, Les émargements sécurisés, les contrats des formateurs, la gestion des financeurs sont inclus dans nos tarifs de base. Le bilan pédagogique et financier (PBF) est généré automatiquement" className="div_block_fonction_detail_img_inside" />
                            <nav className="div_block_prix" > 150 € HT /mois </nav>
                        </div>

                        <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                            <ul className="div_ul_detail_pack">
                                <li> <b> Jusqu'à 25 Sessions / an </b></li>
                                <li> Sans engagement</li>
                                <li> 2 Utilisateurs</li>

                            </ul>
                        </div>

                        <div style={{ width: '100%' }}>
                            <Button variant="contained"

                                className="btn_abonnement_v2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = "/Partner";
                                }}
                            > Je m'abonne  </Button>
                        </div>

                    </div>

                    <div className="div2" >
                        <div className="div_block_fonction_detail_titre">
                            Pack Standard
                            <hr className="hr_break" />
                        </div>

                        <div className="div_block_fonction_detail_img">
                            <img src={pack_standart} alt="pack standard : Destiné aux organismes de formation qui ont entre 26 et 200 sessions de formation par an. Nous automatisons Qualiopi. L’ensemble de nos options : signature électronique, Gestion avis participants, Les émargements sécurisés, les contrats des formateurs, la gestion des financeurs sont inclus dans nos tarifs de base. Le bilan pédagogique et financier (PBF) est généré automatiquement" className="div_block_fonction_detail_img_inside" />
                            <nav className="div_block_prix"> 299 € HT/mois </nav>
                        </div>

                        <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                            <ul className="div_ul_detail_pack">
                                <li> <b> De 26 à 200 Sessions / an </b> </li>
                                <li> Sans engagement </li>
                                <li> Utilisateurs illimités </li>
                            </ul>
                        </div>



                        <div style={{ width: '100%' }}>
                            <Button variant="contained"

                                className="btn_abonnement_v2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = "/Partner";
                                }}
                            > Je m'abonne  </Button>
                        </div>
                    </div>

                    <div className="div3">
                        <div className="div_block_fonction_detail_titre">
                            Pack Gold
                            <hr className="hr_break" />
                        </div>

                        <div className="div_block_fonction_detail_img">
                            <img src={pack_gold} alt="pack Gold : Destiné aux organismes de formation qui ont plus de 200 sessions de formation par an. Nous automatisons Qualiopi. L’ensemble de nos options : signature électronique, Gestion avis participants, Les émargements sécurisés, les contrats des formateurs, la gestion des financeurs sont inclus dans nos tarifs de base. Le bilan pédagogique et financier (PBF) est généré automatiquement" className="div_block_fonction_detail_img_inside" />
                            <nav className="div_block_prix"> 599 € HT/mois </nav>
                        </div>

                        <div className="div_block_fonction_detail_desc detail_content_text_head_3">

                            <ul className="div_ul_detail_pack">
                                <li><b> A partir de 201 Sessions / an </b> </li>
                                <li>Sans engagement </li>
                                <li>Utilisateurs illimités </li>
                            </ul>
                        </div>

                        <div style={{ width: '100%' }}>
                            <Button variant="contained"

                                className="btn_abonnement_v2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = "/Partner";
                                }}
                            > Je m'abonne  </Button>
                        </div>
                    </div>

                </div>
*/}



                <div className="fonctionalite_header">
                    MySy Training Technology
                    Fonctionnalités
                </div>

                <div className="parent">
                    <div className="div1">
                        <br />

                        <div className="text_activite_head" id="gestion_administrative">
                            Gestion Administrative
                        </div>


                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Gestion catalogue formations" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion catalogue formations (intranet / API) </nav>
                        </div>


                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Gestion catalogue formations" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des inscriptions (mise en attente, motif annulation, etc) </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Gestion automatique et manuelle des séquences de formations" /></nav>
                            <nav className="tick_fonctionalite_txt">Gestion automatique et manuelle des séquences de formations </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Gestion avis participants" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion avis participants</nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt=" Gestion absences" /></nav>
                            <nav className="tick_fonctionalite_txt">  Gestion absences</nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Émargement électronique" /></nav>
                            <nav className="tick_fonctionalite_txt"> Émargement électronique</nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Gestion des employés et du matériel pédagogique" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des employés et du matériel pédagogique</nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Gestion des agendas & réservation de matériel (salles, projecteurs, etc)" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des agendas & réservation de matériel (salles, projecteurs, etc)</nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Tableau de bord de pilotage" /></nav>
                            <nav className="tick_fonctionalite_txt"> Tableau de bord de pilotage</nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Intranet intégré pour la publication des formations" /></nav>
                            <nav className="tick_fonctionalite_txt"> Intranet intégré pour la publication des formations</nav>
                        </div>


                    </div>

                    <div className="div2">


                        <br />
                        <div className="text_activite_head">
                            E-Learning (LMS)
                        </div>
                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Hébergement des formations" /></nav>
                            <nav className="tick_fonctionalite_txt"> Hébergement des formations </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Classe virtuelle" /></nav>
                            <nav className="tick_fonctionalite_txt"> Classe virtuelle </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Gestion des prérequis" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des prérequis </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Echange enseignants / apprenants" /></nav>
                            <nav className="tick_fonctionalite_txt"> Echange enseignants / apprenants </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Cours en ligne avec exercices" /></nav>
                            <nav className="tick_fonctionalite_txt"> Cours en ligne avec exercices </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Evaluations (notes)" /></nav>
                            <nav className="tick_fonctionalite_txt"> Evaluations (notes) </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Gestion planning" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion planning </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Forums de discussion" /></nav>
                            <nav className="tick_fonctionalite_txt"> Forums de discussion </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Réseau Social" /></nav>
                            <nav className="tick_fonctionalite_txt"> Réseau Social </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Profil et droit d'accès par utilisateur" /></nav>
                            <nav className="tick_fonctionalite_txt"> Profil et droit d'accès par utilisateur </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Export format SCORM" /></nav>
                            <nav className="tick_fonctionalite_txt"> Export format SCORM </nav>
                        </div>
                    </div>

                    <div className="div3">
                        <br />

                        <div className="text_activite_head" id="crm">
                            Gestion Relation Client
                        </div>
                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Création clients" /></nav>
                            <nav className="tick_fonctionalite_txt"> Création clients </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Gestion des différentes contacts client" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des différentes contacts client </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Gestion des activités par collaborateur (tâches en cours, en retards, à venir)" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des activités par collaborateur (tâches en cours, en retards, à venir) </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Gestion des devis (envoi automatique)" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des devis (envoi automatique) </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Validation automatique des devis" /></nav>
                            <nav className="tick_fonctionalite_txt"> Validation automatique des devis </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Conversion devis en commandes" /></nav>
                            <nav className="tick_fonctionalite_txt"> Conversion devis en commandes </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Facturation et envoi automatique" /></nav>
                            <nav className="tick_fonctionalite_txt"> Facturation et envoi automatique </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Suivi des commandes en cours, des factures à venir" /></nav>
                            <nav className="tick_fonctionalite_txt"> Suivi des commandes en cours, des factures à venir </nav>
                        </div>


                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Tableau de bord de suivi client" /></nav>
                            <nav className="tick_fonctionalite_txt"> Tableau de bord de suivi client </nav>
                        </div>
                    </div>

                </div>

                <div className="fonctionalite_header">
                    Découvrez nos fonctionnalités
                </div>
                <div className="pave_video">

                    <div className="pave_video_gauche">
                        <div className="video_titre">
                            Le catalogue de formation
                        </div>




                    </div>

                    <div className="pave_video_droite">
                        <div className="video_mp4">
                            <div className="only_mobile">
                                <div className="video_titre">
                                    Le catalogue de formation
                                </div>


                            </div>

                            <ReactPlayer
                                controls={true}
                                light="https://img.mysy-training.com/video/mysy_catalog_thumb.png"
                                playing
                                url='https://img.mysy-training.com/video/MySy_Creation_Catalogue.mp4'
                                width="100%"
                            />
                        </div>
                    </div>


                </div>
                <div className="pave_video">

                    <div className="pave_video_gauche">
                        <div className="video_titre">
                            Les sessions  de formation
                        </div>




                    </div>

                    <div className="pave_video_droite">
                        <div className="video_mp4">

                            <div className="only_mobile">
                                <div className="video_sous_titre">
                                    <div className="video_titre">
                                        Les sessions  de formation
                                    </div>

                                </div>
                            </div>
                            <ReactPlayer
                                controls={true}
                                light="https://img.mysy-training.com/video/video_session/mysy_session_thumb.png"
                                playing
                                url='https://img.mysy-training.com/video/video_session/MySy_Creation_Session.mp4'
                                width="100%"
                            />
                        </div>
                    </div>


                </div>
                <div className="pave_video">

                    <div className="pave_video_gauche">
                        <div className="video_titre">
                            Les séquences de Formation
                        </div>



                    </div>

                    <div className="pave_video_droite">
                        <div className="video_mp4">

                            <div className="only_mobile">
                                <div className="video_titre">
                                    Les séquences de Formation
                                </div>

                            </div>
                            <ReactPlayer
                                controls={true}
                                light="https://img.mysy-training.com/video/video_sequence/mysy_sequence_thumb.png"
                                playing
                                url='https://img.mysy-training.com/video/video_sequence/MySy_Creation_Sequences.mp4'
                                width="100%"
                            />
                        </div>
                    </div>


                </div>

                {/*<div className="div_row_where">

                    <div className="div_carte">
                        <a target="_blank"
                            href="https://www.google.com/maps/place/MySy+Training+Technology/@48.8761591,2.3260624,15z/data=!4m5!3m4!1s0x0:0x8239ba23406c9699!8m2!3d48.8761591!4d2.3260624">
                            <img src={ousommesnousimg} alt="logo MySy Technology ou sommes nous" style={{ 'width': "100%", 'height': '50%' }} />
                        </a>


                    </div>

                    <div className="div_adresse_new" >
                        <br />

                        <ButtonMailto label="CONTACTEZ NOUS" mailto="mailto:contact@mysy-training.com" className="contactez_nous" />

                        <div className="contactez_nous_titre">MySy Training Technology</div>

                        <div className="contactez_nous_info">

                            Adresse : 1 Cr du Havre, 75008 Paris <br />
                            Adresse email :
                            <a href="mailto: contact@mysy-training.com?subject=Mail from mysy website" target="_blank"> contact@mysy-training.com</a> <br />

                            Téléphone fixe : +331 77 00 38 57<br />
                            Téléphone : +337 69 20 39 45<br />

                        </div>
                        <br />
                        <div> Sur nos reseaux sociaux

                            <div style={{ 'float': "center", "textAlign": "center" }}>
                                <div className="media_sociaux">
                                    <FacebookShareButton
                                        url={"https://www.facebook.com/MySy-Training-114572794561371/"}
                                    >
                                        <SocialIcon network="facebook" className="SocialIcon" />
                                    </FacebookShareButton>
                                    &nbsp;

                                    <TwitterShareButton
                                        url={"https://twitter.com/mysy_training"}
                                        hashtag={"#MySyTraining"}
                                    >
                                        <SocialIcon network="twitter" className="SocialIcon" />
                                    </TwitterShareButton>

                                    &nbsp;
                                    <LinkedinShareButton
                                        url={"https://www.linkedin.com/"}
                                        summary={"ee"}
                                        source={"https://www.linkedin.com/in/mysy-training-96b53a237/"}

                                    >
                                        <SocialIcon network="linkedin" className="SocialIcon" />
                                    </LinkedinShareButton>


                                </div>

                            </div>


                        </div>
                    </div>
                </div>*/}


                <div className="pieddepage">
                    <Footer />
                </div>

            </div>

        </div>

    )
}


export default Fonctionnalites_V1;
