import Link from "next/link";
import Navigation from "../components/Navigation";
import MySyIA from "../components/Module_IA";
import { Helmet } from "react-helmet";
import Header from "./../components/Header";
import Fotter_v3 from "./../components/Fotter_v3";


export default function Login() {
    return (
        <div style={{ width: "100%", float: "left" }}>
            <div className="moduleia">
                <div>
                    <Navigation />

                </div>

                <Helmet>
                    <title>intelligence artificielle MySy Training</title>
                    <meta name="description" content="Nous sommes une base de données uniquement dediée aux formations et tutoriels" />
                    <meta name="robots" content="noindex, nofollow" />
                    <meta name="title" content="intelligence artificielle MySy Training" />
                    <link rel="canonical" href={window.location.href} />

                </Helmet>
                <Header />
                <div className="div_mobile">

                    <div className="div_row block_entete" >

                        <div className="Background-header">

                            <h1 className="besoin_aide">  
                                L’assistance d’intelligence artificiel de <br />
                            MySy Training Technology</h1>


                        </div>


                    </div>

                    <div className="block_centrer">
                        <div className="display_screen_600_901">
                            <div>
                                <MySyIA />

                            </div>
                        </div>
                    </div>
                </div>



            </div>

            <div style={{ height: "5rem", width: "100%" }}>
                <Fotter_v3 />
            </div>

        </div>
    );
}
