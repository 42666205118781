import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom"
import './App.css';
import NotFound from './pages/NotFound'
import Contact from './pages/Contact'
import Connexion from './pages/Connexion'
import Login from './pages/Login';

import Recherche_new_v2 from "./pages/recherche_new_v2";
import Recherche_new_v3 from "./pages/recherche_new_v3";
import Recherche_new_v4 from "./pages/recherche_new_v4";

import Mon_Catalogue_Public from "./pages/mon_catalogue_public";


import TestUrl from "./pages/testurl";
import Account from "./pages/account";

import DisplayDetailClass_new_v2 from "./pages/displaydetailclass_new_v2";
import DisplayDetailClass_new_v3 from "./pages/displaydetailclass_new_v3";

import Display_Partner_Catalog_DetailClass_new_v2 from "./pages/display_partner_catalog_detailclass_new_v2"

import DisplayDetailArticle from "./pages/displaydetailarticle";
import AddArticleAvis from "./pages/addarticleavis";
import Acceuil from "./pages/acceuil";
import Partner from "./pages/partner";
import CreateAccount from "./pages/createaccount";
import PartnerLogin from "./pages/partnerlogin";
import PartnerLogin0 from "./pages/partnerlogin0";
import PartnerLogin1 from "./pages/partnerlogin1";

import RechecheArticles from "./pages/recherchearticles";
import AddArticle from "./pages/addarticle";
import ResetUserPwd from "./pages/resetuserpwd";
import DeleteUserAccount from "./pages/deleteuseraccount";

import ContactezNous from "./pages/contactez_nous";
import CoupDeCoeur from "./pages/coupdecoeur";
import AccountUserActivated from "./pages/accountuseractivated";
import ProduitsService from "./pages/produitservice";
 
import Recherche_new from "./pages/recherche_new";
import Recherche_old from "./pages/recherche";
import UserSignFirst from "./pages/usersigninfirst";
import GestionAdministrative from "./components/GestionAdministrative";
import Emargement from "./pages/emargementOk";
import Formation_Cartouche_Com from "./pages/recherche_cartouche_com";
import SeDesabonner from './pages/sedesabonner';
import OrderConfirmation_3DS from "./pages/orderconfirmation_3DS";
import Training_Inactive_Or_Not_Found from "./pages/Traning_Inactif_Or_Not_Exist";
import TestUrl_New from "./pages/testurl_new";
import QuiSommesNous_v3 from "./pages/quisommesnous_v3";
import Fonctionnalites from "./pages/fonctionnalite";
import Test_Drag_Drop from "./pages/test_drag_drop";

import Test_Drag_Drop2 from "./pages/test_dnd";

import ESign from "./pages/eSigne";
import ESignDiplayDignedDocument from "./pages/edigndiplaydigneddocument";
import SurveyDocument from "./pages/surveydocument";
import UpdateAttendeeList from "./pages/updateattendeelist";
import Emarge_QR_Code from "./pages/emarge_qr_code";
import AutomaticQuotation from "./pages/automaticquotation";

import MySyIA from "./pages/iamysy";

import Maintenance from "./pages/maintenance_page";

import Subdomain_Recherche_new_v2 from "./pages/subdomain_recherche_new_v2";

function App() {

  const [screenSizeh, getDimension] = useState({
    dynamicWidth: window.innerHeight
  });


  const getSubdomain = url => {
    let domain = url;
    if (url.includes("://")) {
      domain = url.split('://')[1];
    }
    const subdomain = domain.split('.')[0];
    return subdomain;
  };


  useEffect(() => {

    let myurl = window.location.host;
    let myurl_pathname = window.location.pathname;

    //console.log(" url 12 = ", myurl);

    //console.log(" action 12 = ", window.location.pathname);

    var domain = myurl;
    if (domain.includes('://')) {
      domain = myurl.split('://')[1];
    }

    var tab_split = domain.split('.');

    const subdomain = domain.split('.')[0];

    var tab_reserved_subdomain = ['/dev', '/demo', 'demo', 'dev'];

    if (tab_split.length > 1 && myurl_pathname && !tab_reserved_subdomain.includes(subdomain)) {

      var tab_myurl_pathname = myurl_pathname.split('/');

      var tab_action = ['/moncatalog', '/Display-Partner-Catalog-Detail-formation', 'Display-Partner-Catalog-Detail-formation', 'moncatalog'];



      if (tab_myurl_pathname.length > 0 && !tab_action.includes(tab_myurl_pathname[1]) && !tab_reserved_subdomain.includes(subdomain)) {
      
        var new_url = window.location.protocol + "//" + domain.split('.')[0] + "." + domain.split('.')[1] + "/moncatalog";
      

        /*
        window.open(
          new_url,
          '_self'
        );
        */

      }


    } 


  }, []);


  return (
    <div>
      <div className="App">
        {/*API DEV = {process.env.REACT_APP_API_URL_DEV} <br/>
    API REC = {process.env.REACT_APP_API_URL_REC}<br/>
    API URL = {process.env.REACT_APP_API_URL}<br/>
  */}


        <div className="main_haut">
          <BrowserRouter>
            <scrollToTop />
            <Switch>

              <Route path="/" exact component={QuiSommesNous_v3} />

              <Route path="/v3" exact component={Recherche_new_v3} />
              <Route path="/v4" exact component={Recherche_new_v4} />


              <Route path="/subdomain" exact component={Subdomain_Recherche_new_v2} />

              <Route path="/moncatalog" exact component={Mon_Catalogue_Public} />


              <Route path="/acceuil" exact component={Acceuil} />

              <Route path="/maintenance" exact component={Maintenance} />

              <Route path="/fonctionnalites" exact component={Fonctionnalites} />
              <Route path="/markeplace" exact component={Contact} />



              <Route path="/contact" exact component={Contact} />
              <Route path="/mysy-user-account" exact component={Account} />
              <Route path="/create_account" exact component={CreateAccount} />
              <Route path="/user-connexion" exact component={Connexion} />
              <Route path="/Connexion" exact component={Login} />
              <Route path="/recherche-formation" exact component={Recherche_new_v2} />
              <Route path="/recherche-formation/ref/:NewSearchText" exact component={Recherche_new_v2} />
              <Route path="/testurl/:course/:tab" exact component={TestUrl} />
              <Route path="/testurl_new/:courseid/:tabid" exact component={TestUrl_New} />
              
              <Route path="/ajout-article" exact component={AddArticleAvis} />

              <Route path="/Display-Detail-formation_v3/:classId" exact component={DisplayDetailClass_new_v3} />
              <Route path="/Display-Detail-formation/:classId" exact component={DisplayDetailClass_new_v3} />
             
              <Route path="/Display-Detail-formation/:classId/:action" exact component={DisplayDetailClass_new_v3} />
              <Route path="/Display-Detail-formation_old/:classId/:action" exact component={DisplayDetailClass_new_v3} />
              <Route path="/Display-Detail-Article/:articleId" exact component={DisplayDetailArticle} />
              <Route path="/Display-Detail-formation-Coeur/:classId" exact component={CoupDeCoeur} />
              <Route path="/Partner/:action/:orderid/:packs" exact component={Partner} />

              <Route path="/Display-Partner-Catalog-Detail-formation/:classId" exact component={Display_Partner_Catalog_DetailClass_new_v2} />
              <Route path="/Display-Partner-Catalog-Detail-formation/:classId/:action" exact component={Display_Partner_Catalog_DetailClass_new_v2} />


              <Route path="/Partner/:action/:orderid" exact component={Partner} />


              <Route path="/Partner/:action" exact component={Partner} />
              <Route path="/Partner" exact component={Partner} />
              <Route path="/PartnerLogin" exact component={PartnerLogin} />
              <Route path="/PartnerLogin0" exact component={PartnerLogin0} />
              <Route path="/PartnerLogin1" exact component={PartnerLogin1} />
              <Route path="/Recherche-Article-formation" exact component={RechecheArticles} />
              <Route path="/Ajout-Article-formation" exact component={AddArticle} />
              <Route path="/ResetUserPwd/:token/:accounttype" exact component={ResetUserPwd} />
              <Route path="/DeleteUserAccount/:token" exact component={DeleteUserAccount} />
              <Route path="/QuiSommesNous" exact component={QuiSommesNous_v3} />

              <Route path="/qui-sommes-nous" exact component={QuiSommesNous_v3} />

              <Route path="/QuiSommesNous_v3" exact component={QuiSommesNous_v3} />
              <Route path="/ContactezNous" exact component={ContactezNous} />
              <Route path="/mysy-training-login/:obj/:objid" exact component={Login} />
              <Route path="/mysy-account-activated/" exact component={AccountUserActivated} />
             {/* <Route path="/Produits-Services/" exact component={ProduitsService} />*/}
              <Route path="/tarifs_produits_services/" exact component={ProduitsService} />
              <Route path="/Recherche_new/" exact component={Recherche_new} />
              <Route path="/GestionAdministrative/:internal_url" exact component={GestionAdministrative} />
              <Route path="/Recherche_new/:type/:categorie" exact component={Recherche_new} />
              <Route path="/Recherche_old/" exact component={Recherche_old} />
              <Route path="/Connexion-utilisateur-0/" exact component={UserSignFirst} />
              <Route path="/Emargement/" exact component={Emargement} />
              <Route path="/CartoucheComMySy/" exact component={Formation_Cartouche_Com} />
              <Route path="/Test_DragDrop/" exact component={Test_Drag_Drop} />
              <Route path="/Test_DragDrop2/" exact component={Test_Drag_Drop2} />
              <Route path="/Recherche_new_v2/" exact component={Recherche_new_v2} />
              <Route path="/Recherche_new_v2/:type/:categorie" exact component={Recherche_new_v2} />
              <Route path="/unsubscribe/" exact component={SeDesabonner} />
              <Route path="/OrderConfirmation_3DS/:orderid/:packs" exact component={OrderConfirmation_3DS} />
              <Route path="/page-inconnue/" exact component={NotFound} />
              <Route path="/formation-inconnue/" exact component={Training_Inactive_Or_Not_Found} />

              <Route path="/qr_emargement/:session_id/:partner_owner_recid/:my_safe_token" exact component={Emarge_QR_Code} />

              <Route path="/E_Signature/:document_id" exact component={ESign} />
              <Route path="/E_Document/:document_id/:partner_owner_recid/:secret_key_signature" exact component={ESignDiplayDignedDocument} />

              <Route path="/Survey/:survey_id/" exact component={SurveyDocument} />
              <Route path="/UpadateAttendeeList/:client_id/:session_id/:partner_owner_recid/" exact component={UpdateAttendeeList} />

              <Route path="/automatic-quotation/:partner_owner_recid/:objid/" exact component={AutomaticQuotation} />

              <Route path="/intelligence-artificielle-mysy-training/" exact component={MySyIA} />


              <Route component={NotFound} />
            </Switch>
          </BrowserRouter>
        </div>

      </div>


    </div>

  );
}


export default App;
